<template>
    <div class="user-info">
        <div class="collect-head">
            <el-button type="primary" @click="back">返回</el-button>
        </div>
        <div class="msg-result" v-loading="loading">
            <el-result 
                icon="success"
            >
            </el-result>
            <div class="result">
                <div class="result-title">数据汇交证明审核通过</div>
                <div class="result-content">尊敬的TEST01，您在2022年6月10日申请的数据汇交证明，已审核通过文本内容文本内容文本内容文本内容文本内容文本内容，请点击下方文件名下载汇交证明。</div>
                <a href="javascript:void(0)" class="primary" target="_blank">附件：数据汇交证明20220610.word</a>
            </div>
        </div>
    </div>
</template>

<script>
import {ElResult, ElButton, ElIcon} from 'element-plus';
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    components: {ElResult, ElButton},
    setup(){
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1);
        const loading = ref(false);
   

        function loadData(){
            loading.value = true;
            const url = '';
            proxy.$http.post(url, {limit: 10, offset: (currentPage.value-1)*10})
            .then(res=>{
                loading.value = false
                if(res.data.state == 0){
                    tablePager.value = res.data.result;
                }
            }).catch(err=>{
                loading.value = false
            })
        }



        onMounted(()=>{
            // loadData();
        })

        return {loading}
    },
    methods:{
        back(){
            this.$router.back();
        }
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';
.result{
    margin-left: 24px;
}
.msg-result{
    font-size: 14px;
    line-height: 14px;
    display: flex;
    .result-title{
        font-family: SourceHanSansCN-Medium;
        color: rgba(0,0,0,0.85);
    }
    .result-content{
        font-family: SourceHanSansCN-Regular;
        color: rgba(0,0,0,0.50);
        margin-top: 12px;
        margin-bottom: 24px;
    }
}
</style>

