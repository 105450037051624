<template>
    <div class="content">
        <Bread :breads="breads"></Bread>
        <div class="news-content" v-loading="loading">
<!--            <div class="title">{{newsInfo.adTitle}}</div>-->
<!--            <div class="info">-->
<!--                <span class="date">时间：{{newsInfo.adDate}}</span>-->
<!--                <span class="source">来源：{{newsInfo.source}}</span>-->
<!--                <span class="count">浏览量：{{newsInfo.views}}</span>-->
<!--            </div>-->
            
            <div class="desc" id="desc">
                <p></p><p><strong><span style="font-family: 仿宋_GB2312;font-size: 21px">工商联支持</span></strong></p><p style="margin-top: 0;margin-right: 0;margin-bottom: 12px;text-indent: 43px;padding: 0;line-height: 30px;background: rgb(255, 255, 255)"><span style=";font-family:仿宋_GB2312;font-size:21px"><span style="font-family:Times New Roman">2021</span><span style="font-family:仿宋_GB2312">年</span><span style="font-family:Times New Roman">10</span><span style="font-family:仿宋_GB2312">月，全国工商联发布《支持广州民科园改革创新发展十条措施》，授予全国首家“全国工商联民营企业科技创新示范基地”，建立四级协调联动支持民科园改革创新工作机制。</span></span></p><p><img src=" /hf/base/a/fileManage/getImage?attachCode=1594364968705302530" title="黄荣副主席1.jpg" alt="黄荣副主席1.jpg" width="500" height="333"/><img src=" /hf/base/a/fileManage/getImage?attachCode=1594365097432686593" title="示范基地.jpg" alt="示范基地.jpg" width="500" height="333"/></p><p style="line-height: 16px;"><img src="https://www.gdkjzy.net/hfapplication/common/tools/ueditor/dialogs/attachment/fileTypeImages/icon_txt.gif"/><a style="font-size:12px; color:#0066cc;" href="/hf/base/a/fileManage/getImage?attachCode=1594365237971230721" title="示范基地全联批复.pdf">示范基地全联批复.pdf</a></p><p style="line-height: 16px;"><img src="https://www.gdkjzy.net/hfapplication/common/tools/ueditor/dialogs/attachment/fileTypeImages/icon_txt.gif"/><a style="font-size:12px; color:#0066cc;" href="/hf/base/a/fileManage/getImage?attachCode=1594365285593358338" title="全联十条.pdf">全联十条.pdf</a></p><p><br/></p><p></p>
            </div>
        </div>
    </div>
</template>

<script>
import Bread from '../../../components/common/Bread.vue'
import { useRoute} from 'vue-router'
import { ref, onMounted, getCurrentInstance} from 'vue'
export default {
    data(){
        return {
            breads:[
                {
                    text: '特色专区',
                    name: 'SpecialIndustry',
                    path: '/specialIndustry'
                },
                {
                    text: '产业专区',
                    name: 'Industry',
                    path: '/industry'
                },{
                    text: '工商联支持',
                }
            ],
            active: 0
        }
    },
    setup(){
        const route = useRoute();
        const params = route.params;
        const query = route.query;
        const {proxy} = getCurrentInstance();
        const loading = ref(false)
        const newsInfo = ref({});
        const type = ref(query.type);
        const name = ref(query.name);
        const path = ref(query.path);

        function increase(){
            const url = '/hfapplication/gdsti/a/adInfo/addViews';
            proxy.$http.post(url, {id: query.id}).then(res=>{}).catch(err=>{})
        }

        onMounted(()=>{

        })

        return {
            newsInfo, loading
        }
    },
    components: {Bread},
    mounted(){
    //     let script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.src = '/static/ueditor/ueditor.parse.min.js'
    //     document.body.appendChild(script);
    //     // console.log('mounted')
    //     uParse('#desc', {
    //         rootPath: "/static/ueditor";
    //     })
    }
 
}
</script>

<style lang="less" scoped>
.news-content{
    margin-top: 12px;
    margin-bottom: 60px;
    width: 1200px;
    overflow: auto;
    display: flex;
    justify-content: center;
    img{
        max-width: 1000px;
    }
    .title{
        margin-top: 14px;
        font-family: SourceHanSansCN-Medium;
        font-size: 26px;
        color: rgba(0,0,0,0.85);
        text-align: center;
    }
    .info{
        font-family: SourceHanSansCN-Normal;
        font-size: 14px;
        color: rgba(0,0,0,0.50);
        margin-top: 29px;
        padding-bottom: 7px;
        border-bottom: 1px solid #E3E3E3;
        .date{
            margin-right: 27px;
        }
        .count{
            float:right;
        }
    }
    
    .desc{
        margin-top: 25px;
        width: 1000px;
    }
}
</style>

