<template>
    <div class="content technology">
        <div class="tech-title" @click="gotoTech(0)">科技服务</div>
        <div class="tech-box">
            <div class="tech-list">
                <div class="tech-item tech-item-1" @click="gotoTech(0)">
                    <div class="tech-info">
                        <div class="tech-name">科学数据管理</div>
                        <div class="tech-desc">服务简介</div>
                    </div>
                    <img class="tech-icon" src="@static/img/icons8-data_recovery.svg"/>
                </div>
                <div class="tech-item tech-item-2" @click="gotoTech(1)">
                    <div class="tech-info">
                        <div class="tech-name">仪器信息报送</div>
                        <div class="tech-desc">服务简介</div>
                    </div>
                    <img class="tech-icon" src="@static/img/icons8-microscope.svg"/>
                </div>
                <div class="tech-item tech-item-3" @click="gotoTech(2)">
                    <div class="tech-info">
                        <div class="tech-name">科学仪器查重</div>
                        <div class="tech-desc">服务简介</div>
                    </div>
                    <img class="tech-icon" src="@static/img/icons8-ok.svg"/>
                </div>
                <div class="tech-item tech-item-4" @click="gotoTech(3)">
                    <div class="tech-info">
                        <div class="tech-name">创新平台评价</div>
                        <div class="tech-desc">服务简介</div>
                    </div>
                    <img class="tech-icon" src="@static/img/icons8-chat_room.svg"/>
                </div>
                <div class="tech-item tech-item-5" @click="gotoTech(4)">
                    <div class="tech-info">
                        <div class="tech-name">科技专题服务</div>
                        <div class="tech-desc">服务简介</div>
                    </div>
                    <img class="tech-icon" src="@static/img/icons8-list_of_parts.svg"/>
                </div>
                <div class="tech-item tech-item-6" @click="gotoDetail">
                    <div class="tech-info">
                        <div class="tech-text">了解更多管理资讯</div>
                        <div class="tech-text">统计数据和分析报告</div>
                    </div>
                    <img class="tech-icon" src="@static/img/icons8-test_results.svg"/>
                </div>
            </div>
            <!--
            <div class="tech-sys" @click="gotoTech(6)">
                <div class="sys-title">科技服务管理系统</div>
                <div class="sys-en">Technology Services Management System</div>
            </div>
            -->
        </div>
    </div>
    
</template>

<script>
export default {
    methods:{
        gotoTech(index){
            this.$router.push({name: 'Tech', path: '/tech', query:{index: index}})
        },
        gotoDetail(){
            // this.$router.push({name: 'NewsDetail', path: '/newsDetail', query: {id: "1562635671463116801", type: "index"}});
        },
        gotoBuilding(){
            this.$router.push({name: 'Building', path: '/building'})
        }
    }
}
</script>

<style lang="less" scoped>
@import url(../../../public/static/css/index.less);

</style>

