<template>
    <div class="topic-search">
        关键词 <input type="text" class="search-input" placeholder="请输入" v-model="searchKey">
        <button type="button" class="btn btn-primary" @click="search">查询</button>
    </div>
</template>

<script>
export default {
    data(){
        return {
            searchKey:''
        }
    },
    methods:{
        search:function(e){
            const key = this.searchKey;
            this.$emit('onSearch', key);
        }
    }
}
</script>

<style lang="less" scoped>
.topic-search{
 
    line-height: 25px;
    font-size: 14px;
    

    .search-input{
        background: #FFFFFF;
        border: 1px solid rgba(0,0,0,0.15);
        border-radius: 2px;
        padding: 5px 9px;
        margin-left: 9px;
        margin-right: 16px;
        font-size: 14px;
        line-height: 14px;
    }
    .btn{
        display: inline-block;
        padding-top: 2px;
        padding-bottom: 2px;
    }
}
</style>

