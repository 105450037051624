    <template>
        <div class="data-list">       
            <div class="data-type-box" v-for="(item, index) in category">

                <div class="data-type-box-div" @click="goDataResource(index)">
                    <img :src="'/static/img/'+ item.name +'.svg'"/>
                    {{item.name}}
                </div>
            </div> 
        </div>
</template>

<script>
import {ref, reactive, onMounted,computed, watch, getCurrentInstance} from 'vue'
import {useRoute} from 'vue-router'
export default {
    setup(){
        const {proxy} = getCurrentInstance();

        const loading = ref(false)
 

        const oneSelect = ref(-1);
        const twoSelect = ref(-1);

        const categoryLoading = ref(false)
        const category = ref([])
        

        const result = ref([]);

        function loadAllCategory(){
            loading.value = true
            const url = '/hfapplication/gdsti/a/index/dataTypelist';
            proxy.$http.post(url).then(res=>{
                loading.value = false
                if(res.data.state==0){
                    category.value = res.data.result;
                }
				
            }).catch(err=>{
                loading.value = false
            });
        }
            


        onMounted(()=>{
        
            if(category.value.length == 0){
                //加载全部
                loadAllCategory();
            }
            
        })

        return {oneSelect, twoSelect, categoryLoading, category, 
         loading,  result, loadAllCategory}

    },
    methods:{
		goDataResource:function(index){
		    let item = this.category[index];
        let iframeUrl = '/hfapplication/gdsti/v/resourcesCatalogue/typeManageFill.do?cataloguetTypeId=' + item.id;
        this.$router.push({name: 'PlatformPages', path: 'platformPages', query: {iframeUrl: iframeUrl}})
		    // this.$router.push({name: 'DataResource', path: '/DataResource', query:{id: item.id, name: item.name}})
		},
    }
}
</script>

<style lang="less" scoped>
.data-list{
    padding-top: 48px;
    padding-bottom: 48px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.data-type-box{
	background-image: url('/static/img/dataResource.svg');
    width: 45%;
    height: 120px;
    cursor: pointer;
    margin: 18px 22px;
}
.data-type-box:hover{
    filter: brightness(0.9);
}
.data-type-box-div{
	display: flex;
    align-items: center;
    justify-content: center;
    color: #007EEC;
    height: 100%;
    font-family: SourceHanSansCN-Medium;
    font-size: 34px;
    
    img{
        width: 36px;
        margin-right: 12px;
    }
}
</style>


