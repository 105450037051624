<template>
  <!--   资源汇交页面-->
  <div style="display: flex;">
    <div style="width: 30%;">
      <Tree @nodeClick="treeNodeClick"></Tree>
    </div>
    <div style="width: 70%;">{{clickNode.label}}</div>
  </div>
</template>

<script>
import Tree from '../components/common/Tree'
import SearchBar from '../components/common/SearchBar'
import {ElTable, ElTableColumn, ElButton, ElDialog, ElResult, ElPopover, ElIcon} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';

export default {
  components: {
    SearchBar,
    ElTable,
    ElTableColumn,
    ElButton,
    ElIcon,
    ElDialog,
    ElResult,
    ElPopover,
    MoreFilled,
    Pager,
    Tree
  },
  setup() {
    const {proxy} = getCurrentInstance();
    const currentPage = ref(1);
    const loading = ref(false);
    const searchKey = ref('');
    const clickNode = ref({});
    const tablePager = ref({
      total: 1,
      page: 1,
      list: []
    })

    function loadData() {
      loading.value = true;
      const url = '/hfapplication/gdsti/a/remittance/list';
      proxy.$http.post(url, {limit: 10, offset: (currentPage.value - 1) * 10, searchKey: searchKey.value})
        .then(res => {
          loading.value = false
          if (res.data.state == 0) {
            tablePager.value = res.data.result;
          }
        }).catch(err => {
        loading.value = false
      })
    }

    function pageChange(index) {
      currentPage.value = index;
      loadData();
    }

    function search(searchKeyStr) {
      searchKey.value = searchKeyStr;
      pageChange(1);
    }

    const multipleSelection = ref([])

    function handleSelectionChange(selections) {
      multipleSelection.value = selections;
    }


    function indexFormatter(row, column, cellValue, index) {
      return row + 1 + '';
    }

    function handleDelete(index) {
      console.log(index)
    }

    function handleUpdate(index) {

    }

    function handlePublish(index) {

    }

    function handleDeleteAll() {

    }

    function treeNodeClick(data) {
      clickNode.value = data;
      console.log(data)
    }


    const isShow = ref(false)

    function showRequire() {
      isShow.value = true;
    }

    function statusFormatter(row, column, cellValue, index) {
      if (cellValue == 1) {
        return '审核通过';
      } else if (cellValue == 0) {
        return '待审核';
      } else if (cellValue == -1) {
        return '审核不通过';
      } else if (cellValue == -2) {
        return '待提交';
      }
    }


    onMounted(() => {
      loadData();
    })

    return {
      tablePager,clickNode, pageChange, search, currentPage, multipleSelection, indexFormatter, statusFormatter,
      handleDelete, handleDeleteAll, handleSelectionChange, handleUpdate, handlePublish,
      isShow, showRequire, treeNodeClick
    }
  }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

</style>

