<template>


    <div class="banner">
        <!-- banner条 -->
    </div>

    <div class="content">
        <div class="content-topic">
            <Topic></Topic>
            <div class="content-news" v-loading="newsLoading">
                <NewsBox :items="newsItems" :breads="breads"></NewsBox>
            </div>
        </div>
        
        <div class="platform-public">
            <div class="title platform-public-title">
                公共服务技术平台
              <div class="arrow">

                <el-link type="default" :underline="false" :href="'/baiqianwan/enterpriseList?zoneId=' + zoneInfo.id" target="_blank">查看更多</el-link>

                <!-- <div class="arrow-btn"><el-icon><ArrowLeftBold @click="platformCarouselClick('1')" /></el-icon></div>
                <span>{{platformCarouselIndex + 1}} / {{enterpricePage}}</span>
                <div class="arrow-btn"><el-icon><ArrowRightBold @click="platformCarouselClick('2')" /></el-icon></div> -->


              </div>
            </div>
            <div class="platform-list">
              <el-carousel ref="platformCarouselRef" trigger="click" :autoplay="false" arrow="never" indicator-position="none" height="150px">
                <el-carousel-item v-for="pageItem in enterpricePage" :key="'carousel' + pageItem">
                  <div class="platform-item" v-for="(item, index) in enterpriceList" :key="'platform-list-' + index" :style="{'background-image': 'url(' + $getImage(item.img) + ')'}" @click="goToUrl('/baiqianwan/enterpriseInfo?id=' + item.id);">
                    <div class="platform-item-title">
                      {{item.platformName}}
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>

<!--                <div class="platform-item" v-for="i in 10">-->
<!--                    平台图片-->

<!--                    <div class="platform-item-title">-->
<!--                        平台标题-->
<!--                    </div>-->
<!--                </div>-->
            </div>
        </div>

        <div class="res-container">
            <div class="title">
                科技资源
            </div>
            <div class="res-content">
                <div class="res-list-container">
                    <div class="res-title">
                        <el-icon><img src="/static/img/zone/res-ins-icon.png" alt=""></el-icon>
                      <span class="res-title-text">科研仪器</span>
                      <div class="res-title-more"><span><el-link type="default" :underline="false" :href="'/techRes?keyword=&id=INSTRUMENT_DATA&name=科研仪器'" target="_blank">查看更多</el-link></span><el-icon :size="14"><ArrowRight /></el-icon></div>
                    </div>
                  <hr />
                    <div class="res-list">
                        <div class="res-list-item"  v-for="(item, index) in resList" :key="'res-ins'+ index">
                          <div :class="(index > 2)?'res-list-item-index':'res-list-item-index-top'">{{(index + 1)}}</div> <span><el-link type="default" :underline="false" :href="'/instrument?catalogType=INSTRUMENT_DATA&id=' + item.id" target="_blank">{{item.cname}}</el-link></span>
                        </div>
                    </div>
                </div>
                
                <div class="res-list-container">
                    <div class="res-title">
                      <el-icon><img src="/static/img/zone/res-unit-icon.png" alt=""></el-icon>
                      <span class="res-title-text">科研机构</span>
                      <div class="res-title-more"><el-link type="default" :underline="false" :href="'/baiqianwan/unitList?zoneId=' + zoneInfo.id" target="_blank">查看更多</el-link><el-icon><ArrowRight /></el-icon></div>
                    </div>
                  <hr />
                    <div class="res-list">
                        <div class="res-list-item"  v-for="(item, index) in unitList" :key="'res-unit'+ index">
                          <div :class="(index > 2)?'res-list-item-index':'res-list-item-index-top'">{{(index + 1)}}</div> 
                          <span>
                            <!-- <el-link type="default" :underline="false" :href="'/baiqianwan/unitInfo?id=' + item.id" target="_blank">{{item.name}}</el-link> -->
                            <el-link :disabled="true">{{item.name}}</el-link>
                        </span>
                        </div>
                    </div>
                </div>

                <div class="res-list-container">
                    <div class="res-title">
                      <el-icon><img src="/static/img/zone/res-icon-platform.png" alt=""></el-icon>
                      <span class="res-title-text">平台基地</span>
                      <div class="res-title-more"><el-link type="default" :underline="false" :href="'/platform?index=0&navCode=PLATFORM'" target="_blank">查看更多</el-link><el-icon><ArrowRight /></el-icon></div>
                    </div>
                  <hr />
                    <div class="res-list">
                        <div class="res-list-item" v-for="(item, index) in platformList" :key="'res-platform'+ index">
                          <div :class="(index > 2)?'res-list-item-index':'res-list-item-index-top'">{{(index + 1)}}</div> <span><el-link type="default" :underline="false" :href="'/organ?id=' + item.id" target="_blank">{{item.platformName}}</el-link></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="demand-container">
            <div class="title">
                需求征集
            </div>
            <div class="demand-content">
                <div class="publish">
                    <div class="publish-text">
                        发布需求
                    </div>
                    <div class="publish-text">
                        获取优质方案
                    </div>
                    <div class="publish-btn" @click="showDemand">
                        发布需求
                    </div>
                </div>

                <div class="demand-list-container">
                    <div class="demand-title">
                      <el-icon><img src="/static/img/zone/demand-icon.png" alt=""></el-icon>
                      <span class="demand-title-text">需求列表</span>
                    </div>
                  <hr />
                    <div class="demand-list">
                        <div class="demand-list-item" v-for="(item, index) in demandList" :key="'demand'+ index">
                          <div :class="((index + 1) > 3)?'demand-list-item-index':'demand-list-item-index-top'">{{(index + 1)}}</div> <span>[{{item.demandName}}] {{item.demandContent}}</span>
                          <div class="demand-list-item-date">{{formatDate(item.createTime)}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <el-dialog v-model="showDialog" title="请填写您的需求">
            <div>
                <Form class="form" ref="target" v-slot="{errors}">
                    <div class="form-group">
                        <label class="control-label col-3"><span class="required">*</span>需求名称</label>
                        <div class="col-8">
                            <Field type="text" name="demandName" class="form-control" v-model="form.demandName" :rules="checkRequired" placeholder="请输入需求名称"/>
                            <div class="error" >{{errors.demandName}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-3"><span class="required">*</span>详细描述</label>
                        <div class="col-8">
                            <Field  :rules="checkRequired" name="demandContent"  v-model="form.demandContent">
                                <textarea rows="5"   v-model="form.demandContent"  class="form-control" placeholder="请输入内容"></textarea>
                            </Field>
                            <div class="error" >{{errors.demandContent}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-3"><span class="required">*</span>联系人</label>
                        <div class="col-8">
                            <Field type="text" name="contactsName" class="form-control" v-model="form.contactsName" :rules="checkRequired" placeholder="请输入联系人"/>
                            <div class="error" >{{errors.contactsName}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-3"><span class="required">*</span>联系电话</label>
                        <div class="col-8">
                            <Field type="text" name="contactsNumber" class="form-control" v-model="form.contactsNumber" :rules="checkRequired" placeholder="请输入手机号码"/>
                            <div class="error" >{{errors.contactsNumber}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-3">相关附件</label>
                        <div class="col-8">
                            <el-upload
                                    class="uploader-btn"
                                    :multiple="false"
                                    :limit="1"
                                    action="/core/base/c/upload/upload"
                                    :data="{relateType: 'zone-baiqianwan-demand'}"
                                    :on-success="handleFileSuccess"
                                    v-model:file-list="fileList"
                                    :before-upload="beforeAvatarUpload"
                                    :on-exceed="onExceed"
                                    :on-remove="onRemove"
                            >
                                <el-button type="primary">点击上传文件</el-button>
                            </el-upload>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-3">有效期</label>
                        <div class="col-8">
                            <Field type="text" name="termOfValidity" class="form-control" v-model="form.termOfValidity" placeholder="请输入有效期"/>
                            <div class="error" >{{errors.termOfValidity}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-3">地址</label>
                        <div class="col-8">
                            <Field type="text" name="address" class="form-control" v-model="form.address"  placeholder="请输入地址"/>
                            <div class="error" >{{errors.address}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="button" v-loading="submitLoading" class="btn btn-primary next-btn" @click="demandSubmit">提交</button>
                    </div>
                </Form>
            </div>
        </el-dialog>


    </div>
</template>

<script>
import {ref, reactive, onMounted, getCurrentInstance} from 'vue'
import {useRouter, useRoute} from 'vue-router'

import { Form, Field } from 'vee-validate'

import {ArrowLeftBold, ArrowRightBold, ArrowLeft, ArrowRight} from '@element-plus/icons-vue'

import Topic from './Topic'
import NewsBox from '@/components/index/NewsBox'

export default {
    components: {ArrowLeftBold, ArrowRightBold, ArrowLeft, ArrowRight, Topic, NewsBox, Form, Field},
    setup(){
            const {proxy} = getCurrentInstance();

            const route = useRoute();

            const loading = ref(false)
            


            const newsItems = ref([]);
            const breads = ref([{text:'首页', name:'Index', path:'/index'}])
            const newsLoading = ref(false)

            const platformCarouselRef = ref(null);
            const platformCarouselIndex = ref(0);


            const zoneInfo = ref({});

            const enterpriceList = ref([]);
            const enterpricePage = ref(1);

            const platformList = ref([]);
            const unitList = ref([]);
            const resList = ref([]);
            const demandList = ref([]);

            async function loadZoneInfo(zoneIdOrCode){    //专区信息

                loading.value = true;
                await proxy.$http.post('/gdsti2/web/a/webZone/info', {id: zoneIdOrCode}).then(res => {
                    loading.value = false;
                    if(res.data.state === 0){
                        zoneInfo.value = res.data.result;

                        console.log('asdf22', res.data.result);
                    }
                    
                }).catch(err => {
                    loading.value = false;
                })
            }

            function loadEnterpriceList(){    //技术平台

                loading.value = true;
                proxy.$http.post('/gdsti2/web/a/webZone/enterpriseList', {noPage: 1, limit: 4, hasImg: 1, zoneId: zoneInfo.value.id}).then(res => {
                    loading.value = false;
                    if(res.data.state === 0){
                        enterpriceList.value = res.data.result;

                        //页数
                        enterpricePage.value = Math.round(enterpriceList.value.length/4);
                    }
                }).catch(err => {
                    loading.value = false;
                })
            }

            function loadPlatformList(){    //平台

                loading.value = true;
                proxy.$http.post('/gdsti2/web/a/webZone/platformList', {noPage: 1, limit: 7, zoneId: zoneInfo.value.id}).then(res => {
                    loading.value = false;
                    if(res.data.state === 0){
                        platformList.value = res.data.result;
                    }
                }).catch(err => {
                    loading.value = false;
                })
            }

            function loadUnitList(){    //依托单位

                loading.value = true;
                proxy.$http.post('/gdsti2/web/a/webZone/unitList', {noPage: 1, limit: 7, zoneId: zoneInfo.value.id}).then(res => {
                    loading.value = false;
                    if(res.data.state === 0){
                        unitList.value = res.data.result;
                    }
                }).catch(err => {
                    loading.value = false;
                })
            }

            function loadResList(){    //科技仪器

                loading.value = true;
                proxy.$http.post('/gdsti2/web/a/webZone/resourceList', {noPage: 1, limit: 7, zoneId: zoneInfo.value.id}).then(res => {
                    loading.value = false;
                    if(res.data.state === 0){
                        resList.value = res.data.result;
                    }
                }).catch(err => {
                    loading.value = false;
                })
            }

            function loadDemandList(){    //需求列表

                loading.value = true;
                proxy.$http.post('/gdsti2/web/a/webZone/demandList', {noPage: 1, limit: 4, zoneId: zoneInfo.value.id}).then(res => {
                    loading.value = false;
                    if(res.data.state === 0){
                        demandList.value = res.data.result;
                    }
                }).catch(err => {
                    loading.value = false;
                })
            }

            const tempItems = ref([]);
            async function getArticleList(typeCode, newsNum){   //获取文章列表
                newsLoading.value = true
                const url = '/core/cms/a/articleContent/list?limit=' + newsNum + '&typeCode=' + typeCode + '&apprStatus=1,99&publishStatus=1&timeField=publish_time';
                await proxy.$http.post(url).then(res=>{
                    console.log(res)
                    newsLoading.value = false
                    if(res.data.state == 0){
                        tempItems.value = res.data.result;
                        
                        // newsItems.value.push(tempItems.value);

                        // console.log(tempItems);
                    }
                }).catch(err=>{
                    newsLoading.value = false
                })
            }

            async function loadNewsItem(){  //使用同步方法调用文章列表
                //显示文章

                await getArticleList('WEB_ZONE_ZCWJ', 6);   //通知公告
                let zcwjNewsItem = {};
                zcwjNewsItem.id = "WEB_ZONE_ZCWJ";
                zcwjNewsItem.name = "政策文件";
                zcwjNewsItem.content = tempItems.value;

                console.log('zcwjNewsItem.content', zcwjNewsItem.content);

                await getArticleList('WEB_ZONE_JDXW', 6);   //开放课题
                let jdxwNewsItem = {};
                jdxwNewsItem.id = "WEB_ZONE_JDXW";
                jdxwNewsItem.name = "焦点新闻";
                jdxwNewsItem.content = tempItems.value;

                newsItems.value.push(zcwjNewsItem);
                newsItems.value.push(jdxwNewsItem);

            }

            //走马灯点击
          function platformCarouselClick(direct){
            // console.log('direct', direct, platformCarousel);
            if(direct == 1){  //上一页
              (platformCarouselIndex.value > 0)? platformCarouselIndex.value--:'';
            }else if(direct == 2){  //下一页
              (platformCarouselIndex.value < (enterpricePage.value - 1) )? platformCarouselIndex.value++:'';
            }
            platformCarouselRef.value.setActiveItem(platformCarouselIndex.value);
          }

          function formatDate(dateStr){ //仅显示日期
            return dateStr.split(' ')[0];
          }

          function showDemand(){
            showDialog.value = true;
          }


          //需求表单
          const showDialog = ref(false)
          const target = ref(null);
          const fileList = ref([]);
            const fileCodeList = ref([]);
            const fileUrl = ref('');

          const form = reactive({
                zoneId: '',
                demandName: '',
                demandContent: '',
                contactsName: '',
                contactsNumber: '',
                enclosureCode: '',
                termOfValidity: '',
                address: ''
            });

            const checkPhone = value => {
                if(!value) return '请输入手机号码';
                if(!/^1\d{10}/.test(value)) return '请输入11位手机号码';
                return true;
            }

            const checkRequired = value => {
                if(!value){
                    return '请输入内容';
                }else{
                    return true;
                }
            }
            function handleFileSuccess(response, uploadFile){
                uploadFile['attachCode'] = response.result[0].attachInfo.id;
                fileUrl.value = proxy.$getImage(response.result[0].attachInfo.id);

                fileCodeList.value.splice(0, fileCodeList.value.length);
                fileCodeList.value.push(response.result[0].attachInfo.id);

            }
            function onRemove(uploadFile, uploadFiles){
                var removeAttachCode = uploadFile.attachCode;
                for(let i in fileCodeList.value){
                    if(fileCodeList.value[i] == removeAttachCode){
                        fileCodeList.value.splice(i,1);
                    }
                }
            }
            function onExceed(files, uploadFiles){
                proxy.ElMessage.warning('超过最大文件上传个数 ' + 1)
                // console.log('exceed', files, uploadFiles);
            }
            function beforeAvatarUpload(rawFile){
                // if(rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png'){
                //     this.ElMessage.error('图片需要jpg或png格式');
                //     return false;
                // }else
                console.log('fileList', fileList);
                if (fileList.value.length >= 1) {
                    proxy.ElMessage.warning('超过最大文件上传个数 ' + 1)
                    submitLoaing.value = false;
                    return false;
                }
                if(rawFile.size/1024/1024 > 10){
                    proxy.ElMessage.error('文件需要小于10MB');
                    return false;
                }
                return true;
            }

            const submitLoading = ref(false);
            const demandSubmit = () => {
                form.zoneId = zoneInfo.value.id;

                target.value.validate().then(valid=>{
                    if (valid) {
                        //手动校验
                        if(!form.demandName){
                            // proxy.ElMessage.error('需求名称不能为空');
                            return false;
                        }
                        if(!form.demandContent){
                            // proxy.ElMessage.error('详细描述为空');
                            return false;
                        }
                        if(!form.contactsName){
                            // proxy.ElMessage.error('联系人不能为空');
                            return false;
                        }
                        if(!form.contactsNumber){
                            // proxy.ElMessage.error('联系电话不能为空');
                            return false;
                        }


                        submitLoading.value = true;
                        const url = '/gdsti2/web/a/webZone/demandAdd';

                        // console.log("fileCodeList:",fileCodeList.value);

                        var attachCodeStr = "";
                        for(let i in fileCodeList.value){
                            if(i == 0){
                                attachCodeStr += fileCodeList.value[i];
                            }else{
                                attachCodeStr += ","+fileCodeList.value[i]
                            }
                        }
                        form.enclosureCode = attachCodeStr;
                        proxy.$http.post(url, form).then(res=>{
                            submitLoading.value = false
                            showDialog.value = false
                            if(res.data.state == 0){
                                proxy.ElMessageBox.alert('您的需求已提交，稍后将有工作人员联系您', '提示');
                                form.zoneId = '';
                                form.demandName = '';
                                form.demandContent = '';
                                form.contactsName = '';
                                form.contactsNumber = '';
                                form.enclosureCode = '';
                                form.termOfValidity = '';
                                form.address = '';
                            }
                        }).catch(err=>{
                            submitLoading.value = false
                        });
                        // 表单验证通过，调用接口实现登录
                        // 以下为演示代码

                        return true;
                    }else{
                        console.log("校验不通过");
                    }
                }).catch(err=>{
                    return false;
                });

            }


            function goToUrl(url){ //页面跳转
                window.open(url);
            }


        //初始化
          async function init(){
            if(route.query.zoneClass){

            }


            await loadZoneInfo('BQWGCZC');

            //加载文章
            loadNewsItem();

            //加载技术平台
            loadEnterpriceList();

            loadPlatformList(); //平台
            loadUnitList();     //依托单位
            loadResList();      //仪器资源
            loadDemandList();   //需求列表

            console.log('asdf', zoneInfo.value);
          }

            onMounted(()=>{
                init();

            })

            return {loading, newsItems, breads, newsLoading, platformCarouselRef, platformCarouselClick, platformCarouselIndex, zoneInfo, enterpriceList, enterpricePage, platformList, unitList, resList, demandList, formatDate, showDialog, showDemand, form, demandSubmit, fileList, fileCodeList, fileUrl, target, handleFileSuccess, checkPhone, checkRequired, submitLoading, onRemove, beforeAvatarUpload, onExceed, goToUrl}
    },
    watch:{

    },
    mounted(){

    },
    methods:{

    }
}

</script>

<style lang="less" scoped>
.banner{
    height: 150px;
    background-image: url(/static/img/zone/baiqianwan-bar-bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;


}
.content{
    margin-top: 30px;
}
.content-topic{
    margin-bottom: 33px;
    display: flex;
}
.content-news{
    width: 594px;
    height: 343px;
    justify-content: space-between;
}
.title{
    font-family: 思源黑体;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    text-align: justify; /* 浏览器可能不支持 */
    letter-spacing: 0px;
    color: rgba(0, 0, 0, 0.85);

    height: 60px;
    line-height: 60px;
}

.platform-public-title{
  position: relative;
  .arrow{
    position: absolute;
    right: 10px;
    bottom: 15px;
    height: 16px;

    display: flex;
    align-items: center;
    font-size: 14px;

    .arrow-btn{
      height: 20px;
      width: 20px;
      display: inline-block;
      border: 1px solid #007EEC;
      margin: 0 3px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.platform-list{
    width: 100%;
    height: 150px;
    overflow: hidden;

    .platform-item{
        width: 280px;
        height: 150px;
      margin: 5px 10px;
      background-color: #ccc;
      display: inline-block;

        position: relative;
        .platform-item-title{
          position: absolute;
          left: 10px;
          bottom: 10px;
        }
    }
}

.res-container{
  .res-content{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .res-list-container{
      flex: 1;
      height: 350px;
      overflow: hidden;
      //background-color: #ccc;
      margin: 0 10px;

      /* 阴影/低阴影 */
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);

      hr{
        border:0;
        background: #D8D8D8;
        height: 1px;
        margin: 0 10px;
      }

      .res-title{
        height: 35px;
        line-height: 35px;
        padding: 10px 0px 0 12px;
        position: relative;

        img{
          width: 16px;
          height: 16px;
        }

        .res-title-text{
          font-family: 思源黑体;
          font-size: 20px;
          font-weight: 500;
          line-height: normal;
          text-align: justify; /* 浏览器可能不支持 */
          letter-spacing: 0px;
          color: #3D3D3D;

          margin-left: 10px;
        }
        .res-title-more{
          position: absolute;
          display: inline-block;
          right: 10px;
          top: 5px;
          font-size: 14px;

          display: flex;
          align-items: center;
        }


      }
      .res-list{
        padding: 10px 10px 0 10px;

        .res-list-item{
          line-height: 40px;
          font-size: 16px;


          display: flex;
          align-items: center;

          .res-list-item-index{
            text-align: center;
            width: 20px ;
            height: 20px;
            line-height: 20px;
            display: inline-block;
            background: #AAAAAA;
            border-radius: 50% 50%;
            color: #fff;
          }
          .res-list-item-index-top{
            text-align: center;
            width: 20px ;
            height: 20px;
            line-height: 20px;
            display: inline-block;
            background: #007EEC;
            border-radius: 50% 50%;
            color: #fff;
          }

          span{
            display: inline-block;
            margin-left: 5px;
          }

          /deep/ .el-link{
            font-size: 16px;
          }

        }
      }
    }
  }
}
.demand-container{
  .demand-content{
    display: flex;
    flex-direction: row;

    .publish{
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      font-family: SourceHanSansCN-Medium;
      font-size: 24px;
      color: #FFFFFF;
      letter-spacing: 0;
      text-align: center;
      line-height: 30px;
      width: 283px;
      height: 205px;
      background-image: url('/static/img/skyscraper.png');
      .publish-btn{
        background: rgba(255,255,255,0.25);
        border: 1px solid #FFFFFF;
        font-family: SourceHanSansCN-Regular;
        font-size: 14px;
        width: 123px;
        height: 30px;
        line-height: 30px;
        margin-top: 30px;
        cursor: pointer;
      }
      .publish-btn:hover{
        background: rgba(255,255,255,0.5);
      }
    }


    .demand-list-container{
      flex: 1;
      height: 205px;
      overflow: hidden;
      //background-color: #ccc;
      margin: 0 10px 20px 10px;

      /* 阴影/低阴影 */
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.16);

      hr{
        border:0;
        background: #D8D8D8;
        height: 1px;
        margin: 0 10px;
      }

      .demand-title{
        height: 35px;
        line-height: 35px;
        padding: 10px 0px 0 12px;
        position: relative;

        img{
          width: 16px;
          height: 16px;
        }

        .demand-title-text{
          font-family: 思源黑体;
          font-size: 20px;
          font-weight: 500;
          line-height: normal;
          text-align: justify; /* 浏览器可能不支持 */
          letter-spacing: 0px;
          color: #3D3D3D;

          margin-left: 10px;
        }

      }
      .demand-list{
        padding: 10px 10px 0 10px;

        .demand-list-item{
          line-height: 35px;
          font-size: 16px;
          position: relative;

          .demand-list-item-index{
            text-align: center;
            width: 20px ;
            height: 20px;
            line-height: 20px;
            display: inline-block;
            background: #AAAAAA;
            border-radius: 50% 50%;
            color: #fff;
          }
          .demand-list-item-index-top{
            text-align: center;
            width: 20px ;
            height: 20px;
            line-height: 20px;
            display: inline-block;
            background: #007EEC;
            border-radius: 50% 50%;
            color: #fff;
          }

          span{
            margin-left: 5px;
            padding-right: 100px;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          .demand-list-item-date{
            position: absolute;
            right: 10px;
            top: 0px;
          }
        }
      }
    }
  }
}




    .form-group{
        justify-content: center;
        margin-bottom: 12px;
    }
    .form-control{
        width: 350px;
    }
    .error{
        margin-left: 10px;
    }
    .uploader-btn{
        margin-left: 10px;
    }
    .link-more{
        float: right;
    }


</style>
