<template>
  <div class="user-info" v-loading="loading">
    <div class="collect-head">
<!--      <SearchBar @onSearch="search"></SearchBar>-->
      <div class="topic-search">
        名称 <input type="text" class="search-input" placeholder="请输入" v-model="searchKey">
        <button type="button" class="btn btn-primary" @click="search">查询</button>
      </div>
      <div class="select">
        <span v-if="multipleSelection.length>0">
        已选：{{ multipleSelection.length }}
        <el-button type="danger" class="table-btns" @click="handleCancelAll()">删除</el-button>
        </span>
        <el-button type="primary" class="table-btns" @click="addClick('add')">新增</el-button>
        <el-button type="primary" class="table-btns" v-if="isExclOperate" @click="excelDownload()">下载excel导入模板</el-button>
        <el-button type="primary" class="table-btns" v-if="isExclOperate"  @click="addClick('import')">excel导入</el-button>
        <el-button type="primary" class="table-btns" v-if="isExclOperate"  @click="addClick('export')">导出库信息</el-button>
<!--        <el-upload-->
<!--            style="display: inline-block"-->
<!--            v-if="isExclOperate"-->
<!--            class="upload-demo"-->
<!--            action="/hfapplication/gdsti/c/formdataCatalogue/upload.do"-->

<!--            :data = "uploadData"-->
<!--            :show-file-list="false"-->
<!--            :on-success="handleSuccess"-->
<!--            accept=".xlsx"-->
<!--            :before-upload="beforeAvatarUpload">-->
<!--          <el-button type="primary" class="table-btns">excel导入</el-button>-->
<!--        </el-upload>-->
      </div>
    </div>
    <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection"  width="55"/>
<!--      <el-table-column type="index" label="序号" width="80"/>-->

      <el-table-column  v-for="(item, index) in columnList" :prop="item.fieldName" :label="item.fieldAnnotation" />

      <el-table-column label="操作" width="80">
        <template #default="scope">
          <el-popover placement="bottom" :width="50" trigger="click">
            <template #reference>
              <el-icon class="link-more">
                <MoreFilled/>
              </el-icon>
            </template>
            <div class="option-list">
              <a href="javascript:void(0)" class="danger" @click="handleDelete(scope.row.hfc_id)">删除</a>
              <a href="javascript:void(0)" class="primary" @click="handleUpdate(scope.row.id)">修改</a>
              <a href="javascript:void(0)" class="danger" @click="handleInfo(scope.row.id)">查看</a>
            </div>
          </el-popover>
        </template>
      </el-table-column>
    </el-table>

    <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>

    <div class="bottom-btns">
      <el-button type="default" @click="back">返回</el-button>
    </div>
    <el-dialog v-model="isShow" title="选择平台（实验室）">
      <div class="collect-head">
        <div class="select">


<!--          <el-button class="table-btns" type="primary" @click="exportLibraryInformation()">导出库信息</el-button>-->
<!--          <el-button type="primary" class="table-btns" v-if="isExclOperate" @click="excelDownload()">下载excel导入模板</el-button>-->
          <el-button v-if="addClickType != 'import'" type="primary" class="table-btns" @click="projectSelect()">确定</el-button>
          <el-upload
              style="display: inline-block"
              v-if="isExclOperate && addClickType == 'import'"
              class="upload-demo"
              action="/hfapplication/gdsti/c/formdataCatalogue/upload.do"

              :data = "uploadData"
              :show-file-list="false"
              :on-success="handleSuccess"
              :on-error="handleError"
              accept=".xlsx"
              :before-upload="beforeAvatarUpload">
            <el-button type="primary" class="table-btns">确定</el-button>
<!--            <span v-if="addClickType == 'import'" style="color:red;">excel导入会覆盖掉旧数据，请谨慎操作</span>-->
          </el-upload>
        </div>
      </div>
      <el-table :data="tablePagerSelect.list" style="width: 100%" @selection-change="handleSelectionChangeProject">
        <el-table-column width="50px">
          <template v-slot="scope">
            <!-- label值要与el-table数据id实现绑定 -->
            <el-radio v-model="projectId" :label="scope.row.id" @change="handleRowChangeSelect(scope.row)">{{""}}
            </el-radio>
          </template>
        </el-table-column>
        <el-table-column type="index" label="序号" width="80"/>
        <el-table-column prop="projectName" label="平台名称"/>
        <el-table-column prop="establishYear" label="成立年度"/>
        <el-table-column prop="address" label="地址"/>
      </el-table>
    </el-dialog>
    <el-dialog v-model="isUploadShow" title="导入完成前请勿刷新页面或关闭浏览器"
               :show-close="isSuccessShow" style="text-align: center;" width="20%"
               :close-on-press-escape="false" :close-on-click-modal="false">

      <el-progress type="circle" :percentage="percentageNumber" v-if="!isSuccessShow"></el-progress>
      <el-row v-if="isSuccessShow">
        <el-col :sm="6" :lg="24">
          <el-result :icon="uploadIcon" title="" :subTitle="isSuccessStr">
          </el-result>
        </el-col>
      </el-row>


    </el-dialog>

  </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import {ElButton, ElTable, ElTableColumn} from 'element-plus';
import Pager from '../../../components/common/Pager'
import {MoreFilled} from '@element-plus/icons-vue'
import {getCurrentInstance, onMounted, ref} from 'vue';
import {useRoute} from 'vue-router'

export default {
  components: {SearchBar, ElTable, ElTableColumn, ElButton, Pager,MoreFilled},
  setup() {
    const {proxy} = getCurrentInstance();
    const route = useRoute();
    const currentPage = ref(1);
    const loading = ref(false);
    const searchKey = ref('');
    const uploadIcon = ref('success');
    const addClickType = ref('');
    const libPercentageKey = ref('');
    const tablePager = ref({
      total: 1,
      page: 1,
      list: [
        //   {id:'1', title:'产业专区标题', name:'张三', post:'固定研究人员', dept:'实验室所属单位',
        // deptName: '广东省消防科学技术重点实验室', area:'经济学', age: '25', sex:'男',
        // edu: '本科', collect:'无', status:1,
        // date: '2021-12-12'}
      ]
    })
    const columnList = ref([]);
    function loadData() {
      loading.value = true;
      const url = '/hfapplication/biform/c/form/datalistByRelyonWorkplaceId.do';
      proxy.$http.post(url, {limit: 200, offset: (currentPage.value - 1) * 200, searchKey: searchKey.value, formId:route.query.biFormId})
          .then(res => {
            loading.value = false
            if (res.data.state == 0) {
              tablePager.value = res.data.result;
            }
          }).catch(err => {
        loading.value = false
      })
    }

    function loadColumn() {

      loading.value = true;
      const url = '/hfapplication/gdsti/a/remittance/showFormData';
      proxy.$http.post(url, {biFormId: route.query.biFormId})
          .then(res => {
            loading.value = false
            if (res.data.state == 0) {
              console.log(res.data.result)
              columnList.value = res.data.result;
            }
          }).catch(err => {
        loading.value = false
      })
    }


    function pageChange(index) {
      currentPage.value = index;
      loadData();
    }

    function search() {
      // searchKey.value = keyStr;
      pageChange(1);
    }

    const multipleSelection = ref([])

    function handleSelectionChange(selections) {
      multipleSelection.value = selections;
    }


    function indexFormatter(row, column, cellValue, index) {
      return row + 1 + '';
    }

    function handleCancel(ids) {
      console.log(ids)
      const url = '/hfapplication/gdsti/a/adCollect/cancelCollectByIds';
      proxy.$http.post(url, {ids:ids}).then(res=>{
        if(res.data.state==0){
          proxy.ElMessage.success("操作成功");
          loadData();
        }
      }).catch(err=>{

      });
    }

    function handleCancelAll() {
      var ids = [];
      for (const obj of multipleSelection.value) {
        ids.push(obj.hfc_id);
      }
      const url = '/hfapplication/biform/c/form/deleteData.do';
      proxy.$http.post(url, {ids: ids.join(",")}).then(res=>{
        if(res.data.state==0){
          proxy.ElMessage.success("操作成功");
          loadData();
        }
      }).catch(err=>{

      });
    }
    function handleDelete(hfcId) {

      HFConfirm("确认要删除选中该信息吗？", "", "warning", function(isConfirm){
        if(isConfirm){

          const url = '/hfapplication/biform/c/form/deleteData.do';
          proxy.$http.post(url, {ids: hfcId}).then(res=>{
            if(res.data.state==0){
              HFToastrTipResult(res.data);
              loadData();
            }
          }).catch(err=>{

          });

        }
      });



    }

    function handleUpdate(id){
      let iframeUrl = '/hfapplication/gdsti/v/resourcesCatalogue/relyonWorkplaceDataSend.do?formId='+route.query.biFormId+'&dataTid=' + id;
      proxy.$router.push({name: 'PlatformPages', path:'platformPages', query: {iframeUrl : iframeUrl}})
    }

    function handleInfo(id){
      let iframeUrl = '/hfapplication/biform/v/form/justShowHaveBack.do?formId='+route.query.biFormId+'&dataTid=' + id;
      proxy.$router.push({name: 'PlatformPages', path:'platformPages', query: {iframeUrl : iframeUrl}})
    }

    function addClick(type){
      addClickType.value = type;
      let url = '/hfapplication/gdsti/c/relyonWorkplaceProject/list2.do';
      proxy.$http.post(url, {limit: 10, offset: (currentPage.value - 1) * 10})
          .then(res => {
            if (res.data.state == 0) {
              tablePagerSelect.value = res.data.result;
              if (res.data.result.list.length > 0) {
                projectId.value = res.data.result.list[0].id;
              }
              isShow.value = true;
            }
          }).catch(err => {
      })

    }


    const multipleSelectionProject = ref([])
    const projectId = ref('');

    function handleSelectionChangeProject(selections) {
      multipleSelectionProject.value = selections;
    }
    function handleRowChangeSelect(data) {
      if (data) {
        projectId.value = data.id
      }
    }

    const isShow = ref(false)
    const tablePagerSelect = ref({
      total: 1,
      page: 1,
      list: [{}]
    })

    function projectSelect() {
      console.log(addClickType.value);
      if(addClickType.value == 'add'){
        //新增选择
        let iframeUrl = '/hfapplication/gdsti/v/resourcesCatalogue/relyonWorkplaceDataSend.do?formId=' + route.query.biFormId + '&projectId=' + projectId.value;
        proxy.$router.push({name: 'PlatformPages', path: 'platformPages', query: {iframeUrl: iframeUrl}})
      }
      if(addClickType.value == 'export'){
          //导出
        window.open(`/hfapplication/gdsti/c/formdataCatalogue/export.do?type=${libTypeStr.value}&formId=${route.query.biFormId}&projectId=${projectId.value}`);

      }


     }

    function exportLibraryInformation() {
      // this.isUploadShow = true;

      window.open(`/hfapplication/gdsti/c/formdataCatalogue/export.do?type=${libTypeStr.value}&formId=${route.query.biFormId}&projectId=${projectId.value}`);
    }

    const isExclOperate = ref(false);   //是否有下载按钮
    const libTypeStr = ref('');       //下载库模板 类型
    const uploadData = ref({});
    const isUploadShow = ref(false);
    const isSuccessShow = ref(false);
    const percentageNumber = ref(0);       //导入进度条
    const isSuccessStr = ref('');
    onMounted(() => {
      loadColumn();
      loadData();
      isExclOperate.value = route.query.isExclOperate;
      libTypeStr.value = route.query.libTypeStr;
    })

    return {
      tablePager,
      pageChange,
      search,
      currentPage,
      multipleSelection,
      indexFormatter,
      isExclOperate,
      uploadData,
      libTypeStr,
      isUploadShow,
      percentageNumber,
      isSuccessShow,
      isSuccessStr,
      handleCancel,
      handleCancelAll,
      handleSelectionChange,
      columnList,
      handleDelete,
      handleUpdate,
      handleInfo,
      addClick,
      multipleSelectionProject,
      projectId,
      handleSelectionChangeProject,
      handleRowChangeSelect,
      isShow,
      tablePagerSelect,
      projectSelect,
      loading,
      exportLibraryInformation,
      addClickType,
      libPercentageKey,
      searchKey,
      uploadIcon
    }
  },
  methods:{
    back(){
      this.$router.back();
    },
    excelImport(){

    },
    excelDownload(){
      // this.isUploadShow = true;

        window.open(`/hfapplication/gdsti/c/formdataCatalogue/down.do?type=${this.libTypeStr}`);
    },
    handleSuccess(response, uploadFile){
      this.uploadIcon = "success";
        console.log("handleSuccess");
        this.isSuccessStr = response.result.message
        this.percentageNumber = 0;
        this.isSuccessShow = true;

    },

    handleError(response, uploadFile){
      this.uploadIcon = "error";
      console.log("handleError");
      let message = response.message;
      let result = response.result;
      this.isSuccessStr = "导入发生意料之外的异常，请联系网站管理员处理。错误信息：message：" + message + "  result：" + result;

      this.percentageNumber = 0;
      this.isSuccessShow = true;
    },

    beforeAvatarUpload(rawFile){
        let name = rawFile.name;
        let type = '';
        if(name.length < 4){
          this.ElMessage.error('文件需要是xlsx格式');
          return false;
        }
        type = name.substr(name.length - 4)
        if (type != 'xlsx') {
          this.ElMessage.error('文件需要是xlsx格式');
          return false;
        }
        // if (rawFile.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        //   this.ElMessage.error('文件需要是xlsx格式');
        //   return false;
        // }
        this.isSuccessShow = false;
        this.isSuccessStr = "";
        this.isUploadShow = true;
        this.uploadData.type = this.libTypeStr;
        this.uploadData.relyonWorkplaceProjectId = this.projectId;
        //获取进度条前，生成一个随机key
        let libPercentageKey = 'LibPercentage' + Math.floor(Math.random()*1000000);
        this.libPercentageKey = libPercentageKey;
        this.uploadData.libPercentageKey = libPercentageKey;

        //获取进度条
        let timer = setInterval(() => {
            //需要定时执行的代码
            console.log("timertimertimer");
            // this.isSuccessShow = false;
            this.$http.post('/hfapplication/gdsti/c/formdataCatalogue/getSpeedOfProgress.do', {speedOfProgressKey : libPercentageKey}).then(res=>{
              if(res.data.state == 0){
                  this.percentageNumber = res.data.result;
              }
            }).catch(err=>{

            })
            if (this.percentageNumber == 100) {
                clearInterval(timer);
                //导入完成
            }
        },500);

        return true;
    },
  }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

.topic-search{

  line-height: 25px;
  font-size: 14px;


  .search-input{
    background: #FFFFFF;
    border: 1px solid rgba(0,0,0,0.15);
    border-radius: 2px;
    padding: 5px 9px;
    margin-left: 9px;
    margin-right: 16px;
    font-size: 14px;
    line-height: 14px;
  }
  .btn{
    display: inline-block;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}
</style>

