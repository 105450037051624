<template>
  <div class="user-info">
    <div class="collect-head">
      <SearchBar @onSearch="search"></SearchBar>
      <div class="select">
        <span v-if="multipleSelection.length>0">已选：{{ multipleSelection.length }} <el-button type="danger"
                                                                                             class="table-btns"
                                                                                             @click="handleDeleteAll()">删除选中</el-button></span>
        <el-button type="primary" class="table-btns" @click="showRequire()">新增</el-button>
      </div>
    </div>
    <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"/>
      <el-table-column type="index" label="序号" width="80"/>
      <el-table-column prop="cataloguetNameCn" label="名称"/>
      <el-table-column prop="typeName" label="类别"/>
      <el-table-column prop="projectName" label="提交平台（实验室）"/>
      <el-table-column prop="realyCreateTime" label="发起时间"/>
      <el-table-column prop="examineStatus" label="审批结果" :formatter="statusFormatter"/>
      <el-table-column label="操作" width="80">
        <template #default="scope">
          <el-popover placement="bottom" :width="50" trigger="click">
            <template #reference>
              <el-icon class="link-more">
                <MoreFilled/>
              </el-icon>
            </template>
            <div class="option-list">
              <a href="javascript:void(0)" v-if="scope.row.examineStatus == 0" class="primary" @click="handlePublish(scope.row.id)">审核</a>
            </div>
          </el-popover>


        </template>
      </el-table-column>
    </el-table>
    <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>

    <el-dialog v-model="isShow" title="请填写您的需求">
      需求内容
    </el-dialog>
  </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import {ElTable, ElTableColumn, ElButton, ElDialog, ElResult, ElPopover, ElIcon} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';

export default {
  components: {SearchBar, ElTable, ElTableColumn, ElButton, ElIcon, ElDialog, ElResult, ElPopover, MoreFilled, Pager},
  setup() {
    const {proxy} = getCurrentInstance();
    const currentPage = ref(1);
    const loading = ref(false);
    const searchKey = ref('');
    const tablePager = ref({
      total: 1,
      page: 1,
      list: [{
        id: '1', category: '科学数据',
        user: '高性能物理实验室',
        result: '待审批', date: '2021-12-12'
      }]
    })

    function loadData() {
      loading.value = true;
      const url = '/hfapplication/gdsti/c/resourcesCatalogue/list.do';
      proxy.$http.post(url, {limit: 10, offset: (currentPage.value - 1) * 10, cataloguetNameCn: searchKey.value})
          .then(res => {
            loading.value = false
            if (res.data.state == 0) {
              tablePager.value = res.data.result;
            }
          }).catch(err => {
        loading.value = false
      })
    }

    function pageChange(index) {
      currentPage.value = index;
      loadData();
    }

    function search(searchKeyStr) {
      searchKey.value = searchKeyStr;
      pageChange(1);
    }

    const multipleSelection = ref([])

    function handleSelectionChange(selections) {
      multipleSelection.value = selections;
    }


    function indexFormatter(row, column, cellValue, index) {
      return row + 1 + '';
    }

    function handleDelete(index) {
      console.log(index)
    }

    function handleUpdate(index) {

    }

    function handlePublish(index) {

    }

    function handleDeleteAll() {

    }


    const isShow = ref(false)

    function showRequire() {
      isShow.value = true;
    }

    function statusFormatter(row, column, cellValue, index) {
      if (cellValue == 1) {
        return '审核通过';
      } else if (cellValue == 0) {
        return '待审核';
      } else if (cellValue == -1) {
        return '审核不通过';
      } else {
        return '待提交审核';
      }
    }


    onMounted(() => {
      loadData();
    })

    return {
      tablePager, pageChange, search, currentPage, multipleSelection, indexFormatter, statusFormatter,
      handleDelete, handleDeleteAll, handleSelectionChange, handleUpdate, handlePublish,
      isShow, showRequire
    }
  }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

</style>

