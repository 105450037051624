<template>
    <div class="user-info" v-loading="loading">
        <div class="collect-head">
            <SearchBar @onSearch="search"></SearchBar>
            <div class="select" >
                <span v-if="multipleSelection.length>0">已选：{{multipleSelection.length}} <el-button type="danger" class="table-btns" @click="handleDeleteAll()">删除选中</el-button></span>
<!--                <el-button type="primary" class="table-btns" @click="showRequire()">新增</el-button>-->
            </div>
        </div>
        <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
<!--            <el-table-column type="selection"  width="55"/>-->
<!--            <el-table-column type="index" label="序号" width="80"/>-->
            <el-table-column prop="objectionTitle" label="数据名称" />
            <el-table-column prop="objectionType" label="申述类型" />
            <el-table-column prop="objectionInfluence" label="影响" />
            <el-table-column prop="objectionDescribe" label="描述" />
            <el-table-column prop="createTime" label="提交时间" />
            <el-table-column prop="createManName" label="申述用户" />
            <el-table-column  label="状态" width="220">
              <template #default="scope">
               <span>已受理</span>
              </template>
            </el-table-column>
        </el-table>
        <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>


    </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import {ElTable, ElTableColumn, ElButton, ElDialog, ElResult, ElPopover, ElIcon} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    components: {SearchBar, ElTable, ElTableColumn, ElButton, ElIcon,ElDialog, ElResult, ElPopover, MoreFilled, Pager},
    setup(){
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1);
        const loading = ref(false);
        const appraiseLogLoad = ref(false);
        const appraiseLogList = ref([

        ]);

        const searchKey = ref('');
        const tablePager = ref({
            total: 1,
            page: 1,
            list: []
        })
      const dialogVisible = ref(false);

        const appraiseLogParam  = ref({
          content: '',
          equipApplyId: '',
          // 响应方
          appraiseUserType: 2,
        });

        function loadData(){
            loading.value = true;
            let param = {
                limit: 10,
                offset: (currentPage.value-1)*10,
                searchValue: searchKey.value,
                selectType: 1,
            }
            proxy.$http.post('/gdsti2/resource/c/resDataObjection/list', param)
            .then(res=>{
                loading.value = false
                if(res.data.state == 0){
                    tablePager.value = res.data.result;
                }
            }).catch(err=>{
                loading.value = false
            })
        }

        function timestamp(item){
          let text = '';
          if(item.appraiseUserType === 1){
            text = '申请方 '+item.createTime;
          }else{
            text = '响应方（我） '+item.createTime;
          }
          return text;
        }
        

        function pageChange(index){
            currentPage.value = index;
            loadData();
        }

        function search(val){
            searchKey.value = val;
            pageChange(1);
        }

        const multipleSelection = ref([])
        function handleSelectionChange(selections){
            multipleSelection.value = selections;
        }




        function indexFormatter(row, column, cellValue, index){
            return row+1+'';
        }

        function handleDelete(index){
            console.log(index)
        }

        function handleUpdate(index){

        }
        function handlePublish(index){
            
        }

        function handleDeleteAll(){

        }

        

        const isShow = ref(false)
        function showRequire(){
            isShow.value = true;
        }

        function statusFormatter(row, column, cellValue, index){
            if(cellValue == 1){
                return '已处理';
            }else{
                return '待处理';
            }
        }

        

        onMounted(()=>{
            loadData();
        })

        return {tablePager,pageChange, search,currentPage, multipleSelection, indexFormatter, statusFormatter,
        handleDelete, handleDeleteAll, handleSelectionChange, handleUpdate, handlePublish,dialogVisible,appraiseLogParam,
        isShow, showRequire,loading,appraiseLogLoad,appraiseLogList,timestamp
        }
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

</style>

