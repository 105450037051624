<template>
    <div class="carousel" :style="{width: width+'px', height: 420+'px'}" v-loading="loading">
        <template v-for="(item, itemIndex) in bannerList">
            <div class="screen" :class="item.class" >
                <div class="carousel-item" :class="item.animationClass" :style="{width: '340px'}" >
                    <div class="open-item">
                        <div class="item-name">{{item.title}}</div>
                        <div class="item-list" v-html="item.content">
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <img class="carousel-left" src="@static/img/left.svg" @click="previous"/>
        <img class="carousel-right" src="@static/img/right.svg" @click="next" />

    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
  props: {
    width: {
      type: Number,
      default: 1200
    },
    height: {
      type: Number,
      default: 400
    }
  },
  setup(){
    const {proxy} = getCurrentInstance();
    const loading = ref(false)

    const bannerList = ref([]);
    function getBannerList(){
      loading.value = true;
        const url = '/hfapplication/gdsti/a/openService/topListContent';
        proxy.$http.post(url).then(res=>{
                loading.value = false
                if(res.data.state == 0){
                    bannerList.value = res.data.result;
                    changeCarousel(2);
                }
            }).catch(err=>{
                loading.value = false
            })
        }

    onMounted(()=>{
      getBannerList();
    })
  /*
    const bannerList = ref([
                {
                    animationClass: '',
                    class: 'screen1',
                    name: '科技管理规划',
                    items: ["绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题"]
                },
                {
                    animationClass: '',
                    class: 'screen2',
                    name: '科技管理规划',
                    items: ["绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题"]
                },
                {
                    animationClass: '',
                    class: 'screen3',
                    name: '科技管理规划',
                    items: ["绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题"]
                },
                {
                    animationClass: '',
                    class: 'screen4',
                    name: '科技管理规划',
                    items: ["绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题"]
                },
                {
                    animationClass: '',
                    class: 'screen5',
                    name: '科技管理规划',
                    items: ["绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题","绩效评估内容标题"]
                }
            ]);*/

        const carouselActive = ref(0);
        const carouselLeft1 = ref(0);
        const carouselLeft2 = ref(0);
        const carouselRight1 = ref(0);
        const carouselRight2 = ref(0);

        function changeCarousel(index){
            let maxLength = bannerList.value.length;
            if(index >= maxLength){
                carouselActive.value = 0;
            }else if(index < 0){
                carouselActive.value = maxLength - 1;
            }else{
                carouselActive.value = index;
            }

            carouselLeft1.value = carouselActive.value-1;
            if(carouselLeft1.value < 0){
                carouselLeft1.value += maxLength;
            }
            carouselLeft2.value = carouselActive.value-2;
            if(carouselLeft2.value < 0){
                carouselLeft2.value += maxLength;
            }
            carouselRight1.value = carouselActive.value+1;
            if(carouselRight1.value >= maxLength){
                carouselRight1.value -= maxLength;
            }
            carouselRight2.value = carouselActive.value+2;
            if(carouselRight2.value >= maxLength){
                carouselRight2.value -= maxLength;
            }

            let end = 'P';
            if(isNext.value){
                end = 'N';
            }

            bannerList.value[carouselActive.value]['class']='screen3' + end;
            bannerList.value[carouselLeft1.value]['class']='screen2'  + end;
            bannerList.value[carouselLeft2.value]['class']='screen1'  + end;
            bannerList.value[carouselRight1.value]['class']='screen4' + end;
            bannerList.value[carouselRight2.value]['class']='screen5' + end;

            bannerList.value[carouselActive.value]['animationClass']='carouselActive'+ end;
            bannerList.value[carouselLeft1.value]['animationClass']='carouselLeft1' + end;
            bannerList.value[carouselLeft2.value]['animationClass']='carouselLeft2' + end;
            bannerList.value[carouselRight1.value]['animationClass']='carouselRight1' + end;
            bannerList.value[carouselRight2.value]['animationClass']='carouselRight2' + end;

        }

        const isNext = ref(false);

        const lastTime = ref(new Date().getTime());

        function next(){
            const currentTime = new Date().getTime();
            if(currentTime - lastTime.value > 600){
              const index = carouselActive.value-1;
              isNext.value = true;
              changeCarousel(index);
              lastTime.value = currentTime;
            }
            
        }

        function previous(){
          const currentTime = new Date().getTime();
          if(currentTime - lastTime.value > 600){
            const index = carouselActive.value+1;
            isNext.value = false;
            changeCarousel(index);
            lastTime.value = currentTime;
          }
        }


      

        return  {isNext,loading, bannerList, carouselActive, carouselLeft1, carouselLeft2, carouselRight1, carouselRight2, changeCarousel, next, previous};
  }
};
</script>

<style lang="less" scoped>

.open-item{
        width: 300px;
        height: 355px;
        padding: 20px 20px;
        background: #FFFFFF;
        box-shadow: 0 2px 20px 0 rgba(165,184,201,0.30);
        .item-name{
            font-family: SourceHanSansCN-Medium;
            font-size: 22px;
            color: #0A62CA;
            line-height: 22px;
            padding-bottom: 11px;
            border-bottom: 2px solid #0A62CA;
            text-align: center;
        }
        .item-list{
            padding-top: 15px;
            padding-left: 20px;
            padding-right: 20px;
            text-align: left;
            .item-text{
                font-family: SourceHanSansCN-Regular;
                font-size: 18px;
                color: rgba(0,0,0,0.85);
                margin-bottom: 16px;
                line-height: 18px;
            }
        }
    }
.carousel {
  position: relative;
  width: 1200px;
  display: flex;

  .screen {
    transform-style: preserve-3d;
    perspective: 400px;
    height: 400px;
    position: absolute;
  }

  .carousel-right,
  .carousel-left {
    position: absolute;
    cursor: pointer;
    top: 173px;
    z-index: 10;
  }
  .carousel-right {
    right: 340px;
  }
  .carousel-left {
    left: 340px;
  }
  .carousel-item {
    position: absolute;
    text-align: center;
  }

  .screen1P {
    animation: screen1P 600ms;
    animation-fill-mode: forwards;
    z-index: 3;
  }
  .screen2P {
    animation: screen2P 600ms;
    animation-fill-mode: forwards;
    z-index: 4;
  }
  .screen3P {
    z-index: 5;
    animation: screen3P 600ms;
    animation-fill-mode: forwards;
  }
  .screen4P {
    animation: screen4P 600ms;
    animation-fill-mode: forwards;
    z-index: 4;
  }
  .screen5P {
    animation: screen5P 600ms;
    animation-fill-mode: forwards;
    z-index: 3;
  }
  .screen1N {
    animation: screen1N 600ms;
    animation-fill-mode: forwards;
    z-index: 2;
  }
  .screen2N {
    animation: screen2N 600ms;
    animation-fill-mode: forwards;
    z-index: 4;
  }
  .screen3N {
    z-index: 5;
    animation: screen3N 600ms;
    animation-fill-mode: forwards;
  }
  .screen4N {
    animation: screen4N 600ms;
    animation-fill-mode: forwards;
    z-index: 4;
  }
  .screen5N {
    animation: screen5N 600ms;
    animation-fill-mode: forwards;
    z-index: 3;
  }

  @keyframes screen1N {
    0% {
      transform: translateX(1060px);
      width: 140px;
    }
    100% {
      transform: translateX(0px);
      width: 140px;
    }
  }

  @keyframes screen2N {
    0% {
      transform: translateX(0px);
      width: 140px;
    }
    100% {
      transform: translateX(170px);
      width: 230px;
    }
  }

  @keyframes screen3N {
    0% {
      transform: translateX(170px);
      width: 230px;
    }
    100% {
      transform: translateX(430px);
      width: 340px;
    }
  }

  @keyframes screen4N {
    0% {
      transform: translateX(430px);
      width: 340px;
    }
    100% {
      transform: translateX(800px);
      width: 230px;
    }
  }
  @keyframes screen5N {
    0% {
      transform: translateX(800px);
      width: 230px;
    }
    100% {
      transform: translateX(1060px);
      width: 140px;
    }
  }

  @keyframes screen1P {
    0% {
      transform: translateX(170px);
      width: 230px;
    }
    100% {
      transform: translateX(0px);
      width: 140px;
    }
  }

  @keyframes screen2P {
    0% {
      transform: translateX(430px);
      width: 140px;
    }
    100% {
      transform: translateX(170px);
      width: 230px;
    }
  }

  @keyframes screen3P {
    0% {
      transform: translateX(800px);
      width: 230px;
    }
    100% {
      transform: translateX(430px);
      width: 340px;
    }
  }

  @keyframes screen4P {
    0% {
      transform: translateX(1060px);
      width: 340px;
    }
    100% {
      transform: translateX(800px);
      width: 230px;
    }
  }
  @keyframes screen5P {
    0% {
      transform: translateX(0px);
      width: 140px;
    }
    100% {
      transform: translateX(1060px);
      width: 140px;
    }
  }

  .carousel-item.carouselActiveP {
    animation: carouselActiveP 600ms;
    animation-fill-mode: forwards;
    z-index: 5;
  }
  .carousel-item.carouselLeft1P {
    animation: carouselLeft1P 600ms;
    animation-fill-mode: forwards;
    z-index: 4;
  }
  .carousel-item.carouselLeft2P {
    animation: carouselLeft2P 600ms;
    animation-fill-mode: forwards;
    z-index: 3;
  }
  .carousel-item.carouselRight1P {
    animation: carouselRight1P 600ms;
    animation-fill-mode: forwards;
    z-index: 4;
  }
  .carousel-item.carouselRight2P {
    animation: carouselRight2P 600ms;
    animation-fill-mode: forwards;
    z-index: 3;
  }

  .carousel-item.carouselActiveN {
    animation: carouselActiveN 600ms;
    animation-fill-mode: forwards;
    z-index: 5;
  }
  .carousel-item.carouselLeft1N {
    animation: carouselLeft1N 600ms;
    animation-fill-mode: forwards;
    z-index: 4;
  }
  .carousel-item.carouselLeft2N {
    animation: carouselLeft2N 600ms;
    animation-fill-mode: forwards;
    z-index: 2;
  }
  .carousel-item.carouselRight1N {
    animation: carouselRight1N 600ms;
    animation-fill-mode: forwards;
    z-index: 4;
  }
  .carousel-item.carouselRight2N {
    animation: carouselRight2N 600ms;
    animation-fill-mode: forwards;
    z-index: 3;
  }

  @keyframes carouselLeft2P {
    0% {
      transform: rotateY(15deg) scale(0.85);
      left: -50px;
    }
    100% {
      transform: rotateY(60deg) scale(0.7);
      left: -92px;
    }
  }

  @keyframes carouselLeft1P {
    0% {
      transform: rotateY(0deg) scale(1);
      left: 0px;
    }
    100% {
      transform: rotateY(15deg) scale(0.85);
      left: -50px;
    }
  }

  @keyframes carouselActiveP {
    0% {
      transform: rotateY(-15deg) scale(0.85);
      left: -60px;
    }
    100% {
      transform: rotateY(0deg) scale(1);
      left: 0px;
    }
  }

  @keyframes carouselRight1P {
    0% {
      transform: rotateY(-60deg) scale(0.7);
      left: -108px;
    }
    100% {
      transform: rotateY(-15deg) scale(0.85);
      left: -60px;
    }
  }
  @keyframes carouselRight2P {
    0% {
      transform: rotateY(60deg) scale(0.7);
      left: -92px;
    }
    100% {
      transform: rotateY(-60deg) scale(0.7);
      left: -108px;
    }
  }

  @keyframes carouselLeft2N {
    0% {
      transform: rotateY(-60deg) scale(0.7);
      left: -108px;
    }
    100% {
      transform: rotateY(60deg) scale(0.7);
      left: -92px;
    }
  }

  @keyframes carouselLeft1N {
    0% {
      transform: rotateY(60deg) scale(0.7);
      left: -92px;
    }
    100% {
      transform: rotateY(15deg) scale(0.85);
      left: -50px;
    }
  }

  @keyframes carouselActiveN {
    0% {
      transform: rotateY(15deg) scale(0.85);
      left: -50px;
    }
    100% {
      transform: rotateY(0deg) scale(1);
      left: 0px;
    }
  }

  @keyframes carouselRight1N {
    0% {
      transform: rotateY(0deg) scale(1);
      left: 0px;
    }
    100% {
      transform: rotateY(-15deg) scale(0.85);
      left: -60px;
    }
  }
  @keyframes carouselRight2N {
    0% {
      transform: rotateY(-15deg) scale(0.85);
      left: -60px;
    }
    100% {
      transform: rotateY(-60deg) scale(0.7);
      left: -108px;
    }
  }
}
</style>
