<template>
    <div class="content">
        <Bread :breads="breads"></Bread>
        <div class="news-content" v-loading="loading">
<!--            <div class="title">{{newsInfo.adTitle}}</div>-->
<!--            <div class="info">-->
<!--                <span class="date">时间：{{newsInfo.adDate}}</span>-->
<!--                <span class="source">来源：{{newsInfo.source}}</span>-->
<!--                <span class="count">浏览量：{{newsInfo.views}}</span>-->
<!--            </div>-->
            
            <div class="desc" id="desc">
                <p></p><p><strong><span style="font-family: 仿宋_GB2312;font-size: 21px">数字基建</span></strong></p><p style="margin-top: 0;margin-right: 0;margin-bottom: 12px;text-indent: 43px;padding: 0;line-height: 30px;background: rgb(255, 255, 255)"><span style=";font-family:仿宋_GB2312;font-size:21px">建成全国首个化妆品</span><span style=";font-family:仿宋_GB2312;font-size:21px">、</span><span style=";font-family:仿宋_GB2312;font-size:21px">皮革制品</span><span style=";font-family:仿宋_GB2312;font-size:21px">工业互联网标识解析二级节点</span><span style=";font-family:仿宋_GB2312;font-size:21px">，</span><span style=";font-family:仿宋_GB2312;font-size:21px"><span style="font-family:仿宋_GB2312">建设</span><span style="font-family:仿宋_GB2312">“</span><span style="font-family:Times New Roman">1+6+N</span><span style="font-family:仿宋_GB2312">”智慧园区，获评工信部</span><span style="font-family:Times New Roman">2022</span><span style="font-family:仿宋_GB2312">年智慧园区解决方案优秀案例</span></span><span style="font-family: 仿宋_GB2312;font-size: 21px">。</span></p><p><img src=" /hf/base/a/fileManage/getImage?attachCode=1594367315514859522" title="微信截图_20221119163157.png" alt="微信截图_20221119163157.png"/></p><p><img src=" /hf/base/a/fileManage/getImage?attachCode=1594367354152787970" title="微信截图_20221119163301.png" alt="微信截图_20221119163301.png"/></p><p><img src=" /hf/base/a/fileManage/getImage?attachCode=1594367371420737538" title="微信截图_20221119163322.png" alt="微信截图_20221119163322.png"/></p><p><img src=" /hf/base/a/fileManage/getImage?attachCode=1594367397068906497" title="微信截图_20221119163330.png" alt="微信截图_20221119163330.png"/></p><p><img src=" /hf/base/a/fileManage/getImage?attachCode=1594367422570274818" title="微信截图_20221119163340.png" alt="微信截图_20221119163340.png"/></p>
            </div>
        </div>
    </div>
</template>

<script>
import Bread from '../../../components/common/Bread.vue'
import { useRoute} from 'vue-router'
import { ref, onMounted, getCurrentInstance} from 'vue'
export default {
    data(){
        return {
            breads:[
                {
                    text: '特色专区',
                    name: 'SpecialIndustry',
                    path: '/specialIndustry'
                },
                {
                    text: '产业专区',
                    name: 'Industry',
                    path: '/industry'
                },{
                    text: '数字基建',
                }
            ],
            active: 0
        }
    },
    setup(){
        const route = useRoute();
        const params = route.params;
        const query = route.query;
        const {proxy} = getCurrentInstance();
        const loading = ref(false)
        const newsInfo = ref({});
        const type = ref(query.type);
        const name = ref(query.name);
        const path = ref(query.path);

        function increase(){
            const url = '/hfapplication/gdsti/a/adInfo/addViews';
            proxy.$http.post(url, {id: query.id}).then(res=>{}).catch(err=>{})
        }

        onMounted(()=>{

        })

        return {
            newsInfo, loading
        }
    },
    components: {Bread},
    mounted(){
    //     let script = document.createElement('script');
    //     script.type = 'text/javascript';
    //     script.src = '/static/ueditor/ueditor.parse.min.js'
    //     document.body.appendChild(script);
    //     // console.log('mounted')
    //     uParse('#desc', {
    //         rootPath: "/static/ueditor";
    //     })
    }
 
}
</script>

<style lang="less" scoped>
.news-content{
    margin-top: 12px;
    margin-bottom: 60px;
    width: 1200px;
    overflow: auto;
    display: flex;
    justify-content: center;
    img{
        max-width: 1000px;
    }
    .title{
        margin-top: 14px;
        font-family: SourceHanSansCN-Medium;
        font-size: 26px;
        color: rgba(0,0,0,0.85);
        text-align: center;
    }
    .info{
        font-family: SourceHanSansCN-Normal;
        font-size: 14px;
        color: rgba(0,0,0,0.50);
        margin-top: 29px;
        padding-bottom: 7px;
        border-bottom: 1px solid #E3E3E3;
        .date{
            margin-right: 27px;
        }
        .count{
            float:right;
        }
    }
    
    .desc{
        margin-top: 25px;
        width: 1000px;
    }
}
</style>

