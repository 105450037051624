<template>
    <div class="platform">
        <div class="content">
            <div class="platform-title" @click="openPlatform(0)">实验室与平台基地</div>
            <div class="platform-list" v-if="platforms">
                <template v-for="(item, index) in platforms" :key="item.typeCode">
                        <div class="platform-item" @click="openPlatform(index+1)">
                                <div class="number">{{item.platformNum}}</div>
                                <div class="name">{{item.typeName}}</div>
                        </div>
                </template>
                <template v-show="platforms.length%5!=0" v-for="(item1,index1) in 5-platforms.length%5" :key="index1+'.'">
                        <div class="platform-item-space"></div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue'
export default {
    setup(){
            const {proxy} = getCurrentInstance();
            const platforms = ref('');

            function getPlatforms(){
                    const url = '/gdsti2/web/a/webLab/platformCount';
                    proxy.$http.post(url).then(res=>{
                            if(res.data.state == 0){
                                    platforms.value = res.data.result;
                                    // console.log(platforms);
                            }
                    }).catch(err=>{

                    })
            }

            onMounted(()=>{
                    getPlatforms();
            });

            return {platforms}

    },
    methods:{
        openPlatform: function(index){
                // this.$router.push({name:'Platform', path:'/platform', query: {index: index}})
            let routeData = this.$router.resolve({name:'Platform', path:'/platform', query: {index: index}});
            window.open(routeData.href, '_blank');
        }
    }
}
</script>

<style lang="less" scoped>

@import url(../../../public/static/css/index.less);



</style>

