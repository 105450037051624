<template>
    <div class="user-info"  v-loading="loading">
        <div class="collect-head">
            <!--            <SearchBar @onSearch="search"></SearchBar>-->
            <div class="topic-search">
                仪器/数据名称 <input type="text" class="search-input" placeholder="请输入" v-model="searchKey">
                <button type="button" class="btn btn-primary" @click="search">查询</button>
            </div>
        </div>

        <el-table
            :data="tablePager.list"
            style="width: 100%"
            @selection-change="handleSelectionChange"
            :row-key="getRowKey"
        >
            <el-table-column type="selection" :reserve-selection="true" width="55"/>
            <el-table-column type="index" label="序号" width="80"/>
            <el-table-column prop="typeName" label="数据/资源类型"/>
            <el-table-column prop="catalogName" label="所属目录"/>
            <el-table-column prop="cname" label="中文名称"/>
            <el-table-column prop="ename" label="英文名称"/>
            <el-table-column prop="subject" label="学科分类">
                <template #default="scope">
                  {{subjectFormatter(scope.row.subject)}}
                </template>
            </el-table-column>
            <el-table-column prop="innerId" label="仪器所在单位内部编码"/>
            <!--            <el-table-column  label="操作" width="80" v-if="false">-->
            <!--                <template #default="scope">-->
            <!--                    <el-popover placement="bottom" :width="50" trigger="click">-->
            <!--                        <template #reference>-->
            <!--                            <el-icon class="link-more"><MoreFilled /></el-icon>-->
            <!--                        </template>-->
            <!--                        <div class="option-list">-->
            <!--                            <a href="javascript:void(0)" class="danger" @click="handleDelete(scope.$index)">删除</a>-->
            <!--                            <a href="javascript:void(0)" class="primary" @click="handleDownload(scope.$index)">下载</a>-->
            <!--                            <a href="javascript:void(0)" class="primary" @click="handleView(scope.$index)">查看</a>-->
            <!--                        </div>-->
            <!--                    </el-popover>-->
            <!--                    -->

            <!--                    -->
            <!--                </template>-->
            <!--            </el-table-column>-->
        </el-table>
        <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>

        <div class="bottom-btns">
            <el-button type="default" @click="back">返回</el-button>
            <el-button type="primary" class="table-btns" @click="nextClick()">已选：{{ multipleSelection.length }} 下一步
            </el-button>

        </div>
    </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import {ElTable, ElTableColumn, ElButton, ElDialog, ElResult, ElPopover, ElIcon} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';

export default {
    components: {SearchBar, ElTable, ElTableColumn, ElButton, ElIcon, ElDialog, ElResult, ElPopover, MoreFilled, Pager},
    setup() {
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1);
        const loading = ref(false);
        const searchKey = ref('');
        const tablePager = ref({
            total: 1,
            page: 1,
            list: []
        })

        const subjectList = ref([])

        function loadData() {
            loading.value = true;
            const url = '/gdsti2/resource/c/resCatalogInfo/resourceDataList';
            proxy.$http.post(url, {limit: 10, offset: (currentPage.value - 1) * 10, cname: searchKey.value, isMyPlatform: "1"})
                .then(res => {
                    loading.value = false
                    // console.log('resourceDataList', res.data.result)
                    if (res.data.state == 0) {
                        tablePager.value = res.data.result;
                    }
                }).catch(err => {
                loading.value = false
            })
        }
        async function loadDataDictList(dictTypeCode) {    //加载数据字典
            let dataDictList = [];
            await proxy.$http.post('/core/base/c/dictInfo/list?noPage=1&typeCode=' + dictTypeCode, {}).then(res => {

                dataDictList = res.data.result;

            }).catch(err => {

            })

            return dataDictList;
        }

        function pageChange(index) {
            currentPage.value = index;
            loadData();
        }

        function search() {
            pageChange(1);
        }

        const multipleSelection = ref([])

        function handleSelectionChange(selections) {
            multipleSelection.value = selections;
        }

        function getRowKey(row){
            return row.id
        }


        function indexFormatter(row, column, cellValue, index) {
            return row + 1 + '';
        }

        function handleDelete(index) {
            console.log(index)
        }

        function handleDownload(index) {

        }

        function handleView(index) {

        }

        function handleDeleteAll() {

        }


        function statusFormatter(row, column, cellValue, index) {
            if (cellValue == 1) {
                return '已处理';
            } else {
                return '待处理';
            }
        }

        function subjectFormatter(cellValue) {
            // console.log('subjectList', cellValue, subjectList.value);
            if(cellValue){
                let textArr = [];
                let subjectCodeArr = cellValue.split(',');
                for(let item of subjectCodeArr){
                    for(let dictItem of subjectList.value){
                        if(item == dictItem.dictVal){
                            textArr.push(dictItem.dictName);
                        }
                    }
                }
                return textArr.join(',');
            }
            return cellValue;
        }

        async function init(){
            subjectList.value = await loadDataDictList('SUBJECT');

            loadData();
        }



        onMounted(() => {
            init();

            
        })

        return {
            tablePager, pageChange, search, currentPage, multipleSelection, indexFormatter, statusFormatter,
            handleDelete, handleDeleteAll, handleSelectionChange, handleDownload, handleView,searchKey,loading,getRowKey,subjectFormatter
        }
    },
    methods: {
        back() {
            this.$router.back();
        },
        nextClick() {
            console.log(this.multipleSelection)
            if (this.multipleSelection.length == 0) {
                this.ElMessage.error("至少选择一条数据！");
                return
            }

            let remittanceDataList = JSON.stringify(this.multipleSelection);

            this.$router.push({
                name: 'ResDataProveForm',
                path: 'resDataProveForm',
                query: {remittanceDataList: remittanceDataList}
            })
        }
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

.topic-search {

    line-height: 25px;
    font-size: 14px;


    .search-input {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 2px;
        padding: 5px 9px;
        margin-left: 9px;
        margin-right: 16px;
        font-size: 14px;
        line-height: 14px;
    }

    .btn {
        display: inline-block;
        padding-top: 2px;
        padding-bottom: 2px;
    }
}
</style>

