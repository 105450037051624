<template>
    <div>
        <div class="open-head">
            <div class="content">
                <div class="open-title">平台状态指标</div>
                <div class="open-desc">
                    <div class="open-box">
                        <div class="open-box-title">
                            <img src="@static/img/platformstatus1.svg"/>
                            指标体系
                        </div>
                        <div class="open-box-content">
                            指标体系概述、技术说明和使用介绍
                        </div>
                    </div>
                    <div class="open-box">
                        <div class="open-box-title">
                            <img src="@static/img/platformstatus2.svg"/>
                            平台指数
                        </div>
                        <div class="open-box-content">
                            查看科技平台简介，通过指数画像了解运行情况
                        </div>
                    </div>
                    <div class="open-box">
                        <div class="open-box-title">
                            <img src="@static/img/platformstatus3.svg"/>
                            专题报告
                        </div>
                        <div class="open-box-content">
                            阅读调查研究报告，了解最新科技创新管理动态
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="content platformstatus">
            <Breads :breads="breads"></Breads>
            <div class="status-block">
                <div class="block-head">
                    <div class="block-title">实验室体系建设</div>
                    <a class="link-more" href="javascript:void(0);" @click="moreClick(0)">查看更多 > </a>
                </div>

                <div class="block-content">
                    <div class="block-name">指标趋势</div>
                    <div class="block-list">
                        <div class="status-item">
                            <div class="item-name">青年科研人员数量</div>
                            <div class="item-content">
                                <div class="item-left">
                                    <div class="status-name">人才队伍</div>
                                    <div class="status-date">2013-2021</div>
                                </div>
                                <div class="item-right">
                                    <img src="@static/img/statusExample.png"/>
                                </div>
                            </div>
                        </div>

                        <div class="status-item">
                            <div class="item-name">青年科研人员数量</div>
                            <div class="item-content">
                                <div class="item-left">
                                    <div class="status-name">人才队伍</div>
                                    <div class="status-date">2013-2021</div>
                                </div>
                                <div class="item-right">
                                    <img src="@static/img/statusExample.png"/>
                                </div>
                            </div>
                        </div>

                        <div class="status-item">
                            <div class="item-name">青年科研人员数量</div>
                            <div class="item-content">
                                <div class="item-left">
                                    <div class="status-name">人才队伍</div>
                                    <div class="status-date">2013-2021</div>
                                </div>
                                <div class="item-right">
                                    <img src="@static/img/statusExample.png"/>
                                </div>
                            </div>
                        </div>

                        <div class="status-item">
                            <div class="item-name">青年科研人员数量</div>
                            <div class="item-content">
                                <div class="item-left">
                                    <div class="status-name">人才队伍</div>
                                    <div class="status-date">2013-2021</div>
                                </div>
                                <div class="item-right">
                                    <img src="@static/img/statusExample.png"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block-content">
                    <div class="block-name">探索指标</div>
                    <div class="block-list">
                        <div class="status-item">
                            <div class="item-name">广东省重点实验室科研产出与所在地的经费支持</div>
                            <div class="item-list">
                                <div class="item-content">
                                    <div class="item-left">
                                        <div class="status-name">人均SCI论文发表数量</div>
                                        <div class="status-date">2013-2021</div>
                                    </div>
                                    <div class="item-right">
                                        <img src="@static/img/statusExample.png"/>
                                    </div>
                                </div>
                                <div class="item-content">
                                    <div class="item-left">
                                        <div class="status-name">财政科技支出</div>
                                        <div class="status-date">2013-2021</div>
                                    </div>
                                    <div class="item-right">
                                        <img src="@static/img/statusExample.png"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="status-item">
                            <div class="item-name">广东省重点实验室科研产出与所在地的经费支持</div>
                            <div class="item-list">
                                <div class="item-content">
                                    <div class="item-left">
                                        <div class="status-name">人均SCI论文发表数量</div>
                                        <div class="status-date">2013-2021</div>
                                    </div>
                                    <div class="item-right">
                                        <img src="@static/img/statusExample.png"/>
                                    </div>
                                </div>
                                <div class="item-content">
                                    <div class="item-left">
                                        <div class="status-name">财政科技支出</div>
                                        <div class="status-date">2013-2021</div>
                                    </div>
                                    <div class="item-right">
                                        <img src="@static/img/statusExample.png"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="status-block">
                <div class="block-head">
                    <div class="block-title">资源开放共享</div>
                    <a class="link-more"  href="javascript:void(0);" @click="moreClick(1)">查看更多  ></a>
                </div>

                <div class="block-content">
                    <div class="block-name">指标趋势</div>
                    <div class="block-list">
                        <div class="status-item">
                            <div class="item-name">青年科研人员数量</div>
                            <div class="item-content">
                                <div class="item-left">
                                    <div class="status-name">人才队伍</div>
                                    <div class="status-date">2013-2021</div>
                                </div>
                                <div class="item-right">
                                    <img src="@static/img/statusExample.png"/>
                                </div>
                            </div>
                        </div>

                        <div class="status-item">
                            <div class="item-name">青年科研人员数量</div>
                            <div class="item-content">
                                <div class="item-left">
                                    <div class="status-name">人才队伍</div>
                                    <div class="status-date">2013-2021</div>
                                </div>
                                <div class="item-right">
                                    <img src="@static/img/statusExample.png"/>
                                </div>
                            </div>
                        </div>

                        <div class="status-item">
                            <div class="item-name">青年科研人员数量</div>
                            <div class="item-content">
                                <div class="item-left">
                                    <div class="status-name">人才队伍</div>
                                    <div class="status-date">2013-2021</div>
                                </div>
                                <div class="item-right">
                                    <img src="@static/img/statusExample.png"/>
                                </div>
                            </div>
                        </div>

                        <div class="status-item">
                            <div class="item-name">青年科研人员数量</div>
                            <div class="item-content">
                                <div class="item-left">
                                    <div class="status-name">人才队伍</div>
                                    <div class="status-date">2013-2021</div>
                                </div>
                                <div class="item-right">
                                    <img src="@static/img/statusExample.png"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="block-content">
                    <div class="block-name">探索指标</div>
                    <div class="block-list">
                        <div class="status-item">
                            <div class="item-name">广东省重点实验室科研产出与所在地的经费支持</div>
                            <div class="item-list">
                                <div class="item-content">
                                    <div class="item-left">
                                        <div class="status-name">人均SCI论文发表数量</div>
                                        <div class="status-date">2013-2021</div>
                                    </div>
                                    <div class="item-right">
                                        <img src="@static/img/statusExample.png"/>
                                    </div>
                                </div>
                                <div class="item-content">
                                    <div class="item-left">
                                        <div class="status-name">财政科技支出</div>
                                        <div class="status-date">2013-2021</div>
                                    </div>
                                    <div class="item-right">
                                        <img src="@static/img/statusExample.png"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="status-item">
                            <div class="item-name">广东省重点实验室科研产出与所在地的经费支持</div>
                            <div class="item-list">
                                <div class="item-content">
                                    <div class="item-left">
                                        <div class="status-name">人均SCI论文发表数量</div>
                                        <div class="status-date">2013-2021</div>
                                    </div>
                                    <div class="item-right">
                                        <img src="@static/img/statusExample.png"/>
                                    </div>
                                </div>
                                <div class="item-content">
                                    <div class="item-left">
                                        <div class="status-name">财政科技支出</div>
                                        <div class="status-date">2013-2021</div>
                                    </div>
                                    <div class="item-right">
                                        <img src="@static/img/statusExample.png"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="status-block">
                <div class="block-head">
                    <div class="block-title">赛事课题动态</div>
                    <a class="link-more"  href="javascript:void(0);" @click="moreClick(2)">查看更多 ></a>
                </div>

                <div class="block-content">
                    <div class="block-name">指标趋势</div>
                    <div class="block-list">
                        <div class="status-item">
                            <div class="item-name">青年科研人员数量</div>
                            <div class="item-content">
                                <div class="item-left">
                                    <div class="status-name">人才队伍</div>
                                    <div class="status-date">2013-2021</div>
                                </div>
                                <div class="item-right">
                                    <img src="@static/img/statusExample.png"/>
                                </div>
                            </div>
                        </div>

                        <div class="status-item">
                            <div class="item-name">青年科研人员数量</div>
                            <div class="item-content">
                                <div class="item-left">
                                    <div class="status-name">人才队伍</div>
                                    <div class="status-date">2013-2021</div>
                                </div>
                                <div class="item-right">
                                    <img src="@static/img/statusExample.png"/>
                                </div>
                            </div>
                        </div>

                        <div class="status-item">
                            <div class="item-name">青年科研人员数量</div>
                            <div class="item-content">
                                <div class="item-left">
                                    <div class="status-name">人才队伍</div>
                                    <div class="status-date">2013-2021</div>
                                </div>
                                <div class="item-right">
                                    <img src="@static/img/statusExample.png"/>
                                </div>
                            </div>
                        </div>

                        <div class="status-item">
                            <div class="item-name">青年科研人员数量</div>
                            <div class="item-content">
                                <div class="item-left">
                                    <div class="status-name">人才队伍</div>
                                    <div class="status-date">2013-2021</div>
                                </div>
                                <div class="item-right">
                                    <img src="@static/img/statusExample.png"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue';
import Breads from '../../components/common/Bread';
export default {
    components:{Breads},
    setup(){
        const breads = ref([{
            text:'开放服务',
            name: 'Open',
            path: '/open'
        },{
            text: '平台状态指标',
        }]);

        return {breads}
    },
    methods:{
        moreClick(index){
            this.$router.push({name:'Building', path: '/building'})
        }
    }

}
</script>

<style lang="less" scoped>
.platformstatus{
    padding-bottom: 20px;
}
.status-block{
    margin-bottom: 18px;
    .block-head{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;
        .block-title{
            font-family: SourceHanSansCN-Bold;
            font-size: 30px;
            color: rgba(0,0,0,0.85);
            line-height: 30px;
        }
    }
    .block-content{
        margin-bottom: 52px;
        width: 100%;
        .block-name{
            font-family: SourceHanSansCN-Bold;
            font-size: 22px;
            color: rgba(0,0,0,0.85);
            margin-bottom: 15px;
        }
        .block-list{
            display: flex;
            justify-content: space-between;

            .status-item{
                background: #FFFFFF;
                box-shadow: 0 2px 10px 0 rgba(165,184,201,0.30);
                padding: 19px 15px 20px 15px;

                .item-name{
                    font-family: SourceHanSansCN-Medium;
                    font-size: 22px;
                    color: rgba(0,0,0,0.85);
                }
                
                .item-content{
                    display: flex;
                    justify-content: space-between;
                    width: 253px;
                    
                    .item-left{
                        display:flex;
                        flex-direction: column;
                        justify-content: space-between;
                        width: 129px;
                        .status-name{
                            font-family: SourceHanSansCN-Medium;
                            font-size: 22px;
                            line-height: 22px;
                            color: #007EEC;
                        }
                        .status-date{
                            font-family: SourceHanSansCN-Regular;
                            font-size: 18px;
                            color: #007EEC;
                        }
                    }
                    .item-right{
                        display: flex;
                        justify-content: flex-end;
                        align-items: flex-end;
                    }
                }
                .item-list{
                    display: flex;
                    justify-content: space-between;
                    .item-content{
                        width: 271px;
                    }
                    .item-content:first-child{
                        margin-right: 16px;
                    }
                }
            }
            
        }
    }
}
.open-head{
    height: 200px;
    width: 100%;
    background-image: url('../../../public/static/img/open_head1920.png');
    background-position: center;
    background-size: contain 100%;
    background-color: #0A62CA;
    background-repeat: no-repeat;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .open-title{
        font-family: SourceHanSansCN-Medium;
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 14px;
    }
    .open-desc{
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        .open-box{
            background: rgba(255,255,255,0.30);
            color: #fff;
            width: 326px;
            height: 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .open-box-title{
                margin-bottom: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 18px;
                font-family: SourceHanSansCN-Medium;
                font-size: 24px;
                line-height: 24px;
                img{
                    margin-right: 8px;
                }
            }
            .open-box-content{
                text-align: center;
                padding-left: 32px;
                padding-right: 32px;
                font-family: SourceHanSansCN-Regular;
                font-size: 16px;
                line-height: 16px;
            }
        }

       
        
    }
    
}
</style>

