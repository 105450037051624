<template>
    <div class="footer">
        <div class="content">
            <div class="footer-content">
                <div class="footer-qrcode">
                  <img src="@static/img/kefu.png"/>
                  <img src="@static/img/gongzhonghao.png" style="margin-left: 20px;"/>
                </div>

                <div class="about">
                    <div class="footer-title">关于平台</div>
                    <div class="footer-text">
                        <router-link :to="{name: 'About', path: '/about'}" class="link-text">关于我们</router-link>
                        <router-link :to="{name: 'Contact', path: '/about/contact'}" class="link-text">联系我们</router-link>
                    </div>
                </div>

                <div class="friend-link">
                    <div class="footer-title">友情链接</div>
                    <div class="footer-text">
                        <div class="footer-row">
                            <a href="https://www.escience.org.cn/" class="link-text" target="_blank">中国科技资源共享网</a>
                            <a href="http://nrii.org.cn/" class="link-text" target="_blank">重大科研基础设施和大型科研仪器国家网络管理平台</a>
                        </div>
                        <div class="footer-row">
<!--                            <a href="http://gdstc.gd.gov.cn/" class="link-text" target="_blank">广东省科学技术厅</a>-->
<!--                            <a href="https://www.gdcc.com.cn/" class="link-text" target="_blank">广东省科技基础条件平台中心</a>-->
                        </div>
                      <div class="footer-row">
                          <a href="http://wx.gdinfo.net/" class="link-text" target="_blank">广东省科技文献共享平台</a>
                        <a href="http://www.gzstp.cn/" class="link-text" target="_blank">广州综合类科技数据共享服务平台</a>

                      </div>
                    </div>
                </div>
            </div>
            <div class="copy-right">版权所有：<a href="https://www.gdcc.com.cn/" target="_blank">广东省科技基础条件平台中心</a> &nbsp; | &nbsp; <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备05020792-3</a> &nbsp;&nbsp;&nbsp;&nbsp; <img class="" style="height: 15px;width: 15px;position: relative;top: 3px;" src="@static/img/police-logo.png"/><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44010402000497" target="_blank" >粤公网安备 44010402000497号</a></div>
            <div class="address">地址：<a href="https://map.qq.com/?what=%E5%B9%BF%E4%B8%9C%E7%9C%81%E5%B9%BF%E5%B7%9E%E5%B8%82%E8%BF%9E%E6%96%B0%E8%B7%AF171&city=%E5%B9%BF%E5%B7%9E%E5%B8%82&type=poi&ref=DFPBZ-TE23D-HHD4G-PY4TF-VE27J-HYBWQ" target="_blank">广东省广州市越秀区连新路171号3号楼</a> &nbsp; | &nbsp; 电话：<a href="tel:020-83163463">020-83163463</a>   </div>
        </div>
    </div>
</template>

<script>
export default {
    methods:{
        gotoBuilding(){
            this.$router.push({name: 'Building', })
        },
        gotoAbout(){
            this.$router.push({name: 'About', path: '/about'})
        },
        gotoContact(){
            this.$router.push({name: 'Contact', path: '/about/contact'})
        }
    }
}
</script>

<style scoped>
.footer{
    width: 100%;
    height: 200px;
    background:  #E3E4E5;
}
.copy-right,.address{
    font-family: SourceHanSansCN-Regular;
    font-size: 12px;
    color: rgba(0,0,0,0.85);
    letter-spacing: 0;
    text-align: center;    
}
.address{
    margin-top: 8px;
}
.footer-content{
    padding-top: 19px;
    display: flex;
}
.footer-qrcode,.about,.friend-link{
    display: inline-block;
}
.footer-qrcode{
    margin-left: 130px;
}
.footer-qrcode img{
    width: 98px;
    height: 98px;
}
.about{
    margin-left: 39px;
}
.friend-link{
    margin-left: 150px;
}
.about .footer-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.footer-title{
    font-family: SourceHanSansCN-Medium;
    font-size: 16px;
    color: rgba(0,0,0,0.85);
    letter-spacing: 0;
    text-align: start;
    line-height: 30px;

}
.footer-text, .footer-text .link-text{
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: rgba(0,0,0,0.50);
    letter-spacing: 0;
    text-align: center;
    line-height: 28px;
    text-decoration: none;
}
.link-text{
    display: inline-block;
}
.link-text:visited,.link-text:active{
    color: rgba(0,0,0,0.50);
}
.link-text:hover{
    color:rgba(0,0,0,0.90);;
}
.friend-link .footer-text{
    display: table;
}
.footer-row{
    display: table-row;
}
.footer-row .link-text{
    display: table-cell;
    text-align: left;
}
.footer-row>:first-child{
    padding-right: 40px;
}
</style>
