<template>

  <div class="">
    可发现（Findable）、可获取（Accessible）、可互操作（Interoperable）、可重用（Reusable）原则（简称“FAIR原则”）于2016年3月在《Scientific Data》上发表。当年9月G20杭州峰会首次将“创新增长方式”列为核心议题，支持FAIR原则在开发科学中应用。
    <div>
      科学数据管理生命周期：
      <p>✔科学数据采集：数据分类分级、数据采集获取、数据清洗加工、数据标识溯源</p>
      <p>✔科学数据汇交：数据形式审查、数据质量审查、传输安全管理</p>
      <p>✔科学数据保存：数据逻辑存储、数据备份恢复、数据导入导出</p>
      <p>✔科学数据共享：数据目录编制、科学数据集发布、数据出境管理、数据产权配置、开放数据竞赛</p>
      <p>✔科学数据利用：数据分析服务、数据产品开发、数据出版管理、数据引用管理、数据知识发现</p>
    </div>
    <img style="width:100%;" src="@static/img/huijiao12.png"/>
      <el-link type="primary" href="/help" target="_blank" style="font-size: larger;"><b><i class="el-icon-warning-outline"></i>《数据汇交导入指引》</b></el-link>
      <div style="display: flex;justify-content: center;padding: 10px;">
          <button type="button" class="btn btn-primary btn-huijiao" @click="gotoNext"> 汇交入口</button>
      </div>


  </div>
</template>

<script>
import CommonUtil from "@/utils/common"
export default {
    name: "Platform",
    data() {
        return {

        }
    },
    setup() {

    },
    components: {},
    mounted() {


    },
    watch: {

    },
    methods: {

        async gotoNext() {
            var userInfo = this.$store.state.userInfo;
            if (userInfo && userInfo.userCode) {
                let isPlatformAdmin = await CommonUtil.isPlatformAdmin();

                if (isPlatformAdmin) {
                    let goPageurl = this.indexUrl + "/user/dataRes";
                    window.open(goPageurl);
                } else {
                    this.ElMessage.error('您没有权限，只有平台负责人才能进行汇交。');
                }
            } else {
                this.ElMessage.error("请先登录");

            }
        },

    }
}
</script>

<style lang="less" scoped>


</style>

