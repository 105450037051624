<template>
    <div class="user-info" v-loading="loading">
        <div class="collect-head">
            <div class="topic-search">
                作品标题 <input type="text" class="search-input" placeholder="请输入" v-model="searchKey">
                <button type="button" class="btn btn-primary" @click="search">查询</button>
            </div>
            <!--      <SearchBar @onSearch="search"></SearchBar>-->
            <div class="select">
                                <span v-if="webConfig.COMPETITION_WORKINFO_ENABLE == '1' && multipleSelection.length>0">已选：{{ multipleSelection.length }} <el-button type="danger" class="table-btns" @click="handleDeleteAll()">删除选中</el-button></span>
                <el-button type="primary" class="table-btns" @click="showRequire()" v-if="webConfig.COMPETITION_WORKINFO_ENABLE == '1'">新增作品</el-button>
                
<!--                 <el-button type="primary" class="table-btns" @click="testDa()">测试后台</el-button>-->
            </div>

        </div>
        <el-table :data="tablePager.list" style="width: 100%">
            <!--            <el-table-column type="selection"  width="55"/>-->
            <!--            <el-table-column type="index" label="序号" width="80"/>-->
            <el-table-column prop="capName" label="参赛作者"/>
            <el-table-column prop="title" label="作品标题"/>
            <el-table-column prop="updateTime" label="保存时间"/>
            <el-table-column label="状态">
                <template #default="scope">
                    <span v-if="webConfig.COMPETITION_WORKINFO_ENABLE == '1'">作品编辑</span>
                    <span v-if="webConfig.COMPETITION_WORKINFO_ENABLE != '1'">已提交</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="220">
                <template #default="scope">

                    <a style="margin-left:20px;" href="javascript:void(0)" class="primary" @click="showEditTeam(scope.row.id)">团队信息</a>
                    <a style="margin-left:20px;" href="javascript:void(0)" class="primary" @click="showCompetitionInfo(scope.row.id)" v-if="webConfig.COMPETITION_WORKINFO_ENABLE == '1'">作品编辑</a>
                    <a style="margin-left:20px;" href="javascript:void(0)" class="primary" @click="showCompetitionInfo(scope.row.id)" v-if="webConfig.COMPETITION_WORKINFO_ENABLE != '1'">作品查看</a>
                    <a style="margin-left:20px;" href="javascript:void(0)" class="danger" @click="handleDelete(scope.row.id)" v-if="webConfig.COMPETITION_WORKINFO_ENABLE == '1'">删除</a>
                </template>
            </el-table-column>
        </el-table>
        <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>
      <div class="table-tip">
        <p><strong>注意事项：</strong></p>
         <p>1、先新增作品并保存，再在操作栏点击“团队信息”填写团队情况；</p>
         <p>2、报名截止时间后（<strong>2023年12月10日24点后</strong>），系统自动提交作品；</p>
         <p>3、作品评审结果请留意首页的“最新发布”；</p>
         <p>4、如有疑问扫描下方二维码在线咨询。</p>
      </div>

        <el-dialog
            title="团队信息"
            v-model="dialogTeamShow"
            width="50%"
            @closed="closeDialog"

        >
            <div v-loading="teamListLoading">


                <div class="select">
<!--                <span v-if="multipleSelection.length>0">-->
<!--                已选：{{ multipleSelection.length }}-->
<!--                <el-button type="danger" class="table-btns" @click="handleDeleteAll()">删除选中</el-button>-->
<!--                </span>-->
                    <el-button type="primary" class="table-btns" @click="addTeamPeople()" v-if="webConfig.COMPETITION_WORKINFO_ENABLE == '1'">新增</el-button>

                </div>

                <el-table
                    @selection-change="handleSelectionChange"
                    :data="teamTableData"
                    style="width: 100%">
<!--                    <el-table-column-->
<!--                        type="selection"-->
<!--                        width="55">-->
<!--                    </el-table-column>-->
                  <el-table-column
                      label="序号"
                      type="index"
                      width="100">
                  </el-table-column>
                    <el-table-column
                        label="角色"
                        width="180">
                        <template #default="scope">
                            <span v-if="scope.row.role == 1">队长</span>
                            <span v-if="scope.row.role == 2">队员</span>
                            <span v-if="scope.row.role == 3">指导老师</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="姓名"
                        width="180">
                    </el-table-column>
                    <el-table-column
                        prop="phone"
                        label="手机号">
                    </el-table-column>
                    <el-table-column label="操作" width="220" v-if="webConfig.COMPETITION_WORKINFO_ENABLE == '1'">
                        <template #default="scope">
                          <a v-if="scope.$index != 0" style="margin-left:20px;" href="javascript:void(0)" class="primary" @click="moveUp(scope.row.id)">上移</a>
                            <a style="margin-left:20px;" href="javascript:void(0)" class="primary" @click="showPeopleInfo(scope.row.id)">编辑</a>
                            <a style="margin-left:20px;" href="javascript:void(0)" class="danger" @click="deleteTeamPoeple(scope.row.id)">删除</a>
                        </template>
                    </el-table-column>
                </el-table>

            </div>
          <div class="table-tip">
            <p><strong>注意事项：</strong></p>
            <p>1、每个参赛者最多可以加入两个团队；</p>
            <p>2、每个团队成员人数不多于5人，并可邀请不超过2位指导老师；</p>
            <p>3、作品提交截止时间后不可修改团队信息，获奖证书的团队成员会按照序号顺序来印制。</p>
          </div>
        </el-dialog>


        <el-dialog
            title="人员信息"
            v-model="dialogTeamPeopleShow"
            width="50%"
            @closed="peopleCloseDialog"
        >

            <el-form ref="teamForm" label-width="150px" :rules="peopleRules" :model="teamInfo" v-loading="teamInfoLoading">

                <el-form-item label="角色" style="padding: 10px;" prop="role">

                    <el-select v-model="teamInfo.role" placeholder="请选择" style="width: 400px;">
                        <el-option
                            v-for="item in roleList"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key">
                        </el-option>
                    </el-select>

                </el-form-item>

                <el-form-item label="姓名" style="padding: 10px;" prop="name">
                    <el-input maxlength="50" placeholder="请填写姓名" show-word-limit v-model="teamInfo.name"
                              style="width: 400px;"></el-input>
                </el-form-item>

                <el-form-item label="性别" style="padding: 10px;" prop="gender">

                    <el-select v-model="teamInfo.gender" placeholder="请选择" style="width: 400px;">
                        <el-option
                            v-for="item in genderList"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key">
                        </el-option>
                    </el-select>

                </el-form-item>

                <el-form-item label="邮箱" style="padding: 10px;" prop="email">

                    <el-input maxlength="100" placeholder="请填写邮箱" show-word-limit v-model="teamInfo.email"
                              style="width: 400px;"></el-input>

                </el-form-item>

                <el-form-item label="手机号码" style="padding: 10px;" prop="phone">

                    <el-input maxlength="100" placeholder="请填写手机号码" show-word-limit v-model="teamInfo.phone"
                              style="width: 400px;"></el-input>

                </el-form-item>

                <el-form-item label="所在省份" style="padding: 10px;" prop="province">

                    <el-select v-model="teamInfo.province" placeholder="请选择" style="width: 400px;">
                        <el-option
                            v-for="item in provinceList"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key">
                        </el-option>
                    </el-select>

                </el-form-item>

                <el-form-item label="所在院校/单位" style="padding: 10px;" prop="institution">

                    <el-input maxlength="100" placeholder="请填写所在院校/单位" show-word-limit v-model="teamInfo.institution"
                              style="width: 400px;"></el-input>

                </el-form-item>

                <el-form-item label="学历（含在读）" style="padding: 10px;" prop="education">

                    <el-select v-model="teamInfo.education" placeholder="请选择" style="width: 400px;">
                        <el-option
                            v-for="item in educationList"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key">
                        </el-option>
                    </el-select>


                </el-form-item>

                <el-form-item label="职称" style="padding: 10px;" prop="professionalTitle">

                    <el-select v-model="teamInfo.professionalTitle" placeholder="请选择" style="width: 400px;">
                        <el-option
                            v-for="item in professionalTitleList"
                            :key="item.key"
                            :label="item.value"
                            :value="item.key">
                        </el-option>
                    </el-select>

                </el-form-item>


                <el-form-item label="专业" style="padding: 10px;">

                    <el-input maxlength="100" placeholder="请填写专业" show-word-limit v-model="teamInfo.major"
                              style="width: 400px;"></el-input>

                </el-form-item>

                <el-form-item label="地址" style="padding: 10px;">

                    <el-input maxlength="200" placeholder="请填写地址" show-word-limit v-model="teamInfo.address"
                              style="width: 400px;"></el-input>

                </el-form-item>

                <div style="display: flex;justify-content: center;padding-top: 30px;">
                    <el-button type="primary" @click="saveTeam()">保存</el-button>
                    <el-button @click="dialogTeamPeopleShow = false">取消</el-button>
                </div>

            </el-form>


        </el-dialog>


        <el-dialog
            title="作品信息"
            v-model="dialogVisible"
            width="50%"
            top="10px"
            height="200px"
            @closed="closeDialog"
        >
            <div v-loading="file_loading">
            <el-form ref="form" label-width="150px" :rules="formRules" :model="competitionInfo" v-loading="competitionInfoLoading">
              <el-row>
                <el-col :span="12">
                  <el-form-item label="参赛赛道" style="padding: 10px;"  prop="trackName">

                    <el-select v-model="competitionInfo.trackName" placeholder="请选择" style="width: 400px;">
                      <el-option
                          v-for="item in trackNameList"
                          :key="item.key"
                          :label="item.value"
                          :value="item.key">
                      </el-option>
                    </el-select>

                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="赛题类型" style="padding: 10px;" prop="topicType">
                    <el-select v-model="competitionInfo.topicType" placeholder="请选择" style="width: 400px;"  @change="topicChange">
                      <el-option
                          v-for="item in topicTypeList"
                          :key="item.key"
                          :label="item.value"
                          :value="item.key">
                      </el-option>
                    </el-select>

                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="24">

                  <el-form-item label="参赛赛题" style="padding: 10px;" prop="contestTopic">

                    <el-select v-model="competitionInfo.contestTopic" :disabled="competitionInfo.topicType == 2" filterable placeholder="请选择（可输入文本检索）"
                               style="width: 716px;">
                      <el-option
                          v-for="item in jinsaiAdList"
                          :key="item.id"
                          :label="item.title"
                          :value="item.id">
                      </el-option>
                    </el-select>


                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="24">
                  <el-form-item label="作品标题" style="padding: 10px;" prop="title">

                    <el-input maxlength="200" show-word-limit v-model="competitionInfo.title"
                              style="width: 716px;"></el-input>

                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="12">
                  <el-form-item label="技术领域" style="padding: 10px;" prop="technologyField">

                    <el-select v-model="competitionInfo.technologyField" placeholder="请选择" style="width: 400px;">
                      <el-option
                          v-for="item in technologyFieldList"
                          :key="item.key"
                          :label="item.value"
                          :value="item.key">
                      </el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="作品形式" style="padding: 10px;" prop="form">
                    <el-select v-model="competitionInfo.form" placeholder="请选择" style="width: 400px;">
                      <el-option
                          v-for="item in formList"
                          :key="item.key"
                          :label="item.value"
                          :value="item.key">
                      </el-option>
                    </el-select>

                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="作品简介" style="padding: 10px;" prop="description">

                    <el-input placeholder="包括作品背景、创新点、拟解决的问题及效果等（限500字）" :rows="5" maxlength="500" type="textarea" show-word-limit
                              v-model="competitionInfo.description" style="width: 716px;"></el-input>
                    <div>
                      <p style="color: red">*作品简介是评审的重要依据之一，请认真填写，主要内容包括作品背景、创新点、拟解决的问题及效果等（限500字）</p>
                    </div>

                  </el-form-item>
                </el-col>

              </el-row>


              <el-row>
                <el-col :span="24">
                  <el-form-item label="作品文件" style="padding: 10px;" prop="fileId">

                    <el-upload
                        class="uploader-btn"
                        :limit="1"
                        :action="uploadUrl"
                        :data="{relateType: 'gdsti_cpt_work_info'}"
                        :on-success="handleFileSuccess"
                        :on-error="handleError"
                        :before-upload="beforeAvatarUpload"
                        :show-file-list="false"
                    >
                      <el-button type="primary">点击上传/替换文件</el-button>
                    </el-upload>
                    <p>
                      <span class="fileName" @click="">{{ competitionInfo.fileName }}</span>
                    </p>


                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="科技资源支撑平台" style="padding: 10px;" prop="platformName">

                    <el-input placeholder="请输入" maxlength="50" show-word-limit v-model="competitionInfo.platformName"
                              style="width: 716px;"></el-input>

                  </el-form-item>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="24">
                  <el-form-item label="科技资源使用说明" style="padding: 10px;" prop="platformUsageId">
                    <el-upload
                        class="uploader-btn"

                        :limit="1"
                        :action="uploadUrl"
                        :on-success="handleFileSuccess2"
                        :on-error="handleError"
                        :before-upload="beforeAvatarUpload"
                        :show-file-list="false"
                    >
                      <el-button
                                  type="primary">点击上传/替换文件</el-button>
                    </el-upload>

                    <p>
                      <span class="fileName" @click="">{{ competitionInfo.platformUsageName }}</span>
                    </p>
                    <el-button type="primary" @click="down()" style="margin-left: 24px">下载模板《科技资源使用说明》</el-button>


                  </el-form-item>
                </el-col>
              </el-row>









                <div style="border: 1px solid black;margin-top: 30px;padding: 10px;">
                    <p>注意事项：</p>
                    <p>1、如参赛作品使用了各类科技资源，请在“科技资源支撑平台”栏填写提供科技资源的平台或单位名称，如未使用则填写“无”；</p>
                    <p>2、论文报告类作品需提交学术论文、研究报告、专利或其他相关证明材料，文件类型限pdf，多个文件请压缩打包上传；</p>
                    <p>3、软硬件开发类作品需提交作品介绍PPT、短视频或其他相关证明材料，文件类型不限，多个文件请压缩打包上传；</p>
                    <P>4、作品文件大小不超过500M，超过500M请扫下方二维码咨询。</P>
                </div>
                <div style="display: flex;justify-content: center;padding-top: 30px;">
<!--                    <el-button type="primary" @click="save()">保存</el-button>-->
                    <el-button @click="closeDialog()">取消</el-button>
                </div>

            </el-form>
</div>

        </el-dialog>


        <el-dialog
            title="参赛须知"
            :show-close="false"
            v-model="isShow"
            width="70%"
            center
            @closed="closeNotice"
        >

            <div>
                <p>一、参赛作品应当导向明确，立场清晰，内容真实客观，符合国家法律法规和风俗习惯，契合公众道德标准，坚持社会主义核心价值观。</p>
                <p>二、“共享杯”大赛坚持公正、公平、公益原则。面向全国范围内在校大学生、科研人员和创新企业征选优秀作品，不向参赛者收取任何参赛费用。</p>
                <p>三、在校大学生、科研人员可以团队或个人名义报名参赛，可根据需要邀请1-2名指导教师给予帮助指导。以团队名义报名参赛的，每个团队成员不超过5人，鼓励跨校跨专业组建团队，鼓励创新型企业科研人员参赛。</p>
                <p>四、请确保参赛作品信息提交准确无误。作品提交时间截止后，所有相关信息（含作品名称及内容、作者姓名及排序、指导老师、单位等）将不接受调整申请。</p>
                <p>五、大赛谢绝已经获得其他竞赛奖项、商业化的或已经获得投资的项目作品参赛。</p>
                <p>六、所有参赛者必须是参赛作品的合法拥有者，不得侵犯他人知识产权或存有涉密风险，如有发现，立即取消参赛资格，涉及相应法律责任由参赛者承担。</p>
                <p>
                    七、组委会协助参赛者获取相关科技资源，涉密科技资源不属于本次大赛所提供的资源范围。鼓励参赛者对大赛提供的资源进行充分的分析挖掘，形成有价值的科技成果。对于资源提供方提出有关协议约定的，参赛者须遵照双方约定。</p>
                <p>八、组委会和各科技资源服务平台优先享有参赛作品或成果的非商业使用权，大赛组委会有保存、展示相关作品的权利。</p>
                <p>九、合作企业、投融机构等大赛支持单位参与大赛作品评审工作，作品商业使用等事宜由支持单位与参赛选手另行协商。</p>
                <p>十、基于大赛提供资源形成的所有成果（含论文、专利等）在正式发表时，参赛者须清晰、明确地标注所用资源的名称、CSTR资源标识符、提供者等信息，同时对大赛进行致谢。</p>


            </div>
            <div style="display: flex;justify-content: center;padding-top: 30px;">
                <el-checkbox v-model="agreeWith">我已阅读并同意以上协议</el-checkbox>
            </div>
            <div style="display: flex;justify-content: center;padding-top: 30px;">
                <el-button @click="nextStep()">下一步</el-button>
            </div>

        </el-dialog>


    </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import {ElTable, ElTableColumn, ElButton, ElDialog, ElResult, ElPopover, ElIcon} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '../../../components/common/Pager'
import {ref, onMounted, computed, getCurrentInstance} from 'vue';

export default {
    components: {SearchBar, ElTable, ElTableColumn, ElButton, ElIcon, ElDialog, ElResult, ElPopover, MoreFilled, Pager},
    setup() {
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1);
        const loading = ref(false);
      const file_loading = ref(false);
        const appraiseLogLoad = ref(false);
        const appraiseLogList = ref([]);

        const webConfig = ref({});



        //赛题资源文章列表
        const jinsaiAdList = ref([]);

        //参赛赛道
        const trackNameList = ref([
            {key: 1, value: '基础研究'},
            {key: 2, value: '技术开发'},
        ]);

        //赛题类型
        const topicTypeList = ref([
            {key: 1, value: '指定性赛题'},
            {key: 2, value: '开放性赛题'},
        ]);

        //技术领域
        const technologyFieldList = ref([
            {key: 1, value: '数学'},
            {key: 2, value: '物理'},
            {key: 3, value: '化学'},
            {key: 4, value: '生命科学'},
            {key: 5, value: '地球科学'},
            {key: 6, value: '材料科学'},
            {key: 7, value: '信息科学'},
            {key: 8, value: '管理科学'},
            {key: 9, value: '医学'},
            {key: 10, value: '工程技术'},
            {key: 11, value: '其他'},
        ]);

      const formList = ref([
        {key: '论文报告', value: '论文报告'},
        {key: '软硬件开发', value: '软硬件开发'},
      ]);

        const formRecommendation = ref([
            {value: '论文报告'},
            {value: '软硬件开发'},
        ]);

        const searchKey = ref('');
        const tablePager = ref({
            total: 1,
            page: 1,
            list: []
        })
        //作品详情
        const competitionInfo = ref({
            id: '',
            trackName: '',
            topicType: '',
            contestTopic: '',
            title: '',
            technologyField: '',
            form: '',
            description: '',
            fileId: '',
            //作品文件名称
            fileName: '',
            platformName: '',
            platformUsageId: '',
            //科技资源支撑平台名称
            platformUsageName: '',
            createName: '',
            createTime: '',
            updateName: '',
            updateTime: '',
            isDeleted: '',
        })
        const dialogVisible = ref(false);

        const appraiseLogParam = ref({
            content: '',
            equipApplyId: '',
            // 申请方
            appraiseUserType: 1,
        });

        //加载网站全局配置
        function loadWebConfig() {
            loading.value = true;
            const url = '/gdsti2/web/c/webManager/getWebConfig';
            proxy.$http.post(url).then(res => {
                    loading.value = false
                    if (res.data.state == 0) {
                        webConfig.value = res.data.result;
                    }
                }).catch(err => {
                loading.value = false
            })
        }


        function loadData() {
            loading.value = true;
            // const url = '/hfapplication/gdsti/a/competition/listCompetitionVO';
            const url = '/gdsti2/competition/c/cptWorkInfo/list';
            proxy.$http.post(url, {
                limit: 10,
                offset: (currentPage.value - 1) * 10,
                searchValue: searchKey.value,
                createMan: proxy.$store.state.userInfo.userCode,
                selectType: 1
            })
                .then(res => {
                    loading.value = false
                    if (res.data.state == 0) {
                        tablePager.value = res.data.result;
                    }
                }).catch(err => {
                loading.value = false
            })
        }

        function timestamp(item) {
            let text = '';
            if (item.appraiseUserType === 1) {
                text = '申请方（我） ' + item.createTime;
            } else {
                text = '响应方 ' + item.createTime;
            }
            return text;
        }


        function pageChange(index) {
            currentPage.value = index;
            loadData();
        }

        function search() {
            pageChange(1);
        }

        const multipleSelection = ref([])

        function handleSelectionChange(selections) {
            multipleSelection.value = selections;
        }



        function handleDelete(id) {

            HFConfirm("确认要删除该作品吗？", "", "warning", function (isConfirm) {
                if (isConfirm) {

                    // const url = '/hfapplication/gdsti/a/competition/removeCompetitionInfo';
                    const url = '/gdsti2/competition/c/cptWorkInfo/delete';
                    proxy.$http.post(url, {ids: id}).then(res => {
                        if (res.data.state == 0) {
                            HFToastrTipResult(res.data);
                            loadData();
                        }
                    }).catch(err => {

                    });

                }
            });
            console.log(index)
        }

        function handleUpdate(index) {

        }

        function handlePublish(index) {

        }


        function handleDeleteAll() {
            HFConfirm("确认要删除选中的成员吗？", "", "warning", function (isConfirm) {
                if (isConfirm) {

                    // const url = '/hfapplication/gdsti/a/competition/removeCompetitionInfo';
                    // proxy.$http.post(url, {competitionInfoId: id}).then(res => {
                    //     if (res.data.state == 0) {
                    //         HFToastrTipResult(res.data);
                    //         loadData();
                    //     }
                    // }).catch(err => {
                    //
                    // });

                }
            });
        }

        const worksFileList = ref([])

        function beforeAvatarUpload(rawFile) {
          file_loading.value = true;
            if (rawFile.size / 1024 / 1024 > 500) {
                proxy.ElMessage.error("文件需要小于500M");
              file_loading.value = false;
                return false;
            }
            return true;
        }

        const fileCodeList = ref([]);


        function handleFileSuccess(res, uploadFile) {
          file_loading.value = false;
            if (res && res.result && res.result.list.length > 0) {
                proxy.ElMessage.success('上传成功');
                var attachInfo = res.result.list[0].attachInfo;
                let oneFile = {
                    fileName: attachInfo.attachName,
                    fileId: attachInfo.id,
                }
                competitionInfo.value.fileId = attachInfo.id;
                competitionInfo.value.fileName = attachInfo.attachName;
            }
        }

        function handleError(response, uploadFile){
          file_loading.value = false;
          proxy.ElMessage.error('上传失败!发生意料之外的异常，请联系网站管理员处理。');
        }

        function handleFileSuccess2(res, uploadFile) {
          file_loading.value = false;
            if (res && res.result && res.result.list.length > 0) {
                proxy.ElMessage.success('上传成功');
                var attachInfo = res.result.list[0].attachInfo;
                let oneFile = {
                    fileName: attachInfo.attachName,
                    fileId: attachInfo.id,
                }
                competitionInfo.value.platformUsageId = attachInfo.id;
                competitionInfo.value.platformUsageName = attachInfo.attachName;
            }
        }


        const isShow = ref(false)
        const dialogTeamShow = ref(false)

        function showRequire() {
          let total = tablePager.value.total
          //不得大于2
            if(total >= 2){
              proxy.ElMessage.error("每个参赛者最多只能提交两件作品。");
            }else {
              isShow.value = true;
            }
            // dialogVisible.value = true;

        }

        //编辑团队信息
        function showEditTeam(id) {
            competitionId.value = id;
            dialogTeamShow.value = true;
            loadTeamList(id);
        }

        //团队成员列表
        function loadTeamList(competitionId) {
            teamListLoading.value = true;
            let data = {
                cptId: competitionId,
                limit: 9999,
            }
            // '/hfapplication/gdsti/a/competition/listTeamInfoVO'
            const url = '/gdsti2/competition/c/cptMemberInfo/list';
            proxy.$http.post(url, data).then(res => {
                console.log(res.data.result.list);
                if (res.data.state == 0) {
                    teamTableData.value = res.data.result.list;
                }
                teamListLoading.value = false;
            }).catch(err => {
                teamListLoading.value = false;
            })
        }

        //作品信息
        function showCompetitionInfo(id) {
            // '/hfapplication/gdsti/a/competition/getCompetitionInfoById'
            const url = '/gdsti2/competition/c/cptWorkInfo/info';
            proxy.$http.post(url, {id: id}).then(res => {
                if (res.data.state == 0) {
                    competitionInfo.value = res.data.result;
                    dialogVisible.value = true;
                }
            }).catch(err => {

            })
        }

        //团队成员信息
        function showPeopleInfo(id) {
            // '/hfapplication/gdsti/a/competition/teamPropleInfo'
            const url = '/gdsti2/competition/c/cptMemberInfo/info';
            proxy.$http.post(url, {id: id}).then(res => {
                if (res.data.state == 0) {
                    teamInfo.value = res.data.result;
                    dialogTeamPeopleShow.value = true;
                }
            }).catch(err => {

            })
        }

        //团队成员排名向上一位
        function moveUp(teamId) {
            // '/hfapplication/gdsti/a/competition/moveUp';
          const url = '/gdsti2/competition/c/cptMemberInfo/moveUp';
          proxy.$http.post(url, {memberId: teamId}).then(res => {
            if (res.data.state == 0) {
              // 刷新列表
              loadTeamList(competitionId.value);
            }
          }).catch(err => {

          })

        }

        function statusFormatter(row, column, cellValue, index) {
            if (cellValue == 1) {
                return '已处理';
            } else {
                return '待处理';
            }
        }

        //是否已阅读并同意
        const agreeWith = ref(false)

        function nextStep() {
            if (agreeWith.value == false) {
                proxy.ElMessage.error("请勾选”我已阅读并同意以上协议“");
            } else {
                isShow.value = false;
                dialogVisible.value = true;

            }
        }

        //获取赛题资源文章列表
        function getJinsaiAdList() {
            loading.value = true;
            // '/hfapplication/gdsti/a/jinsai/list'
            const url = '/core/cms/a/articleContent/list?noPage=1&typeCode=CPT-QUESTION-RESOURCE&timeField=publish_time';
            proxy.$http.post(url, {limit: 9999}).then(res => {
                loading.value = false
                if (res.data.state == 0) {
                    jinsaiAdList.value = res.data.result;
                  jinsaiAdList.value.push(
                      {
                    intro: "",
                    title: "自拟赛题",
                    id: "-1",
                    articleSource: "广东省科技资源共享服务平台"
                  })
                }
            }).catch(err => {
                loading.value = false
            })
        }


        function querySearch(queryString, cb) {

            const results = queryString
                ? formRecommendation.value.filter(createFilter(queryString))
                : formRecommendation.value
            // call callback function to return suggestions
            cb(results)
        }

        function createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.label.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        }

        //下载模板《科技资源使用说明》
        function down() {
          window.location.href="/gdsti2/web/a/web/downloadTemplate?filename=科技资源使用说明";
        }


        function save() {
          proxy.$refs['form'].validate((valid) => {
            if (valid) {
              //通过
              competitionInfoLoading.value = true;
              // const url = '/hfapplication/gdsti/a/competition/saveCompetitionInfo';
              const url = '/gdsti2/competition/c/cptWorkInfo/edit';
              proxy.$http.post(url, competitionInfo.value).then(res => {
                competitionInfoLoading.value = false
                closeDialog();
                loadData();
              }).catch(err => {
                competitionInfoLoading.value = false
              })

            } else {
              //不通过
              console.log('error submit!!');
              return false;
            }
          });


        }

        function closeNotice() {
            agreeWith.value = false;
        }


        function closeDialog() {
            competitionInfo.value = {
                id: '',
                trackName: '',
                topicType: '',
                contestTopic: '',
                title: '',
                technologyField: '',
                form: '',
                description: '',
                fileId: '',
                //作品文件名称
                fileName: '',
                platformName: '',
                platformUsageId: '',
                //科技资源支撑平台名称
                platformUsageName: '',
                createName: '',
                createTime: '',
                updateName: '',
                updateTime: '',
                isDeleted: '',
            }
            dialogVisible.value = false
        }


        const uploadUrl = computed(() => {
            const url = proxy.indexUrl + '/core/base/c/upload/upload';
            return url;
        });


        const teamTableData = ref([]);


        onMounted(() => {
            console.log('abc');
            loadWebConfig();    //加载网站配置
            console.log('abc2');
            loadData();     //加载作品
            console.log('abc3');
            getJinsaiAdList();  //加载赛题资源
            console.log('abc4');
        })


        const dialogTeamPeopleShow = ref(false)

        function peopleCloseDialog() {
            teamInfo.value = {
                id: '',
                role: '',
                name: '',
                gender: '',
                email: '',
                phone: '',
                province: '',
                institution: '',
                education: '',
                professionalTitle: '',
                major: '',
                address: '',
                createName: '',
                createTime: '',
                updateName: '',
                updateTime: '',
            };
            //加载人员列表
            loadTeamList(competitionId.value);
        }

        function addTeamPeople(id) {
            dialogTeamPeopleShow.value = true;
        }

        const teamInfo = ref({
            id: '',
            role: '',
            name: '',
            gender: '',
            email: '',
            phone: '',
            province: '',
            institution: '',
            education: '',
            professionalTitle: '',
            major: '',
            address: '',
            createName: '',
            createTime: '',
            updateName: '',
            updateTime: '',

        })

        const peopleRules = ref({
            role: [
                {required: true, message: '请选择角色', trigger: 'change'},
            ],
            name: [
                {required: true, message: '请填写姓名', trigger: 'blur'}
            ],
            gender: [
                {required: true, message: '请选择性别', trigger: 'change'},
            ],
            email: [
                {required: true, message: '请填写邮箱', trigger: 'blur'},
              {
                type: 'email',
                message: '请输入正确的邮箱地址',
                trigger: ['blur', 'change']
              }
            ],
            phone: [
                {required: true, message: '请填写手机号', trigger: 'blur'},
              {
                pattern: /^1[3|4|5|6|7|8|9][0-9]\d{8}$/,
                message: "请输入正确的手机号码",
                trigger: "blur"
              }
            ],
            province: [
                {required: true, message: '请选择所在省份', trigger: 'change'}
            ],
            institution: [
                {required: true, message: '请填写所在院校/单位', trigger: 'blur'}
            ],
            education: [
                {required: true, message: '请选择学历', trigger: 'change'}
            ],
            professionalTitle: [
                {required: true, message: '请选择职称', trigger: 'change'}
            ]
        })

      const formRules = ref({
        trackName: [
          {required: true, message: '请选择参赛赛道', trigger: 'change'},
        ],
        fileId: [
          {required: true, message: '请上传作品文件', trigger: 'blur'},
        ],
        topicType: [
          {required: true, message: '请选择赛题类型', trigger: 'change'},
        ],
        contestTopic: [
          {required: true, message: '请选择参赛赛题', trigger: 'blur'},
        ],
        title: [
          {required: true, message: '请输入作品标题', trigger: 'blur'},
        ],
        technologyField: [
          {required: true, message: '请选择技术领域', trigger: 'change'},
        ],
        form: [
          {required: true, message: '请选择作品形式', trigger: 'blur'},
        ],
        description: [
          {required: true, message: '请输入作品简介', trigger: 'blur'},
        ],
        platformName: [
          {required: true, message: '请输入科技资源支撑平台名称', trigger: 'blur'},
        ],
        platformUsageId: [
          {required: true, message: '请上传科技资源使用说明', trigger: 'blur'},
        ],
      })


        const roleList = ref([
            {key: 1, value: '队长'},
            {key: 2, value: '队员'},
            {key: 3, value: '指导老师'},
        ])

        const provinceList = ref([
            {key: '11', value: '北京'},
            {key: '12', value: '天津'},
            {key: '13', value: '河北'},
            {key: '14', value: '山西'},
            {key: '15', value: '内蒙古'},
            {key: '21', value: '辽宁'},
            {key: '22', value: '吉林'},
            {key: '23', value: '黑龙江'},
            {key: '31', value: '上海'},
            {key: '32', value: '江苏'},
            {key: '33', value: '浙江'},
            {key: '34', value: '安徽'},
            {key: '35', value: '福建'},
            {key: '36', value: '江西'},
            {key: '37', value: '山东'},
            {key: '41', value: '河南'},
            {key: '42', value: '湖北'},
            {key: '43', value: '湖南'},
            {key: '44', value: '广东'},
            {key: '45', value: '广西'},
            {key: '46', value: '海南'},
            {key: '50', value: '重庆'},
            {key: '51', value: '四川'},
            {key: '52', value: '贵州'},
            {key: '53', value: '云南'},
            {key: '54', value: '西藏'},
            {key: '61', value: '陕西'},
            {key: '62', value: '甘肃'},
            {key: '63', value: '青海'},
            {key: '64', value: '宁夏'},
            {key: '65', value: '新疆'},
            {key: '71', value: '台湾'},
            {key: '81', value: '香港'},
            {key: '82', value: '澳门'},
            {key: '91', value: '国外'},
        ])

        const genderList = ref([
            {key: 1, value: '男'},
            {key: 2, value: '女'},
        ])

        const educationList = ref([
            {key: 1, value: '博士'},
            {key: 2, value: '硕士'},
            {key: 3, value: '本科'},
            {key: 4, value: '大专'},
            {key: 5, value: '其他'},
        ])

        const professionalTitleList = ref([
            {key: 1, value: '正高级'},
            {key: 2, value: '副高级'},
            {key: 3, value: '中级'},
            {key: 4, value: '初级'},
            {key: 5, value: '无'},
        ])

        const teamListLoading = ref(false)
        const teamInfoLoading = ref(false)
        const competitionInfoLoading = ref(false)
        const competitionId = ref('');

        function deleteTeamPoeple(htcId){

            HFConfirm("确认删除该成员吗？", "", "warning", function (isConfirm) {
                if (isConfirm) {

                    //删除人员
                    proxy.$http.post('/gdsti2/competition/c/cptMemberInfo/delete', {ids:htcId}).then(res => {
                        if (res.data.state == 0) {
                            //保存成功
                            proxy.ElMessage.success("操作成功");
                            loadTeamList(competitionId.value);
                        }
                    }).catch(err => {

                    })

                }
            });
        }

        function testDa(){
            openDialog('webwebSpecialResRelateAdd', '关联资源', '/gdsti2v/zone/zoneDefaultInfo/formMulti',  {}, '95%', '95%', function(callbackData){
                    
                    console.log('结果', callbackData);


                });

            // openDialog("selectSchool","项目（机构）","/hfapplication/gdsti/v/relyonWorkplaceProject/dialogEdit.do?justShow=yes&id="+ id,"",900, 700,function(){
            //   that.loadData();
            // });
        }

        function topicChange(e){
          if (e == 2){
            competitionInfo.value.contestTopic = "-1"
          }
        }

        function saveTeam() {
            proxy.$refs['teamForm'].validate((valid) => {
                if (valid) {
                    //通过
                    teamInfoLoading.value = true;
                    let duplicateCheckingParam = {
                        name: teamInfo.value.name,
                        phone: teamInfo.value.phone
                    }
                    //判断角色是否绑定过两个团队
                    proxy.$http.post('/hfapplication/gdsti/a/competition/duplicateChecking', duplicateCheckingParam).then(res => {

                        if (res.data.state == 0) {
                            //通过，没有超过两个团队
                            teamTableData.value = res.data.result.list;
                            teamInfo.value.competitionId = competitionId.value;
                            //保存人员
                            proxy.$http.post('/gdsti2/competition/c/cptMemberInfo/edit', teamInfo.value).then(res => {
                                teamInfoLoading.value = false;
                                loadData();
                                if (res.data.state == 0) {
                                    //保存成功
                                    proxy.ElMessage.success("保存成功");
                                    //关闭人员编辑弹窗
                                    dialogTeamPeopleShow.value = false;

                                } else {
                                    // proxy.ElMessage.error(res.data.message);
                                }

                            }).catch(err => {
                                teamInfoLoading.value = false;
                            })


                        } else{
                            teamInfoLoading.value = false;
                        }

                    }).catch(err => {
                        teamInfoLoading.value = false;
                    })


                } else {
                    //不通过
                    console.log('error submit!!');
                    return false;
                }
            });
        }


        return {
            testDa,
            deleteTeamPoeple,
            teamInfoLoading,
            competitionInfoLoading,
            teamListLoading,
            competitionId,
            saveTeam,
            provinceList,
            teamInfo,
            professionalTitleList,
            educationList,
            roleList,
            genderList,
            peopleRules,
            formRules,
            peopleCloseDialog,
            tablePager,
            pageChange,
            search,
            currentPage,
            multipleSelection,
            statusFormatter,
            handleDelete,
            handleDeleteAll,
            handleSelectionChange,
            handleUpdate,
            handlePublish,
            closeDialog,
            closeNotice,
            teamTableData,
            dialogVisible,
            appraiseLogParam,
            isShow,
            showRequire,
            loading,
          file_loading,
            appraiseLogLoad,
            appraiseLogList,
            timestamp,
            searchKey,
            nextStep,
            agreeWith,
            trackNameList,
            topicTypeList,
            technologyFieldList,
            formList,
            competitionInfo,
            jinsaiAdList,
            querySearch,
            createFilter,
            formRecommendation,
            uploadUrl,
            handleFileSuccess,
            handleFileSuccess2,
          handleError,
            worksFileList,
            beforeAvatarUpload,
            fileCodeList,
            save,
            down,
            showCompetitionInfo,
            showPeopleInfo,
            moveUp,
            showEditTeam,
            dialogTeamShow,
            addTeamPeople,
            dialogTeamPeopleShow,
          topicChange,
          webConfig,
        }
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

.topic-search {

    line-height: 25px;
    font-size: 14px;


    .search-input {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 2px;
        padding: 5px 9px;
        margin-left: 9px;
        margin-right: 16px;
        font-size: 14px;
        line-height: 14px;
    }

    .btn {
        display: inline-block;
        padding-top: 2px;
        padding-bottom: 2px;
    }
}
</style>

