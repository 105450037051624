<template>
    <div class="user-info" v-loading="loading">


        <div class="">
            <div class="topic-search">

                <div class="demo-input-suffix">

                    <!--          项目库-->
                    <el-form label-width="120px" >
                        <el-row>

                            <el-col :span="6">
                                <el-form-item label="依托单位名称" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="searchParam.positionName" placeholder="请输入内容" clearable></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="平台名称" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="searchParam.unitName" placeholder="请输入内容" clearable></el-input>
                                </el-form-item>
                            </el-col>




                        </el-row>
                    </el-form>



                </div>


            </div>

        </div>
        <div style="padding-bottom: 10px;display: flex;justify-content: right;">
            <button type="button" class="btn btn-primary" @click="cleanParam" style="margin-right: 10px;">清空</button>
            <button type="button" class="btn btn-primary" @click="search">查询</button>

        </div>
        <div class="collect-head">
            <div class="select">
                <el-badge :value="multipleSelection.length" class="item">
                    <el-button type="danger" class="table-btns" @click="handleDeleteAll()">删除</el-button>
                </el-badge>
                <el-button type="primary" class="table-btns" @click="showEdit('')">新增</el-button>

                <!--
                <el-button type="primary" class="table-btns" @click="excelDownload()">下载excel导入模板
                </el-button>
                <el-button type="primary" class="table-btns" @click="addClick('import')">excel导入
                </el-button>
                <el-button type="primary" class="table-btns" @click="exportLibraryInformation()">导出库信息</el-button>
            -->
            </div>
        </div>


        <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="selection"  width="55"/>
            <el-table-column  prop="unitName" label="依托单位" />
            <el-table-column  prop="platformName" label="平台/实验室" />
            <el-table-column  prop="name" label="姓名" />
            <el-table-column  prop="cardNumber" label="身份证号" />
            <el-table-column  prop="nowWorkUnit" label="现在工作单位" />
            <el-table-column  prop="phoneNumber" label="电话" />
            <el-table-column  prop="committeePositionStr" label="学术委员会职务" />
            <el-table-column  prop="titleName" label="职称" />
            <el-table-column  prop="isAcademicianStr" label="是否成为院士" />



            <el-table-column label="操作" width="250">
                <template #default="scope">


                    <el-button style="margin-top: 10px;" type="success" size="small" @click="showInfo(scope.row.id)">详情</el-button>
                    <el-button style="margin-top: 10px;" type="primary" size="small" @click="showEdit(scope.row.id)">修改</el-button>
                    <el-button style="margin-top: 10px;" type="danger" size="small" @click="handleDelete(scope.row.id)">删除</el-button>

                </template>
            </el-table-column>
        </el-table>

        <Pager :totalSizesDisabled="false" :totalPage="tablePager.totalPage" :total="tablePager.total" :currentPage="currentPage" @pageChange="pageChange"  @sizeChange="sizeChange" class="pager"/>

        <div class="bottom-btns" v-if="!isAdmin">
            <el-button type="default" @click="back">返回</el-button>
        </div>
        <el-dialog v-model="isShow" title="选择平台（实验室）" >
            <div v-loading="projectLoading">
                <el-form>
                    <el-row>

                        <el-col :span="8">
                            <el-form-item label="平台名称" style="padding: 5px;">
                                <el-input style="width: 100%;" v-model="projectParam.projectName" placeholder="请输入内容" clearable></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="平台类型" style="padding: 5px;">
                                <el-select style="width: 100%;" v-model="projectParam.laboratoryType" placeholder="请选择" clearable>
                                    <el-option
                                        v-for="item in labTypeList"
                                        :key="item.id"
                                        :label="item.theValue"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col :span="8">
                            <el-form-item label="依托单位名称" style="padding: 5px;">
                                <el-input style="width: 100%;" v-model="projectParam.hrwName" placeholder="请输入内容" clearable></el-input>
                            </el-form-item>
                        </el-col>

                    </el-row>
                    <div style="padding-bottom: 10px;display: flex;justify-content: right;">
                        <button type="button" class="btn btn-primary" @click="searchProject">查询</button>

                    </div>

                </el-form>
                <div class="collect-head">

                    <div class="select">


                        <!--            新增确定按钮，跳转新增页面-->
                        <el-button v-if="addClickType != 'import'" type="primary" class="table-btns" @click="projectSelect()">确定</el-button>

                        <!--            导入确定按钮，跳转导入页面-->
                        <el-upload
                            style="display: inline-block"
                            class="upload-demo"
                            action="/hfapplication/gdsti/c/formdataCatalogue/upload.do"

                            :data="uploadData"
                            :show-file-list="false"
                            :on-success="handleSuccess"
                            :on-error="handleError"
                            accept=".xlsx"
                            :before-upload="beforeAvatarUpload">
                            <el-button type="primary" class="table-btns">确定</el-button>
                            <!--            <span v-if="addClickType == 'import'" style="color:red;">excel导入会覆盖掉旧数据，请谨慎操作</span>-->
                        </el-upload>
                    </div>
                </div>
                <el-table :data="tablePagerSelect.list" style="width: 100%" @selection-change="handleSelectionChangeProject">
                    <el-table-column width="50px">
                        <template v-slot="scope">
                            <!-- label值要与el-table数据id实现绑定 -->
                            <el-radio v-model="projectId" :label="scope.row.id" @change="handleRowChangeSelect(scope.row)">{{ "" }}
                            </el-radio>
                        </template>
                    </el-table-column>
                    <!--          <el-table-column type="index" label="序号" width="80"/>-->
                    <el-table-column prop="projectName" label="平台名称"/>
                    <el-table-column prop="laboratoryTypeName" label="平台类型"/>
                    <el-table-column prop="hrwName" label="所属依托单位"/>
                </el-table>
                <Pager :totalPage="tablePagerSelect.page" :total="tablePagerSelect.total" :currentPage="projectCurrentPage" @pageChange="projectPageChange" class="pager"/>

            </div>

        </el-dialog>
        <el-dialog v-model="isUploadShow" title="导入完成前请勿刷新页面或关闭浏览器"
                   :show-close="isSuccessShow"  width="20%"
                   :close-on-press-escape="false" :close-on-click-modal="false">

            <el-progress type="circle" :percentage="percentageNumber" v-if="!isSuccessShow"></el-progress>
            <el-row v-if="isSuccessShow">
                <el-col :sm="6" :lg="24">
                    <el-result :icon="uploadIcon" title="" :subTitle="isSuccessStr">
                    </el-result>
                    <div v-if="errorDataList.length > 0" style="text-align: center;padding-top: 10px;">
                        <el-button type="primary" @click="exportToExcel()">下载存疑数据</el-button>
                    </div>

                </el-col>
            </el-row>


        </el-dialog>

    </div>
</template>

<script>

import dayjs from 'dayjs';
import {ElButton, ElTable, ElTableColumn} from 'element-plus';
import Pager from '../../../components/common/Pager'
import {MoreFilled} from '@element-plus/icons-vue'
import {getCurrentInstance, onMounted, ref} from 'vue';
import {useRoute} from 'vue-router'
import * as XLSX from 'xlsx';
import async from "async";

export default {
    components: { ElTable, ElTableColumn, ElButton, Pager, MoreFilled},
    setup() {

        const XLSX = require('xlsx');
        const {proxy} = getCurrentInstance();
        const route = useRoute();
        const currentPage = ref(1);
        const limit = ref(10);
        const projectCurrentPage = ref(1);
        const loading = ref(false);
        const projectLoading = ref(false);
        const searchKey = ref('');
        const uploadIcon = ref('success');
        const addClickType = ref('');
        const libPercentageKey = ref('');
        const isAdmin = ref(false);

        const projectParam = ref({
            projectName: '',
            laboratoryType: '',
            hrwName: '',
        });

        // 检索字段
        const searchParam = ref({
            id: '',
            title: '',
            year: '',
            fileType: '',
            fileTime: '',
            attachCode: '',
            createMan: '',
            createTime: '',
            updateMan: '',
            updateTime: '',
            isDelete: '',
            tenantId: '',
            belongDept: '',
            platformId: '',

            personArr: [],
            personIds:'',
            personNames:'',
            positionName:'',
            unitName:'',
        });
        const typeList = ref([])


        const tablePager = ref({
            total: 1,
            page: 1,
            list: []
        })
        const columnList = ref([]);
        const labTypeList = ref([]);

        function loadData() {
            loading.value = true;
            let paramData = getParams();
            console.log("查询",paramData);
            proxy.$http.post('/gdsti2/lab/c/labLibCommittee/list', paramData)
                .then(res => {
                    loading.value = false
                    if (res.data.state == 0) {
                        tablePager.value = res.data.result;
                    }
                }).catch(err => {
                loading.value = false
            })
        }

        function cleanParam() {
            Object.keys(searchParam.value).forEach((key)=>{
                searchParam.value[key] = "";
            })
            loadData();
        }


        function pageChange(index) {
            currentPage.value = index;
            loadData();
        }

        function sizeChange(number){
            limit.value = number;
            loadData();
        }

        function search() {
            pageChange(1);
        }

        function searchProject(){
            projectPageChange(1);//先写到这
        }

        function projectPageChange(index) {
            projectCurrentPage.value = index;
            loadProjectData();
        }

        function loadProjectData() {
            projectLoading.value = true;
            let paramData = {
                limit: 10,
                offset: (projectCurrentPage.value - 1) * 10,

                projectName: projectParam.value.projectName,
                laboratoryType: projectParam.value.laboratoryType,
                hrwName: projectParam.value.hrwName,

            }
            proxy.$http.post('/hfapplication/gdsti/c/relyonWorkplaceProject/list2.do', paramData)
                .then(res => {
                    if (res.data.state == 0) {
                        tablePagerSelect.value = res.data.result;
                        if (res.data.result.list.length > 0) {
                            projectId.value = res.data.result.list[0].id;
                        }
                        isShow.value = true;
                    }
                    projectLoading.value = false
                }).catch(err => {
                projectLoading.value = false
            })
        }

        const multipleSelection = ref([])

        function handleSelectionChange(selections) {
            multipleSelection.value = selections;
        }


        function indexFormatter(row, column, cellValue, index) {
            return row + 1 + '';
        }

        function formatDate(row, column, cellValue, index) {
            if(cellValue){
                return dayjs(cellValue).format('YYYY-MM-DD');
            }else{
                return '';
            }
        }


        function handleDeleteAll(){
            if(multipleSelection.value.length < 1){
                proxy.ElMessage.error('至少选中一项!');
                return;
            }else{
                handleDelete(multipleSelection.value.map(obj => obj.id).join(','));
            }

        }

        function handleDelete(id) {
            if (id == '') {
                return;
            }

            HFConfirm("确认要删除选中信息吗？", "", "warning", function(isConfirm){
                if(isConfirm){
                    loading.value = true;
                    proxy.$http.post('/gdsti2/lab/c/labLibCommittee/delete.do', {ids: id}).then(res => {
                        if (res.data.state == 0) {
                            HFToastrTipResult(res.data);
                            loadData();
                        }
                        loading.value = false;
                    }).catch(err => {
                        loading.value = false;
                    });
                }
            });
        }


        function addClick(type) {
            addClickType.value = type;
            let url = '/hfapplication/gdsti/c/relyonWorkplaceProject/list2.do';
            proxy.$http.post(url, {limit: 10, offset: (currentPage.value - 1) * 10})
                .then(res => {
                    if (res.data.state == 0) {
                        tablePagerSelect.value = res.data.result;
                        if (res.data.result.list.length > 0) {
                            projectId.value = res.data.result.list[0].id;
                        }
                        isShow.value = true;
                    }
                }).catch(err => {
            })

        }

        function showInfo(id) {

            openDialog("showInfo","详情","/gdsti2v/lab/labLibCommittee/form?disabled=true&id="+ id,"",'95%', '95%',function(){
                loadData();
            });

        }

        function showEdit(id) {
            let title = "新增"
            if(id){
                title = '编辑'
            }
            openDialog("showEdit",title,"/gdsti2v/lab/labLibCommittee/form?id="+ id, {},'95%', '95%',function(){
                loadData();
            });

        }

        const multipleSelectionProject = ref([])
        const projectId = ref('');

        function handleSelectionChangeProject(selections) {
            multipleSelectionProject.value = selections;
        }

        function handleRowChangeSelect(data) {
            if (data) {
                projectId.value = data.id
            }
        }

        const isShow = ref(false)
        const tablePagerSelect = ref({
            total: 1,
            page: 1,
            list: [{}]
        })

        function projectSelect() {
            if(isAdmin.value){
                if (addClickType.value == 'add') {
                    //新增选择
                    let iframeUrl = '/hfapplication/gdsti/v/resourcesCatalogue/relyonWorkplaceDataSend.do?formId=' + route.query.biFormId + '&projectId=' + projectId.value;
                    proxy.$router.push({name: 'ViewsUserPlatformPages', path: 'viewsUserPlatformPages', query: {iframeUrl: iframeUrl}})
                }
            }else{
                if (addClickType.value == 'add') {
                    //新增选择
                    let iframeUrl = '/hfapplication/gdsti/v/resourcesCatalogue/relyonWorkplaceDataSend.do?formId=' + route.query.biFormId + '&projectId=' + projectId.value;
                    proxy.$router.push({name: 'PlatformPages', path: 'platformPages', query: {iframeUrl: iframeUrl}})
                }
            }

        }

        //导出库信息
        function exportLibraryInformation() {

            let paramData = getParams();
            const params = new URLSearchParams(Object.entries(paramData));

            console.log("导出",paramData);

            window.open(`/hfapplication/gdsti/c/formdataCatalogue/export.do?${params}`);

            // window.open(`/hfapplication/gdsti/c/formdataCatalogue/export.do?type=${libTypeStr.value}&formId=${route.query.biFormId}&projectId=${projectId.value}`);

        }

        function getParams() {

            return {
                id: '',
                platformId: '',
                name: '',
                cardNumber: '',
                nowWorkUnit: '',
                phoneNumber: '',
                committeePosition: '',
                titleType: '',
                title: '',
                province: '',
                city: '',
                county: '',
                education: '',
                isAcademician: '',
                academicianType: '',
                belongDept: '',
                tenantId: '',
                isDelete: '',
                updateTime: '',
                updateMan: '',
                createTime: '',
                createMan: '',
                positionName: searchParam.value.positionName,
                unitName: searchParam.value.unitName,
                limit : limit.value,
                offset : (currentPage.value - 1) * limit.value,
            };
        }



        //导入相关参数
        const uploadData = ref({});
        const isUploadShow = ref(false);
        const isSuccessShow = ref(false);
        const percentageNumber = ref(0);       //导入进度条
        const isSuccessStr = ref('');
        const errorDataList = ref([]); //存疑数据
        //导入相关参数end

        onMounted(() => {
            
            loadData();
        })

        return {
            errorDataList,
            tablePager,
            typeList,
            pageChange,
            projectPageChange,
            sizeChange,
            search,
            currentPage,
            projectCurrentPage,
            multipleSelection,
            indexFormatter,
            uploadData,
            isUploadShow,
            percentageNumber,
            isSuccessShow,
            isSuccessStr,

            handleSelectionChange,
            labTypeList,
            columnList,
            formatDate,
            handleDeleteAll,
            handleDelete,
            showInfo,
            showEdit,
            addClick,
            multipleSelectionProject,
            projectId,
            handleSelectionChangeProject,
            handleRowChangeSelect,
            isShow,
            tablePagerSelect,
            projectSelect,
            loading,
            exportLibraryInformation,
            addClickType,
            libPercentageKey,
            searchKey,
            uploadIcon,
            searchParam,
            projectParam,
            cleanParam,
            route,
            searchProject,
            projectLoading,

            isAdmin
        }
    },
    methods: {
        back() {
            this.$router.back();
        },
        excelImport() {

        },
        excelDownload() {
            // this.isUploadShow = true;

            window.open(`/hfapplication/gdsti/c/formdataCatalogue/down.do?type=${this.libTypeStr}`);
        },
        handleSuccess(response, uploadFile) {
            this.uploadIcon = "success";
            console.log("response",response);
            //由于返回数据中有个LinkedHashMap需要保证有序，但是在后端返回前端的过程中会导致key顺序被打乱，所以在后端把返回数据先转成了json
            let result = JSON.parse(response.result);
            //导入完成消息
            this.isSuccessStr = result.message
            //进度条
            this.percentageNumber = 0;
            //显示导入完成
            this.isSuccessShow = true;
            //存疑数据
            this.errorDataList = result.errorDataList;
        },
        //导出存疑数据
        exportToExcel() {

            // 创建一个工作簿
            const wb = XLSX.utils.book_new();
            // 创建一个工作表
            const ws = XLSX.utils.json_to_sheet(this.errorDataList);
            // 将工作表添加到工作簿
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

            // 保存工作簿为Excel文件
            XLSX.writeFile(wb, '存疑数据.xlsx');
            this.$message({
                message: '下载成功，请通过浏览器下载记录查看',
                type: 'success'
            });
        },


        handleError(response, uploadFile) {
            this.uploadIcon = "error";
            console.log("handleError");
            let message = response.message;
            let result = response.result;
            this.isSuccessStr = "导入发生意料之外的异常，请联系网站管理员处理。错误信息：message：" + message + "  result：" + result;
            //进度条
            this.percentageNumber = 0;
            this.isSuccessShow = true;
        },
        beforeAvatarUpload(rawFile) {
            let name = rawFile.name;
            let type = '';
            if (name.length < 4) {
                this.ElMessage.error('文件需要是xlsx格式');
                return false;
            }
            type = name.substr(name.length - 4)
            if (type != 'xlsx') {
                this.ElMessage.error('文件需要是xlsx格式');
                return false;
            }
            this.isSuccessShow = false;
            this.isSuccessStr = "";
            this.isUploadShow = true;
            this.uploadData.type = this.libTypeStr;
            this.uploadData.relyonWorkplaceProjectId = this.projectId;
            //获取进度条前，生成一个随机key
            let libPercentageKey = 'LibPercentage' + Math.floor(Math.random() * 1000000);
            this.libPercentageKey = libPercentageKey;
            this.uploadData.libPercentageKey = libPercentageKey;

            //获取进度条
            let timer = setInterval(() => {
                //需要定时执行的代码
                console.log("timertimertimer");
                // this.isSuccessShow = false;
                this.$http.post('/hfapplication/gdsti/c/formdataCatalogue/getSpeedOfProgress.do', {speedOfProgressKey: libPercentageKey}).then(res => {
                    if (res.data.state == 0) {
                        this.percentageNumber = res.data.result;
                    }
                }).catch(err => {

                })
                if (this.percentageNumber == 100) {
                    clearInterval(timer);
                    //导入完成
                }
            }, 500);

            return true;
        },
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

.topic-search {

    line-height: 25px;
    font-size: 14px;


    .search-input {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 2px;
        padding: 5px 9px;
        margin-left: 9px;
        margin-right: 16px;
        font-size: 14px;
        line-height: 14px;
    }

    .btn {
        display: inline-block;
        padding-top: 2px;
        padding-bottom: 2px;
    }
}

/deep/  input::-webkit-outer-spin-button,
/deep/  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
/deep/ input[type="number"]{
    -moz-appearance: textfield;
}

</style>

