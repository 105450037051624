<template>
    <div class="tech-res">
        <div class="content">
            <div class="search-form" id="queryForm">
                <div class="search-title">资源检索</div>
                <div class="search-box">
                    <div class="search-split">
                        <div class="form-group">
                            <label class="control-label col-2">中文名称</label>
                            <div class="col-10">
                                <input type="text" name="chName" v-model="queryForm.cname" class="form-control" placeholder="请输入中文名称"/>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="control-label col-2">资源分类</label>
                            <div class="col-10" style="display:flex;width:361px;justify-content:space-between;">
                                <select class="form-control" id="ziyuanfenlei-leng" v-model="queryForm.catalogType" @change="choiseType();" style="flex:1;margin:0;margin-left:10px;">
                                    <option value="-1" v-if="showResSelect">请选择分类</option>
                                    <option v-for="(item,index) in category" :key="item.dictVal" :value="item.dictVal" >{{item.dictName}}</option>
                                </select>

                                <el-tree-select
                                        style="margin-left: 10px;height: 100%;"
                                        v-if="queryForm.catalogType != ''"
                                        v-model="queryForm.catalogId"
                                        @node-click="(data,node,nodeItem)=>handleNodeClick(data,node,nodeItem)"
                                        :data="getCatalogTreeByType(queryForm.catalogType).catalogTree"
                                        :props="defaultProps"
                                        check-strictly
                                        :render-after-expand="false"
                                ></el-tree-select>

                            </div>

                        </div>
                        <div class="form-group">
                            <label class="control-label col-2">机构名称</label>
                            <div class="col-10">
                                <input type="text" name="platformName" v-model="queryForm.platformName" class="form-control" placeholder="请输入机构名称"/>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="control-label col-2">更新时间</label>
                            <div class="col-10">
                                <Datepicker
                                        lang="zh-CN"
                                        v-model="updateDate"
                                        inputClass="form-control"
                                        id="techDate"
                                        :range="true"
                                        :circle="true"
                                        position="left"
                                        placeholder="请选择日期"
                                ></Datepicker>
                            </div>
                        </div>
                    </div>
                    <div class="search-split">
                        <div class="form-group">
                            <label class="control-label col-2">英文名称</label>
                            <div class="col-10">
                                <input type="text" name="chName" v-model="queryForm.ename" class="form-control" placeholder="请输入英文名称"/>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="control-label col-2">所在地区</label>
                            <div class="col-10">
                                <select class="form-control" name="area" id="area" v-model="queryForm.address">
                                    <option value="">请选择城市</option>
                                    <template v-for="item in cityList" :key="item.value">
                                        <option :value="item.code">{{item.name}}</option>
                                    </template>
                                </select>
                            </div>
                        </div>

                        <div class="form-group">
                            <label class="control-label col-2">机构类别</label>
                            <div class="col-10">
                                <select class="form-control" v-model="queryForm.platformType">
                                    <option value="">请选择</option>
                                    <option v-for="item in platformTypeList" :key="item.typeCode" :value="item.typeCode">{{item.typeName}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="search-btns">
                        <div class="verhicle-btns">
                            <button type="button" class="btn btn-primary" @click="searchClick">
                                <img src="@static/img/search.svg" class="search-icon"/>搜索</button>

                            <button type="button" class="btn btn-outline btn-clear" @click="resetClick">
                                <img src="@static/img/reload.svg" class="reset-icon"/>清空</button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="tech-box">
                <div class="res-category">
                    <div class="res-title">{{hasQuery?'筛选条件':'资源分类'}}</div>

                    <div class="category-content" v-for="(item,oneIndex) in category" :key="item.dictVal">
                        <div class="category-title">{{item.dictName}}</div>

                        <div class="category-list" v-loading="categoryLoading" v-if="item.catalogTree">

                            <el-tree
                                    :data="item.catalogTree"
                                    :props="defaultProps"
                                    @node-click="(data,node,nodeItem)=>handleNodeClick(data,node,nodeItem)"
                                    accordion
                            ></el-tree>


                        </div>
                    </div>


                </div>
                <div class="res-content">
                    <div class="sizer-bar">
                        <div class="sizer-name">筛选条件 > </div>
                        <div class="sizer-box" v-if="hasQuery">

                            <div class="sizer-item" v-if="queryForm.catalogId">
                                {{getCatalogTreeNamePath()}}
                                <span class="close" @click="removeQuery('catalogTree')">
                                    <template v-if="showResSelect">x</template>
                                </span>
                            </div>

                            <div class="sizer-item" v-if="queryForm.cname">
                                {{queryForm.cname}}
                                <span class="close" @click="removeQuery('cname')">x</span>
                            </div>
                            <div class="sizer-item" v-if="queryForm.ename">
                                {{queryForm.ename}}
                                <span class="close" @click="removeQuery('ename')">x</span>
                            </div>
                            <div class="sizer-item" v-if="queryForm.address">
                                {{showCityNameByCode(queryForm.address)}}
                                <span class="close" @click="removeQuery('address')">x</span>
                            </div>
                            <div class="sizer-item" v-if="queryForm.startTime">
                                {{queryForm.startTime.substring(0,11)}}~{{queryForm.endTime.substring(0, 11)}}
                                <span class="close" @click="removeQuery('updateDate')">x</span>
                            </div>
                            <div class="sizer-item" v-if="queryForm.platformName">
                                {{queryForm.platformName}}
                                <span class="close" @click="removeQuery('platformName')">x</span>
                            </div>
                            <div class="sizer-item" v-if="queryForm.searchKey">
                                {{queryForm.searchKey}}
                                <span class="close" @click="removeQuery('searchKey')">x</span>
                            </div>
                            <div class="sizer-item clear" @click="resetClick">
                                清空筛选
                            </div>

                        </div>
                    </div>
                    <div class="content-title">共<span class="highline">{{resourceDataList.total}}</span>个资源</div>
                    <div class="res-list" v-loading="loading">
                        <template v-for="item in resourceDataList.list" :key="item.id">
                            <div class="res-item" >
                                <router-link :to="{name: 'Instrument', path:'/instrument', query: {id: item.id, catalogType: item.catalogType}}" target="_blank" class="title"  v-html="matchKey(item.cname)"></router-link>
                                <div class="info">
                                    <div class="time">更新时间：{{item.createTime ? item.createTime.substring(0, 10) : ''}}</div>
                                    <div class="dept" >所属平台：<router-link :to="{name: 'Organ', path: '/organ', query:{id: item.platformId}}"  target="_blank" class="dept-name" v-if="item.platformName" v-html="matchKeyPlatformName(item.platformName)"></router-link></div>
                                    <div class="dept">依托单位：<span v-html="matchKeyPlatformName(item.unitName)"></span></div>
                                </div>
                                <div class="desc">
                                    {{item.describeContent}}
                                </div>
                            </div>
                        </template>
                    </div>
                    <Pager @pageChange="pageChange" class="res-pager" :totalPage="resourceDataList.totalPage" :currentPage="currentPage"></Pager>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Pager from "../components/common/Pager"
    import VueDatepickerUi from 'vue-datepicker-ui'
    import 'vue-datepicker-ui/lib/vuedatepickerui.css'
    import city from '../city'
    import {ref, reactive, onMounted,computed, watch, getCurrentInstance} from 'vue'
    import {useRoute} from 'vue-router'
    export default {

        components:{ Pager, Datepicker: VueDatepickerUi },
        setup(){
            const {proxy} = getCurrentInstance();
            
            const route = useRoute();

            const loading = ref(false)
            const categoryLoading = ref(false) 

            const currentPage = ref(1)

            const cityList = ref(city.cityList);    //城市选择
            const category = ref([]);       //资源目录类型 + 资源目录树
            const platformTypeList = ref([]);  //平台类型列表
            const updateDate = ref();   //搜索条件 - 更新时间
            const showResSelect = ref(true) //是否显示资源类型选择

            const resourceDataList = ref([]);   //资源数据列表
            const queryForm = reactive({    //搜索条件
                cname: '',
                ename: '',
                address: '',
                platformName:'',
                platformType:'',
                catalogType: '',    //资源类型
                catalogId: '',      //资源目录树ID
                catalogTreeCode: '',    //资源目录树treeCode
                startTime:'',
                endTime: '',
                searchKey: '',
            });

            const defaultProps = {  //资源目录树节点配置
                children: 'children',
                label: 'catalogName',
                value: 'id'
            }

            //顶部搜索条
            if(route.query && route.query.id && route.query.name){
                let catalogTypes = route.query.id;
                let catalogNames = route.query.name;
                let catalogTypeArr = catalogTypes.split(',');
                let catalogNameArr = catalogNames.split(',');
                for(let i = 0 ; i < catalogTypeArr.length; i++){
                    category.value.push({id:catalogTypeArr[i], dictVal:catalogTypeArr[i], dictName: catalogNameArr[i]});
                }

                queryForm.catalogType = category.value[0].id;
                showResSelect.value = false;    //为顶部搜索进来，不能选择修改资源类别
            }
            if(route.query && route.query.keyword){
                queryForm.searchKey = route.query.keyword;
            }


            function loadCategory(categoryItem){    //资源目录加载（目录树，一次仅加载一种分类）

                categoryLoading.value = true;
                proxy.$http.post('/gdsti2/web/a/webResource/catalogTree.do?isOpen=ALL&catalogType=' + categoryItem.dictVal).then(res => {
                    categoryLoading.value = false;
                    if(res.data.state === 0){

                        // console.log(res.data.result);

                        categoryItem['catalogTree'] = res.data.result;
                    }
                    
                }).catch(err => {
                    categoryLoading.value = false;
                })
            }

            function loadAllCategory(){ //加载所有资源目录分类
                const url = '/gdsti2/web/a/webResource/dataTypelist';
                proxy.$http.post(url).then(res=>{
                    if(res.data.state==0){
                        category.value = res.data.result;

                        // console.log(category.value)

                        for(let i = 0 ; i < category.value.length; i++){
                            loadCategory(category.value[i]);
                        }


                    }
                }).catch(err=>{

                });
            }

            function getPlatforms(){    //加载平台（机构）
                const url = '/gdsti2/web/a/webLab/platformCount';
                proxy.$http.post(url).then(res=>{
                    loading.value = false
                    if(res.data.state == 0){
                        if(res.data.state == 0){
                            platformTypeList.value = res.data.result;

                        }
                    }
                }).catch(err=>{
                    loading.value = false
                })
            }

            function loadResData(){ //资源数据加载（列表内容）
                loading.value = true;
                const url = '/gdsti2/web/a/webResource/resourceDataList';

                queryForm.limit = 10;
                queryForm.offset = (currentPage.value-1)*10;


                proxy.$http.post(url, queryForm).then(res=>{

                    if(res.data.state == 0){
                        resourceDataList.value = res.data.result;
                        loading.value = false
                    }
                }).catch(err=>{
                    loading.value = false
                })
            }

            watch(updateDate, (newVal)=>{   //监测日期选择
                if(newVal && newVal.length == 2){
                    const startTime = newVal[0];
                    const endTime = newVal[1];
                    if(startTime){
                        queryForm.startTime = proxy.$formateDate(startTime) + ' 00:00:00';
                    }
                    if(endTime){
                        queryForm.endTime =  proxy.$formateDate(endTime) + ' 23:59:59';
                    }
                }
            })




            const hasQuery = computed(()=>{ //检查是否有搜索条件

                let isQuery = false; 
                for (const key in queryForm) {
                    if(queryForm[key] != ""){
                        isQuery = true;
                        break;
                    }
                }
                return isQuery;
            })


            onMounted(()=>{
                loadResData();
                getPlatforms();
                if(category.value.length == 0){
                    //加载全部
                    loadAllCategory();
                }else{
                    //加载单个(根据外部传入搜索条件搜索)
                    for(let i = 0 ; i < category.value.length; i++){
                        loadCategory(category.value[i]);
                    }
                }

            })
            

            return {queryForm, showResSelect, platformTypeList,cityList, hasQuery,
                categoryLoading, category, updateDate, loading, currentPage,
                resourceDataList, loadResData, loadCategory, loadAllCategory, defaultProps}

        },
        watch:{
            $route(newval, oldVal){
                window.location.reload();
            }
        },
        methods:{
            showCityNameByCode(code){//根据地区code显示名称
                if(code){
                    for(let i = 0;i<this.cityList.length;i++){
                        let city = this.cityList[i];
                        if(code == city.code){
                            return city.name;
                        }
                    }
                }
                return '';

            },
            choiseType:function(){  //如果资源类型变更，则清空树

                // console.log(this.queryForm);

                this.queryForm.catalogId = '';
                this.queryForm.catalogTreeCode = '';
            },
            pageChange:function(index){
                this.currentPage = index;
                this.loadResData();
            },
            getCatalogTreeNamePath(){   //显示资源目录选择路径
                let catalogNamePath = '';

                for(let i = 0; i < this.category.length; i++){
                    let item = this.category[i];

                    if(item.dictVal == this.queryForm.catalogType){
                        //资源类型名称
                        catalogNamePath += item.dictName + ": ";

                        //资源目录路径
                        catalogNamePath += this.getTreeNamePath(item.catalogTree, this.queryForm.catalogTreeCode);
                    }
                }

                return catalogNamePath;
            },
            getTreeNamePath(catalogTree, catalogTreeCode){   //显示资源目录选择路径
                let name = '';
                let currentTree = catalogTree;
                for(let i = 0; i < 9; i++){ //最多取9级
                    if( (i + 1) * 3 <= catalogTreeCode.length){
                        let treeCode = catalogTreeCode.substring(0, (i + 1) * 3);   //读取当前级别的树编号

                        for(let j = 0; j < currentTree.length; j++){    //从当前树结点中读取出名称
                            let node = currentTree[j];

                            if(node.treeCode == treeCode){
                                if(i != 0){
                                    name += " / "
                                }
                                name += node.nodeName;
                                currentTree = node.children;    //当前节点变成下一级
                                break;
                            }
                        }
                    }else{  //树读取结束
                        break;
                    }
                }
                return name;
            },
            getCatalogTreeByType:function(catalogType){  //如果资源类型变更，则清空树
                let catalogTree = null;
                for(let i = 0; i < this.category.length; i++){
                    let item = this.category[i];

                    if(item.dictVal == catalogType){
                        catalogTree = item;
                        break;
                    }
                }

                return catalogTree;
            },
            searchClick(){  //搜索资源数据

                this.pageChange(1);
            },
            resetClick(){   //重置搜索条件
                // 遍历 json 对象的属性并重置值
                for (const key in this.queryForm) {
                  this.queryForm[key] = "";
                }
                if(this.updateDate){
                    this.updateDate[0] = null;
                    this.updateDate[1] = null;
                }

                this.pageChange(1);
            },
            removeQuery(key){   //移除搜索条件
                if(key === 'catalogTree'){
                    this.queryForm.catalogType = '';
                    this.queryForm.catalogId = '';
                    this.queryForm.catalogTreeCode = '';
                }else if(key === 'updateDate'){
                    this.updateDate[0] = null;
                    this.updateDate[1] = null;
                    this.queryForm.startTime = '';
                    this.queryForm.endTime = '';
                }else{
                    if(key == 'platformName'){
                        this.platformType = '';
                    }
                    this.queryForm[key] = '';
                    this.queryForm[key] = '';
                }

                this.searchClick();
            },
            handleNodeClick:function(data,node,item,own){   //资源目录树分类点击
                // console.log('data:',data);
                // console.log('node:',node);
                // console.log('item:',item);

                this.queryForm.catalogType = data.catalogType;
                this.queryForm.catalogId = data.treeId;
                this.queryForm.catalogTreeCode = data.treeCode;

                // console.log(this.queryForm);

                this.searchClick();
            },
            matchKey(val){
                if(this.queryForm.cname || this.queryForm.searchKey){
                    let keyword = this.queryForm.cname || this.queryForm.searchKey;
                    let replaceReg = new RegExp(keyword, 'ig')
                    let replaceString = `<span style="color: #E02020;">${keyword}</span>`
                    return val.replace(replaceReg, replaceString);
                }else{
                    return val;
                }
            },
            matchKeyPlatformName(val){
                if(this.queryForm.platformName || this.queryForm.searchKey){
                    let keyword = this.queryForm.platformName || this.queryForm.searchKey;
                    let replaceReg = new RegExp(keyword, 'ig')
                    let replaceString = `<span style="color: #E02020;">${keyword}</span>`
                    return val.replace(replaceReg, replaceString);
                }else{
                    return val;
                }
            },

        }
    }
</script>

<style lang="less" scoped>
    @import url('../../public/static/css/techRes.less');

    #ziyuanfenlei-leng{
        /*cursor: pointer;*/
        /*background: #FFFFFF;*/
        /*border: 1px solid rgba(0, 0, 0, 0.15);*/
        /*border-radius: 5px;*/
        /*padding: 5px 14px;*/
        /*margin-left: 10px;*/
        /*margin-right: 20px;*/
        /*font-size: 14px;*/
        /*line-height: 22px;*/
        /*outline: none;*/
        /*box-sizing: border-box;*/
    }
    .tech-res{
        background-position: 0 0;
    }

    .search-form{
        width: 100%;

        #techDate{
            display: inline-block;
        }


        .search-title{
            padding-top: 33px;
            font-family: SourceHanSansCN-Bold;
            font-size: 20px;
            color: rgba(0,0,0,0.85);
        }
        .search-icon{
            margin-right: 8px;
            color: white;
        }
        .reset-icon{
            margin-right: 8px;
            width: 16px;
            color: #007EEC;
        }
        .search-box{
            display: flex;
            justify-content: space-between;
            background: #FFFFFF;
            box-shadow: 0 2px 15px 0 rgba(103,183,255,0.20);
            border-radius: 6px;
            padding: 18px 18px 8px 18px;

            .form-control{
                width: 320px;
            }
            select.form-control{
                width: 350px;
            }

            .form-group{
                margin-bottom: 10px;
            }
            .search-split{
                width: 500px;
            }
            .search-btns{
                flex: 1;
                .verhicle-btns{
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .btn{
                        width: 135px;
                    }
                }
                .btn-clear{
                    margin-top: 8px;
                }
            }
        }
    }

    .sizer-bar{
        display: flex;
        align-items: center;

        .sizer-box{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            flex: 1;
        }
        .sizer-name{
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            color: #2E8CE3;
            margin-right: 12px;

        }
        .sizer-item{
            margin-right: 12px;
            font-family: SourceHanSansCN-Regular;
            font-size: 12px;
            color: #FFFFFF;
            line-height: 22px;
            padding-left: 8px;
            margin-top: 4px;
            margin-bottom: 4px;
            background: #007EEC;
            .close{
                cursor: pointer;
                padding-right: 8px;
                padding-left: 8px;
                line-height: 22px;
            }
        }
        .sizer-item.clear{
            cursor: pointer;
            border: 1px solid #007EEC;
            color: #007EEC;
            background: #fff;
            padding-right: 8px;
        }
        .sizer-item.clear:hover{
            background: rgba(103,183,255,0.20);
        }
    }

    .tech-box{
        display: flex;
        justify-content: space-between;
        margin-top: 37px;

        .res-category{
            width: 283px;
            margin-right: 22px;

            .res-title{
                font-family: SourceHanSansCN-Bold;
                font-size: 18px;
                color: rgba(0,0,0,0.85);
                text-align: center;
                margin-bottom: 18px;
            }

            .category-content{
                background: #FFFFFF;
                border: 1px solid #E7E7E7;
                margin-bottom: 14px;
                .category-title{
                    background: #007EEC;
                    line-height: 44px;
                    padding-left: 17px;
                    padding-right: 17px;
                    font-family: SourceHanSansCN-Regular;
                    font-size: 18px;
                    color: #FFFFFF;
                }
                .category-list{
                    padding: 7px 0;
                    width: 100%;
                    max-height: 400px;
                    overflow-y: auto;

                    .category-item{
                        cursor: pointer;
                        font-family: SourceHanSansCN-Regular;
                        font-size: 14px;
                        padding: 7px 15px;
                    }
                    .category-item:hover{
                        color: #bfbfbf;
                    }
                }
            }
        }
        .res-content{
            flex: 1;
            max-width: 900px;

            .content-title{
                font-family: SourceHanSansCN-Normal;
                font-size: 20px;
                color: rgba(0,0,0,0.85);
                line-height: 30px;
                margin-bottom: 16px;
            }
            .res-list{
                padding-left: 12px;
                padding-right: 12px;
                background: #FFFFFF;
                border: 1px solid #E7E7E7;

                .res-item{

                    padding-top: 20px;
                    padding-bottom: 16px;
                    border-bottom: 1px dashed #E7E7E7;

                    .title{
                        padding-left: 16px;
                        padding-right: 16px;
                        font-family: SourceHanSansCN-Medium;
                        font-size: 18px;
                        color: #007EEC;
                        cursor: pointer;
                    }
                    .info{
                        padding-left: 16px;
                        padding-right: 16px;
                        margin-top: 14px;
                        display: flex;
                        .time{
                            font-family: SourceHanSansCN-Regular;
                            font-size: 14px;
                            color: rgba(0,0,0,0.85);
                            margin-right: 27px;
                        }
                        .dept{
                            font-family: SourceHanSansCN-Normal;
                            font-size: 14px;
                            color: rgba(0,0,0,0.85);
                            margin-right: 27px;
                            .dept-name{
                                color: #007EEC;
                                cursor: pointer;
                            }

                        }
                    }
                    .desc{
                        padding-left: 16px;
                        padding-right: 16px;
                        margin-top: 10px;
                        font-family: SourceHanSansCN-Regular;
                        font-size: 14px;
                        color: rgba(0,0,0,0.50);
                        line-height: 17px;
                    }
                }
                .res-item:last-child{
                    border-bottom: none;
                }
            }
        }
    }
    .res-pager{
        margin-top: 28px;
        margin-bottom: 28px;
    }
</style>


