
<template>
    <div class="container" v-if="!isQuery">
        <div class="title">
          证书编号查询
        </div>
      <div class="search-container">
        <el-input
            v-model="searchInputVal"
            style="max-width: 600px"
            placeholder="请输入证书编号"
            class="search-input"
        >
          <template #append>
            <el-button class="search-button" @click="certSearch">
              <img style="width:22px;height:22px; " src="/static/img/science/icons8-search@1x.png" />
            </el-button>
          </template>
        </el-input>
      </div>
      <div class="desc">
        可在此进行证书编号查询，证书进度，证书真伪，若需下载证书，请前往用户中心下载。
      </div>

    </div>


    <div class="container2" v-if="isQuery">
        <div class="search-container">
            <el-input
                v-model="searchInputVal"
                style="max-width: 600px"
                placeholder="请输入证书编号"
                class="search-input"
            >
              <template #append>
                <el-button class="search-button" @click="certSearch" >
                  <img style="width:22px;height:22px; " src="/static/img/science/icons8-search@1x.png" />
                </el-button>
              </template>
            </el-input>


          </div>
          <div style="margin: 10px auto;"><el-tag>若需下载证书，请前往用户中心下载。</el-tag></div>

          <div class="table-container">
                <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
        <!--            <el-table-column type="selection"  width="55"/>-->
        <!--            <el-table-column type="index" label="序号" width="80"/>-->
                    <el-table-column prop="title" label="凭证标题" />
                    <el-table-column prop="proveCode" label="凭证编号" />
                    <el-table-column prop="createTime" label="发起时间" />
                    <el-table-column prop="status" label="审批状态">
                      <template #default="scope">
                        {{statusFormatter(scope.row)}}
                      </template>
                    </el-table-column>
                </el-table>
                <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>
        </div>
    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue'
import {useRouter, useRoute} from 'vue-router'
import { Search } from '@element-plus/icons-vue'
import { ElMessage } from 'element-plus';

import {ElTable, ElTableColumn, ElButton, ElDialog, ElResult, ElPopover, ElIcon} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '@/components/common/Pager'

export default {
  components: {ElTable, ElTableColumn, ElButton, ElIcon,ElDialog, ElResult, ElPopover, MoreFilled, Pager},

    setup(){
            const {proxy} = getCurrentInstance();

            const router = useRouter();
            const route = useRoute();

            const isQuery = ref(false); //是否搜索
            const searchInputVal = ref('');
            
            function certSearch() { //搜索
              if(searchInputVal.value){
                isQuery.value = true;
                loadData();
              }else{
                ElMessage.error("请输入搜索内容。");
              }
            }


            const currentPage = ref(1);
            const loading = ref(false);
            const tablePager = ref({
                total: 1,
                page: 1,
                list: []
            })
            function loadData(){    //加载专项证明
              loading.value = true;
              let param = {
                  limit: 10,
                  offset: (currentPage.value-1)*10,
                  proveCode: searchInputVal.value,
              }
              proxy.$http.post('/gdsti2/resource/a/resRemittanceProve/list', param)
              .then(res=>{
                  loading.value = false
                  if(res.data.state == 0){
                      tablePager.value = res.data.result;
                  }
              }).catch(err=>{
                  loading.value = false
              })
          }
          function pageChange(index){
              currentPage.value = index;
              loadData();
          }

          const multipleSelection = ref([])
          function handleSelectionChange(selections){
              multipleSelection.value = selections;
          }

          function statusFormatter(row, column, cellValue, index){
            let text = '';
            if(row.status == 0){
                text = '待审核';
            }else if(row.status == 1){
                text = '审核通过';
            }else if(row.status == 2){
                text = '审核不通过';
            }
            return text;
          }

            onMounted(()=>{
              // loadData();
          })


            return { Search, searchInputVal, certSearch, isQuery, tablePager, handleSelectionChange, pageChange, currentPage, statusFormatter}
    },
    watch:{

    },
    mounted(){

    },
    methods:{
      
    }
}

</script>

<style lang="less" scoped>
.container{
  height: 440px;
  background-image: url(/static/img/science/data-exchange-bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0 auto 30px auto;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  .title{
    margin: 70px auto 10px auto;
    font-family: '微软雅黑';
    font-size: 24px;
    color: #3D3D3D;
  }
  .search-container{
    .search-input{
      width: 489px;
      height: 42px;
      border-radius: 30px;
      box-sizing: border-box;
      /* 蓝色 */
      border: 1px solid #007EEC;
      background: #FFFFFF;

      margin: 10px auto;
    }
    .search-button{
      width: 94px;
      height: 42px;
      border-radius: 21px;
      opacity: 1;

      /* 蓝色 */
      background: #007EEC;

    }

  }
  .desc{
    margin: 10px auto;

    font-family: Source Han Sans;
    font-size: 12px;
    font-weight: normal;
    line-height: 30px;
    text-align: justify; /* 浏览器可能不支持 */
    letter-spacing: 0.55px;
    font-variation-settings: "opsz" auto;
    color: #3D3D3D;
  }
}


.container2{
  height: 600px;
  margin: 0 auto 30px auto;

  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  .search-container{
    .search-input{
      width: 489px;
      height: 42px;
      border-radius: 30px;
      box-sizing: border-box;
      /* 蓝色 */
      border: 1px solid #007EEC;
      background: #FFFFFF;

      margin: 10px auto;
    }
    .search-button{
      width: 94px;
      height: 42px;
      border-radius: 21px;
      opacity: 1;

      /* 蓝色 */
      background: #007EEC;

    }

  }

}

.search-button{
  width: 2em;
  height: 2em;

  background: #FFFFFF;
}
.el-input /deep/ .el-input__wrapper {
  background-color: transparent;
  box-shadow: 0 0 0 0;
}
.el-input /deep/ .el-input-group__append{
  background-color: transparent;
  box-shadow: 0 0 0 0;
}

</style>
