<template>
    <div class="category">
        <div class="category-title">
            {{title}}
            <span class="refresh" v-if="refresh"  @click="refreshClick">
            <img src="@static/img/refresh.svg" class="btn-icon"/>换一换</span>
        </div>
        <div class="category-list">
            <div class="center" v-if="!items || items.length == 0">{{empty}}</div>
            <template v-else>
                <div class="category-item" v-for="(item, index) in items" :key="index" @click="gotoDetail(item.id, item.catalogType)">
                    <div class="number" :class="'number-'+(index+1)" >{{index+1}}</div>
                    <a href="#" class="link">{{item.cname}}</a>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
   data(){
       return {
           
       }
   },
   props:{
       title:{
           type: String,
           default: ''
       },
       catalogType:{
         type: String,
         default: ''
       },
       items:{
           type: Array,
           default:[]
       },
       refresh:{
           type: Boolean,
           default: false
       },
       empty: {
            type: String,
            default: '暂无数据'
       }
   },
   methods:{
       gotoDetail(id, catalogType){
           this.$router.push({name:'Instrument', path:'/instrument', query: {id: id, catalogType: catalogType}})
       },
       refreshClick(){
           this.$emit('refresh', {});
       }
   }
}
</script>

<style lang="less" scoped>
.category{
    background: #FFFFFF;
    
    .category-title{
        font-family: SourceHanSansCN-Regular;
        font-size: 18px;
        color: #FFFFFF;
        letter-spacing: 0;
        text-align: justify;
        background: #007EEC;
        line-height: 40px;
        padding-left: 17px;
        padding-right: 17px;
       
    }
    .refresh{
        float: right;
        cursor: pointer;
        display: flex;
        align-items: center;
    }
    .refresh:hover{
        color: rbga(0,0,0,0.5);
    }
    .category-list{
        border: 1px solid #E7E7E7;
        padding: 17px;
        .category-item{
            display: flex;
            align-items: center;
            cursor: pointer;

            .number{
                display: inline-block;
                margin-right: 14px;
                font-family: PingFangSC-Regular;
                font-size: 12px;
                color: #FFFFFF;
                text-align: center;
                background: #959595;
                min-width: 18px;
                height: 18px;
                line-height: 18px;
                border-radius: 18px;
            }
            .number-1{
                background: #df2222;
            }
            .number-2{
                background: #fa8131;
            }
            .number-3{
                background: #f7b500;
            }
            .link{
                text-decoration: none;
                font-family: PingFangSC-Regular;
                font-size: 18px;
                color: #000;
                word-break: keep-all;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 34px;
            }
            .link:hover{
                color:#959595;
            }

        }
    }
}
</style>
