<template>
    <div class="user-info">

        <div class="">
            <div class="topic-search">
                <div class="demo-input-suffix">
                    <!--          其他-->
                    <el-form label-width="120px" >
                        <el-row>

                            <el-col :span="6">
                                <el-form-item label="标题" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="searchForm.title" placeholder="请输入内容" clearable></el-input>
                                </el-form-item>
                            </el-col>

                        </el-row>

                        <el-row>
<!--                            <el-col :span="8">-->
<!--                                <el-form-item label="申请时间范围" style="padding: 5px;">-->
<!--                                    <el-date-picker-->
<!--                                        v-model="searchForm.createTimeRange"-->
<!--                                        type="datetimerange"-->
<!--                                        range-separator="至"-->
<!--                                        start-placeholder="开始时间"-->
<!--                                        end-placeholder="结束时间"-->
<!--                                    />-->

<!--                                </el-form-item>-->
<!--                            </el-col>-->

                        </el-row>


                    </el-form>

                </div>


            </div>

        </div>
        <div style="padding-bottom: 10px;display: flex;justify-content: right;">
            <!--      <button type="button" class="btn btn-primary" @click="cleanParam" style="margin-right: 10px;">清空</button>-->
            <button type="button" class="btn btn-primary" @click="search">查询</button>
        </div>
        <div class="collect-head">
            <div class="select">
                <el-badge :value="multipleSelection.length" class="item">
                    <el-button type="danger" @click="hanleDeleteAll">删除</el-button>
                </el-badge>
                <el-button type="primary" class="table-btns" @click="showAdd()">新增</el-button>
            </div>
        </div>

        <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange" v-loading="loading">
                  <el-table-column type="selection"  width="55"/>
<!--            <el-table-column type="index" label="序号" width="80"/>-->
            <el-table-column prop="typeName" label="文章类型"/>
            <el-table-column prop="title" label="标题"/>
            <el-table-column prop="publishStatusName" label="是否发布"/>
            <el-table-column prop="createManName" label="创建人"/>
            <el-table-column prop="createTime" label="创建时间"/>
            <el-table-column prop="apprStatusName" label="审核状态"/>
            <el-table-column label="操作" width="250">
                <template #default="scope">
                    <el-button style="margin-top: 10px;" type="success" size="small" @click="showDetail(scope.row.id)">详情</el-button>
                    <el-button style="margin-top: 10px;" type="primary" size="small" @click="showEdit(scope.row.id)" v-if="scope.row.apprStatus === -1 || scope.row.apprStatus === 2">编辑</el-button>
                    <el-button style="margin-top: 10px;" type="primary" size="small" @click="showEditExamine(scope.row.id,0)" v-if="scope.row.apprStatus === -1 || scope.row.apprStatus === 2">提交审核</el-button>
                    <el-button style="margin-top: 10px;" type="primary" size="small" @click="showEditExamine(scope.row.id,-1)" v-if="scope.row.apprStatus === 1 || scope.row.apprStatus === 0">撤回审核状态</el-button>
<!--                    <el-button style="margin-top: 10px;" type="primary" size="small" @click="showEditPublish(scope.row.id,1)" v-if="scope.row.publishStatus == 2">发布</el-button>-->
<!--                    <el-button style="margin-top: 10px;" type="primary" size="small" @click="showEditPublish(scope.row.id,0)" v-if="scope.row.publishStatus == 1">撤销发布</el-button>-->

                    <el-button style="margin-top: 10px;" type="danger" size="small" @click="hanleDelete(scope.row.id)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>

    </div>
</template>

<script>
import {ElTable, ElTableColumn, ElButton, ElSwitch, ElIcon, ElPopover} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';

export default {
    components: { ElTable, ElTableColumn, ElButton, Pager, ElSwitch, ElIcon, ElPopover, MoreFilled},
    setup() {
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1);
        const limit = ref(10);

        const loading = ref(false);
        const searchForm = ref({
            title: '',
        });
        const tablePager = ref({
            total: 1,
            page: 1,
            list: []
        })
        const iframeUrl = ref('');

        function loadData() {
            loading.value = true;

            searchForm.value.limit = limit.value
            searchForm.value.offset = (currentPage.value - 1) * limit.value


            searchForm.value.createMan = proxy.$store.state.userInfo.userCode;

            proxy.$http.post('/core/cms/c/articleContent/list.do', searchForm.value)
                .then(res => {
                    loading.value = false
                    if (res.data.state == 0) {
                        tablePager.value = res.data.result;
                    }
                }).catch(err => {
                loading.value = false
            })
        }

        function pageChange(index) {
            currentPage.value = index;
            loadData();
        }

        function search() {
            pageChange(1);
        }

        const multipleSelection = ref([])

        function handleSelectionChange(selections) {
            multipleSelection.value = selections;
        }

        function hanleDeleteAll(){
            if(multipleSelection.value.length < 1){
                proxy.ElMessage.error('至少选中一项!');
                return;
            }else{
                hanleDelete(multipleSelection.value.map(obj => obj.id).join(','));
            }

        }

        function hanleDelete(id) {
            if (id == '') {
                return;
            }

            HFConfirm("确认要删除选中信息吗？", "", "warning", function(isConfirm){
                if(isConfirm){
                    loading.value = true;
                    proxy.$http.post('/core/cms/c/articleContent/delete.do', {ids: id}).then(res => {
                        if (res.data.state == 0) {
                            HFToastrTipResult(res.data);
                            loadData();
                        }
                        loading.value = false;
                    }).catch(err => {
                        loading.value = false;
                    });
                }
            });


        }

        function indexFormatter(row, column, cellValue, index) {
            return row + 1 + '';
        }

        function handleView(index) {
            console.log(index)
        }



        onMounted(() => {
            searchForm.value.createMan = proxy.$store.state.userInfo.userCode;
            loadData();
        })

        return {
            tablePager,
            pageChange,
            search,
            searchForm,
            currentPage,
            multipleSelection,
            indexFormatter,
            hanleDelete,
            hanleDeleteAll,
            handleView,
            handleSelectionChange,
            iframeUrl,
            loading,
            loadData,
            proxy,
        }
    },
    methods : {


        showEditExamine(id,status) {
            var that = this;

            let title = "";
            if(status == 0){
                title = "确认要提交审核吗？";
            }
            if(status == -1){
                title = "确认要撤回审核状态吗，撤回审核状态后需要重新提交审核？";
            }
            HFConfirm(title, "", "warning", function (isConfirm) {
                if (isConfirm) {
                    that.loading = true;
                    let param = {
                        id: id,
                        apprStatus: status,
                    }
                    that.proxy.$http.post('/core/cms/c/articleContent/edit', param).then(res => {
                        if (res.data.state == 0) {
                            HFToastrTipResult(res.data);
                            that.loadData();
                        }
                        that.loading = false;
                    }).catch(err => {
                        that.loading = false;
                    });
                }
            });
        },
        showEditPublish(id,val) {
            var that = this;

            let title = "";
            if(val == 1){
                title = "确认要发布吗？";
            }
            if(val == 0){
                title = "确认要撤销发布吗？";
            }
            HFConfirm(title, "", "warning", function (isConfirm) {
                if (isConfirm) {
                    that.loading = true;
                    let param = {
                        id: id,
                        publishStatus: val,
                    }
                    that.proxy.$http.post('/core/cms/c/articleContent/edit', param).then(res => {
                        if (res.data.state == 0) {
                            HFToastrTipResult(res.data);
                            that.loadData();
                        }
                        that.loading = false;
                    }).catch(err => {
                        that.loading = false;
                    });
                }
            });
        },


        showLogApprList(id) {

            openDialog("showLogApprList","审核记录","/gdsti2v/base/logAppr/list?sourceType=RES_REVIEW_DUP&sourceId="+ id,"",'95%', '95%',function(){

            });
        },
        //显示添加的框
        showAdd() {
            var that = this;
            //新增，默认已发布
            openDialog('showAdd', '新增', '/corev/cms/articleContent/form?typeId=1755190838880239617&publishStatus=1', {}, '95%', '95%', function (callbackData) {
                that.loadData();
            });
        },
        showEdit(id) {
            var that = this;
            openDialog("showEdit","编辑","/corev/cms/articleContent/form?typeId=1755190838880239617&id="+ id,"",'95%', '95%',function(){
                that.loadData();
            });
        },
        showDetail(id) {
            var that = this;
            openDialog("showDetail","详情","/corev/cms/articleContent/form?justShow=yes&id="+ id,"",'95%', '95%',function(){
                that.loadData();
            });
        },
        downFile(fileId) {
            window.open("/core/base/c/download/file?attachId=" + fileId)
        },
    }

}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';
</style>

