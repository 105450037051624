<template>
  <el-button type="primary" @click="expandAll">全部展开</el-button>
  <el-button type="primary" @click="collapseAll">全部收起</el-button>
  <el-tree
    node-key="id"
    ref="tree"
    :data="data"
    :props="defaultProps"
    @node-click="handleNodeClick"/>
</template>

<script>
import {ElCarousel, ElCarouselItem} from 'element-plus'

export default {
  data() {
    return {
      defaultProps: {
        children: 'children',
        label: 'label',
      },
      data: [
        {
          id:'1',
          label: 'Level one 1',
          children: [
            {
              id:'1-1',
              label: 'Level two 1-1',
              children: [
                {
                  id:'1-1-1',
                  label: 'Level three 1-1-1',
                },
              ],
            },
          ],
        },
        {
          id:'2',
          label: 'Level one 2',
          children: [
            {
              id:'2-1',
              label: 'Level two 2-1',
              children: [
                {
                  id:'2-1-1',
                  label: 'Level three 2-1-1',
                },
              ],
            },
            {
              id:'2-2',
              label: 'Level two 2-2',
              children: [
                {
                  id:'2-2-1',
                  label: 'Level three 2-2-1',
                },
              ],
            },
          ],
        },
        {
          id:'3',
          label: 'Level one 3',
          children: [
            {
              id:'3-1',
              label: 'Level two 3-1',
              children: [
                {
                  id:'3-1-1',
                  label: 'Level three 3-1-1',
                },
              ],
            },
            {
              id:'3-2',
              label: 'Level two 3-2',
              children: [
                {
                  id:'3-2-1',
                  label: 'Level three 3-2-1',
                },
              ],
            },
          ]
        },
      ]

    }
  },
  components: {ElCarousel, ElCarouselItem},
  props: {
    height: {
      type: Number,
      default: 300
    },
    width: {
      type: Number,
      default: 1200
    },
    imageList: {
      type: Array,
      default: []
    }
  },
  setup() {

  },
  watch: {
    imageList(newVal, oldVal) {
      if (newVal.length > 0) {
        //重新加载数据时，第一张图会隐藏显示。因此需要操作一下
        const that = this;
        setTimeout(function () {
          that.$refs.carousel.next();
        }, 100);
      }
    }
  },
  methods: {
    openDetail(index) {
      let url = this.imageList[index].url;
      if (url) {
        window.open(url);
      }
    },
    handleNodeClick(data){
      this.$emit('nodeClick', data);
    },
    expandAll() {
      for (var i = 0; i < this.$refs.tree.store._getAllNodes().length; i++) {
        this.$refs.tree.store._getAllNodes()[i].expanded = true;
      }
    },
    collapseAll() {
      for (var i = 0; i < this.$refs.tree.store._getAllNodes().length; i++) {
        this.$refs.tree.store._getAllNodes()[i].expanded = false;
      }
    },
  }
}
</script>

<style scoped>
.banner {
  display: inline-block;
  width: 100%;
}

.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 14px;
}

.dot {
  animation: dot 2s infinite steps(3, start);
  overflow: hidden;
}

.pointer {
  cursor: pointer;
}
</style>
