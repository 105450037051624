<template>
    <div class="register">
        <LoginHead></LoginHead>
        <div class="register-box">
            <div class="regiser-note" v-if="userCode">
                <div class="note-img">
                    <img src="@static/img/icons8-open_envelope_stamp.svg"/>
                </div>
                <div class="note-info">
                    你好，{{ userName }}<br/>
                    请在下方填写产业专区资料，提交审核后，我们会尽快为您审核，审核成功后，即可开通产业专区。
                </div>
            </div>
            <div class="step">
                <div class="step-item">
                    <div class="step-num" :class="{active:active==0}">1</div>
                    <div class="step-name">填写基本信息</div>
                </div>
                <div class="step-arrow">
                    <div></div>
                    <span></span></div>
                <div class="step-item">
                    <div class="step-num" :class="{active:active==1}">2</div>
                    <div class="step-name">等待审核</div>
                </div>
                <div class="step-arrow">
                    <div></div>
                    <span></span></div>
                <div class="step-item">
                    <div class="step-num" :class="{active:active==2}">3</div>
                    <div class="step-name">注册成功</div>
                </div>
            </div>
            <div class="step-content" v-if="active==0">
                <Form class="form" ref="target" v-slot="{ errors}">
                    <template v-if="!userCode">

                        <div class="form-group">
                            <label class="control-label col-3">账号<span class="required">*</span></label>
                            <div class="col-8">
                                <Field autocomplete="new-userCode" type="text" name="userCode" v-model="form.userCode"
                                       :rules="checkUserCode" class="form-control" placeholder="请输入账号（将用于登录账号）"/>
                                <div class="error">{{ errors.userCode }}</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-3">密码<span class="required">*</span></label>
                            <div class="col-8">
                                <Field autocomplete="new-password" type="password" name="password"
                                       v-model="form.password" :rules="checkPwd" class="form-control"
                                       placeholder="请输入密码"/>
                                <div class="error">{{ errors.password }}</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-3">确认密码<span class="required">*</span></label>
                            <div class="col-8">
                                <Field autocomplete="two-password" type="password" name="password1"
                                       v-model="form.password1" :rules="checkTwoPwd" class="form-control"
                                       placeholder="确认密码"/>
                                <div class="error">{{ errors.password1 }}</div>
                            </div>
                        </div>
                        <div class="form-group" v-if="type==0">
                            <label class="control-label col-3">邮箱地址<span class="required">*</span></label>
                            <div class="col-8">
                                <Field autocomplete="new-email" type="email" name="email" v-model="form.email"
                                       :rules="checkEmail" class="form-control" placeholder="请输入邮箱"/>
                                <div class="error">{{ errors.email }}</div>
                            </div>
                        </div>
                        <div class="form-group" v-if="type==1">
                            <label class="control-label col-3">手机号码<span class="required">*</span></label>
                            <div class="col-8">
                                <Field autocomplete="new-phone" type="text" name="phone" v-model="form.phone"
                                       :rules="checkPhone" class="form-control" placeholder="请输入手机号码"/>
                                <div class="error">{{ errors.phone }}</div>
                            </div>
                        </div>
                        <div class="form-group" v-if="type==1">
                            <label class="control-label col-3">短信验证码<span class="required">*</span></label>
                            <div class="col-8">
                                <div style="display: flex;">
                                    <Field type="text" name="vsCode" v-model="form.vsCode" :rules="checkRequired"
                                           class="form-control" style="width:120px" placeholder="短信验证码"/>
                                    <button type="button" class="btn btn-primary btn-send"
                                            :class="{'disabled':showCount}" @click="initImgCode"
                                            v-loading="codeLoading">{{ showCount ? count : '发送验证码' }}
                                    </button>
                                </div>
                                <div class="error">{{ errors.vsCode }}</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-3">所在地址</label>
                            <div class="col-8 control-addr">
                                <select name="province" id="province" class="form-control"
                                        v-model="form.provinceTreeCode">
                                    <option value="-1">请选择省份</option>
                                    <option v-for="(item, index) in provinceList" :key="item.id" :value="item.treeCode">
                                        {{ item.nodeName }}
                                    </option>
                                </select>
                                <select name="city" id="city" class="form-control" v-model="form.cityTreeCode">
                                    <option value="-1">请选择城市</option>
                                    <option v-for="(item, index) in cityList" :key="item.id" :value="item.treeCode">
                                        {{ item.nodeName }}
                                    </option>
                                </select>
                                <select name="area" id="area" class="form-control" v-model="form.areaTreeCode">
                                    <option value="-1">请选择区县</option>
                                    <option v-for="(item, index) in areaList" :key="item.id" :value="item.treeCode">
                                        {{ item.nodeName }}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-3"></label>
                            <div class="col-8">
                                <Field type="text" name="address" v-model="form.address" class="form-control"
                                       placeholder="街道、镇、门牌号"/>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-3">
                                <el-popover
                                    placement="top"
                                    :width="460"
                                    trigger="hover"
                                >
                                    <template #reference>
                                        <div class="question">?</div>
                                    </template>
                                    <div class="question-hint">
                                        <p><b>单位管理员</b>：如您负责单位的科研管理工作，需审核各平台提交的资源，管理全单位的人才、项目等信息，请勾选；</p><br/>
                                        <p><b>个人用户：</b>如您仅需访问、下载、收藏等操作，请勾选；</p><br/>
                                        <p><b>平台/项目负责人：</b>如您负责实验室、科学数据中心、野外台站、专项科考等具体平台或项目的数据汇交、黄页展示、年报填写等业务，请勾选；</p>
                                        <br/>

                                        <p>若不清楚如果选择用户角色，欢迎致电平台咨询电话。</p>
                                    </div>
                                </el-popover>
                                用户角色<span class="required">*</span></label>
                            <div class="col-8 radio-group" v-loading="posiLoading">
                                <label v-for="(item, index) in roleList" :key="item.id">
                                    <input type="radio" name="roleCode" class="form-radio" v-model="form.roleCode"
                                           :value="item.roleCode">{{ item.roleName }}
                                </label>
                            </div>
                        </div>
                    </template>
                    <div class="form-group">
                        <label class="control-label col-3">联系人姓名<span class="required">*</span></label>
                        <div class="col-8">
                            <template v-if="selectType == 'industrialPark'">
                                <Field type="text" name="name" v-model="form.name" :rules="checkRequired"
                                       class="form-control" placeholder="请输入联系人姓名"/>
                            </template>
                            <template v-else>
                                <Field type="text" name="name" v-model="form.name" :rules="checkRequired"
                                       class="form-control" placeholder="请输入联系人姓名"/>
                            </template>
                            <div class="error">{{ errors.name }}</div>
                        </div>
                    </div>

                    <template v-if="!userCode">
                        <div class="form-group"
                             v-if="selectType=='workplaceProjectAdmin'||selectType=='workplaceAdmin'">
                            <label class="control-label col-3">依托单位<span class="required">*</span></label>
                            <div class="col-8">
                                <Field type="text" name="organ" @click="showDeptDialog" readonly style="cursor:pointer;"
                                       v-model="form.organ" class="form-control" placeholder="点击选择依托单位"/>
                            </div>
                        </div>
                        <div class="form-group" v-if="selectType=='workplaceProjectAdmin'">
                            <label class="control-label col-3">平台/实验室</label>
                            <div class="col-8">
                                <Field type="text" @click="showPlatformDialog" readonly style="cursor:pointer;"
                                       name="platform" v-model="form.platform" class="form-control"
                                       placeholder="点击选择平台/实验室"/>
                            </div>
                        </div>
                        <!-- <div class="form-group"
                             v-if="(selectType=='workplaceProjectAdmin'||selectType=='workplaceAdmin')">
                            <label class="control-label col-3">身份证号码<span class="required">*</span></label>
                            <div class="col-8">
                                <Field type="text" name="idcard" v-model="form.idcard" :rules="checkIdcard"
                                       class="form-control" placeholder="请输入身份证号码"/>
                                <div class="error">{{ errors.idcard }}</div>
                            </div>
                        </div>
                        <div class="form-group"
                             v-if="selectType=='workplaceProjectAdmin'||selectType=='workplaceAdmin'">
                            <label class="control-label col-3">身份证人像面<span class="required">*</span></label>
                            <div class="col-8">
                                <el-upload
                                    class="uploader-box"
                                    :action="uploadUrl"
                                    :show-file-list="false"
                                    :data="{relateType: 'gdsti_user_register'}"
                                    :on-success="handleIdcard1Success"
                                    :before-upload="beforeAvatarUpload"
                                >
                                    <img v-if="uploadIdcard1" :src="uploadIdcard1" class="uploader-idcard-img"/>
                                    <el-icon v-else class="uploader-icon uploader-idcard">
                                        <Plus/>
                                    </el-icon>
                                </el-upload>
                                <div class="error">
                                    {{ checkIdcardImg(form.idcard1AttachCode) == true ? '' : checkIdcardImg(form.idcard1AttachCode) }}
                                </div>
                            </div>
                        </div>
                        <div class="form-group"
                             v-if="selectType=='workplaceProjectAdmin'||selectType=='workplaceAdmin'">
                            <label class="control-label col-3">身份证国徽面<span class="required">*</span></label>
                            <div class="col-8">
                                <el-upload
                                    class="uploader-box"
                                    :action="uploadUrl"
                                    :show-file-list="false"
                                    :data="{relateType: 'gdsti_user_register'}"
                                    :on-success="handleIdcard2Success"
                                    :before-upload="beforeAvatarUpload"
                                >
                                    <img v-if="uploadIdcard2" :src="uploadIdcard2" class="uploader-idcard-img"/>
                                    <el-icon v-else class="uploader-icon uploader-idcard">
                                        <Plus/>
                                    </el-icon>
                                </el-upload>
                                <div class="error">
                                    {{ checkIdcardImg(form.idcard1AttachCode) == true ? '' : checkIdcardImg(form.idcard1AttachCode) }}
                                </div>
                            </div>
                        </div> -->


                        
                    </template>

                    <div class="form-group" style="text-align: center;">
                        <span @click="readWebRegisterInfo" style="margin: 0 auto;">  
                                <el-checkbox v-model="form.onlineSign" :true-label="1" :false-label="0"> 
                                已经阅读注册须知的内容，并承诺遵照要求。
                            </el-checkbox> 
                        </span>
                    </div>


                    <div class="form-group">
                        <button type="button" v-loading="loading" class="btn btn-primary next-btn" @click="handleLogin">
                            下一步
                        </button>
                    </div>

                    <!--                    <div class="support">技术支持电话：13632332125</div>-->

                </Form>
            </div>

            <div class="step-content wait-apply" v-if="active==1">
                <el-result icon="success" title="提交成功，等待审核" sub-title="您的注册信息已提交，审核通过后将通过短信通知您"></el-result>
            </div>

            <el-dialog v-model="showDept" title="请选择依托单位" width="910px">
                <div v-loading="deptLoading">
                    <div style="margin-bottom: 24px;">
                        <div class="form-inline">
                            <!--                            <button type="button" class="btn btn-primary btn-add" @click="showAddDept=true">新建依托单位</button>-->
                            <input type="text" class="form-control" v-model="searchDeptName" placeholder="请输入关键词搜索"
                                   @keyup.enter="deptPageChange(1)"/>
                            <button type="button" class="btn btn-primary btn-search" @click="deptPageChange(1)">查询
                            </button>
                            <button type="button" class="btn btn-white btn-reset"
                                    @click="searchDeptName='',deptPageChange(1)">重置
                            </button>
                        </div>
                        <div class="form-inline">
                            <span style="color: red;">找不到依托单位？请联系工作人员处理。</span>
                        </div>
                        <div class="form-inline new-dept" v-show="showAddDept">
                            <input type="text" class="form-control" placeholder="依托单位名称" v-model="addDeptForm.name"/>
                            <input type="text" class="form-control" placeholder="依托单位地址" v-model="addDeptForm.address"/>
                            <input type="text" class="form-control" placeholder="依托单位邮箱" v-model="addDeptForm.eMail"/>
                            <input type="text" class="form-control" placeholder="依托单位联系电话"
                                   v-model="addDeptForm.contactNumber"/>
                            <button type="button" class="btn btn-primary" @click="submitAddDept"
                                    v-loading="addDeptLoading">确定
                            </button>
                        </div>
                    </div>
                    <div class="select-list" v-if="deptPage.total > 0">
                        <template v-for="(item, index) in deptPage.list" :key="item.id">
                            <div class="select-item" @click="selectDept(index)">
                                {{ item.name }}
                            </div>
                        </template>
                    </div>
                    <el-divider v-else>
                        暂无数据
                    </el-divider>

                    <Pager @pageChange="deptPageChange" :currentPage="deptCurrentPage"
                           :totalPage="deptPage.totalPage"  :total="deptPage.total" :layout="'total, prev, pager, next'"></Pager>
                </div>
            </el-dialog>
            <el-dialog v-model="showPlatform" :title="'请选择属于“'+form.organ+'”依托单位的平台/实验室'">
                <div v-loading="platformLoading">
                    <div style="margin-bottom: 24px;">
                        <div class="form-inline">
                            <input type="text" class="form-control" v-model="searchPlatformName" placeholder="请输入关键词搜索"
                                   @keyup.enter="platformPageChange(1)"/>
                            <button type="button" class="btn btn-primary btn-search" @click="platformPageChange(1)">查询
                            </button>
                            <button type="button" class="btn btn-white btn-reset"
                                    @click="searchPlatformName='',platformPageChange(1)">重置
                            </button>
                        </div>
                        <div class="form-inline">
                            <span style="color: red;">如果找不到平台/实验室，可在注册审核通过后，在个人中心添加平台/实验室。</span>
                        </div>
                    </div>
                    <div class="select-list" v-if="platformPage.total > 0">
                        <template v-for="(item, index) in platformPage.list" :key="item.id">
                            <div class="select-item" @click="selectPlatform(index)">
                                {{ item.platformName }}
                            </div>
                        </template>
                    </div>
                    <el-divider v-else>
                        暂无数据
                    </el-divider>

                    <Pager @pageChange="platformPageChange" :currentPage="platformCurrentPage"
                           :totalPage="platformPage.totalPage" :total="platformPage.total" :layout="'total, prev, pager, next'"></Pager>
                </div>
            </el-dialog>
        </div>
        <el-dialog v-model="showDialog" title="验证码" width="300px" @close='closeDialog'>
            <div @click="resetImgCode" style="width: 100%;display: flex;justify-content: center;">
                <img style="width:300px;" :src="changeImgUrl()" alt="验证码"/>
            </div>
            <div style="display: flex;justify-content: center;padding-top: 20px;">
                <input type="text" class="form-control" v-model="imgCode" placeholder="验证码"/>
                <button type="button" class="btn btn-primary btn-search" @click="sendCode">确定</button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {Form, Field} from 'vee-validate'
import {ElUpload, ElButton, ElIcon, ElDialog, ElResult, ElPopover} from 'element-plus'
import {Download, Plus} from '@element-plus/icons-vue'
import {ref, reactive, onMounted, computed, watch, getCurrentInstance} from 'vue'
import {useRoute} from 'vue-router'
import LoginHead from '../components/common/LoginHead'
import Pager from "../components/common/Pager"

export default {
    components: {
        LoginHead,
        Form,
        Field,
        ElUpload,
        ElButton,
        ElIcon,
        ElResult,
        ElDialog,
        Pager,
        Download,
        Plus,
        ElPopover
    },
    setup() {
        const {proxy} = getCurrentInstance();
        const target = ref(null);
        const form = reactive({
            userCode: '',
            username: null,
            password: null,
            password1: null,
            email: '',
            phone: '',
            vsCode: '',
            province: '',
            city: '',
            area: '',
            provinceTreeCode: '',
            cityTreeCode: '',
            areaTreeCode: '',
            address: '',
            roleCode: '',
            name: '',
            organ: '',
            platform: '',
            organId: '',
            platformId: '',
            idcard: '',
            idcard1AttachCode: '',
            idcard2AttachCode: '',
            headImageAttachCode: '',
            zoneName: '',
            zoneAddress: '',
            businessLicenseImg1: '',
            businessLicenseImg2: '',
            businessLicenseImg3: '',

            onlineSign: '',
        });
        const imgCode = ref('');
        const imgUrl = ref('');
        const showDialog = ref(false)
        const count = ref(60);
        const showCount = ref(false);
        const codeLoading = ref(false);

        const loading = ref(false)
        const posiLoading = ref(false)
        const deptLoading = ref(false)
        const platformLoading = ref(false)

        const roleList = ref([]);
        const deptPage = ref({total: 0, page: 1, list: []});
        const platformPage = ref({total: 0, page: 1, list: []});
        const deptCurrentPage = ref(1);
        const platformCurrentPage = ref(1);
        const headImageUrl = ref(''); //头像文件
        const uploadIdcard1 = ref(''); //身份证正面
        const uploadIdcard2 = ref(''); //身份证反面

        const showDept = ref(false);
        const showPlatform = ref(false);
        const showAddDept = ref(false);
        const showAddPlatform = ref(false);
        const searchDeptName = ref('');
        const searchPlatformName = ref('');

        const type = ref(1)
        const active = ref(0)
        const route = useRoute();
        if (route.query.type) {
            type.value = route.query.type;
        }

        if (route.query.organType) {
            form.roleCode = route.query.organType;
        }


        const userCode = ref('');
        const userName = ref('');
        //从缓存中取userInfo
        if (proxy.$store.state.userInfo && proxy.$store.state.userInfo.userName) {

            // userCode.value = proxy.$store.state.userInfo.userCode;
            if (userCode.value) {
                //账号不为空，跳转首页
                // proxy.$router.push({path: '/index', name: 'Index'})
            }

        }


        const checkUserCode = value => {
            if (!value) return '请输入账号';
            if (!/^[a-zA-Z]\w{0,32}$/.test(value)) return '字母开头且1-32个字符';
            return true;
        }

        function validPwd(value) {
            let i = /\d+/.test(value) ? 1 : 0;
            let j = /[a-zA-Z]+/.test(value) ? 1 : 0;
            let k = /[~!@#$%^&*()_+|<>,.?/:;'\[\]\{\}\"]+/.test(value) ? 1 : 0;
            let length = value.length;
            if (value.indexOf(form.username) >= 0) {
                return '密码不能包含账号';
            } else if (length < 8) {
                return '密码至少8位字符';
            } else if (i + j + k <= 2) {
                return '密码须包含数字、字母、特殊字符';
            } else {
                return true;
            }
        }

        const checkPwd = value => {
            if (!value) return '请输入密码';
            let result = validPwd(value);
            return result;
        }

        const checkTwoPwd = value => {
            if (!value) return '请输入密码';
            let result = validPwd(value);
            if (result === true) {
                if (value != form.password) {
                    return '两次密码输入不一致';
                }
            } else {
                return result;
            }
            return true;
        }

        const checkEmail = value => {
            if (!value) return '请输入邮箱';
            if (!/\S+@\S+\.\S+/.test(value)) return '请输入正确的邮箱地址';
            return true;
        }

        const checkPhone = value => {
            if (!value) return '请输入手机号码';
            if (!/^1\d{10}/.test(value)) return '请输入11位手机号码';
            return true;
        }

        const checkIdcardImg = value => {
            if (!selectType.value || selectType.value == 'webSiteUser') {
                return true;
            } else if (!value) {
                return '请上传身份证照片';
            } else {
                return true;
            }
        }

        const checkRequired = value => {
            if (!value) {
                return '请输入内容';
            } else {
                return true;
            }
        }


        const checkIdcard = value => {
            if (!selectType.value || selectType.value == 'webSiteUser') {
                return true;
            }
            var patrn = /(^\d{15}$)|(^\d{17}(\d|x|X)$)/;//长度或格式校验
            //地区校验
            var aCity = {
                11: "北京",
                12: "天津",
                13: "河北",
                14: "山西",
                15: "内蒙古",
                21: "辽宁",
                22: "吉林",
                23: "黑龙江",
                31: "上海",
                32: "江苏",
                33: "浙江",
                34: "安徽",
                35: "福建",
                36: "江西",
                37: "山东",
                41: "河南",
                42: "湖北",
                43: "湖南",
                44: "广东",
                45: "广西",
                46: "海南",
                50: "重庆",
                51: "四川",
                52: "贵州",
                53: "云南",
                54: "西藏",
                61: "陕西",
                62: "甘肃",
                63: "青海",
                64: "宁夏",
                65: "新疆",
                71: "台湾",
                81: "香港",
                82: "澳门",
                91: "国外"
            };
            // 出生日期验证
            var sBirthday = (
                    value.substr(6, 4) +
                    "-" +
                    Number(value.substr(10, 2)) +
                    "-" +
                    Number(value.substr(12, 2))
                ).replace(/-/g, "/"),
                d = new Date(sBirthday)
            // 身份证号码校验 最后4位  包括最后一位的数字/字母X
            var sum = 0,
                weights = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2],
                codes = "10X98765432";
            for (var i = 0; i < value.length - 1; i++) {
                sum += value[i] * weights[i];
            }
            var last = codes[sum % 11]; //计算出来的最后一位身份证号码

            var errorMsg = '';
            if (value === '') {
                errorMsg = "身份证号不能为空"
            } else if (!patrn.exec(value)) {
                errorMsg = "你输入的身份证长度或格式错误"
            } else if (!aCity[parseInt(value.substr(0, 2))]) {
                errorMsg = "你的身份证地区非法"
            } else if (sBirthday != d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate()) {
                errorMsg = "身份证上的出生日期非法"
            } else if (last == 'X') {
                if (value[value.length - 1] == 'x' || value[value.length - 1] == 'X') {
                    return true;
                } else {
                    errorMsg = "你输入的身份证号非法"
                }
            } else if (value[value.length - 1] != last) {
                errorMsg = "你输入的身份证号非法"
            } else {
                return true;
            }
            return errorMsg;
        }

        // 点击登录
        const handleLogin = () => {
            console.log('handleLogin');

            if(!form.onlineSign || form.onlineSign != '1'){
                proxy.ElMessage.error('请勾选注册须知！');
                return false;
            }

            target.value.validate().then(valid => {
                if (valid) {
                    console.log(selectType.value)
                    //已登录用户注册园区
                    if (userCode.value) {
                        regiserZone();
                        return true;
                    }
                    // if (!checkIdcardImg(form.idcard1AttachCode) ||
                    //     !checkIdcardImg(form.idcard2AttachCode)) {
                    //     proxy.ElMessage.error('请上传身份证照片');
                    //     return false;
                    // }
                    if (!form.roleCode) {
                        proxy.ElMessage.error('请选择用户角色');
                        return false;
                    }
                    loading.value = true;
                    // const url = '/hf/base/a/user/addRegisterLog';
                    const url = '/gdsti2/web/a/web/webRegister';
                    proxy.$http.post(url,
                        {
                            userCode: form.userCode,
                            userPassword: form.password,
                            userPassword2: form.password1,
                            mobilePhone: form.phone,
                            eMailAddr: form.email,
                            vsCode: form.vsCode,
                            contractAddr: form.address,
                            userName: form.name,
                            roleCode: form.roleCode,
                            attachCode: form.headImageAttachCode,
                            userCardId: form.idcard,
                            userCardFrontImg: form.idcard1AttachCode,
                            userCardBackImg: form.idcard2AttachCode,
                            unitId: form.organId,
                            platformId: form.platformId,
                            province: form.province,
                            city: form.city,
                            area: form.area
                        }).then(res => {
                        if (selectType.value == 'industrialPark') {
                            if (res.data.state == 0) {
                                form.usercode = res.data.result;
                                regiserZone();
                            }
                        } else {
                            loading.value = false
                            if (res.data.state == 0) {
                                active.value = 1;
                            }
                        }
                        loading.value = false
                    }).catch(err => {
                        loading.value = false
                    });


                    // 表单验证通过，调用接口实现登录
                    // 以下为演示代码
                    return true;
                }
            }).catch(err => {
                return false;
            });

        }

        //注册园区
        function regiserZone() {
            loading.value = true;
            // const url = '/hfapplication/gdsti/a/industryZone/addIndustry';
            const url = '/gdsti2/web/a/webZone/addZone';
            proxy.$http.post(url, {
                userCode: form.usercode,
                contactName: form.name,
                zoneName: form.zoneName,
                zoneAddress: form.zoneAddress,
                businessLicenseImg1: form.businessLicenseImg1,
                businessLicenseImg2: form.businessLicenseImg2,
                businessLicenseImg3: form.businessLicenseImg3
            }).then(res => {
                loading.value = false;
                if (res.data.state == 0) {
                    active.value = 1;
                }
            }).catch(err => {
                loading.value = false;
            })
        }


        //获取岗位（注册类型）
        function getRoleList() {
            roleList.value.push({roleName: '单位管理员', roleCode: 'workplaceAdmin'});
            roleList.value.push({roleName: '个人用户', roleCode: 'webSiteUser'});
            roleList.value.push({roleName: '平台/项目负责人', roleCode: 'workplaceProjectAdmin'});
        }

        function getAllDept() {
            deptLoading.value = true;
            // const url = '/hfapplication/gdsti/a/relyonWorkplaceProject/relyonWorkplaceList';
            const url = '/gdsti2/web/a/webLab/unitList';
            proxy.$http.post(url, {
                limit: 10,
                offset: (deptCurrentPage.value - 1) * 10,
                name: searchDeptName.value
            }).then(res => {
                deptLoading.value = false
                if (res.data.state == 0) {
                    deptPage.value = res.data.result;
                }
            }).catch(err => {
                deptLoading.value = false
            })
        }

        function getPlatform(id) {
            platformLoading.value = true;
            // const url = '/hfapplication/gdsti/a/relyonWorkplaceProject/relyonWorkplaceProjectList';
            const url = '/gdsti2/web/a/webLab/platformList';
            proxy.$http.post(url, {
                unitId: id,
                limit: 10,
                offset: (platformCurrentPage.value - 1) * 10,
                platformName: searchPlatformName.value,
                canShow: 1,
            }).then(res => {
                platformLoading.value = false
                if (res.data.state == 0) {
                    platformPage.value = res.data.result;
                }
            }).catch(err => {
                platformLoading.value = false
            })
        }

        //选择的用户角色
        const selectType = computed(() => {
            if (userCode.value) {
                return 'industrialPark';
            }

            return form.roleCode;
        })


        const uploadUrl = computed(() => {
            const url = proxy.indexUrl + '/core/base/c/upload/upload';
            return url;
        });


        onMounted(() => {
            getRoleList();
            loadAreaTree();
        });

        const addDeptForm = ref({
            name: '',
            address: '',
            eMail: '',
            contactNumber: ''
        });

        const addPlatformForm = ref({
            hfgdstiRelyonProjectId: '',
            projectName: '',
            img: ''
        })

        const addDeptLoading = ref(false), addPlatformLoading = ref(false)


        //依托单位新增，现在已经取消，只能管理员在后台添加
        function submitAddDept() {
            if (!addDeptForm.value.name) {
                proxy.ElMessage.error('请输入单位名称');
                return;
            }
            addDeptLoading.value = true;
            console.log(addDeptForm.value)
            // const url = '/hfapplication/gdsti/a/relyonWorkplaceProject/addRelyonWorkplace';
            const url = '/gdsti2/web/a/webLab/addUnit';
            proxy.$http.post(url, addDeptForm.value).then(res => {
                addDeptLoading.value = false;
                if (res.data.state == 0) {
                    showAddDept.value = false;
                    let page = deptPage.value;
                    page.list.push(res.data.result);
                    if (page.total == 0) {
                        page.total = 1;
                        page.page = 1;
                    }
                    deptPage.value = page;
                    addDeptForm.value = {
                        name: '',
                        address: '',
                        eMail: '',
                        contactNumber: ''
                    };
                }
            }).catch(err => {
                addDeptLoading.value = false;
            })
        }

        //平台新增，现在已经取消，只能管理员在后台添加
        function submitAddPlatform() {
            if (!addPlatformForm.value.projectName) {
                proxy.ElMessage.error('请输入平台/实验室名称')
                return
            }
            // if(!addPlatformForm.value.img){
            //     proxy.ElMessage.error('请上传平台/实验室Logo')
            //     return
            // }
            addPlatformLoading.value = true;
            addPlatformForm.value.hfgdstiRelyonProjectId = form.organId;
            console.log(addPlatformForm.value)
            // const url = '/hfapplication/gdsti/a/relyonWorkplaceProject/addRelyonWorkplaceProject';
            const url = '/gdsti2/web/a/webLab/addPlatform';
            proxy.$http.post(url, addPlatformForm.value).then(res => {
                addPlatformLoading.value = false;
                if (res.data.state == 0) {
                    showAddPlatform.value = false;
                    let page = platformPage.value;
                    page.list.push(res.data.result);
                    if (page.total == 0) {
                        page.total = 1;
                        page.page = 1;
                    }
                    platformPage.value = page;

                    addPlatformForm.value = {
                        hfgdstiRelyonProjectId: '',
                        projectName: '',
                        img: ''
                    }
                }
            }).catch(err => {
                addPlatformLoading.value = false;
            })
        }

        const licenseList = ref([]);

        const areaTree = ref([]);
        const provinceList = ref([]);
        const cityList = ref([]);
        const areaList = ref([]);
        let imgCodeTimer = undefined;

        function loadAreaTree() { //加载省市区
            proxy.$http.post('/gdsti2/web/a/web/areaTree', {parentTreeCode: '001', treeType: '101'}).then(res => {


                areaTree.value = res.data.result[0].children;    //第1个节点是中国，所以从第二级开始

                for (let i = 0; i < areaTree.value.length; i++) {
                    provinceList.value.push(areaTree.value[i]);
                }

            }).catch(err => {
            })
        }


        //省选择联动显示城市
        watch(() => form.provinceTreeCode, (provinceTreeCode, oldVal) => {
            if (provinceTreeCode == oldVal) {
                return;
            }

            if (provinceTreeCode != '') {
                //从选中的省份读取城市
                for (let i = 0; i < provinceList.value.length; i++) {
                    let item = provinceList.value[i];
                    if (item.treeCode == provinceTreeCode) { //选中项
                        cityList.value.splice(0, cityList.value.length);

                        for (let j = 0; j < item.children.length; j++) {
                            cityList.value.push(item.children[j]);
                        }

                        break;
                    }
                }
            }

            form.cityTreeCode = '';
            form.areaTreeCode = '';

        }, {immediate: true})

        //省选择联动显示区
        watch(() => form.cityTreeCode, (cityTreeCode, oldVal) => {
            if (cityTreeCode == oldVal) {
                return;
            }

            if (cityTreeCode != '') {
                //从选中的城市读取区域
                for (let i = 0; i < cityList.value.length; i++) {
                    let item = cityList.value[i];

                    if (item.treeCode == cityTreeCode) { //选中项
                        areaList.value.splice(0, areaList.value.length);

                        if (item.children) {
                            for (let j = 0; j < item.children.length; j++) {
                                areaList.value.push(item.children[j]);
                            }
                        }


                        break;
                    }
                }
            }

            form.areaTreeCode = '';
        }, {immediate: true})


        let timer = undefined;

        //发送手机验证码
        function sendCode() {

            if (showCount.value) {
                return;
            }
            if (timer) {
                clearInterval(timer);
            }

            if (!form.phone || form.phone.length != 11) {
                proxy.ElMessage.error("手机号码长度不对");
                this.showDialog = false
                return;
            }
            codeLoading.value = true;

            const url = '/gdsti2/web/a/web/sendVCodeMsg';
            proxy.$http.post(url, {phone: form.phone, randomCode: imgCode.value, relateType: 'register'}).then(res => {
                codeLoading.value = false;
                if (res.data.state == 0) {
                    showDialog.value = false;
                    showCount.value = true;
                    clearInterval(imgCodeTimer);
                    //180秒
                    count.value = 180;
                    timer = setInterval(function () {
                        count.value = count.value - 1;
                        if (count.value <= 0) {
                            codeLoading.value = false;
                            clearInterval(timer);
                            timer = undefined;
                            showCount.value = false;
                        }
                    }, 1000);
                } else {
                    // proxy.ElMessage(res.data.message)
                    showCount.value = false;
                }
            }).catch(err => {
                codeLoading.value = false;
                showCount.value = false;
            })

        };

        // 初始化图形验证码
        function initImgCode() {
            if (!form.phone) {
                proxy.ElMessage.error("请输入手机号码");
                showDialog.value = false
                return;
            }
            if (form.phone.length != 11) {
                proxy.ElMessage.error("手机号码长度不对");
                showDialog.value = false
                return;
            }

            //打开验证码
            showDialog.value = true;

            //启动定时器
            initTimer();
        };


        // 重置图形验证码
        function resetImgCode() {
            clearInterval(imgCodeTimer);
            initTimer();
        }


        // 刷新图形验证码
        function reloadImgCode() {
            imgCode.value = ""
            imgUrl.value = "/core//base/c/index/randomcode?time=" + new Date().getTime()
        }

        //启动刷新验证码定时器
        function initTimer() {
            reloadImgCode()
            // imgCodeTimer = setInterval(()=>{
            //   console.log("定时器");
            //   reloadImgCode();
            // },15000)

        }

        //关闭dialog时，清空定时器
        function closeDialog() {
            clearInterval(imgCodeTimer);
        };

        return {
            provinceList, cityList, areaList, userCode, userName, licenseList, addDeptLoading, addPlatformLoading,
            submitAddDept, checkRequired, submitAddPlatform, addPlatformForm, addDeptForm, active, type,
            showDept, showPlatform, showAddDept, showAddPlatform, searchDeptName, searchPlatformName,
            uploadUrl, headImageUrl, uploadIdcard1, uploadIdcard2, selectType,
            loading, posiLoading, deptLoading, platformLoading, roleList, platformPage,
            deptCurrentPage, platformCurrentPage, deptPage,
            target, form, checkUserCode, checkPwd, checkTwoPwd, checkEmail, checkPhone, checkIdcardImg, checkIdcard,
            getAllDept, getPlatform, handleLogin,
            count, showCount, sendCode, codeLoading,
            initImgCode, reloadImgCode, imgCode, imgUrl, showDialog, closeDialog, imgCodeTimer, resetImgCode
        }
    },
    methods: {
        toImage(id) {
            return this.$getImage(id);
        },
        showDeptDialog() {
            this.showDept = true;
            this.deptPageChange(1);
        },
        showPlatformDialog() {
            if (!this.form.organId) {
                this.ElMessage.error('请先选择依托单位');
            } else {
                this.showPlatform = true;
                this.platformPageChange(1);
            }
        },
        selectDept(index) {
            const selectItem = this.deptPage.list[index];
            this.form.organ = selectItem.name;
            this.form.organId = selectItem.id;
            this.showDept = false;
        },
        selectPlatform(index) {
            const selectItem = this.platformPage.list[index];
            console.log(selectItem)
            this.form.platform = selectItem.platformName;
            this.form.platformId = selectItem.id;
            this.showPlatform = false;
        },
        deptPageChange(index) {
            this.deptCurrentPage = index;
            this.getAllDept();
        },
        platformPageChange(index) {
            this.platformCurrentPage = index;
            this.getPlatform(this.form.organId);
        },
        handleIdcard1Success(response, uploadFile) {
            if (response.state == 0) {
                console.log(response)
                this.form.idcard1AttachCode = response.result[0].attachInfo.id;
                this.uploadIdcard1 = this.$getImage(response.result[0].attachInfo.id);
            } else {
                this.ElMessage.error(response.message);
            }
        },
        handleIdcard2Success(response, uploadFile) {
            if (response.state == 0) {
                this.form.idcard2AttachCode = response.result[0].attachInfo.id;
                this.uploadIdcard2 = this.$getImage(response.result[0].attachInfo.id);
            } else {
                this.ElMessage.error(response.message);
            }
        },
        beforeAvatarUpload(rawFile) {
            if (rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png') {
                this.ElMessage.error('图片需要jpg或png格式');
                return false;
            } else if (rawFile.size / 1024 / 1024 > 2) {
                this.ElMessage.error('图片需要小于2MB');
                return false;
            }
            return true;
        },
        changeImgUrl: function () {
            return this.imgUrl;
        },
        readWebRegisterInfo: function(e, row, type){    //阅读注册须知
            if (e.target.localName === "input") {   //防止多次弹窗
                let dialogId = 'readWebRegisterInfo' + Math.floor(Math.random()*1000000);
                openDialog(dialogId, "注册须知", "/newsContent?id=WEB_REGISTER_INFO&close=1", "", '80%', '90%', function () {

                });
            }
        }
    },
    beforeUnmount() {
        clearInterval(this.imgCodeTimer);
    }
}
</script>

<style lang="less" scoped>
.regiser-note {
    background: #EBF6FF;
    border: 1px solid #007EEC;
    border-radius: 3px;
    padding: 17px 17px 17px 21px;
    display: flex;
    align-items: center;
    width: 480px;
    margin: 0 auto;

    .note-img {
        margin-right: 19px;
    }

    .note-info {
        font-family: SourceHanSansCN-Medium;
        font-size: 16px;
        color: #007EEC;
        line-height: 18px;
    }
}

.select-list {
    .select-item {
        cursor: pointer;
        font-size: 14px;
        line-height: 32px;
        color: #000000;
    }

    .select-item:hover {
        color: rgba(0, 0, 0, 0.5);
    }

}

.register {
    min-height: 100%;
    background: #F6F6F6;
}

.register-box {
    border-radius: 2px;
    margin-top: 32px;
    width: 610px;
    min-height: 660px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    background: #FFFFFF;
}

.step {
    padding-left: 53px;
    padding-right: 53px;
    margin-bottom: 29px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .step-item {
        display: flex;
        flex-direction: column;
        align-items: center;

        .step-num {
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 30px;
            color: #fff;
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            background: rgba(0, 0, 0, 0.15);
            text-align: center;
        }

        .step-num.active {
            background: #007EEC;
        }

        .step-name {
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            margin-top: 17px;
        }
    }

    .step-arrow {
        width: 110px;
        display: flex;
        align-items: center;

        div {
            width: 104px;
            height: 1px;
            background: rgba(0, 0, 0, 0.15);
        }

        span {
            float: right;
            display: block;
            margin: 27px auto;
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-left-color: rgba(0, 0, 0, 0.15);;
        }
    }
}

.wait-apply {
    text-align: center;
    padding-top: 74px;
    font-size: 24px;
}


.form-control {
    width: 350px;
}

.control-addr {
    .form-control {
        width: 120px;
        margin-right: 0px;
    }
}

.control-addr, .radio-group {
    display: flex;
    width: 390px;
    justify-content: space-between;

    .form-control:last-child {
        margin-right: 0;
    }
}

.uploader-box {
    margin-left: 10px;
}

.form-group {
    margin-bottom: 12px;
}

.form-radio {
    margin-left: 10px;
    cursor: pointer;
}

.next-btn {
    margin: 0 auto;
    width: 387px;
    margin-top: 10px;
}

.error {
    margin-left: 10px;
}

.btn-add {
    margin-right: 24px;
}

.btn-reset {
    margin-left: 12px;
}

.new-dept {
    margin-top: 12px;
}

.question {
    display: inline-block;
    text-align: center;
    font-size: 12px;
    width: 14px;
    height: 14px;
    line-height: 14px;
    border-radius: 14px;
    color: rgba(0, 0, 0, 0.85);
    border: 1px solid rgba(0, 0, 0, 0.85);
    cursor: pointer;
}

.question-hint {
    p {
        font-size: 14px;
        line-height: 24px;
    }
}

.support {
    text-align: center;
    font-family: SourceHanSansCN-Regular;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.50);
    margin: 14px;
}
</style>



