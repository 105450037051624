<template>
    <div>

        <div class="news-content" v-loading="loading">

            <div class="desc" v-html="newsInfo.content" id="desc"></div>
            <div>

                <div class="down-item" v-for="(item, index) in relateAttach" :key="index">
                    <a :href="'/core/base/c/download/file?attachId='+item.id"  class="down-link">附件：{{item.name}}</a>

                </div>

            </div>

            <div style="margin: 20px auto;text-align: center;" v-if="showClose">
              <el-button type="primary" @click="closeDialog('')">关闭</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import Bread from '../components/common/Bread'
import { useRoute} from 'vue-router'
import { ref, onMounted, watch, getCurrentInstance, defineProps} from 'vue'
export default {
    props: {
        artIdOrCode: String
    },
    setup(props){

        const route = useRoute();
        const params = route.params;
        const query = route.query;
        const {proxy} = getCurrentInstance();
        const loading = ref(false)
        const newsInfo = ref({});
        const type = ref(query.type);
        const name = ref(query.name);
        const path = ref(query.path);
        const relateAttach = ref([]);


        const showClose = ref(false);
        if(query.close){
            showClose.value = (query.close == '1'); //显示关闭按钮
        }
        // console.log(query)

        const breads = ref([{text:'首页', path: '/index', name: 'Index'}]);
        if(type.value =='training'){
            breads.value = [{text: '科普与培训', path: '/training', name: 'Training'}];
        }else if(type.value == 'industry'){
            breads.value = [{text: '产业专区', path: '/industry', name: 'Industry'}];
        }else if(type.value == 'contest'){
            breads.value = [{text: '竞赛专区', path: '/contest', name: 'Contest'}];
        }else if(type.value == 'index'){
            //什么都没有
        }else if(type.value == 'organ'){
            breads.value.push({text: name.value, path: path.value, name: "Organ"});
        }
    
        function getArticleInfo(id){    //获取文章信息

            loading.value = true;
            const url = '/core/cms/a/articleContent/info?id=' + id;
            proxy.$http.post(url)
            .then(res=>{
                loading.value = false;
                if(res.data.state == 0){
                    // console.log(newsInfo)
                    newsInfo.value = res.data.result;
                    let typeName = newsInfo.value.typeName;
                    let typeId = newsInfo.value.typeId;
                    if(type.value == 'organ'){ 
                        breads.value.push({text: '文章详情（典型案例）'})
                    }else{
                        if(type.value){
                            breads.value.push({text: typeName, name:'NewsListPage', path:'/newsListPage?id='+ typeId + '&name='+ typeName + '&type='+type.value});
                        }else{
                            breads.value.push({text: typeName, name:'Institution', path:'/institution?id='+ typeId + '&name='+ typeName + '&type='+type.value});
                        }
                        breads.value.push({text: '文章详情'})
                    }
                    if(newsInfo.value.attachIdArr){
                        let idArr = newsInfo.value.attachIdArr.split(",");
                        let nameArr = newsInfo.value.attachNameArr.split(",");
                        for(let i = 0; i < idArr.length; i++){
                            relateAttach.value.push({id:idArr[i], name:nameArr[i]});
                        }
                    }

                    increase(id); //增加浏览量
                    getAccessCount(id); //获取浏览量
                }
            }).catch(err=>{
                loading.value = false;
            })
        }

        //获取浏览量
        function getAccessCount(artId){
            const url = '/core/cms/a/articleContent/getArticleAccessCount?id=' + artId;
            proxy.$http.post(url)
            .then(res=>{
                if(res.data.state == 0){
                    newsInfo.value.views = res.data.result;
                }
            }).catch(err=>{
            })
        }

        //增加浏览量
        function increase(artId){
            const url = '/core/cms/a/articleContent/articleAccess?id=' + artId;
            proxy.$http.post(url).then(res=>{}).catch(err=>{})
        }

        onMounted(()=>{
            if(query.id){
                getArticleInfo(query.id);
            }else if(props.artIdOrCode){
                // console.log('artIdOrCode2', props.artIdOrCode);
                getArticleInfo(props.artIdOrCode);
            }

            
        })

        watch(
            () => props.artIdOrCode,
            (newV) => {
                if(newV){
                    // console.log('artIdOrCode', newV);
                    getArticleInfo(newV);
                }
            }
        )


        return {
            newsInfo, breads, loading, relateAttach, props, closeDialog, showClose
        }
    },

    components: {Bread},
    mounted(){
        
    }
 
}
</script>

<style lang="less" scoped>
.down-link{
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: #007EEC;
    letter-spacing: 0;
    text-align: justify;
    margin-bottom: 12px;
    max-width: 440px;
    overflow: hidden;
    text-decoration: none;
}
.news-content{
    margin-top: 12px;
    margin-bottom: 12px;
    overflow: auto;
    padding: 0 20px;
    img{
        max-width: 1200px;
    }
    .title{
        margin-top: 14px;
        font-family: SourceHanSansCN-Medium;
        font-size: 26px;
        color: rgba(0,0,0,0.85);
        text-align: center;
    }
    .info{
        font-family: SourceHanSansCN-Normal;
        font-size: 14px;
        color: rgba(0,0,0,0.50);
        margin-top: 29px;
        padding-bottom: 7px;
        border-bottom: 1px solid #E3E3E3;
        .date{
            margin-right: 27px;
        }
        .count{
            float:right;
        }
    }
    
    /*
    .desc{
        margin-top: 25px;
    }
    */
}
</style>

