<template>
    <div class="training content">
        <div class="training-box">
            <div class="training-left">
                <div class="training-title">
                    数据汇交培训
                </div>
                <div class="video"  >
                    <img src="@static/img/play.svg" v-if="!isPlaying" @click="playClick"/>
                    <video :src="videoUrl" ref="video" :style="{filter: !isPlaying?'brightness(0.8)':''}"/>
                </div>
            </div>
            <div class="training-right" v-loading="loading">
                <div class="training-title">
                    竞赛培训
                    <a href="javascript:void(0);"  class="link-more" @click="gotoMore('1535176027454586882', '竞赛培训')">查看更多></a>
                </div>
                <div class="training-class">
                    <template v-for="(item, index) in trainPage.content.list" :key="index">
                        <div class="class-item" @click="gotoDetail(item.id)"  v-if="index < 5">
                            <div class="class-date" >
                                <div class="day">{{item.adDateForMat.substr(8, 9)}}</div>
                                <div class="month">{{item.adDateForMat.substr(0, 7)}}</div>
                            </div>
                            <div class="class-name">{{item.adTitle}}</div>
                        </div>
                    </template>
                   
                </div>
            </div>
        </div>

        <div class="traning-block">
            <NewsSwiper title="数据制作培训" :newsPager="newsPager" type="training">
                <template  v-slot:title > <div class="training-title" >数据制作培训</div></template>
            </NewsSwiper>
        </div>

        <div class="training-box">
            <div class="training-left" v-loading="loading">
                <div class="training-title">
                    科普作品
                    <a href="javascript:void(0);"  class="link-more" @click="gotoMore('1535190432493903873', '科普作品')">查看更多></a>

                </div>
                <div class="work-list">
                    <template v-for="(item, index) in workPage.content.list">
                        <div class="work-item" :class="'work-item'+(index+1)" @click="gotoDetail(item.id)" v-if="index < 6">
                            <div class="work-content">
                                <div class="work-title">
                                    {{item.adTitle}}
                                </div>
                                <div class="work-date">{{item.adDateForMat}}</div>
                            </div>
                        </div>
                    </template>
                    
                </div>
            </div>
            <div class="training-right" v-loading="loading">
                <div class="training-title">
                    科普资讯<a href="javascript:void(0);"  class="link-more" @click="gotoMore('1535170920948187138', '科普资讯')">查看更多></a>
                </div>
                <div class="content-news">
                    <Info :item="infoPage" name="科普资讯" :breads="breads" :isPadding="false"></Info>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Info from '../components/Info'
import NewsSwiper from '../components/common/NewsSwiper';
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    components: {NewsSwiper, Info},
    setup(){
        const newsPager = ref({total: 1, page:1, list:[
            {
                name: '数据制作教程',
                date: '2021-10-15',
                img: '../static/img/case1.png'
            },
            {
                name: '服务案例标题',
                date: '2021-10-15',
                img: '../static/img/case2.png'
            },
            {
                name: '服务案例标题',
                date: '2021-10-15',
                img: '../static/img/case1.png'
            },
            {
                name: '服务案例标题',
                date: '2021-10-15',
                img: '../static/img/case2.png'
            }
            ]})
        const breads = ref([{text:'科普培训', name:'Training', path:'/training'}])
        const videoUrl = ref('');

        const loading = ref(false);
        

        const isPlaying = ref(false)
        const {proxy} = getCurrentInstance();

        const infoPage = ref({content: {total:0}});
        const trainPage = ref({content: {total:0}});
        const workPage = ref({content: {total:0}});

        function getList(id){
            //竞赛培训 1535176027454586882
            //科普作品 1535190432493903873
            //科普资讯 1535170920948187138
            loading.value = true;
            const url = '/hfapplication/gdsti/a/adInfo/list';
            proxy.$http.post(url, {adType: id, limit: 7, offset:0})
            .then(res=>{
                 loading.value = false;
                if(res.data.state == 0){
                    if(id == '1535176027454586882'){
                        trainPage.value.content = res.data.result;
                    }else if(id == '1535190432493903873'){
                        workPage.value.content = res.data.result;
                    }else if(id == '1535170920948187138'){
                        infoPage.value.content = res.data.result;
                    }
                }
            }).catch(err=>{
                 loading.value = false;
            });
        }

        function getVideo(){
            const url = '/hfapplication/gdsti/a/train/dataFillVideo';
            proxy.$http.post(url).then(res=>{
                if(res.data.state == 0){
                    videoUrl.value = res.data.result.videoUrl;
                    console.log(videoUrl.value)

                    videoUrl.value = "http://rint3jd5c.hn-bkt.clouddn.com/202209/GDSTI.m4v";
                }
            }).catch(err=>{

            })
        }

        function playClick(){
            if(isPlaying.value){
                proxy.$refs.video.pause();
                document.getElementsByTagName('video')[0].controls=false
            }else{
                proxy.$refs.video.play();
                document.getElementsByTagName('video')[0].controls=true
            }

            proxy.$refs.video.onpause = function(){
                console.log("暂停")
                isPlaying.value = false;
            }
            proxy.$refs.video.onplay = function(){
                console.log("播放")
                isPlaying.value = true;
            }
            // isPlaying.value = !isPlaying.value;
        }

        function getDataMakeList(){
            const url = '/hfapplication/gdsti/a/train/trainDataMakeAdInfoList';
            proxy.$http.post(url, {limit: 100, offset: 0}).then(res=>{
                if(res.data.state == 0){
                    newsPager.value = res.data.result;
                }
            }).catch(err=>{

            })
        }

        onMounted(()=>{
            getList('1535176027454586882')
            getList('1535190432493903873')
            getList('1535170920948187138')
            getVideo();
            getDataMakeList();
        })

        return {infoPage,trainPage, workPage, newsPager, breads, isPlaying, videoUrl, loading, playClick}
    },
    methods:{
        gotoBuilding(){
            this.$router.push({name: 'Building', path: '/building'});
        },
        gotoDetail(id){
            this.$router.push({name: 'NewsDetail', path: '/newsDetail', query: {id: id, type: 'training'}})
        },
        gotoMore(id, name){
            this.$router.push({name: 'NewsListPage', path: '/newsListPage', query: {id: id, name: name, type: 'training'}})
        }
    }
}
</script>

<style lang="less" scoped>
.work-item1{
    width:278px; height:144px;background-image: url('../../public/static/img/training1.png');
}
.work-item2{
    width:162px; height:144px;background-image: url('../../public/static/img/training2.png');
}
.work-item3{
    width:222px; height:144px;background-image: url('../../public/static/img/training3.png');
}
.work-item4{
    width:162px; height:144px;background-image: url('../../public/static/img/training4.png');
}
.work-item5{
    width:163px; height:144px;background-image: url('../../public/static/img/training5.png');
}
.work-item6{
    width:338px; height:144px;background-image: url('../../public/static/img/training6.png');
}
.training{
    padding-bottom: 137px;
}
.traning-block{
    margin-top: 58px;
}
.training-class{
    .class-item{
        cursor: pointer;
        display: flex;
        margin-bottom: 16px;
        height: 65px;
        .class-date{
            background: #007EEC;
            width: 117px;
            font-family: SourceHanSansCN-Regular;
            color: #FFFFFF;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .day{
                font-size: 24px;
                margin-bottom: 6px;
                line-height: 24px;
            }
            .month{
                font-size: 14px;
                line-height: 14px;
            }
        }
        .class-name{
            background: #E8F3FF;
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            color: rgba(0,0,0,0.70);
            line-height: 22px;
            padding: 0 16px;
            display: flex;
            align-items:center;
            width: 336px;
            height: 65px;
            text-overflow: ellipsis;
            -webkit-line-clamp: 2;
            word-wrap:break-word;
            word-break: break-all;
            overflow: hidden;
        }
        .class-name:hover{
            background: #bfbfbf;
        }
    }
    
}
.video{
    video{
        height: 388px;
        width: 690px;
        position: absolute;
        left: 0;
        top: 0;
        z-index:1;
    }
    height: 388px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: rgba(0,0,0,0.25);

    img{
        cursor: pointer;
        z-index: 2;
        filter: drop-shadow(1px 1px 10px white);
    }
}
.training-title{
    font-family: SourceHanSansCN-Bold;
    font-size: 30px;
    color: rgba(0,0,0,0.85);
    line-height: 30px;
    margin-bottom: 15px;
}
.training-box{
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .training-left{
        width: 690px;
    }
    .training-right{
        width: 448px;
    }
}
.work-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .work-item{
        height: 144px;
        overflow: hidden;
        cursor: pointer;
        margin-bottom: 18px;

        .work-content{
            width: 100%;
            height: 100%;
            background: rgba(0,0,0,0.50);
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .work-title{
                font-family: SourceHanSansCN-Medium;
                font-size: 16px;
                color: #FFFFFF;
                line-height: 22px;
                padding: 20px 20px 0px 20px;
                -webkit-line-clamp: 3;
                word-wrap:break-word;
                word-break:break-all;
                text-overflow: ellipsis;
                max-height: 108px;
                overflow: hidden;
            }
            .work-date{
                font-family: SourceHanSansCN-Regular;
                font-size: 12px;
                color: #FFFFFF;
                padding: 0 20px 20px 20px ;
            }
        }
    }
}
.link-more{
    float: right;
}
</style>
