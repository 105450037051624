<template>
    <div class="content head-bar">
        <div class="logo-box">
            <div class="logo" @click="gotoIndex">
                <img src="@assets/big_logo.svg" style="width: 268px;"/>
                <div v-show="false" class="site-name">广东省科技资源共享服务平台</div>
            </div>
        </div>

        <div class="search-bar">
            <div class="search-input">
                <input type="text" placeholder="请输入关键字" name="keywork" id="keyword" v-model="keyword"/>
                <div class="search-icon" @click="onSearch"><img src="@assets/search.png"/></div>
            </div>
            <div class="search-checkbox">
                <label class="checkbox" v-for="(item, index) in category" :key="item.dictVal"><input type="radio"
                                                                                                name="radio"
                                                                                                v-model="radioIndex"
                                                                                                :checked="item.checked"
                                                                                                :value="index"/>{{ item.dictName }}</label>
            </div>
        </div>

        <div class="user-bar">

            <img src="@assets/user.png" class="icon-user"/>
            <template v-if="userName == ''">
                <a href="javascript:void(0)" @click="gotoLogin" class="link-text">登录</a>
                &nbsp;|&nbsp;
                <a href="javascript:void(0)" @click="gotoRegister" class="link-text">注册</a>
            </template>
            <template v-else>
                <!--    <a href="javascript:void(0)" @click="gotoAdmin"  class="link-text">{{userName}}</a> -->
                <router-link to="/user/baseInfo" class="menu-item" :class="{active: currentPath=='baseInfo'}">
                    {{ userName }}
                </router-link>
                &nbsp;|&nbsp;
                <a href="javascript:void(0)" @click="gotoLogout" class="link-text logout">退出</a>
            </template>

        </div>

    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue'
import router from "@/router";
import CommonUtil from "@/utils/common"

export default {
    data() {
        return {
            token: '',
            userName: '',
            radioIndex: '',
            currentPath: '',
        }
    },
    setup() {
        const {proxy} = getCurrentInstance();
        const category = ref([]);
        const checkboxIndex = ref([]);
        const keyword = ref('');

        let searchVal = proxy.$store.state.searchVal;
        keyword.value = searchVal.keyword;
        let tempIds = searchVal.id.split(',');


        //获取用户信息，并检查用户的登录状态
        async function checkUserInfo(){
            let userInfo = await CommonUtil.getUserInfo();

            if(userInfo && userInfo.userCode){  //有登录用户数据
                if(userInfo.userCode != proxy.$store.state.userInfo.userCode){  //判断前后台用户是否一致;  有可能后台会更换登录用户
                    proxy.$store.commit('updateUserInfo', {userInfo: userInfo});    //重新更新网站用户为后台用户信息
                }else{
                    //前后台用户一致，不需要处理
                }
            }else{
                //无登录用户数据，清空本地信息

                //清空本地用户信息
                proxy.$store.commit('updateLoginStatus', {token: ''});
                proxy.$store.commit('updateUserInfo', {userInfo: '{}'});

            }

            //设置显示的用户名
            if (proxy.$store.state.userInfo && proxy.$store.state.userInfo.userName) {
                proxy.userName = proxy.$store.state.userInfo.userName;
            }
        };

        function loadAllCategory() {
            const url = '/gdsti2/web/a/webResource/dataTypelist';
            proxy.$http.post(url).then(res => {
                if (res.data.state == 0) {
                    let result = res.data.result;
                    for (let i = 0; i < result.length; i++) {
                        let item = result[i];
                        if (tempIds.indexOf(item.dictVal) >= 0) {
                            checkboxIndex.value.push(i);
                            item['checked'] = true
                        } else {
                            item['checked'] = false
                        }
                    }

                    category.value = result


                }
            }).catch(err => {

            });
        }

        function gotoLogout() {
            const url = '/core/base/c/index/logout';
            proxy.$http.post(url).then(res => {
                if (res.data.state == 0) {
                    proxy.$store.commit('updateLoginStatus', {token: ''});
                    proxy.$store.commit('updateUserInfo', {userInfo: '{}'});
                    proxy.$router.push({name: 'Index', path: '/'});
                    setTimeout(function () {
                        window.location.reload();
                    }, 100);
                    // location.reload();
                }
            }).catch(err => {

            });
        }


        onMounted(() => {
            loadAllCategory();

            //获取用户信息，并判断是否登录，且登录后前后台用户是否一致，不一致则以后台用户为准
            checkUserInfo();
        })

        return {category, checkboxIndex, keyword, gotoLogout}
    },
    mounted() {


        //百度统计
        //本地开发时一定一定要注释掉，只有在更新到生产机再放开
        // var _hmt = _hmt || [];
        // var hm = document.createElement("script");
        // hm.src = "https://hm.baidu.com/hm.js?156d40bf7b3327adcf6afb2e63aa343b";
        // var s = document.getElementsByTagName("script")[0];
        // s.parentNode.insertBefore(hm, s);

    },
    methods: {
        gotoSearch() {

        },
        gotoLogin() {
            this.$router.push({path: '/login', name: 'Login'})
        },
        gotoRegister() {
            this.$router.push({path: '/register', name: 'Register'})
        },
        gotoIndex() {
            this.$router.push({path: '/index', name: 'Index'})
            let query = {keyword: "", id: '', name: ''};
            this.$store.commit('updateSearch', {search: query});
            //清空搜索项
        },

        changeCheck(index) {
            let indexOf = this.checkboxIndex.indexOf(index);
            if (indexOf >= 0) {
                this.checkboxIndex.splice(indexOf, 1);
            } else {
                this.checkboxIndex.push(index);
            }
            console.log(this.checkboxIndex)
        },
        gotoAdmin() {
            this.reloadHead();
            this.change(0);
            this.$router.push({name: 'IndustryIntroduce', path: '/industryIntroduceL'})
        },

        onSearch() {
            let id = [];
            let name = [];
            if (this.radioIndex === 0 || this.radioIndex === 1 || this.radioIndex === 2 || this.radioIndex === 3) {
                id.push(this.category[this.radioIndex].dictVal);
                name.push(this.category[this.radioIndex].dictName);
            }
            let query = {keyword: this.keyword, id: id.join(','), name: name.join(',')};
            this.$store.commit('updateSearch', {search: query});
            this.$router.push({path: '/techRes', name: 'TechRes', query: query})
        }
    }
}
</script>

<style scoped>
.logo {
    cursor: pointer;
}

.head-bar {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
}

.site-name {
    font-family: SourceHanSansCN-Medium;
    font-size: 12px;
    color: #2F86C2;
    letter-spacing: 0;
    text-align: justify;
    margin-left: 12px;
}

.logout {
    width: 40px;
}

.link-text {
    font-family: SourceHanSansCN-Medium;
    font-size: 16px;
    color: #3F3F3F;
    letter-spacing: 0;
    text-align: justify;
    text-decoration: none;
}

.link-text:hover {
    color: #000;
}

.link-text:visited, .link-text:active {
    color: #3F3F3F;
}

.user-bar {
    display: flex;
    align-items: center;
    position: absolute;
    right: 12px;
    top: 18px;
    max-width: 200px;
}

.icon-user {
    margin-right: 8px;
}

.search-bar {
    margin-left: 50px;
}

.search-input {
    display: flex;
    background: #FFFFFF;
    border: 2px solid #007EEC;
}

.search-input input {
    border: 0;
    outline-width: 0;
    outline-style: none;
    outline-color: invert;
    border-style: none;
    -webkit-appearance: none;
    -webkit-user-select: none;
    outline-color: transparent;
    box-shadow: none;
    width: 515px;
    height: 50px;
    padding: 0 19px;
    font-family: PingFangSC-Regular;
    font-size: 16px;
    line-height: 24px;
}

.search-input input::placeholder {
    color: rgba(0, 0, 0, 0.45);
}

.search-input input:focus-visible, input:focus {
    border: none;
}

.search-icon {
    background: #007EEC;
    width: 92px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.search-icon:hover {
    background: #116EDC;
}

.search-checkbox {
    display: flex;
    justify-content: space-around;
    margin-top: 8px;
}

.search-checkbox .checkbox {
    font-family: PingFangSC-Regular;
    font-size: 12px;
    color: rgba(45, 45, 45, 0.85);
    display: flex;
    align-items: center;
    cursor: pointer;
}

.checkbox input[type='radio'] {
    margin: 0;
    margin-right: 3px;
}
</style>
