<template>
    <div class="user-info" v-loading="loading">
        <div class="collect-head">
            <div class="topic-search">
                仪器/数据名称 <input type="text" class="search-input" placeholder="请输入" v-model="searchKey">
                <button type="button" class="btn btn-primary" @click="search">查询</button>
            </div>
            <div class="select" >
                <span v-if="multipleSelection.length>0">已选：{{multipleSelection.length}}
<!--                  <el-button type="danger" class="table-btns" @click="handleDeleteAll()">删除选中</el-button>-->
                </span>
                <el-button type="primary" class="table-btns" @click="showRequire()">发起数据/资源收录证明</el-button>
            </div>
        </div>
        <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange" :row-key="getRowKey">
            <el-table-column type="selection" :reserve-selection="true"  width="55"/>
            <el-table-column type="index" label="序号" width="80"/>
            <el-table-column prop="dataType" label="数据/资源类型" />
            <el-table-column prop="className" label="所属目录" />
            <el-table-column prop="cnName" label="中文名称" />
            <el-table-column prop="enName" label="英文名称" />
            <el-table-column prop="subjectType" label="学科分类" />
            <el-table-column prop="innerId" label="仪器所在单位内部编码" />

<!--            <el-table-column  label="操作" width="80">-->
<!--                <template #default="scope">-->
<!--                    <el-popover placement="bottom" :width="50" trigger="click">-->
<!--                        <template #reference>-->
<!--                            <el-icon class="link-more"><MoreFilled /></el-icon>-->
<!--                        </template>-->
<!--                        <div class="option-list">-->
<!--                            <a href="javascript:void(0)" class="danger" @click="handleDelete(scope.$index)">删除</a>-->
<!--                            <a href="javascript:void(0)" class="primary" @click="handleDownload(scope.$index)">下载</a>-->
<!--                            <a href="javascript:void(0)" class="primary" @click="handleView(scope.$index)">查看</a>-->
<!--                        </div>-->
<!--                    </el-popover>-->
<!--                    -->

<!--                    -->
<!--                </template>-->
<!--            </el-table-column>-->
        </el-table>
        <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>

    </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import {ElTable, ElTableColumn, ElButton, ElDialog, ElResult, ElPopover, ElIcon} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    components: {SearchBar, ElTable, ElTableColumn, ElButton, ElIcon,ElDialog, ElResult, ElPopover, MoreFilled, Pager},
    setup(){
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1);
        const loading = ref(false);
        const searchKey = ref('');
        const tablePager = ref({
            total: 1,
            page: 1,
            list: []
        })

        function loadData(){
            loading.value = true;
            const url = '/hfapplication/gdsti/c/remittanceConfigure/remittanceDataList.do';
            proxy.$http.post(url, {limit: 10, offset: (currentPage.value-1)*10, cnName: searchKey.value})
            .then(res=>{
                loading.value = false
                if(res.data.state == 0){
                    tablePager.value = res.data.result;
                }
            }).catch(err=>{
                loading.value = false
            })
        }

        function pageChange(index){
            currentPage.value = index;
            loadData();
        }

        function search(){
            pageChange(1);
        }

        const multipleSelection = ref([])
        function handleSelectionChange(selections){
            multipleSelection.value = selections;
        }


        function indexFormatter(row, column, cellValue, index){
            return row+1+'';
        }

        function handleDelete(index){
            console.log(index)
        }

        function handleDownload(index){

        }
        function handleView(index){

        }

        function handleDeleteAll(){

        }



        const isShow = ref(false)
        function showRequire(){
            if(multipleSelection.value.length < 1){
                proxy.ElMessage.error('至少一条数据');
                return;
            }
            let dataIds = [];
            for(let i = 0;i<multipleSelection.value.length;i++){
                dataIds.push(multipleSelection.value[i].id);
            }
            let dataIdsStr = dataIds.join(",");
            console.log("dataIdsStr:",dataIdsStr);

            proxy.$router.push({name: 'ResRecordProveNotice', path:'resRecordProveNotice', query: {dataIds : dataIdsStr}})

        }

        function statusFormatter(row, column, cellValue, index){
            if(cellValue == 1){
                return '已处理';
            }else{
                return '待处理';
            }
        }

        function getRowKey(row){
            return row.id
        }




        onMounted(()=>{
            loadData();
        })

        return {tablePager,pageChange, search,currentPage, multipleSelection, indexFormatter, statusFormatter,
        handleDelete, handleDeleteAll, handleSelectionChange, handleDownload, handleView,loading,getRowKey,searchKey,
        isShow, showRequire}
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';
.topic-search {

    line-height: 25px;
    font-size: 14px;


    .search-input {
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.15);
        border-radius: 2px;
        padding: 5px 9px;
        margin-left: 9px;
        margin-right: 16px;
        font-size: 14px;
        line-height: 14px;
    }

    .btn {
        display: inline-block;
        padding-top: 2px;
        padding-bottom: 2px;
    }
}
</style>

