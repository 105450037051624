<template>
    <div class="content about-container">
        <div class="about-box">
            <h3 class="about-title">省共享平台发展历程</h3>
            <p class="about-content">
                国发[2014]70号文《国务院关于国家重大科研基础设施和大型科研仪器向社会开放的意见》指出：管理单位的服务平台统一纳入国家网络管理平台，逐步形成跨部门、跨领域、多层次的网络服务体系。
                国科办基〔2016〕333号《科技部办公厅关于2016年全面落实&lt;国务院关于国家重大科研基础设施和大型科研仪器向社会开放的意见&gt;相关工作安排的通知》指出：各地方所属的管理单位现在服务平台，应先与本省（直辖市、自治区）共享服务管理平台对接，再通过省级管理平台统一纳入国家网络管理平台。
                《广东省人民政府促进大型科学仪器设施开放共享的实施意见》（粤府函[2015]347号）指出： 按照科技部制定的《管理单位在线服务平台建设标准规范》和《纳入国家网络管理平台科研设施与仪器数据标准规范》，建立我省科研设施与仪器数据库和统一开放的服务平台，加快向社会提供科研设施与仪器的信息查询、服务推介、技术培训等服务。


            </p>
        </div>

        <div class="history-step">
            <div class="step-name">阶段一：建设运行广东省科技资源共享服务平台</div>
            <div class="step-box">
                <div class="step-line"></div>
                <div class="step">
                    <div class="step-dot"></div>
                    <div class="step-desc">
                        <div class="step-title">
                            2010年12月，广东启动加盟中国科技资源共享网相关工作，并签订《中国科技资源共享网加盟协议》；
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="step-box">
                <div class="step-line"></div>
                <div class="step">
                    <div class="step-dot"></div>
                    <div class="step-desc">
                        <div class="step-title">2012年，建设运行广东省科技资源共享服务平台；</div>
                        <div class="step-content">
                            <p>根据国家科技基础条件平台建设的要求和目标，结合广东实际，遵循“整合、共享、创新、发展”的建设方针，有效地整合和优化配置全省的科技资源，初步建成了布局合理、功能完善、体系健全、共享高效的科技基础条件平台，为我省相关支柱产业发展提供了强有力的科技支撑，推动创新型广东的建设。</p>
                            <img src="@static/img/about-step1.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="history-step">
            <div class="step-name">阶段二：建设运行一站式科技基础条件资源共享与服务平台</div>
            <div class="step-box">
                <div class="step-line"></div>
                <div class="step">
                    <div class="step-dot"></div>
                    <div class="step-desc">
                        <div class="step-title">
                            2014年12月，启动一站式科技基础条件资源共享与服务平台建设方案设计；
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="step-box">
                <div class="step-line"></div>
                <div class="step">
                    <div class="step-dot"></div>
                    <div class="step-desc">
                        <div class="step-title">2015年5月，建设完成一站式科技基础条件资源共享与服务平台</div>
                        <div class="step-content">
                            <p>于9月正式上线服务运营。初步整合我省现有科技基础条件平台资源，提供“一站式”的科技资源共享与服务，探索市场化运行机制，推动科技基础条件平台资源的开放共享与运行服务，服务于我省产业、经济、社会的发展。</p>
                            <img src="@static/img/about-step2.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="history-step">
            <div class="step-name">阶段三：建设运行广东省科技资源共享与服务平台--“省共享平台”</div>
            <div class="step-box">
                <div class="step-line"></div>
                <div class="step">
                    <div class="step-dot"></div>
                    <div class="step-desc">
                        <div class="step-title">
                            2018年，开展建设广东省科技资源共享与服务平台--“省共享平台”
                        </div>
                        <div class="step-content">
                            <p>拟成为我省科研设施与仪器数据库和统一开放的服务平台，加快向社会提供科研设施与仪器的信息查询、服务推介、技术培训等服务，在原一站式服务平台基础上，加强大型科研仪器标准建设、对接国家、在线预约等功能。
</p>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="step-box">
                <div class="step-line"></div>
                <div class="step">
                    <div class="step-dot"></div>
                    <div class="step-desc">
                        <div class="step-title">2019年，省共享平台平台正式上线</div>
                        <div class="step-content">
                            <p>坚持“四个面向”，汇聚全链条科技资源要素，打造集科技资源检索、科学仪器查重、科学数据管理、创新平台评价、科技专题服务、科普与竞赛于一体的专业化服务体系，提升创新整体效能，营造开放共享的创新生态，聚力推进高水平科技自立自强，打造具有全球影响力的科技和产业创新高地。</p>
                            <img src="@static/img/about-step3.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="history-step">
            <div class="step-name">阶段四：建设运行中广东省科技资源共享服务平台V2.0-省共享平台</div>
            <div class="step-box">
                <div class="step-line"></div>
                <div class="step">
                    <div class="step-dot"></div>
                    <div class="step-desc">
                        <div class="step-content">
                            <p>省共享平台是集共享资讯、资源整合、在线服务、数据管理于一体的“互联网+科技资源”的综合性平台，以大型科研仪器、科学数据为切入口，同时汇集了平台基地、科研成果等科技资源、搭建供需交流平台，是我省科技资源开放共享的官方门户网站。</p>
                            <img src="@static/img/about-step4p.png"/>
                            <img src="@static/img/about-step4.png"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
</template>

<script setup>

</script>

<style lang="less" scoped>
@import url('../../../public/static/css/about.less');
.about-box{
    margin-bottom: 28px;
}
.history-step{
    margin-left: 43px;
    .step-name{
         background: #007EEC;
        line-height: 34px;
        font-family: SourceHanSansCN-Bold;
        font-size: 18px;
        color: #FFFFFF;
        padding-left: 53px;
        width: 600px;
        border-top-right-radius: 34px;
        border-bottom-right-radius: 34px;    
    }
    .step-box:last-child{
        padding-bottom: 33px;
    }
    .step-box{
        padding-top: 26px;
        
        position: relative;
        .step-line{
            width: 3px;
            height: 100%;
            background-color:  #D8D8D8;
            position: absolute;
            top: 0;
            left: 18px;
            z-index: 1;
        }
        .step{
            padding-left: 12px;
            position: relative;
            z-index: 2;
            display: flex;
            flex-direction: row;
            .step-dot{
                background: #007EEC;
                width: 14px;
                height: 14px;
                border-radius: 14px;
                z-index: 10;
            }
            .step-desc{
                margin-top: 7px;
                margin-left: 24px;
                flex: 1;
                padding: 12px 22px;
                background: #F4F5F9;
                .step-title{
                    font-family: SourceHanSansCN-Medium;
                    font-size: 18px;
                    color: #007EEC;
                    line-height: 22px;
                }
                .step-content{
                    margin-top: 12px;
                    font-family: SourceHanSansCN-Regular;
                    font-size: 14px;
                    color: #262626;
                    line-height: 18px;
                    p{
                        margin-bottom: 12px;
                    }
                }
            }
        }
    }
}
</style>
