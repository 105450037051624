<template>
    <div class="science-container">
        <router-view />
    </div>
</template>

<script>
import {ref} from 'vue'
import {useRouter, useRoute} from 'vue-router'
export default {
    setup(){
            const active = ref(0)

            const router = useRouter();
            const route = useRoute();

            

            function changeActive(index){
                // active.value = index;
                switch(index){
                    case 0:
                        router.push({name: 'Entry', path: '/entry'});
                        break;
                    case 1:
                        router.push({name: 'Data', path: '/data'});
                        break;
                    case 2:

                        router.push({name: 'History', path: '/history'});
                        break;
                    case 3:
                        router.push({name: 'Manage', path: '/manage'});
                        break;
                    case 4:
                        router.push({name: 'Dependent', path: '/dependent'});
                        break;
                    case 5:
                        router.push({name: 'Contact', path: '/contact'});
                        break;
                }
            }



            return {active, changeActive}
    },
    watch:{
        $route:{
            handler:function(newVal, oldVal){
                this.updateActive(newVal)
            },
            deep: true
        }
    },
    mounted(){
        this.updateActive(this.$route);
    },
    methods:{
        updateActive(newVal){
                if(newVal.path.indexOf('/entry') >= 0){
                    this.active = 0;
                }else if(newVal.path.indexOf('/dependent') >= 0){
                    this.active = 1;
                }else if(newVal.path.indexOf('/history') >= 0){
                    this.active = 2;
                }else if(newVal.path.indexOf('/manage') >= 0){
                    this.active = 3;
                }else if(newVal.path.indexOf('/data') >= 0){
                    this.active = 4;
                }else if(newVal.path.indexOf('/contact') >= 0){
                    this.active = 5;
                } 
        }
    }
}

</script>

<style lang="less" scoped>

</style>
