
<template>
<div class="user-info" v-loading="loading">
    <div class="collect-head">
      <SearchBar @onSearch="search"></SearchBar>
      <div class="select" >
        <span v-if="multipleSelection.length>0">已选：{{multipleSelection.length}} <el-button type="danger" class="table-btns" @click="handleDeleteAll()">删除选中</el-button></span>
        <!--                <el-button type="primary" class="table-btns" @click="showRequire()">新增</el-button>-->
      </div>
    </div>
    <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
      <!--            <el-table-column type="selection"  width="55"/>-->
                  <el-table-column type="index" label="序号" width="80"/>
      <el-table-column prop="cname" label="科学数据" />
      <el-table-column prop="applyCode" label="申请编码" />
      <el-table-column prop="applyUserName" label="申请用户名称" />
      <el-table-column prop="applyUserUnit" label="申请用户所在单位" />
      <el-table-column prop="useRequirements" label="使用需求" />
      <el-table-column prop="applyUserPhone" label="联系方式" />
      <el-table-column prop="createTime" label="提交时间" />
      <el-table-column prop="stateStr" label="确认状态">
        <template #default="scope">
          {{statusFormatter(scope.row.state)}}
        </template>
      </el-table-column>
      <el-table-column  label="操作" width="120">
        <template #default="scope">
          <a href="javascript:void(0)" class="primary" @click="editInfo(scope.row.id)" v-if="scope.row.state == 0">编辑</a>
          <a href="javascript:void(0)" class="primary" @click="showInfo(scope.row.id)" v-else>查看</a>
          <a style="margin-left:20px;" href="javascript:void(0)" class="danger" @click="pinjia(scope.row.id)" v-if="scope.row.state == 2 && scope.row.agreeNum > 0">评价</a>
        </template>
      </el-table-column>
    </el-table>
    <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>


  </div>
</template>

<script>
import SearchBar from '@/components/common/SearchBar'
import {ElTable, ElTableColumn, ElButton, ElDialog, ElResult, ElPopover, ElIcon} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '@/components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
  components: {SearchBar, ElTable, ElTableColumn, ElButton, ElIcon,ElDialog, ElResult, ElPopover, MoreFilled, Pager},
  setup(){
    const {proxy} = getCurrentInstance();
    const currentPage = ref(1);
    const loading = ref(false);

    const searchKey = ref('');
    const tablePager = ref({
      total: 1,
      page: 1,
      list: []
    })


    function loadData(){  //加载申请记录
      loading.value = true;
      const url = '/gdsti2/resource/c/resSciUseApply/list';
      let param = {
          limit: 10,
          offset: (currentPage.value-1)*10,
          searchKey: searchKey.value,
          selectType:1
      }
      proxy.$http.post(url, param)
          .then(res=>{
            loading.value = false
            if(res.data.state == 0){
              tablePager.value = res.data.result;
            }
          }).catch(err=>{
        loading.value = false
      })
    }


    function pageChange(index){
      currentPage.value = index;
      loadData();
    }

    function search(val){
      searchKey.value = val;
      pageChange(1);
    }

    const multipleSelection = ref([])
    function handleSelectionChange(selections){
      multipleSelection.value = selections;
    }





    //评价
    function pinjia(id){
      let dialogId = 'pinjia' + Math.floor(Math.random()*1000000);
      openDialog(dialogId, "评价", "/gdsti2v/resource/resSciUseEvaluate/sciDataItemEvaluate?applyId=" + id, "", '95%', '95%', function () {
        loadData();
      });
    }
    //查看信息
    function showInfo(id){
      let dialogId = 'showInfo' + Math.floor(Math.random()*1000000);
      openDialog(dialogId, "申请信息", "/gdsti2v/resource/resSciUseApply/info?disabled=true&id=" + id, "", '95%', '95%', function () {

      });
    }
    //查看信息
    function editInfo(id){
      let dialogId = 'editInfo' + Math.floor(Math.random()*1000000);
      openDialog(dialogId, "申请信息", "/gdsti2v/resource/resSciUseApply/form?disabled=&id=" + id, "", '95%', '95%', function () {
        loadData();
      });
    }

    

    function indexFormatter(row, column, cellValue, index){
      return row+1+'';
    }

    function statusFormatter(stateVal){
      let text = '';
      if(stateVal == 0){
          text = '草稿';
      }else if(stateVal == 1){
          text = '待确认';
      }else if(stateVal == 2){
          text = '已确认';
      }else if(stateVal == 3){
          text = '已评价';
      }
      return text;
    }



    onMounted(()=>{
      loadData();
    })

    return {tablePager,pageChange, search,currentPage, multipleSelection, indexFormatter, statusFormatter,
       handleSelectionChange, pinjia,
      loading, showInfo, editInfo
    }
  }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

</style>

