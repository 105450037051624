<template>
    <div class="register">
        <LoginHead></LoginHead>
        <div class="register-box">
            <div class="step">
                <div class="step-item">
                    <div class="step-num" :class="{active:active==0}" >1</div>
                    <div class="step-name">重置密码</div>
                </div>
                <div class="step-arrow"><div></div><span></span></div>
                <div class="step-item">
                    <div class="step-num" :class="{active:active==1}">2</div>
                    <div class="step-name">修改成功</div>
                </div>
            </div>
            <div class="step-content" v-if="active==0">
                <Form class="form" ref="target" v-slot="{ errors}">
                
                        <div class="form-group">
                            <label class="control-label col-3">账号<span class="required">*</span></label>
                            <div class="col-8">
                                <Field autocomplete="new-username" type="text" name="username" v-model="form.username" :rules="checkUsername" class="form-control" placeholder="请输入用户名"/>
                                <div class="error" >{{errors.username}}</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-3">手机号码<span class="required">*</span></label>
                            <div class="col-8">
                                <Field autocomplete="new-phone" type="number" name="phone" v-model="form.phone" :rules="checkPhone" class="form-control" placeholder="请输入手机号码"/>
                                <div class="error">{{errors.phone}}</div>
                                <div class="help-block">注：账号需要绑定手机号才能使用忘记密码功能</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-3">短信验证码<span class="required">*</span></label>
                            <div class="col-8">
                                <div style="display: flex;">
                                    <Field type="number" name="vsCode" v-model="form.vsCode" :rules="checkRequired" class="form-control" style="width:120px" placeholder="短信验证码"/>
                                    <button type="button" class="btn btn-primary btn-send" :class="{'disabled':showCount}" @click="sendCode" v-loading="codeLoading" >{{showCount?count:'发送验证码'}}</button>
                                </div>
                                <div class="error">{{errors.vsCode}}</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-3">密码<span class="required">*</span></label>
                            <div class="col-8">
                                <Field autocomplete="new-password" type="password" name="password" v-model="form.password" :rules="checkPwd" class="form-control" placeholder="请输入密码"/>
                                <div class="error">{{errors.password}}</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="control-label col-3">确认密码<span class="required">*</span></label>
                            <div class="col-8">
                                <Field autocomplete="two-password" type="password" name="password1" v-model="form.password1" :rules="checkTwoPwd" class="form-control" placeholder="确认密码"/>
                                <div class="error">{{errors.password1}}</div>
                            </div>
                        </div>
                        
                        
                        
                    <!-- industrialPark -->
                    <div class="form-group">
                        <button type="button" v-loading="loading" class="btn btn-primary next-btn" @click="handleLogin">下一步</button>
                    </div>
                </Form>
            </div>

            <div class="step-content wait-apply" v-if="active==1">
                <el-result icon="success" title="密码修改成功" sub-title="请重新登录"></el-result>
                <button type="button" class="btn btn-primary next-btn" @click="backClick" style="margin-top:107px;">完成</button>

            </div>

        </div>
    </div>
</template>

<script>
import { Form, Field } from 'vee-validate'
import { ElUpload, ElButton, ElIcon, ElDialog, ElResult, ElPopover} from 'element-plus'
import { Download, Plus } from '@element-plus/icons-vue'
import {ref, reactive, onMounted, computed, watch, getCurrentInstance} from 'vue'
import {useRoute} from 'vue-router'
import LoginHead from '../components/common/LoginHead'
import Pager from "../components/common/Pager"
export default {
    components: {LoginHead, Form, Field, ElUpload, ElButton, ElIcon, ElResult, ElDialog, Pager, Download, Plus, ElPopover},
    setup(){
        const {proxy} = getCurrentInstance();
        const target = ref(null);
        const form = reactive({
            usercode: '',
            username: null,
            password: null,
            password1: null,
            email: '',
            phone: '',
            vsCode: ''
        });

        const count = ref(60);
        const showCount = ref(false);
        
        const loading = ref(false)
        const codeLoading = ref(false)


        const active = ref(0)
        const route = useRoute();
        if(route.query.type){
            type.value = route.query.type;
        }
   
        
      
        const checkUsername = value =>{
            if(!value) return '请输入账号';
            if (!/^[a-zA-Z]\w{0,32}$/.test(value)) return '字母开头且1-32个字符';
            return true;
        }

        function validPwd(value){
            let i = /\d+/.test(value)?1:0;
            let j = /[a-zA-Z]+/.test(value)?1:0;
            let k = /[~!@#$%^&*()_+|<>,.?/:;'\[\]\{\}\"]+/.test(value)?1:0;
            let length = value.length;
            if(value.indexOf(form.username) >= 0){
                return '密码不能包含账号';
            }else if(length < 8){
                return '密码至少8位字符';
            }else if(i+j+k <= 2){
                return '密码须包含数字、字母、特殊字符';
            }else {
                return true;
            }
        }

        const checkPwd = value => {
            if (!value) return '请输入密码';
            let result = validPwd(value);
            return result;
        }

        const checkTwoPwd = value => {
            if(!value) return '请输入密码';
            let result = validPwd(value);
            if(result === true){
                if(value != form.password){
                    return '两次密码输入不一致';
                }
            }else{
                return result;
            }
            return true;
        }

        const checkEmail = value => {
            if(!value) return '请输入邮箱';
            if(!/\S+@\S+\.\S+/.test(value)) return '请输入正确的邮箱地址';
            return true;
        }
        
        const checkPhone = value => {
            if(!value) return '请输入手机号码';
            if(!/^1\d{10}/.test(value)) return '请输入11位手机号码';
            return true;
        }

  
        const checkRequired = value => {
            if(!value){
                return '请输入内容';
            }else{
                return true;
            }
        }

  
        // 点击登录
        const handleLogin = () => {
            console.log('handleLogin');
            
            target.value.validate().then(valid=>{
                if (valid) {
                    loading.value = true;
                    const url = '/hf/base/a/user/changePassword';
                    proxy.$http.post(url, 
                    {
                        userCode: form.username,
                        newPassword: form.password,
                        repeatPassword: form.password1,
                        mobilePhone: form.phone,
                        vsCode: form.vsCode
                    }).then(res=>{
                       
                        loading.value = false
                        if(res.data.state == 0){
                                active.value = 1;
                        }
                    }).catch(err=>{
                        loading.value = false
                    });
                    
                    
                    // 表单验证通过，调用接口实现登录
                    // 以下为演示代码
                    return true;
                }
            }).catch(err=>{
                return false;
            });
            
        }



        onMounted(()=>{
        });

       


        let timer = undefined;

        function sendCode(){
            if(showCount.value){
                return;
            }
            if(timer){
                clearInterval(timer);
            }

            if(!form.phone || form.phone.length != 11){
                proxy.ElMessage("手机号码长度不对");
                return;
            }
            if(!form.username || form.username.length <= 0){
                proxy.ElMessage("请输入账号");
                return;
            }
            codeLoading.value = true;
            
            const url = '/hf/base/a/user/resetVsCode';
            proxy.$http.post(url, {mobilePhone: form.phone, userCode: form.username}).then(res=>{
                codeLoading.value = false;
                if(res.data.state == 0){
                    showCount.value = true;
                    count.value = 300;
                    timer = setInterval(function(){
                                count.value = count.value-1;
                                if(count.value <= 0){
                                    codeLoading.value = false;
                                    clearInterval(timer);
                                    timer = undefined;
                                    showCount.value = false;
                                }
                        }, 1000);
                }else{
                    // proxy.ElMessage(res.data.message)
                    showCount.value = false;
                }
            }).catch(err=>{
                codeLoading.value = false;
                showCount.value = false;
            })
            
        };


        return {checkRequired, active, loading,
         
         target, form, checkUsername, checkPwd, checkTwoPwd, checkEmail, checkPhone, 
            handleLogin,
         count, showCount, sendCode, codeLoading}
    },
    methods:{
        backClick(){
            this.$router.back();
        }
    }
}
</script>

<style lang="less" scoped>
.regiser-note{
    background: #EBF6FF;
    border: 1px solid #007EEC;
    border-radius: 3px;
    padding: 17px 17px 17px 21px;
    display: flex;
    align-items: center;
    width: 480px;
    margin: 0 auto;
    .note-img{
        margin-right: 19px;
    }
    .note-info{
        font-family: SourceHanSansCN-Medium;
        font-size: 16px;
        color: #007EEC;
        line-height: 18px;
    }
}
.select-list{
    .select-item{
        cursor:pointer;
        font-size: 14px;
        line-height: 32px;
        color: #000000;
    }
    .select-item:hover{
        color: rgba(0,0,0,0.5);
    }

}
.register{
    min-height: 100%;
    background: #F6F6F6;
}
.register-box{
    border-radius: 2px;
    margin-top: 32px;
    width: 610px;
    min-height: 660px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 40px;
    padding-bottom: 40px;
    background: #FFFFFF;
}
.step{
    padding-left: 53px;
    padding-right: 53px;
    margin-bottom: 29px;
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    .step-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        .step-num{
            width: 30px;
            height: 30px;
            line-height: 30px;
            border-radius: 30px;
            color: #fff;
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            background: rgba(0,0,0,0.15);
            text-align: center;
        }
        .step-num.active{
            background: #007EEC;
        }
        .step-name{
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            color: rgba(0,0,0,0.85);
            margin-top: 17px;
        }
    }
    .step-arrow{
        width: 110px;
        display: flex;
        align-items: center;
        div{
            width: 104px;
            height: 1px;
            background:  rgba(0,0,0,0.15);
        }
        span{
            float: right;
            display: block;
            margin: 27px auto;
            width: 0;
            height: 0;
            border: 6px solid transparent;
            border-left-color: rgba(0,0,0,0.15);;
        }
    }
}
.wait-apply{
    text-align: center;
    padding-top: 74px;
    font-size: 24px;
}
.help-block{
    font-family: SourceHanSansCN-Regular;
    font-size: 10px;
    padding-left: 14px;
    color: rgba(0,0,0,0.30);
    letter-spacing: 0;
    text-align: left;
}

.form-control{
    width: 350px;
}
.control-addr{
    .form-control{
        width: 120px;
        margin-right: 0px;
    }
}
.control-addr, .radio-group{
    display: flex;
    width: 390px;
    justify-content: space-between;
    .form-control:last-child{
        margin-right: 0;
    }
}
.uploader-box{
    margin-left: 10px;   
}

.form-group{
    margin-bottom: 12px;
}
.form-radio{
    margin-left: 10px;
    cursor: pointer;
}
.next-btn{
    margin: 0 auto;
    width: 387px;
    margin-top: 10px;
}
.error{
    margin-left: 10px; 
}
.btn-add{
    margin-right: 24px;
}
.btn-reset{
    margin-left: 12px;
}
.new-dept{
    margin-top: 12px;
}
</style>



