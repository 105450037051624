<template>
    <div class="user-info">

        <div class="">
            <div class="topic-search">
                <div class="demo-input-suffix">
                    <!--          其他-->
                    <el-form label-width="120px">
                        <el-row>

                            <el-col :span="6">
                                <el-form-item label="任务书编号" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="searchForm.taskNumber" placeholder="请输入内容"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="项目名称" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="searchForm.projectName" placeholder="请输入内容"
                                              clearable></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :span="6">
                                <el-form-item label="仪器名称" style="padding: 5px;">
                                    <el-input style="width: 100%;" v-model="searchForm.instrumentName"
                                              placeholder="请输入内容" clearable></el-input>
                                </el-form-item>
                            </el-col>


                        </el-row>

                        <el-row>
                            <el-col :span="8">
                                <el-form-item label="申请时间范围" style="padding: 5px;">
                                    <el-date-picker
                                        v-model="searchForm.createTimeRange"
                                        type="datetimerange"
                                        range-separator="至"
                                        start-placeholder="开始时间"
                                        end-placeholder="结束时间"
                                    />

                                </el-form-item>
                            </el-col>

                        </el-row>


                    </el-form>

                </div>


            </div>

        </div>
        <div style="padding-bottom: 10px;display: flex;justify-content: right;">
            <!--      <button type="button" class="btn btn-primary" @click="cleanParam" style="margin-right: 10px;">清空</button>-->
            <button type="button" class="btn btn-primary" @click="search">查询</button>
        </div>
        <div class="collect-head">
            <div class="select">


            </div>
        </div>

        <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange"
                  v-loading="loading">
            <!--      <el-table-column type="selection"  width="55"/>-->
            <el-table-column type="index" label="序号" width="80"/>
            <el-table-column prop="applyTypeStr" label="申请类型"/>
            <el-table-column prop="taskNumber" label="任务书编号"/>
            <el-table-column prop="projectName" label="项目名称"/>
            <el-table-column prop="instrumentName" label="仪器名称"/>
            <el-table-column prop="categoryCode" label="仪器类型及代码"/>
            <el-table-column prop="unitPrice" label="单价（万元）"/>
            <el-table-column prop="quantity" label="数量（台套）"/>
            <el-table-column prop="totalPrice" label="总价（万元）"/>
            <el-table-column prop="createTime" label="申请时间"/>
            <el-table-column prop="statusName" label="状态"/>
            <el-table-column label="操作" width="250">
                <template #default="scope">
                    <el-button style="margin-top: 10px;" type="success" size="small" @click="showDetail(scope.row.id)">
                        详情
                    </el-button>
                    <el-button style="margin-top: 10px;" type="primary" size="small" @click="showEdit(scope.row.id)"
                               v-if="scope.row.status === -1 || scope.row.status === 0 ">编辑
                    </el-button>
                    <el-button style="margin-top: 10px;" type="primary" size="small"
                               @click="showEditExamine(scope.row.id,1)" v-if="scope.row.status === -1 || scope.row.status === 0 ">提交审核
                    </el-button>
                    <el-button style="margin-top: 10px;" type="warning" size="small"
                               @click="showLogApprList(scope.row.id)">查看审核记录
                    </el-button>
                    <el-button style="margin-top: 10px;" type="primary" size="small"
                               @click="downFile(scope.row.reportFileId)" v-if="scope.row.status == 3">下载查重报告
                    </el-button>

                    <el-button style="margin-top: 10px;" type="danger" size="small"
                               @click="hanleDelete(scope.row.id)">删除
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>

    </div>
</template>

<script>
import {ElTable, ElTableColumn, ElButton, ElSwitch, ElIcon, ElPopover} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';

export default {
    components: {ElTable, ElTableColumn, ElButton, Pager, ElSwitch, ElIcon, ElPopover, MoreFilled},
    setup() {
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1);
        const limit = ref(10);

        const loading = ref(false);
        const searchForm = ref({
            taskNumber: '',
            projectName: '',
            instrumentName: '',
            createTimeRange: [],
        });
        const tablePager = ref({
            total: 1,
            page: 1,
            list: []
        })
        const iframeUrl = ref('');

        function loadData() {
            loading.value = true;

            searchForm.value.limit = limit.value
            searchForm.value.offset = (currentPage.value - 1) * limit.value
            if (searchForm.value.createTimeRange.length > 1) {
                searchForm.value.createTimeStart = searchForm.value.createTimeRange[0]
                searchForm.value.createTimeEnd = searchForm.value.createTimeRange[1]
            }

            searchForm.value.createMan = proxy.$store.state.userInfo.userCode;

            proxy.$http.post('/gdsti2/resource/c/resReviewDuplication/list.do', searchForm.value)
                .then(res => {
                    loading.value = false
                    if (res.data.state == 0) {
                        tablePager.value = res.data.result;
                    }
                }).catch(err => {
                loading.value = false
            })
        }

        function pageChange(index) {
            currentPage.value = index;
            loadData();
        }

        function search() {
            pageChange(1);
        }

        const multipleSelection = ref([])

        function handleSelectionChange(selections) {
            multipleSelection.value = selections;
        }

        function hanleDelete(id) {
            if (id == '') {
                return;
            }

            HFConfirm("确认要删除选中该信息吗？", "", "warning", function (isConfirm) {
                if (isConfirm) {
                    loading.value = true;
                    proxy.$http.post('/gdsti2/resource/c/resReviewDuplication/delete.do', {ids: id}).then(res => {
                        if (res.data.state == 0) {
                            HFToastrTipResult(res.data);
                            loadData();
                        }
                        loading.value = false;
                    }).catch(err => {
                        loading.value = false;
                    });
                }
            });


        }

        function indexFormatter(row, column, cellValue, index) {
            return row + 1 + '';
        }

        function handleView(index) {
            console.log(index)
        }


        onMounted(() => {
            loadData();
        })

        return {
            tablePager,
            pageChange,
            search,
            searchForm,
            currentPage,
            multipleSelection,
            indexFormatter,
            hanleDelete,
            handleView,
            handleSelectionChange,
            iframeUrl,
            loading,
            loadData,
            proxy,
        }
    },
    methods: {


        showEditExamine(id, status) {
            var that = this;

            let title = "";
            if (status == 1) {
                title = "确认要提交审核吗？";
            }
            HFConfirm(title, "", "warning", function (isConfirm) {
                if (isConfirm) {
                    that.loading = true;
                    let param = {
                        id: id,
                        status: status,
                    }
                    that.proxy.$http.post('/gdsti2/resource/c/resReviewDuplication/examine.do', param).then(res => {
                        if (res.data.state == 0) {
                            HFToastrTipResult(res.data);
                            that.loadData();
                        }
                        that.loading = false;
                    }).catch(err => {
                        that.loading = false;
                    });
                }
            });
        },
        showLogApprList(id) {

            openDialog("showLogApprList", "审核记录", "/gdsti2v/base/logAppr/list?sourceType=RES_REVIEW_DUP&sourceId=" + id, "", '95%', '95%', function () {

            });
        },
        showEdit(id) {
            var that = this;
            openDialog("showEdit", "编辑", "/gdsti2v/resource/resReviewDuplication/form?id=" + id, "", '95%', '95%', function () {
                that.loadData();
            });
        },
        showDetail(id) {
            var that = this;
            openDialog("showDetail", "详情", "/gdsti2v/resource/resReviewDuplication/form?disabled=true&id=" + id, "", '95%', '95%', function () {
                that.loadData();
            });
        },
        downFile(fileId) {
            window.open("/core/base/c/download/image?attachId=" + fileId)
        },
    }

}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';
</style>

