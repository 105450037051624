<template>
    <div>
        <open-head title="调查统计数据" content="广东省科技基础条件平台中心定期开展广东省科技创新数据调查，以评估在粤科技基础条件平台建设运行情况，对象包括实验室、科学数据中心、大型科研基础设施等。"></open-head>

        <div class="content stat">
            <Breads :breads="breads"></Breads>
            
            <div class="stat-block">
                <div class="block-title">
                    研发投入与科研产出
                </div>
                <div class="stat-list">
                    <div class="stat-item">
                        <div class="item-title">调查青年科研人员数量增幅统计数据</div>
                        <div class="item-content">摘要：文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字示例文字</div>
                    </div>
                    <div class="stat-item">
                        <div class="item-title">调查青年科研人员数量增幅统计数据</div>
                        <div class="item-content">摘要：文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字示例文字</div>
                    </div>
                    <div class="stat-item">
                        <div class="item-title">调查青年科研人员数量增幅统计数据</div>
                        <div class="item-content">摘要：文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字示例文字</div>
                    </div>
                    <div class="stat-item">
                        <div class="item-title">调查青年科研人员数量增幅统计数据</div>
                        <div class="item-content">摘要：文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字示例文字</div>
                    </div>
                </div>
            </div>

            <div class="stat-block">
                <div class="block-title">
                    重大科研基地设施和科学仪器
                </div>
                <div class="stat-list">
                    <div class="stat-item">
                        <div class="item-title">调查青年科研人员数量增幅统计数据</div>
                        <div class="item-content">摘要：文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字示例文字</div>
                    </div>
                    <div class="stat-item">
                        <div class="item-title">调查青年科研人员数量增幅统计数据</div>
                        <div class="item-content">摘要：文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字示例文字</div>
                    </div>
                    <div class="stat-item">
                        <div class="item-title">调查青年科研人员数量增幅统计数据</div>
                        <div class="item-content">摘要：文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字示例文字</div>
                    </div>
                    <div class="stat-item">
                        <div class="item-title">调查青年科研人员数量增幅统计数据</div>
                        <div class="item-content">摘要：文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字示例文字</div>
                    </div>
                </div>
            </div>

            <div class="stat-block">
                <div class="block-title">
                    人才引进和培养
                </div>
                <div class="stat-list">
                    <div class="stat-item">
                        <div class="item-title">调查青年科研人员数量增幅统计数据</div>
                        <div class="item-content">摘要：文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字示例文字</div>
                    </div>
                    <div class="stat-item">
                        <div class="item-title">调查青年科研人员数量增幅统计数据</div>
                        <div class="item-content">摘要：文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字示例文字</div>
                    </div>
                    <div class="stat-item">
                        <div class="item-title">调查青年科研人员数量增幅统计数据</div>
                        <div class="item-content">摘要：文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字示例文字</div>
                    </div>
                    <div class="stat-item">
                        <div class="item-title">调查青年科研人员数量增幅统计数据</div>
                        <div class="item-content">摘要：文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字示例文字</div>
                    </div>
                </div>
            </div>

            <div class="stat-block">
                <div class="block-title">
                    科学数据共享与利用
                </div>
                <div class="stat-list">
                    <div class="stat-item">
                        <div class="item-title">调查青年科研人员数量增幅统计数据</div>
                        <div class="item-content">摘要：文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字示例文字</div>
                    </div>
                    <div class="stat-item">
                        <div class="item-title">调查青年科研人员数量增幅统计数据</div>
                        <div class="item-content">摘要：文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字示例文字</div>
                    </div>
                    <div class="stat-item">
                        <div class="item-title">调查青年科研人员数量增幅统计数据</div>
                        <div class="item-content">摘要：文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字示例文字</div>
                    </div>
                    <div class="stat-item">
                        <div class="item-title">调查青年科研人员数量增幅统计数据</div>
                        <div class="item-content">摘要：文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字文字说明示例文字示例文字</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Breads from '../../components/common/Bread';
import OpenHead from '../../components/open/OpenHead';
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    components:{Breads, OpenHead},
    setup(){
        const breads = ref([{
            text:'开放服务',
            name: 'Open',
            path: '/open'
        },{
            text: '调查统计数据',
        }]);

        return {breads}
    }
}
</script>

<style lang="less" scoped>
.stat{
    padding-bottom: 149px;
}
.stat-block{
    margin-bottom: 49px;
    .block-title{
        font-family: SourceHanSansCN-Bold;
        font-size: 30px;
        color: rgba(0,0,0,0.85);
        text-align: justify;
        line-height: 30px;
        margin-bottom: 27px;
    }
    .stat-list{
        display:flex;
        justify-content: space-between;

        .stat-item{
            background: #FFFFFF;
            box-shadow: 0 2px 10px 0 rgba(165,184,201,0.30);
            padding: 18px 16px;
            width: 251px;
            height: 147px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .item-title{
                font-family: SourceHanSansCN-Medium;
                font-size: 22px;
                color: rgba(0,0,0,0.85);
                line-height: 26px;
            }
            .item-content{
                font-family: SourceHanSansCN-Regular;
                font-size: 14px;
                color: rgba(0,0,0,0.50);
                line-height: 18px;
            }
        }
    }
}
</style>

