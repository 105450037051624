<template>
  <div class="content">
    <NewsList :breads="breads" :categories="items" @itemClick="categoryClick" :active="active">
      <template v-slot:content>
        <NewsContent v-if="active==0" artIdOrCode="SCIENCE-DATA-EXCHANGE-FLOW"></NewsContent>
        <DataExchangeCertQuery v-if="active==1"></DataExchangeCertQuery>
        <DataExchangeFile v-if="active==2"></DataExchangeFile>
        <NewsContent v-if="active==3" artIdOrCode="SCIENCE-DATA-EXCHANGE-INTRO"></NewsContent>

        <div class="data-exchange-entry" v-if="active==3">
          <el-button type="primary" @click="gotoDataExchange()">汇交入口</el-button>
        </div>
      </template>

    </NewsList>
  </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue'
import NewsList from '@/components/common/NewsList'
import NewsContent from '@/views/NewsContent'
import DataExchangeCertQuery from '@/views/science/DataExchangeCertQuery'
import DataExchangeFile from '@/views/science/DataExchangeFile'

import CommonUtil from "@/utils/common"

export default {
  name: "Platform",
  data() {
    return {
      breads: [{
        text: '首页',
        path: '/index',
        name: 'Index'
      }, {
        text: '科学数据',
        link: ''
      }],
      items: [
        {name: "汇交流程展示", id: 1},
        {name: "汇交凭证查询", id: 2},
        {name: "汇交参考文件", id: 3},
        {name: "汇交介绍", id: 4},
      ],
      active: 0
    }
  },
  setup() {
    const {proxy} = getCurrentInstance();

    return {proxy}
  },
  components: {NewsList, NewsContent, DataExchangeCertQuery, DataExchangeFile},
  mounted() {
    const index = this.$route.query.index;
    if(index){
      this.active = Number.parseInt(index);
    }else{
      this.active = 0;  //默认显示第1个
    }
    

  },
  watch: {
    active(val, oldVal) {
      if (val == 6) {
        this.breads[1].text = "科学数据";
      } else {
        // console.log('hello', val, this.items);
        this.breads[1].text = this.items[val].name;
      }
    }
  },
  methods: {
    categoryClick: function (index) {
      // console.log('目录点击', index)
      this.active = index;
    },
    async gotoDataExchange() {
      const that = this;

      let isPlatformAdmin = await CommonUtil.isPlatformAdmin();

      if (isPlatformAdmin) {
        let goPageurl = that.indexUrl + "/user/dataRes";
        window.open(goPageurl);
      } else {
        that.ElMessage.error('您没有权限，只有平台负责人才能进行汇交。');
      }
    },
    gotoBuilding() {
      this.$router.push({name: 'Building', path: '/building'})
    }
  }
}
</script>

<style lang="less" scoped>
.tech-sys-list {
  display: flex;
  justify-content: space-between;

  .tech-sys-item {
    cursor: pointer;
  }
}

.tech-content {
  display: flex;
  flex-direction: column;
  font-family: SourceHanSansCN-Regular;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  letter-spacing: 0.8px;
  line-height: 20px;
  margin-bottom: 40px;

  img {
    margin: 0 auto;
  }

  p {
    text-indent: 30px;
  }

  .btn-huijiao {
    width: 160px;
    height: 60px;
    margin: 0 auto;
    font-family: SourceHanSansCN-Medium;
    font-size: 20px;
    color: #FFFFFF;
    letter-spacing: 1.11px;

  }

}

.data-exchange-entry{
  margin: 30px auto;
  text-align: center;

}
</style>
