<template>
    <ResStore></ResStore>
</template>

<script>
import ResStore from '../resource/ResStore'
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    components:{ResStore},
    setup(){
        const {proxy} = getCurrentInstance();

        return {}
    }
}
</script>

<style lang="less" scoped>

</style>

