<template>
    <div class="content">
        <Breads :breads="breads"></Breads>
        <div class="share-list">

            <div class="share-item">
                <img src="@static/img/datashare1.png"/>
            </div>
            <div class="share-item">
                <img src="@static/img/datashare2.png"/>
            </div>
            <div class="share-item">
                <img src="@static/img/datashare3.png"/>
            </div>
            <div class="share-item">
                <img src="@static/img/datashare4.png"/>
            </div>

            <!--
            <div class="share-item">
                <div class="share-name">仪器共享</div>
                <div class="share-desc">如果您有仪器，可在这里对外共享</div>
            </div>
            <div class="share-item">
                <div class="share-name">数据共享</div>
                <div class="share-desc">如果您有数据，可在这里对外共享</div>
            </div>
            <div class="share-item">
                <div class="share-name">课题发布</div>
                <div class="share-desc">在此处可以发布课题供其它科研工作者进行申请，经审核后可以在首页开放课题中显示</div>
            </div>
            <div class="share-item">
                <div class="share-name">管理数据赛题合作</div>
                <div class="share-desc">如果您想参与竞赛，或想发布赛题，可在这里寻找合作</div>
            </div>
            -->
        </div>
    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue';
import Breads from '../../components/common/Bread';
export default {
    components:{Breads},
    setup(){
        const breads = ref([{
            text:'开放服务',
            name: 'Open',
            path: '/open'
        },{
            text: '数据共享合作',
            name: 'DataShare',
            path: '/dataShare'
        }]);

        return {breads}
    }

}
</script>

<style lang="less" scoped>
.share-list{
    margin-top: 6px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 250px;

    .share-item{
        margin-bottom: 22px;
        cursor: pointer;
    }
}
</style>