<template>
    <div class="content">
        <Breads :breads="breads"></Breads>
        <div class="data  about-container">
            <div class="left-box">
                <a class="left-item" :class="{active: active==0}" @click="changeActive(0, 'id0')">研究介绍</a>
                <a class="left-item" :class="{active: active==1}" @click="changeActive(1, 'id1')">报告摘要</a>
                <a class="left-item" :class="{active: active==2}" @click="changeActive(2, 'id2')">科研产出统计分析</a>
                <a class="left-item" :class="{active: active==3}" @click="changeActive(3, 'id3')">不同依托单位的比较</a>
                <a class="left-item" :class="{active: active==4}" @click="changeActive(4, 'id4')">不同地区的比较</a>
                <a class="left-item" :class="{active: active==5}" @click="changeActive(5, 'id5')">讨论</a>
                <a class="left-item" :class="{active: active==6}" @click="changeActive(7, 'id6')">术语解释</a>
                <a class="left-item" :class="{active: active==7}" @click="changeActive(8, 'id7')">参考资料</a>
                <a class="left-item" :class="{active: active==8}" @click="changeActive(9, 'id8')">致谢 </a>
                <a class="left-item" :class="{active: active==9}" @click="changeActive(10, 'id9')">数据 </a>
                <a class="left-item" :class="{active: active==10}" @click="changeActive(11, 'id10')">联系作者  </a>
            </div>
            <div class="right-box">
                <div class="about-box" id="id0">
                    <h3 class="about-title" >研究介绍</h3>
                    <p class="about-content">
                                科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明的创新创造能力。七届以来，赛事规模呈阶梯式增长，全国累计参赛人数超过26万人。
        <br/><br/>
        全国青年科普创新实验暨作品大赛（简称“科普创新大赛”）作为一项面向青年学生的全国性科普赛事，旨在进一步贯彻落实《中华人民共和国科学技术普及法》、深入实施《全民科学素质行动计划纲要实施方案（2021-2035年）》，动员和激励广大青年学生参与科普创作和实践，促进科学思想、科学精神、科学方法和科学知识的传播和普及，提高广大青年学生的创新创造能力。七届以来，赛事规模呈阶梯式增长，全国累计参赛人数超过26万人。
    <br/><br/>
        全国青年科普创新实验暨作品大赛（简称“科普创新大赛”）作为一项面向青年学生的全国性科普赛事，旨在进一步贯彻落实《中华人民共和国科学技术普及法》、深入实施《全民科学素质行动计划纲要实施方案（2021-2035年）》，动员和激励广大青年学生参与科普创作和实践，促进科学思想、科学精神、科学方法和科学知识的传播和普及，提高广大青年学生的创新创造能力。七届以来，赛事规模呈阶梯式增长，全国累计参赛人数超过26万人。
                    </p>
                
                </div>
                <div class="about-box" id="id1">
                    <h3 class="about-title" >报告摘要</h3>
                    <p class="about-content">
                                科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明的创新创造能力。七届以来，赛事规模呈阶梯式增长，全国累计参赛人数超过26万人。
                    </p>
                
                </div>
                <div class="about-box" id="id2">
                    <h3 class="about-title" >科研产出统计分析</h3>
                    <p class="about-content">
                                科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明的创新创造能力。七届以来，赛事规模呈阶梯式增长，全国累计参赛人数超过26万人。
                    </p>
                
                </div>
                <div class="about-box"  id="id3">
                    <h3 class="about-title">不同依托单位的比较</h3>
                    <p class="about-content">
                            科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明的创新创造能力。七届以来，赛事规模呈阶梯式增长，全国累计参赛人数超过26万人。
                    </p>
                
                </div>
                <div class="about-box" id="id4">
                    <h3 class="about-title">不同地区的比较</h3>
                    <p class="about-content">
                            科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明的创新创造能力。七届以来，赛事规模呈阶梯式增长，全国累计参赛人数超过26万人。
                    </p>
                
                </div>
                <div class="about-box" id="id5">
                    <h3 class="about-title">讨论</h3>
                    <p class="about-content">
                            科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明的创新创造能力。七届以来，赛事规模呈阶梯式增长，全国累计参赛人数超过26万人。
                    </p>
                
                </div>
                <div class="about-box" id="id6">
                    <h3 class="about-title">术语解释</h3>
                    <p class="about-content">
                            科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明的创新创造能力。七届以来，赛事规模呈阶梯式增长，全国累计参赛人数超过26万人。
                    </p>
                
                </div>
                <div class="about-box" id="id7">
                    <h3 class="about-title">参考资料</h3>
                    <p class="about-content">
                            科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明的创新创造能力。七届以来，赛事规模呈阶梯式增长，全国累计参赛人数超过26万人。
                    </p>
                
                </div>
                <div class="about-box" id="id8">
                    <h3 class="about-title">致谢</h3>
                    <p class="about-content">
                            科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明的创新创造能力。七届以来，赛事规模呈阶梯式增长，全国累计参赛人数超过26万人。
                    </p>
                
                </div>
                <div class="about-box" id="id9">
                    <h3 class="about-title">数据</h3>
                    <p class="about-content">
                            科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明的创新创造能力。七届以来，赛事规模呈阶梯式增长，全国累计参赛人数超过26万人。
                    </p>
                
                </div>
                <div class="about-box" id="id10">
                    <h3 class="about-title">联系作者</h3>
                    <p class="about-content">
                            科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明科技平台统计内容内容介绍说明的创新创造能力。七届以来，赛事规模呈阶梯式增长，全国累计参赛人数超过26万人。
                    </p>
                
                </div>
            </div>
            <div>
                <div class="info-box">
                    <div class="info-item">作者：张三</div>
                    <div class="info-item">单位：国家农业科学数据中心</div>
                    <div class="info-item">日期：2021年3月</div>
                    <div class="info-item">联系方式：1391234123</div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<script>
import Breads from '../../components/common/Bread';
import {ref} from 'vue';
export default {
    components: {Breads},
    setup(){
        const active = ref(0);

        function changeActive(index, id){
            active.value = index;
            goAnchor(id)
        }

        function goAnchor(id){
            var anchor = document.getElementById(id);
            anchor.scrollIntoView();
        }

        const breads = ref([
            {text: '开放服务', name: 'Open', path: '/open'},
            {text: '专题服务', name: 'Report', path: '/report'},
            {text: '专题服务详情'}
        ])

        return {breads, active, changeActive, goAnchor}
    },
    methods:{
        
    }
}

</script>

<style lang="less" scoped>

@import url('../../../public/static/css/about.less');
.data{
    display: flex;
    justify-content: space-between;

    .left-box{
        display:flex;
        flex-direction: column;
        align-items: flex-end;
        width: 132px;
        margin-right: 21px;

        .left-item{
            cursor: pointer;
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            line-height: 26px;
            border-right: 2px solid #F2F2F2;
            padding-right: 7px;
        }
        .left-item.active{
            color: #007EEC;
            border-right: 2px solid #007EEC;
        }
    }

    .right-box{
        flex:1;
        max-height: 680px;
        overflow-y: auto;
    }

    .info-box{
        padding: 18px 9px;
        width: 186px;
        margin-left: 20px;
        background: #FFFFFF;
        border: 1px solid #C7C7C7;
        .info-item{
            font-family: SourceHanSansCN-Regular;
            font-size: 12px;
            line-height: 12px;
            color: rgba(0,0,0,0.85);
            margin-bottom: 12px;
        }
    }
}
</style>
