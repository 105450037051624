<template>
    <div class="content">
        <NewsList v-loading="loading" :active="active" :breads="breads" :categories="items" @itemClick="categoryClick">
            <template v-slot:content>
                <div class="platform" v-if="active==0" v-loading="platformLoading" v-html="platformIntro">

                </div>
                <div class="news-content" >
                    <div class="query-box form-inline">
                        <div class="result-count">共<span class="blue">{{totalRecord}}</span>条记录</div>
                        <div class="form-group">
                            <label for="key" class="control-label">关键词</label>
                            <input type="text" id="key" class="form-control" v-model="queryForm.searchKey" placeholder="请输入"/>
                        </div>
                        <button type="button" class="btn btn-primary" @click="query">查询</button>
                        
                    </div>
                    <div v-if="newsList.total == 0" style="text-align:center;">暂无内容</div>
                    <div v-else class="news-content-list">
                        
                        <div class="news-item" v-for="item in newsList.list" :key="item.id">
                            <div class="title" @click="gotoDetail(item.id)" v-html="matchKey(item.name)"></div>
                        </div>
                    </div>
                   
                    <Pager class="i-pager" :currentPage="currentPage" v-if="newsList.totalPage" :totalPage="newsList.totalPage" :totalRecord="newsList.total" @pageChange="pageChange" layout="total, prev, pager, next" ></Pager>
                    
                    <br />
                     <br /> 
                </div>
            </template>
        </NewsList>
    </div>
</template>

<script>
import NewsList from '../../../components/common/NewsList.vue'
import Pager from '../../../components/common/Pager.vue'
import {ref, onMounted, watch, reactive, getCurrentInstance} from 'vue'
import {useRoute} from 'vue-router'
export default {
    name:"Platform",
    data(){
        return {
            breads:[{
                text:'首页',
                name: 'Index',
                path: '/index'
            }, {
                text: '科研机构',
                link: ''
            }],
           
        }
    },
    setup(){
        const {proxy} = getCurrentInstance();
        const active = ref(0)

        const items = ref([]);  //侧边标题：{id: 'xx', name: 'xx'}
        const loading = ref(false)
        const platformLoading = ref(false)
        const platformIntro = ref('');
        const queryForm = reactive({
            searchKey: '',
            zoneId: ''
        })
        const currentPage = ref(1);

        const newsList = ref({});
        const totalRecord = ref(0)

        const route = useRoute();
        if(route.query.index){
            const index = route.query.index;
            active.value = Number.parseInt(index);
        }
        if(route.query.zoneId){ //专区ID
            queryForm.zoneId = route.query.zoneId;
        }


        async function getItems(){  //读取企业分类
            items.value.push({id: 'ZONE_UNIT', name: '科研机构'});

            getNewsList(items.value[active.value].id);
        }

        async function loadDataDictList(dictTypeCode){    //加载数据字典
            let dataDictList = [];
            loading = true;
            await proxy.$http.post('/core/base/c/dictInfo/list?noPage=1&typeCode=' + dictTypeCode, {}).then(res=>{
                loading = false;

                dataDictList = res.data.result;
                
            }).catch(err =>{
                loading = false;
            })

            return dataDictList;
        }

        function getNewsList(typeVal){
            platformLoading.value = true;

            const url = '/gdsti2/web/a/webZone/unitList';
            proxy.$http.post(url, {
                searchValue: queryForm.searchKey,
                zoneId: queryForm.zoneId,
                typeVal: typeVal,
                limit: 8,
                offset: (currentPage.value - 1)*8
            }).then(res=>{
                platformLoading.value = false;
                if(res.data.state == 0){
                    newsList.value = res.data.result;

                    totalRecord.value = newsList.value.total
                }
            }).catch(err=>{
                platformLoading.value = false;
            })
        }

        
        

        onMounted(()=>{
            getItems();
        })

        

        return {active, items, queryForm, loading,platformLoading, platformIntro, getNewsList, currentPage, newsList, totalRecord}
    },
    components:{NewsList, Pager},
    watch: {
        '$route.query': {
            handler(newValue) {
                if(newValue.index != undefined){
                    this.active = Number.parseInt(newValue.index);
                    console.log(this.active);

                }
               
            },
            immediate: true,
        }
    },
    methods:{
        categoryClick: function(index){
            console.log("点击");
            this.breads[1].text = this.items[index].name;
            this.active = index;
            this.currentPage = 1;
            this.getNewsList(this.items[this.active].id);

            // const newUrl = "/#"+this.$route.path + `?index=${index}`
            // window.history.pushState('', '', newUrl);
        },
        pageChange: function(page){
            this.currentPage = page;
            this.getNewsList(this.items[this.active].id);
        },
        gotoDetail: function(id){
            // this.$router.push({ path: '/baiqianwan/unitInfo', query: {unitId: id} })
            // let routeData = this.$router.resolve({ path: '/baiqianwan/unitInfo', query: {unitId: id} });
            // window.open(routeData.href, '_blank');

        },
        query: function(){
            this.currentPage = 1;
            this.getNewsList(this.items[this.active].id);
        },
        matchKey(val){
            if(this.queryForm.searchKey){
                let keyword = this.queryForm.searchKey;
                let replaceReg = new RegExp(keyword, 'ig')
                let replaceString = `<span style="color: #E02020;">${keyword}</span>`
                return val.replace(replaceReg, replaceString);
            }else{
                return val;
            }
        }
    }
}
</script>

<style lang="less" scoped>
.i-pager{
    margin-top: 38px;
    margin-bottom: 27px;
}
.table td{
    font-family: SourceHanSansCN-Regular;
    font-size: 16px;
    color: rgba(0,0,0,0.85);
}
.query-box{
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
#category{
    width: 100px;
}
#key{
    width: 171px;
}
#date{
    display: inline-block;
}
.result-count{
    flex:1;
    text-align: left;
    font-family: SourceHanSansCN-Normal;
    font-size: 20px;
    color: rgba(0,0,0,0.85);
    line-height: 30px;
}
.news-content-list{
    background: #FFFFFF;
    border: 1px solid #E7E7E7;
    padding-left: 12px;
    padding-right: 12px;
    .news-item{
        padding-top: 18px;
        padding-bottom: 15px;
        border-bottom: 1px solid #E7E7E7;

        .title{
            font-family: SourceHanSansCN-Medium;
            font-size: 18px;
            /*  color: #007EEC;
            cursor: pointer; */
        }
        .dept{
            margin-top: 13px;
            margin-bottom: 13px;
            font-family: SourceHanSansCN-Normal;
            font-size: 14px;
            color: rgba(0,0,0,0.85);
            .dept-name{
                color: #007EEC;
            }
        }
        .intro{
            font-family: SourceHanSansCN-Regular;
            font-size: 12px;
            color: #A5A5A5;
            line-height: 20px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            line-clamp: 3;
            text-overflow: ellipsis;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
    }
    .news-item:last-child{
        border-bottom: none;
    }
}
</style>
