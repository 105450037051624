import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios  from 'axios'
import qs from 'qs'
import apiConfig from './config'


// import { library } from '@fortawesome/fontawesome-svg-core'
// import { fas } from '@fortawesome/free-solid-svg-icons'
// import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { ElMessage, ElMessageBox, ElLoading} from 'element-plus'
import ElementPlus from 'element-plus'


import 'element-plus/dist/index.css'

import lazyPlugin from 'vue3-lazy'

// library.add(fas);

const app = createApp(App);



// 让post使用 application/x-www-form-urlencoded 传递
axios.interceptors.request.use(config=>{
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded';
    config.headers['token'] = store._state.data.token;
    if(config.method == 'post'){
      config.data = qs.stringify({...config.data})
    }

    return config
  }, err=>{
    ElMessage.error(err.message);
    return Promise.reject(err)
  });

axios.interceptors.response.use(data=>{

  //判断后台返回链接是否已经非登录状态
  if(data.request.responseURL && (data.request.responseURL.lastIndexOf('/core/base/v/index/login') >= 0 || data.request.responseURL.lastIndexOf('login.jsp') >= 0)){
    ElMessage.error("登陆状态已过期，请重新登陆");
    //清空本地用户信息
    store.commit('updateLoginStatus', {token:''});
    store.commit('updateUserInfo', {userInfo: '{}' });

    // 登陆过期跳转登录页
    router.push({name:'Login', path: '/login'})
  }



  if(data.status == 200 || data.status == 202){
    if(data.data.state != 0){
        if(data.data.state === 10402){
            ElMessage.error("登陆状态已过期，请重新登陆");
            // 登陆过期跳转登录页
            router.push({name:'Login', path: '/login'})
        }else{
            ElMessage.error(data.data.message);
        }

    }
    return Promise.resolve(data)
  }else{
    return Promise.reject(data)
  }
  
}, err=>{

    if(err.code == 'ERR_NETWORK'){
      ElMessage.error("网络错误，请检查您的网络链接");
    }else{
      ElMessage.error(err.message);
    }
    return Promise.reject(err)
});
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers.post['accessrights'] = 'visitor';
app.config.globalProperties.$http = axios
app.config.globalProperties.qs = qs
app.config.globalProperties.indexUrl = apiConfig.baseURL;
app.config.globalProperties.$getImage = attachCode =>{
  return app.config.globalProperties.indexUrl  + '/core/base/c/download/image?attachId='+attachCode;
}
app.config.globalProperties.$upgrading = () =>{
    ElMessageBox.alert('该功能升级中，敬请谅解！', '提示', {
        // if you want to disable its autofocus
        // autofocus: false,
        confirmButtonText: '确定',
    })
}
app.config.globalProperties.$formateDate = (date) => {
  let month = (date.getMonth()+1);
  if(month < 10){
      month = '0'+month;
  }
  let day =  date.getDate();
  if(day < 10){
      day = '0' + day;
  }
  return date.getFullYear() + '-' + month + '-' + day;
}
app.config.globalProperties.ElMessage = ElMessage;
app.config.globalProperties.ElLoading = ElLoading;
app.config.globalProperties.ElMessageBox = ElMessageBox;
import './assets/base.less'

app
.use(store)
.use(router)
.use(ElementPlus)
.use(lazyPlugin, {
  preload: 1,
  loading: require('../public/static/img/loading.svg'),
  error: require('../public/static/img/error.png')
})
.directive('loading', ElLoading.directive)
// .use(ElementPlus)
// .component("font-awesome-icon", FontAwesomeIcon)
.mount('#app')
