<template>
    <div class="contact content about-container">
        <div class="about-box">

            <p class="about-content">
                围绕构建“基础研究+技术攻关+成果转化+科技金融+人才支撑”全过程创新生态链，聚合科技资源要素，提升专业服务能力，顺应产业高端化、数字化、绿色化、服务化发展趋势，助力打造具有全球影响力的科技和产业创新高地。     
            </p>
            <br/>
            <div class="center">
                <img src="@static/img/about-contact.png"/>
            </div>
            <p class="about-way">
                单ㅤㅤ位：广东省科技基础条件平台中心<br/>
                地ㅤㅤ址：广东省广州市越秀区连新路171号3号楼<br/>
                ㅤㅤㅤㅤㅤ广东省广州市天河区东莞庄一横路116号广东生产力大厦二楼<br/>
                联系方式：020-83163463，陈小姐<br/>
                邮件地址：gdsti@gdcc.com.cn<br/>
                投诉邮箱：gdsti@gdcc.com.cn<br/>
            </p>
        </div>
<!--        <div class="about-box">-->
<!--            <h3 class="about-title">开放课题</h3>-->
<!--            <p class="about-content">汇交的科学数据范围包括国家政府性资金支持的科技活动所形成的人口健康领域相关科学数据、合作机构根据特定需求加工的相关科学数据、机构和个人产出的科学数据（如论文相关数据），以及相关的辅助科学数据和工具软件等。为便于理解，列出下列数据类型包括但不限于以下：<br/>-->
<!--记录数据： 各种门(急)诊记录、电子病历、电子健康记录、实验室记录、住院记录、用药记录、手术记录、随访记录、医保记录、生物样本记录和临床诊疗记录等。关于重要的医学标准、政策等整理形成的目录数据等。<br/>-->
<!--<br/>-->
<!--观察和检测数据： 临床观察和测量数据，包括临床症状、表现等观察数据和实验室的检查、测量和检验数据，如体重、心率、血压、血糖等，医学检测仪器拍摄的医学影像数据、医学信号数据等，移动或物联网连接的可穿戴设备产生的数据和基因测序数据等。-->

<!--</p>-->
<!--            <div class="about-btn">-->
<!--                <button class="btn btn-primary" @click="gotoKeti">发布课题</button>-->
<!--            </div>-->
<!--        </div>-->
<!--        <div class="about-box">-->
<!--            <h3 class="about-title">竞赛专区</h3>-->
<!--            <p class="about-content">-->
<!--汇交的科学数据范围包括国家政府性资金支持的科技活动所形成的人口健康领域相关科学数据、合作机构根据特定需求加工的相关科学数据、机构和个人产出的科学数据（如论文相关数据），以及相关的辅助科学数据和工具软件等。为便于理解，列出下列数据类型包括但不限于以下：<br/>-->
<!--记录数据： 各种门(急)诊记录、电子病历、电子健康记录、实验室记录、住院记录、用药记录、手术记录、随访记录、医保记录、生物样本记录和临床诊疗记录等。关于重要的医学标准、政策等整理形成的目录数据等。<br/>-->
<!--<br/>-->
<!--观察和检测数据： 临床观察和测量数据，包括临床症状、表现等观察数据和实验室的检查、测量和检验数据，如体重、心率、血压、血糖等，医学检测仪器拍摄的医学影像数据、医学信号数据等，移动或物联网连接的可穿戴设备产生的数据和基因测序数据等。-->


<!--            </p>-->
<!--            <div class="about-btn">-->
<!--                 <button class="btn btn-primary" @click="gotoContest">竞赛专区</button>-->
<!--            </div>-->
<!--           -->
<!--        </div>-->
<!--        <div class="about-box">-->
<!--            <h3 class="about-title">产业专区</h3>-->
<!--            <p class="about-content">-->
<!--                汇交的科学数据范围包括国家政府性资金支持的科技活动所形成的人口健康领域相关科学数据、合作机构根据特定需求加工的相关科学数据、机构和个人产出的科学数据（如论文相关数据），以及相关的辅助科学数据和工具软件等。为便于理解，列出下列数据类型包括但不限于以下：<br/>-->
<!--记录数据： 各种门(急)诊记录、电子病历、电子健康记录、实验室记录、住院记录、用药记录、手术记录、随访记录、医保记录、生物样本记录和临床诊疗记录等。关于重要的医学标准、政策等整理形成的目录数据等。<br/>-->
<!--<br/>-->
<!--观察和检测数据： 临床观察和测量数据，包括临床症状、表现等观察数据和实验室的检查、测量和检验数据，如体重、心率、血压、血糖等，医学检测仪器拍摄的医学影像数据、医学信号数据等，移动或物联网连接的可穿戴设备产生的数据和基因测序数据等。-->


<!--            </p>-->
<!--            <div class="about-btn">-->
<!--                <button class="btn btn-primary" @click="gotoIndustry">产业专区</button>-->
<!--            </div>-->
<!--        </div>-->
<!--
        <div style="display: flex;justify-content: space-between;">
            <div class="about-box">
                <div class="about-title">联系我们</div>
                <p class="about-way">
                    单        位：广东省科技基础条件平台中心<br/>
                    单位地址：广东省广州市越秀区连新路171号3号楼<br/>
                    联系电话：020-83561811<br/>
                    传       真：020-83549360<br/>
                    邮       编：510033<br/>
                </p>
            </div>
            <div class="about-box">
                <div class="about-title">联系我们</div>
                <p class="about-way">
                    单        位：广东省科技基础条件平台中心<br/>
                    地        址：广东省广州市越秀区连新路171号3号楼<br/>
                    &nbsp;&nbsp;&nbsp;广东省广州市天河区东莞庄一横路116号广东生产力大厦二楼<br/>
                    联系方式：020-83163463，陈小姐<br/>


                </p>
            </div>
        </div>
        -->

    </div>
</template>

<script>
export default {
    methods:{
        gotoKeti(){
            //TODO: 发布课题
        },
        gotoContest(){
            this.$router.push({name: 'Contest', path: '/contest'});           
        },
        gotoIndustry(){
            this.$router.push({name: 'Industry', path: '/industry'});
        }
    }
}
</script>

<style lang="less" scoped>
@import url('../../../public/static/css/about.less');

.contact{
    padding-top: 8px;
}

</style>
