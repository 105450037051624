<template>
  <div class="content">
    <NewsList :breads="breads" :categories="items" @itemClick="categoryClick" :active="active">
      <template v-slot:content>
        <TechDataManage v-if="active==0"></TechDataManage>
        <TechInstrumentSub v-if="active==1"></TechInstrumentSub>
        <TechResReview v-if="active==2"></TechResReview>
        <TechPlatEval v-if="active==3"></TechPlatEval>
        <TechServiceTopic v-if="active==4"></TechServiceTopic>

      </template>
      <template v-slot:menu>
        <div class="tech-sys" v-show="false">
          科技服务管理系统
          <a href="javascript:void(0)" @click="categoryClick(6)" class="tech-entry">进入 ></a>
        </div>
      </template>
    </NewsList>
  </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue'
import NewsList from '../components/common/NewsList'
import TechResReview from '../views/tech/TechResReview'
import TechDataManage from '../views/tech/TechDataManage'
import TechInstrumentSub from '../views/tech/TechInstrumentSub'
import TechPlatEval from '../views/tech/TechPlatEval'
import TechServiceTopic from '../views/tech/TechServiceTopic'
import CommonUtil from "@/utils/common"

export default {
  name: "Platform",
  data() {
    return {
      breads: [{
        text: '首页',
        path: '/index',
        name: 'Index'
      }, {
        text: '科学数据管理',
        link: ''
      }],
      items: [
        {name: "科学数据管理", id: 1},
        {name: "仪器信息报送", id: 2},
        {name: "科学仪器查重", id: 3},
        {name: "创新平台评价", id: 4},
        {name: "科技专题服务", id: 5}
      ],
      active: 0
    }
  },
  setup() {
    const {proxy} = getCurrentInstance();

    return {proxy}
  },
  components: {NewsList, TechResReview, TechDataManage, TechInstrumentSub,TechPlatEval,TechServiceTopic},
  mounted() {
    const index = this.$route.query.index;
    this.active = Number.parseInt(index);

  },
  watch: {
    active(val, oldVal) {
      if (val == 6) {
        this.breads[1].text = "科技服务管理系统";
      } else {
        this.breads[1].text = this.items[val].name;
      }
    }
  },
  methods: {
    categoryClick: function (index) {
      console.log(index)
      this.active = index;
    },
    async gotoNext() {
      const that = this;

      let isPlatformAdmin = await CommonUtil.isPlatformAdmin();

      if (isPlatformAdmin) {
        let goPageurl = that.indexUrl + "/user/dataRes";
        window.open(goPageurl);
      } else {
        that.ElMessage.error('您没有权限，只有平台负责人才能进行汇交。');
      }
    },
    gotoBuilding() {
      this.$router.push({name: 'Building', path: '/building'})
    }
  }
}
</script>

<style lang="less" scoped>
.tech-sys-list {
  display: flex;
  justify-content: space-between;

  .tech-sys-item {
    cursor: pointer;
  }
}

.tech-content {
  display: flex;
  flex-direction: column;
  font-family: SourceHanSansCN-Regular;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
  letter-spacing: 0.8px;
  line-height: 20px;
  margin-bottom: 40px;

  img {
    margin: 0 auto;
  }

  p {
    text-indent: 30px;
  }

  .btn-huijiao {
    width: 160px;
    height: 60px;
    margin: 0 auto;
    font-family: SourceHanSansCN-Medium;
    font-size: 20px;
    color: #FFFFFF;
    letter-spacing: 1.11px;

  }

}

.tech-sys {
  font-family: SourceHanSansCN-Medium;
  font-size: 18px;
  color: #003CAC;
  line-height: 20px;
  padding: 12px;
  margin-top: 86px;
  margin-bottom: 86px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(215, 229, 255);

  .tech-entry {
    font-family: SourceHanSansCN-Medium;
    font-size: 14px;
    color: #003CAC;
    line-height: 14px;
  }
}
</style>
