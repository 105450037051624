<template>
    <div class="user-info">
        <Form class="form" ref="target" v-slot="{ errors}">
            <div class="form-group">
                <label class="control-label col-3">旧密码<span class="required">*</span></label>
                <div class="col-8">
                    <Field type="password" name="oldPwd"  class="form-control" v-model="form.oldPwd" placeholder="请输入旧密码"/>
                    <div class="error" >{{errors.oldPwd}}</div>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label col-3">新密码<span class="required">*</span></label>
                <div class="col-8">
                    <Field type="password" name="newPwd1" :rules="checkPwd" class="form-control" v-model="form.newPwd1" placeholder="请输入新密码" />
                    <div class="error" >{{errors.newPwd1}}</div>
                </div>
            </div>
            <div class="form-group">
                <label class="control-label col-3">确认密码<span class="required">*</span></label>
                <div class="col-8">
                    <Field type="password" name="newPwd2" :rules="checkTwoPwd" class="form-control" v-model="form.newPwd2"  placeholder="请再次输入新密码"/>
                    <div class="error" >{{errors.newPwd2}}</div>
                </div>
            </div>
            <div class="form-group">
                <el-button type="primary" v-loading="loading" class="next-btn" @click="reset">提交</el-button >
            </div>
        </Form>
    </div>
</template>

<script>
import { Form, Field } from 'vee-validate';
import { ElButton } from 'element-plus';
import {ref, reactive, onMounted, getCurrentInstance} from 'vue';
export default {
    components:{Form, Field, ElButton},
    setup(){
        const {proxy} = getCurrentInstance();
        const form = reactive({
            oldPwd:'',
            newPwd1: '',
            newPwd2: ''
        })
        const target = ref(null)
        const loading = ref(false);

        function validPwd(value){
            let i = /\d+/.test(value)?1:0;
            let j = /[a-zA-Z]+/.test(value)?1:0;
            let k = /[~!@#$%^&*()_+|<>,.?/:;'\[\]\{\}\"]+/.test(value)?1:0;
            let length = value.length;
            if(value.indexOf(form.username) >= 0){
                return '密码不能包含账号';
            }else if(length < 8){
                return '密码至少8位字符';
            }else if(i+j+k <= 2){
                return '密码须包含数字、字母、特殊字符';
            }else {
                return true;
            }
        }

        const checkPwd = value => {
            if (!value) return '请输入密码';
            let result = validPwd(value);
            return result;
        }

        const checkTwoPwd = value => {
            if(!value) return '请输入密码';
            let result = validPwd(value);
            if(result === true){
                if(value != form.newPwd1){
                    return '两次密码输入不一致';
                }
            }else{
                return result;
            }
            return true;
        }

        return {target, form, loading, checkPwd, checkTwoPwd,proxy}
    },
  methods:{
      reset(){

        if(this.form.oldPwd == '' || this.form.newPwd1 == '' && this.form.newPwd2 == ''){
            this.proxy.ElMessage.error("请输入密码");
            return;
        }
        if(this.form.newPwd1 != this.form.newPwd2 ){
            this.proxy.ElMessage.error("新密码与确认密码不一致");
            return;
        }

        var data = {
            prepassword: this.form.oldPwd,
              password: this.form.newPwd1
        };

        // console.log(data);

        const url = "/core/base/c/admin/modifypassword";
        this.proxy.$http.post(url, data).then(res=>{
          if(res.data.state==0){
            this.proxy.ElMessage.success("修改成功");
            // loadData();
          }
        }).catch(err=>{

        });
      }
  }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';
.next-btn{
    margin: 0 auto;
}
.form{
    width: 800px;
    margin: 0 auto;
    margin-top: 90px;
}
.form-group{
    margin-bottom: 26px;
    .form-control{
        width: 350px;
    }
}
</style>

