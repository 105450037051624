<template>
    <div class="organ" v-loading="loading">
        <div class="organ-info">
            <div class="content organ-head">
                <div class="logo">
                    <img :src="toImage(platformInfo.img)" v-if="platformInfo.img" class="logo-img">
                </div>
                <div class="info">
                    <div class="organ-name">{{platformInfo.platformName}}</div>
                    <div class="organ-desc">
                        <!-- <div class="dept">
                            依托单位：{{platformInfo.unitName}}
                        </div> -->
                        <div class="intro">
                            简介：{{platformInfo.briefIntroduction}}
                        </div>
<!--                        <div class="intro" v-if="platformInfo.contactNumberLabel">-->
<!--                            {{platformInfo.contactNumberLabel}}：{{platformInfo.contactNumber}}-->
<!--                        </div>-->
                        <div class="intro" v-if="platformInfo.cooperationProjectName">
                            共建单位：{{platformInfo.cooperationProjectName}}
                        </div>
                        <div class="intro" v-if="platformInfo.projectDirectorNameLabel">
                            {{platformInfo.projectDirectorNameLabel}}：{{platformInfo.projectDirectorName}}
                        </div>
                        <!-- <div class="intro" >
                            <button style="" type="button" class="btn btn-primary" @click="getPlatformAdminPhone">获取该平台相关负责人联系方式</button>
                        </div> -->
                    </div>
                </div>
            </div>
            
        </div>
        <div class="content organ-content">
            <!-- 左侧资源显示 -->
            <div class="left-box" v-if="(newestItems && newestItems.length > 0)  || (hotItems && hotItems.length > 0)">
                <div class="newest" v-if="newestItems && newestItems.length > 0">
                    <Category :items="newestItems" :title="newestTitle"></Category>
                </div>
                <div class="hot" v-if="hotItems && hotItems.length > 0">
                    <Category :items="hotItems" :title="hotTitle"></Category>
                </div>
            </div>

            <!-- 右侧内容显示 -->
            <div class="right-box">
                <div class="res-title">
                    资源/数据简介
                </div>
                <div class="res-box">
                    <div class="res-content" v-html="platformInfo.dataBriefIntroduction">
                    </div>
                </div>
                <div class="res-title">
                    资源/数据体系
                </div>
                <div class="res-box">
                    <div class="res-content" v-html="platformInfo.dataSystem">
                    </div>
                </div>
                <div class="res-title" style="margin-top: 62px;">
                    服务方式
                </div>
                <div class="res-box">
                    <div class="res-content" v-html="platformInfo.dataServiceMode">
                    </div>
                </div>
                <div class="res-box" v-if="platformInfo.resultProcessingListLabel">
                    <div class="title">{{platformInfo.resultProcessingListLabel.substring(platformInfo.resultProcessingListLabel.indexOf('-')+1)}}</div>
                    <div class="res-content">
                        <div class="res-deal">
                            <router-link class="deal-item" v-for="(item, index) in platformInfo.resultProcessingList" :key="index" :to="{name:'NewsDetail', path:'/newsDetail', query:{id:item.adInfoId}}">
                                <img :src="toImage(item.adInfoImg)" class="deal-img"/>
                                <p class="deal-name">{{item.adInfoTitle}}</p>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <!-- 产学研合作案例 / 典型案例 -->
        <div class="case content" v-if="group>0 && platformInfo.adInfoListLabel">
            <div class="case-title">
                {{platformInfo.adInfoListLabel}}
                <div class="pager" >
                    <div class="previous" @click="previous()">&lt;</div>
                    <span>{{current}}/{{group}}</span>
                    <div class="next" @click="next()">&gt;</div>
                </div>
            </div>

            <el-carousel class="case-content" ref="swiper">
                <el-carousel-item  v-for="(items,index) in getAdInfoList" :key="index">
                    <div class="case-list">
                            <router-link class="case-item" v-for="item in items" :key="item.adInfoId" target="_blank" :to="{name:'NewsDetail', path:'/newsDetail', query:{id:item.adInfoId, name:'平台黄页（'+platformInfo.name+')', type:'organ', path:'/organ?id='+id}}">
                                <div class="case-img">
                                    <img :src="toImage(item.adInfoImg)"/>
                                </div>
                                <div class="case-name">
                                    {{item.adInfoTitle}}
                                </div>
                            </router-link>
                    </div>
                </el-carousel-item>
            </el-carousel>
        </div>
        <!-- 典型案例 END -->
    </div>
</template>

<script>
import {ElCarousel, ElCarouselItem} from 'element-plus'
import 'element-plus/theme-chalk/el-carousel.css'
import 'element-plus/theme-chalk/el-carousel-item.css'
import Category from '../../../components/organ/Category'
import {ref, onMounted, getCurrentInstance} from 'vue'
import {useRoute} from 'vue-router'
export default {
    name:"Organ",
    data(){
        return {
            swiperIndex:0,
            newestTitle: '本资源库最新资源',
            hotTitle: '本资源库最热资源'
        }
    },
    components:{Category, ElCarousel, ElCarouselItem},
    setup(){
        const {proxy} = getCurrentInstance();
        const route = useRoute();
        const id = ref('');
        // console.log(route)
        id.value = route.query.id;
        const loading = ref(false)
        const platformInfo = ref({});
        const current = ref(1)
        const newestItems = ref([]);
        const hotItems = ref([]);

        function getPlatformInfo(){ //获取平台信息
            loading.value = true
            const url = '/gdsti2//web/a/webZone/enterpriseInfo';
            proxy.$http.post(url,{id: id.value}).then(response=>{
                loading.value = false
                if(response.data.state == 0){
                    platformInfo.value = response.data.result;
                }

                // console.log(platformInfo);
            }).catch(err =>{
                loading.value = false
            })
        }

        function getNewRes(){   //本资源库最新资源
            const url = '/gdsti2/web/a/webResource/resourceDataList';
            proxy.$http.post(url, {platformId: id.value, type:'new', noPage: 1, limit: 10}).then(res=>{
                if(res.data.state == 0){
                    newestItems.value = res.data.result;
                }
            }).catch(err=>{

            })
        }

        function getHotRes(){   //本资源库最热资源
            const url = '/gdsti2/web/a/webResource/resourceDataList';
            proxy.$http.post(url, {platformId: id.value, type:'hot', noPage: 1, limit: 10}).then(res=>{
                if(res.data.state == 0){
                    hotItems.value = res.data.result;
                }
            }).catch(err=>{

            })
        }

        onMounted(()=>{
            getPlatformInfo();
            getNewRes();
            getHotRes();
        });

        return {newestItems, hotItems, loading, current, platformInfo, id }

    },
    mounted(){
        // this.mySwiper = new Swiper(".swiper-container", {
           
        // })
    },
    computed:{
        getAdInfoList(){    //产学研合作案例 / 典型案例
            if(!this.platformInfo || !this.platformInfo.adInfoList || this.platformInfo.adInfoList.length ==0){
                return [];
            }else{
                let result = [];
                let items = [];
                for(let i = 0 ; i < this.platformInfo.adInfoList.length; i++){
                    if(i%4 == 0){
                        items = [];
                    }
                    items.push(this.platformInfo.adInfoList[i]);
                    if(i%4 == 3 || i == this.platformInfo.adInfoList.length-1){
                        result.push(items);
                    }
                }
                return result;
            }
        },
        group(){    //产学研合作案例 / 典型案例   页数(组数，多少组)
            if(!this.platformInfo || !this.platformInfo.adInfoList || this.platformInfo.adInfoList.length ==0){
                return 0;
            }else{
                let group = Number.parseInt(this.platformInfo.adInfoList.length / 4);
                if(this.platformInfo.adInfoList.length%4 != 0){
                    group += 1;
                }
                return group;
            }
        },
        resType(){
            if(this.platformInfo.dataType == 1){
                return '资源';
            }else{
                return '数据';
            }
        }
    },
    methods:{
        toImage(id){
            return this.$getImage(id);
        },
        next: function(){
            this.$refs.swiper.next();
        },
        previous: function(){
             this.$refs.swiper.prev();
        },
        gotoProcess: function(index){
            const url = this.platformInfo.resultProcessingList[index].processingUrl;
            window.open(url)
        },
        getPlatformAdminPhone(){    //获取数据管理员联系方式
            var userInfo = this.$store.state.userInfo;
            if (userInfo && userInfo.userCode) {
                var that = this;
                const url = '/gdsti2/lab/c/labPlatform/getPlatformPhone';
                this.$http.post(url, {id: that.id}).then(res => {
                    that.categoryLoading = false;
                    if (res.data.state == 0) {
                        that.ElMessage.success(res.data.result);
                    }
                }).catch(err => {
                    that.categoryLoading = false;
                })
            } else {
                this.ElMessage.error("请先登录");
            }
        }
    }
}
</script>

<style lang="less" scoped>

@import url('../../../../public/static/css/organ.less');

.case{
    margin-bottom: 63px;
    .case-title{
        font-family: SourceHanSansCN-Bold;
        font-size: 30px;
        color: rgba(0,0,0,0.85);
        letter-spacing: 0;
        text-align: justify;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .pager{
            float: right;
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            color: rgba(0,0,0,0.85);
            letter-spacing: 0;
            text-align: justify;
            .previous, .next{
                border: 1px solid #007EEC;
                line-height: 20px;
                width: 20px;
                text-align: center;
                color: #007EEC;
                cursor: pointer;
            }
            .previous{
                margin-right: 20px;
            }
            .next{
                margin-left: 20px;
            }
            .previous:hover, .next:hover{
                background: #007EEC;
                color: #fff;
            }
            div{
                display:inline-block;
            }
        }
    }
    .case-content{
        margin-top: 35px;
        .case-list{
            display: flex;

            .case-item{
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                margin-right: 22px;
                width: 284px;
                cursor: pointer;
                .case-img{
                    width: 284px;
                    height: 169px;
                    img{
                        width: 284px;
                        height: 169px;
                    }
                }
                .case-name{
                    font-family: SourceHanSansCN-Medium;
                    font-size: 20px;
                    color: rgba(0,0,0,0.85);
                    letter-spacing: 0;
                    text-align: justify;
                    margin-top: 13px;
                    -webkit-line-clamp: 2;
                    text-overflow: ellipsis;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden; 
                           }
            }
            .case-item:last-child{
                margin-right: 0px;
            }
        }

        
    }
}
.res-title{
    font-family: SourceHanSansCN-Medium;
    font-size: 24px;
    color: rgba(0,0,0,0.85);
    margin-bottom: 16px;
    line-height: 24px;
    margin-top: 10px;
} 
.res-box{
    margin-bottom: 16px;
        .title{
            font-family: SourceHanSansCN-Medium;
            font-size: 20px;
            color: rgba(0,0,0,0.85);
            margin-bottom: 16px;
            padding-left: 3px;
            border-left: 2px solid #007EEC;
            line-height: 22px;
        }
        .res-content{
            font-family: SourceHanSansCN-Regular;
            font-size: 16px;
            color: rgba(0,0,0,0.85);
            line-height: 30px;

            /deep/ a{
                color: #007EEC;
            }

            .res-deal{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                .deal-item{
                    display: flex;
                    flex-direction: column;
                    width: 181px;
                    margin-right: 12px;
                    cursor: pointer;

                    .deal-img{
                        width: 181px;
                        height: 101px;
                    }
                    .deal-name{
                        font-family: SourceHanSansCN-Regular;
                        font-size: 16px;
                        color: rgba(0,0,0,0.85);
                        text-align: center;
                    }
                }
            }
        }
    }
.organ-info{
    margin-bottom: 25px;
    min-height: 300px;
    background-size: cover;

    
    .organ-head{
        padding-top: 53px;
        padding-bottom: 50px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        color: white;

        .info{
                width: 900px;
            }
            .logo{
                .logo-img{
                    margin-left: 180px;
                    width: 95px;
                    height: 95px;
                }
            }
        .organ-name{
            font-family: SourceHanSansCN-Bold;
            font-size: 30px;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: justify;
            line-height: 30px;
        }
        .organ-desc{
            margin-top: 18px;
            font-family: SourceHanSansCN-Regular;
            font-size: 12px;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: justify;
            line-height: 18px;

            .dept{
                font-size: 12px;
                margin-bottom: 20px;
            }
            .intro{
                margin-bottom: 16px;
                font-size: 12px;
                line-height: 20px;
                letter-spacing: 1.5px;
            }
            .contract-title{
                font-size: 14px;
                margin-bottom: 6px;
            }
            .contract-info{
                font-size: 12px;
            }
        }
    }
}

.organ-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    
    .left-box{
        width: 283px;
        .newest{
            margin-bottom: 25px;
        }
        .hot{
            margin-bottom: 25px;
        }
    }
    .right-box{
        width: 900px;
    }
}
</style>
