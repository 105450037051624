<template>
    <div>
        <Head></Head>
        <div class="menu-list">
            <div class="content">
                <ul class="menu">
                    <li class="menu-item" :class="{active:active==item.navCode}" v-for="(item, index) in menuList"><a href="javascript:void(0);" @click="gotoUrl(item)">{{item.navName}}</a></li>

                    <!--
                        <li class="menu-item" :class="{active:active==0}"><a href="javascript:void(0);" @click="gotoIndex">首页</a></li>
                        <li class="menu-item" :class="{active:active==1}"><a href="javascript:void(0);" @click="gotoTechRes">科技资源</a></li>
                        <li class="menu-item" :class="{active:active==2}"><a href="javascript:void(0);" @click="gotoTech">科技服务</a></li>
                        <li class="menu-item" :class="{active:active==8}"><a href="javascript:void(0);" @click="gotoScienceData">科学数据</a></li>
                        <li class="menu-item" :class="{active:active==3}"><a href="javascript:void(0);" @click="gotoPlatform">实验室与平台基地</a></li>
                        <li class="menu-item" :class="{active:active==4}"><a href="javascript:void(0);" @click="gotoIndustry">特色专区</a></li>

                        <li class="menu-item" :class="{active:active==5}"><a href="javascript:void(0);" @click="gotoContest">竞赛专区</a></li>

                        <li class="menu-item" :class="{active:active==6}"><a href="javascript:void(0);" @click="gotoAbout">关于我们</a></li>
                      <li class="menu-item" :class="{active:active==7}"><a href="javascript:void(0);" @click="gotoXuqiuzixun">需求与咨询</a></li>
                  -->
                </ul>
            </div>
        </div>
    </div>

</template>

<script>
import Head from "./Head";
import Footer from "./Footer";

export default {
    components: {
        Head
    },
    data(){
        return {
            active: -1, //默认全部不高亮
            menuList: [],
        }
    },
    watch:{
        $route:{
            handler:function(val, oldVal){
                // console.log('hander', val);
                this.updateActive(val);
            },
            deep: true
        }
    },
    mounted(){
        this.updateActive(this.$route)

        this.loadData();
    },
    methods:{
        loadData(){    //加载专项证明
              this.$http.post('/core/cms/a/webNavigate/treelistauth', {})
              .then(res=>{
                  if(res.data.state == 0){
                      // console.log('menu', res.data.result);
                    
                      for(let item of res.data.result){
                        if (item.parentTreeCode == '-1') {
                            //仅一级菜单
                            this.menuList.push(item);
                        }
                      }
                  }
              }).catch(err=>{
              })
        },
        gotoUrl(item){    //跳转链接
            if (item.linkUrl) {
                // console.log('这里', item.linkUrl);
                if (item.linkUrl && item.linkUrl.indexOf("_blank:") == 0) {
                    //新开窗口
                    let url = item.linkUrl.substring("_blank:".length);
                    window.open(url);
                    return;
                } else if (item.linkUrl && item.linkUrl.indexOf("_dialog:") == 0) {
                    let dialogId = 'dialog' + Math.floor(Math.random() * 1000000)
                    let url = item.linkUrl.substring("_dialog:".length);
                    window.openDialog(dialogId, '', url, {}, '95%', window.innerHeight - 80, function (callbackData) {
                        //回调
                    });

                } else {
                    // router 无法把 query 参数放到链接中，所以需要转换
                    let urlArr = item.linkUrl.split('?');
                    let queryJSON = {};
                    let paramsArr = [];
                    if(urlArr[1] && urlArr[1] != ''){
                      paramsArr = urlArr[1].split('&');
                      
                      paramsArr.forEach(param => {
                          const [key, value] = param.split('=');
                          queryJSON[key] = decodeURIComponent(value);
                      });
                    }

                    queryJSON['navCode'] = item.navCode;    //导航栏编码

                    //跳转
                    this.$router.push({ path: urlArr[0], query: queryJSON })
                }
            }
        },
        updateActive:function(val){
            this.active = val.query.navCode;
            // console.log('this.active', this.active);
            if(val.name == 'Index'){
                this.active = 'HOME';
            // }else if(val.name == 'TechRes'){
            //     this.active = 'TECH_RES';
            // }else if(val.name == 'Tech'){
            //     this.active = 'TECH';
            // }else if(val.name == 'science'){
            //     this.active = 'SCIENCE';
            // }else if(val.name == 'SpecialIndustry' || val.name == 'Industry' || val.name == 'IndustryDetail'){
            //     this.active = 'SPECIAL';
            // }else if(val.name == 'Platform'){
            //     this.active = 'PLATFORM';
            // }else if(val.name == 'Contest'){
            //     this.active = 'CONTEST';
            // }else if(val.name == 'Training'){
            //     this.active = 4;
            // }else if(['/open','/introduce', '/statistics', '/report', '/dataDownload', '/dataShare'].indexOf(val.path) >= 0){
            //     this.active = 'ABOUT';
            }else if(val.path.indexOf('/about') >= 0){
                this.active = 'ABOUT';
            }else if(val.name == ''){
                //TODO: 其他变化
            }
        },
        // change:function(index){
        //     this.active = index;
        // },
        // gotoIndex(){
        //     this.reloadHead();
        //     this.change(0);
        //     this.$router.push({name: 'Index', path: '/index'});
        // },
        // gotoTechRes(){
        //     this.reloadHead();
        //     this.change(1);
        //     this.$router.push({name: 'TechRes', path: '/techRes'})
        // },
        // gotoTech(){
        //     this.reloadHead();
        //     this.change(2);
        //     this.$router.push({name: 'Tech', path: '/tech', query: {index: 0}});
        // },
        // gotoScienceData(){
        //     this.reloadHead();
        //     this.change(8);
        //     this.$router.push({name: 'science', path: '/science', query: {index: 0}});
        // },
        // gotoPlatform(){
        //     this.reloadHead();
        //     this.change(3);
        //     this.$router.push({name: 'Platform', path: '/platform', query: {index: 0}});
        // },
        // gotoContest(){
        //     this.reloadHead();
        //     this.change(5);
        //     this.$router.push({name: 'Contest', path: '/contest'})
        // },
        //  gotoIndustry(){
        //      this.reloadHead();
        //     this.change(4);
        //     this.$router.push({name: 'SpecialIndustry', path: '/specialIndustry'})
        // },

        // gotoTraining(){
        //     this.reloadHead();
        //     this.change(4);
        //     this.$router.push({name: 'Training', path: '/training'})

        // },
        // gotoOpen(){
        //     this.reloadHead();
        //     this.change(5);
        //     this.$router.push({name: 'Open', path: '/open'})
        // },
        // gotoAbout(){
        //     this.reloadHead();
        //     this.change(6);
        //     this.$router.push({name: 'About', path: '/about'});
        // },
        // gotoXuqiuzixun(){
        //   this.reloadHead();
        //   this.change(7);
        //   this.$router.push({name: 'Xuqiuzixun', path: '/xuqiuzixun'})
        // },
        
        // gotoBuilding(index){
        //     this.reloadHead();
        //     this.change(index);
        //     this.$router.push({name: 'Building', path: '/building'})
        // },
        // reloadHead(){
        //     this.$store.commit('delSearch');
        // }
        
    }
}
</script>

<style scoped>
.menu-list{
    background: #007EEC;
    box-shadow: 0 2px 4px 0 rgba(221,221,221,0.50);
}
    .menu{
        list-style: none;
        display: flex;
        justify-content: space-between;
        margin: 24px 0 0 0;
        
    }
    .menu .active{
        border-bottom: 3px solid #fff;
        
    }
    .menu-item{
        padding: 14px 10px;
    }
    .menu-item a{
        font-family: SourceHanSansCN-Medium;
        font-size: 18px;
        color: #fff;
        letter-spacing: 0;
        text-align: justify;
        text-decoration: none;
       
        
    }
    .menu-item.active a{
        color: #fff;
    }
    .menu-item a:hover{
        color: #ccc;
    }
    
</style>
