<template>
    <div class="about-container content">
        <div class="about-box">
            <h3 class="about-title">省共享平台介绍</h3>
            <div class="about-content">

<!--                <div class="intro-img">-->
<!--                    <img src="@static/img/about-entry1.png"/>-->
<!--                </div>-->
                <div class="intro-video">
                    <div class="video"  >
                        <img src="@static/img/play.svg" v-if="!isPlaying" @click="playClick"/>
<!--                        <video :src="videoUrl" ref="video" :style="{filter: !isPlaying?'brightness(0.8)':''}"/>-->
                      <video src="@static/video/publicize_video.mp4" ref="video" :style="{filter: !isPlaying?'brightness(0.8)':''}"/>
                    </div>
                </div>
                <div class="intro">
                    <div class="intro-content">
                        广东省科技资源共享服务平台（省共享平台）集共享资讯、资源整合、开放共享、服务应用、数据管理于一体的面全省科技资源的综合性门户网站，有效汇聚了科研仪器、科学数据、生物种质与实验材料、平台基地等多类科技资源，是我省科技工作者手边工具与企业创新活动的源泉，改善了我省科技创新环境，为我省各类科技创新活动提供了强有力的基础条件支撑。
                        <img src="@static/img/quito2.png" class="quito1"/>
                        <img src="@static/img/quito2.png" class="quito2"/>
                    </div>
                       
                    
                    
                </div>
            </div>
        </div>



        <div class="entry-box">
            <div class="entry-title">首页</div>
            <div class="entry-list">
                <router-link class="entry-item link-text" :to="{name:'TechRes', path:'/techRes'}">科技资源</router-link>
                <router-link class="entry-item link-text" :to="{name:'', path:''}">科技服务</router-link>
<!--                <router-link class="entry-item link-text" :to="{name:'Industry', path:'/industry'}">产业专区</router-link>-->
                <router-link class="entry-item link-text" :to="{name:'SpecialIndustry', path:'/specialIndustry'}">特色专区</router-link>
                <router-link class="entry-item link-text" :to="{name:'Contest', path:'/contest'}">竞赛专区</router-link>
<!--                <router-link class="entry-item link-text" :to="{name:'Open', path:'/open'}">开放服务</router-link>-->
<!--                <router-link class="entry-item link-text" :to="{name:'Training', path:'/training'}">科普与培训</router-link>-->
                <router-link class="entry-item link-text" :to="{name:'About', path:'/about'}">关于我们</router-link>
            </div>
        </div>

        <div class="entry-box">
            <div class="entry-title">科技资源</div>
            <div class="entry-list">
                <router-link class="entry-item link-text" :to="{name:'TechRes', path:'/techRes'}">科研仪器</router-link>
                <router-link class="entry-item link-text" :to="{name:'TechRes', path:'/techRes'}">科学数据</router-link>
                <router-link class="entry-item link-text" :to="{name:'TechRes', path:'/techRes'}">生物种质与实验材料</router-link>
                <router-link class="entry-item link-text" :to="{name:'TechRes', path:'/techRes'}">科研成果</router-link>
                <router-link class="entry-item link-text" :to="{name:'TechRes', path:'/techRes'}">实验室与平台基地</router-link>
            </div>
        </div>
        
        <div class="entry-box">
            <div class="entry-title">科技服务</div>
            <div class="entry-list">
                <router-link class="entry-item link-text" :to="{name:'Tech', path:'/tech', query:{index:0}}">数据汇交</router-link>
                <router-link class="entry-item link-text" :to="{name:'Tech', path:'/tech', query:{index:1}}">仪器信息报送</router-link>
                <router-link class="entry-item link-text" :to="{name:'Tech', path:'/tech', query:{index:2}}">科学仪器查重</router-link>
                <router-link class="entry-item link-text" :to="{name:'Tech', path:'/tech', query:{index:3}}">创新平台评价</router-link>
                <router-link class="entry-item link-text" :to="{name:'Tech', path:'/tech', query:{index:4}}">科技专题服务</router-link>
            </div>
        </div>

        
     

        
        <div class="entry-box"  v-loading="loading">
            <div class="entry-title">特色专区</div>

            <div class="entry-list">
                <router-link class="entry-item link-text" :to="{name:'Industry', path:'/industry'}">产业专区</router-link>
                <router-link class="entry-item link-text" :to="{name:'Industry', path:'/industry'}">港澳专区</router-link>
                <router-link class="entry-item link-text" :to="{name:'Industry', path:'/industry'}">省实验室专区</router-link>
                <router-link class="entry-item link-text" :to="{name:'Industry', path:'/industry'}">X专区</router-link>
            </div>

        </div>

        
<!--        <div class="entry-box">-->
<!--            <div class="entry-title">科普与培训</div>-->
<!--            <div class="entry-list">-->
<!--                <router-link class="entry-item link-text" :to="{name:'NewsListPage', path:'/newsListPage', query:{id:'1535176027454586882', name:'竞赛培训', type:'training'}}">竞赛培训</router-link>-->
<!--                <router-link class="entry-item link-text" :to="{name:'NewsListPage', path:'/newsListPage', query:{id:'1535190432493903873', name:'科普作品', type:'training'}}">科普作品 </router-link>-->
<!--                <router-link class="entry-item link-text" :to="{name:'NewsListPage', path:'/newsListPage', query:{id:'1535170920948187138', name:'科普资讯', type:'training'}}">科普资讯</router-link>-->
<!--            </div>-->
<!--        </div>-->

<!--        -->
<!--        <div class="entry-box">-->
<!--            <div class="entry-title">开放服务 </div>-->
<!--            <div class="entry-list">-->
<!--                <router-link class="entry-item link-text" :to="{name:'Introduce', path:'/introduce'}">科技平台简介</router-link>-->
<!--                <router-link class="entry-item link-text" :to="{name:'Statistics', path:'/statistics'}">调查统计数据</router-link>-->
<!--                <router-link class="entry-item link-text" :to="{name:'Report', path:'/report'}">专题研究报告</router-link>-->
<!--                <router-link class="entry-item link-text" :to="{name:'PlatformStatus', path:'/platformStatus'}">平台状态指标</router-link>-->
<!--                <router-link class="entry-item link-text" :to="{name:'DataDownload', path:'/dataDownload'}">数据定制下载</router-link>-->
<!--                <router-link class="entry-item link-text" :to="{name:'DataShare', path:'/dataShare'}">数据共享合作</router-link>-->
<!--            </div>-->
<!--        </div>-->

        
        <div class="entry-box">
            <div class="entry-title">关于我们</div>
            <div class="entry-list">
                <router-link class="entry-item link-text" :to="{name:'Entry', path:'/about/entry'}">网站地图</router-link>
                <router-link class="entry-item link-text" :to="{name:'History', path:'/about/history'}">发展历程</router-link>
                <router-link class="entry-item link-text" :to="{name:'Manage', path:'/about/manage'}">管理规范</router-link>
                <router-link class="entry-item link-text" :to="{name:'Dependent', path:'/about/dependent'}">依托单位</router-link>
<!--                <router-link class="entry-item link-text" :to="{name:'Entry', path:'/about/entry'}">走进省共享平台</router-link>-->

<!--                <router-link class="entry-item link-text" :to="{name:'Data', path:'/about/data'}">数据汇交</router-link>-->
                <router-link class="entry-item link-text" :to="{name:'Contact', path:'/about/contact'}">联系我们</router-link>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue'
export default {
    setup(){
        const loading = ref(false)
        const zoneList = ref([]);
        const {proxy} = getCurrentInstance();
        const videoUrl = ref('http://hongfeng-oss.oss-cn-shenzhen.aliyuncs.com/test//202210/2c8437c374abb5dccd038934419413d2.mp4?Expires=1982135918&OSSAccessKeyId=LTAI4GJJCUJq1tXLdDjTm3mW&Signature=PbnsLPUl5Gr4kIW5%2Bbl7FucEdgc%3D');
        const isPlaying = ref(false)
        const playingTimes = ref(1)

        function playClick(){
            addGdstiVideoPlayVolume();
            if(isPlaying.value){
                proxy.$refs.video.pause();
                document.getElementsByTagName('video')[0].controls=false
            }else{
                proxy.$refs.video.play();
                document.getElementsByTagName('video')[0].controls=true
            }

            proxy.$refs.video.onpause = function(){
                console.log("暂停")
                isPlaying.value = false;
            }
            proxy.$refs.video.onplay = function(){
                console.log("播放")
                isPlaying.value = true;
            }
            // isPlaying.value = !isPlaying.value;
            playingTimes.value = playingTimes.value+1

        }

        function addGdstiVideoPlayVolume(){
            //添加视频播放量
            if(playingTimes.value <= 1){
                //只有第一次点击播放才算
                const url = '/gdsti2/web/a/web/addGdstiVideoPlayVolume';

                proxy.$http.post(url).then(res=>{

                }).catch(err=>{

                })
            }

        }
        onMounted(()=>{

        })

        return {zoneList, loading,videoUrl,playClick,isPlaying,addGdstiVideoPlayVolume}
    }
}
</script>

<style lang="less" scoped>
@import url('../../../public/static/css/about.less');
.about-content{
    margin-bottom: 65px;
    position: relative;
    padding-bottom: 100px;
    .intro-img{
        position: absolute;
        right: 0;
        top: 45px;
    }
    .intro-video{
        height: 388px;
        width: 690px;
    }
    .intro{
        padding: 75px 60px;
        width: 400px;
        height: 235px;
        background-image: linear-gradient(132deg, #6CA9FF 0%, #007EEC 99%);
        box-shadow: 0 2px 15px 0 rgba(103,183,255,0.20);
        /*position: relative; */

        position: absolute;
        right: 0;
        bottom: 0;

        .intro-content{
            font-family: SourceHanSansCN-Medium;
            font-size: 18px;
            color: #FFFFFF;
            letter-spacing: 0;
            text-align: justify;
            line-height: 22px;
            padding-top: 20px;
        }
        .quito1{
            transform: rotate(180deg);
            position: absolute;
            left: 30px;
            top: 30px;
        }
        .quito2{
            position: absolute;
            right: 30px;
            bottom: 30px;
        }
    }
}
.entry-box{
    .entry-title{
        font-family: SourceHanSansCN-Medium;
        font-size: 24px;
        line-height: 48px;
        width: 100%;
        color: #007EEC;
        border-bottom: 2px solid #007EEC;
        padding-left: 2px;
    }
    .entry-list{
        margin-top: 12px;
        margin-bottom: 30px;
        .entry-item{
            font-family: SourceHanSansCN-Medium;
            font-size: 20px;
            display: inline-block;
            width: 200px;
            color: rgba(0,0,0,0.50);
        }
        .entry-item:hover{
            color: #000;
        }
    }
}
.video{
    video{
        height: 100%;
        width: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index:1;
    }
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background: rgba(0,0,0,0.25);

    img{
        cursor: pointer;
        z-index: 2;
        filter: drop-shadow(1px 1px 10px white);
    }
}
</style>
