<template>
    <div class="banner" :style="{width: width+'px', height: height+'px'}">

        <el-carousel ref="carousel" :height="height+'px'" :style="{width: width+'px', height: height+'px'}">

            
                <el-carousel-item  v-for="(item, index) in imageList" :key="item.src" :style="{width: width+'px', height: height+'px'}">
                    <img v-lazy="item.src" :style="{width: width+'px', height: height+'px'}" @click="openDetail(index)" :class="{pointer: item.url&&item.url.length > 0}"/>
                </el-carousel-item>
  
        </el-carousel>
    </div>
</template>

<script>
import {ElCarousel, ElCarouselItem} from 'element-plus'

export default {
    data(){
        return {
        }
    },
    components:{ElCarousel, ElCarouselItem},
    props:{
        height: {
            type: Number,
            default:300
        },
        width: {
            type: Number,
            default:1200
        },
        imageList:{
            type:Array,
            default: []
        }
    },
    setup(){
        
    },
    watch:{
        imageList(newVal, oldVal){
            if(newVal.length > 0){
                //重新加载数据时，第一张图会隐藏显示。因此需要操作一下
                const that = this;
                setTimeout(function(){
                    that.$refs.carousel.next();
                }, 100);
            }
        }
    },
    methods:{
        openDetail(index){
            let url = this.imageList[index].url;
            if(url){
                window.open(url);
            }
        }
    }
}
</script>

<style scoped>
.banner{
    display: inline-block;
    width: 100%;
}
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 14px;
}
.dot {
  animation: dot 2s infinite steps(3, start);
  overflow: hidden;
}
.pointer{
    cursor: pointer;
}
</style>
