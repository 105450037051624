<template>


    <div class="banner">
        <div class="banner-container">
                <div class="banner-left">
                  <div class="banner-title">广东省科学数据服务管理中心</div>
                  <div class="banner-sub-title">推动数据汇交  促进数据共享使用</div>
              </div>
              <div class="banner-rigth">
                    <div>
                        <img style="width:381px;height:306px; margin-top: 22px;" src="/static/img/science/banner-icon.png" />
                    </div>
              </div>
          </div>
    </div>

    <div class="content">
        <div class="data-count">
        	<div class="count-item" v-for="(item, index) in dataCountList"
                                :key="item.id">
                <div class="item-border" v-if="index != 0"></div>
                <div class="count-item-title">{{item.countNum}}</div>
                <div class="count-item-sub-title">{{item.catalogName}}</div>
            </div>

        </div>

        <div class="content-container">
        	<div class="left-container">
        		<div class="special">
                    <Topic></Topic>
                    <!-- 专题服务 -->
                </div>
        		<div class="help-support">
        			<a href="/newsDetail?id=SCIENCE-HELP-SUPPORT" target="_blank">
                        <img style="width:588px;height:160px;" src="/static/img/science/help-bg.png" />
                    </a>
        		</div>
        	</div>

        	<div class="right-container">
        		<div class="data-exchange">
        			<div class="data-exchange-item1"><a href="/science/dataExchange" target="_blank"><img style="width:302px;height:145px;" src="/static/img/science/data-exchange-item1.png" /></a></div>
        			<div class="data-exchange-item1"><a href="/science/dataCatalog" target="_blank"><img style="width:302px;height:145px;" src="/static/img/science/data-exchange-item2.png" /></a></div>
        		</div>
        		<div class="platform-intro">
        			<a href="/newsDetail?id=SCIENCE-PLATFORM-INTRO" target="_blank">
                        <img style="width:596px;height:386px;" src="/static/img/science/platform-bg.png" />
                    </a>
        		</div>
        	</div>
        </div>

    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue'
import {useRouter, useRoute} from 'vue-router'

import Topic from '@/components/index/Topic'

export default {
    components: {Topic, },
    setup(){
            const {proxy} = getCurrentInstance();

            const loading = ref(false)
            
            const dataCountList = ref([]);   //科学数据统计

            function loadDataCount(){    //科学数据统计

                loading.value = true;
                proxy.$http.post('/gdsti2/web/a/webResource/scienceDataCount').then(res => {
                    loading.value = false;
                    if(res.data.state === 0){
                        dataCountList.value = res.data.result;
                    }
                    
                }).catch(err => {
                    loading.value = false;
                })
            }

            onMounted(()=>{
                loadDataCount();

            })

            return {loading, dataCountList}
    },
    watch:{

    },
    mounted(){

    },
    methods:{

    }
}

</script>

<style lang="less" scoped>
.banner{
    height: 350px;
    background-image: url(/static/img/science/banner-bg.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;

  .banner-container{
    width:1200px;
      display: flex;
      flex-direction: row;
    margin: 0 auto;

      .banner-left{
        width: 60%;
        padding-left: 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .banner-title{
            color: #fff;
          font-family: "黑体","宋体",serif;
          font-size: 46px;
        }
        .banner-sub-title{
            color: #fff;
              font-family: '微软雅黑', "宋体", serif;
              font-size: 20px;
        }
      }
    .banner-rigth{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
  }
}
.data-count{
    height: 1218px;
    height: 172px;
    margin: -50px auto 0 auto;
    background-image: url(/static/img/science/data-count-bg.png);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: cover;

      display: flex;
      flex-direction: row;
      justify-content: center;

      .count-item{
        margin-top: 40px;
        width: 180px;
        text-align: center;

        .count-item-title{
          font-family: '微软雅黑';
          font-size: 50px;
          color:#007EEC;
        }
        .count-item-sub-title{
          font-family: 'Microsoft YaHei';
          font-size: 18px;
        }

        .item-border{
            float: left;
            width: 1px;
            height: 60%;
            border-right: 1px solid #ccc;
            margin-top: 10px;
        }
      }


}
.content-container{
    display: flex;
      flex-direction: row;
      justify-content: center;
}
.left-container{
    width: 598px;
    .special{

    }
    .help-support{
        margin: 32px 0 0 0;
        width: 588px;
        height: 160px;
        background-image: url(/static/img/science/help-bg.png);
    }
}
.right-container{
    width: 598px;
    margin: 0 0 0 4px;
    .data-exchange{
        display: flex;
        flex-direction: row;
        justify-content: center;

        .data-exchange-item1{

        }
        .data-exchange-item2{

        }
    }
    .platform-intro{

    }
}
</style>
