<template>
    <div class="open-head">
        <div class="content">
            <div class="open-title">{{title}}</div>
            <div class="open-desc">{{content}}</div>
        </div>
    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    props: {
        title: {
            type: String,
            default: ''
        },
        content: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="less" scoped>
.open-head{
    height: 150px;
    width: 100%;
    background-image: url('../../../public/static/img/open_head1920.png');
    background-position: center;
    background-size: contain 100%;
    background-color: #0A62CA;
    background-repeat: no-repeat;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .open-title{
        font-family: SourceHanSansCN-Medium;
        font-size: 40px;
        line-height: 40px;
        margin-bottom: 14px;
    }
    .open-desc{
        font-family: SourceHanSansCN-Regular;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 24px;
    }
}
</style>

