<template>
  <div class="user-info" v-loading="loading">
    <div class="collect-head">
      <SearchBar @onSearch="search"></SearchBar>
      <div class="select">
        <span v-if="multipleSelection.length>0">已选：{{ multipleSelection.length }} <el-button type="danger"
                                                                                             class="table-btns"
                                                                                             @click="handleDeleteAll()">删除选中</el-button></span>
        <el-button type="primary" class="table-btns" @click="showRequire()">发起专项汇交证明</el-button>
      </div>
    </div>
    <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55"/>
      <el-table-column type="index" label="序号" width="80"/>
      <el-table-column prop="title" label="标题"/>
      <el-table-column prop="proveCode" label="凭证编号"/>
      <el-table-column prop="createTime" label="发起时间"/>
      <el-table-column prop="status" label="审批结果" :formatter="statusFormatter"/>
      <el-table-column prop="downloadCount" label="下载次数"/>

      <el-table-column label="操作" width="200">
        <template #default="scope">
            <a href="javascript:void(0)" class="primary" @click="handleView(scope.row.id, scope.row.dataIds)">查看</a>
            <a style="margin-left: 50px;" :href="downloadUrl(scope.row.attachId)" class="primary" v-if="scope.row.status == 1" target="_blank">下载</a>

        </template>
      </el-table-column>
    </el-table>
    <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>

    <el-dialog v-model="infoDialog" title="详情">
      <div class="form-group">
        <label class="control-label col-1">申请标题</label>
        <div class="col-8">
          <textarea rows="3" cols="50" readonly v-model="remittanceProve.title" class="form-control"></textarea>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-1">申请理由</label>
        <div class="col-8">
          <textarea rows="3" cols="50" readonly v-model="remittanceProve.reason" class="form-control"></textarea>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-1">备注</label>
        <div class="col-8">
          <textarea rows="3" cols="50" readonly v-model="remittanceProve.remarks" class="form-control"></textarea>
        </div>
      </div>
      <div class="form-group">
        <label class="control-label col-1">汇交清单</label>
        <div class="col-11">
          <el-table :data="infoPager.list" style="width: 100%;margin-left: 10px;">
            <el-table-column type="index" label="序号" width="80"/>
            <el-table-column prop="dataType" label="数据/资源类型"/>
            <el-table-column prop="className" label="所属目录"/>
            <el-table-column prop="cnName" label="中文名称"/>
            <el-table-column prop="enName" label="英文名称"/>
            <el-table-column prop="subjectType" label="学科分类"/>
            <el-table-column prop="innerId" label="仪器所在单位内部编码"/>
          </el-table>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import {ElTable, ElTableColumn, ElButton, ElDialog, ElResult, ElPopover, ElIcon} from 'element-plus';
import {MoreFilled} from '@element-plus/icons-vue'
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';

export default {
  components: {SearchBar, ElTable, ElTableColumn, ElButton, ElIcon, ElDialog, ElResult, ElPopover, MoreFilled, Pager},
  setup() {
    const {proxy} = getCurrentInstance();
    const currentPage = ref(1);
    const loading = ref(false);
    const searchKey = ref('');
    const tablePager = ref({
      total: 1,
      page: 1,
      list: []
    })

    const infoPager = ref({
      total: 1,
      page: 1,
      list: []
    })
    const remittanceProve = ref({});

    const infoDialog = ref(false);

    function loadData() {
      loading.value = true;
      const url = '/gdsti2/resource/c/resRemittanceProve/list';
      proxy.$http.post(url, {limit: 10, offset: (currentPage.value - 1) * 10, title: searchKey.value})
          .then(res => {
            loading.value = false
            if (res.data.state == 0) {
              tablePager.value = res.data.result;
            }
          }).catch(err => {
        loading.value = false
      })
    }
    
    function pageChange(index) {
      currentPage.value = index;
      loadData();
    }

    function search(keyStr) {
      searchKey.value = keyStr;
      pageChange(1);
    }

    const multipleSelection = ref([])

    function handleSelectionChange(selections) {
      multipleSelection.value = selections;
    }


    function indexFormatter(row, column, cellValue, index) {
      return row + 1 + '';
    }

    function handleDelete(index) {
      console.log(index)
    }

    function downloadUrl(attachId) {
      return proxy.$getImage(attachId);
    }

    function handleDownload(id, attachId) {
      // const url = '/hfapplication/gdsti/c/remittanceProve/downloadCount.do';
      // proxy.$http.post(url, {id: id}).then(res => {
      //   if (res.data.state == 0) {
      //     // proxy.ElMessage.success(res.data.message);
      //     loadData();
      //   }
      // }).catch(err => {
      //
      // });
    }

    function downloadFileByAttachId(attachId) {
      const url = '/hf/base/a/fileManage/downloadFile';
      proxy.$http.post(url, {attachCode: attachId}).then(res => {

      }).catch(err => {

      });
    }

    function handleView(id,dataIds) { //凭证信息

      let dialogId = 'handleView' + Math.floor(Math.random()*1000000);
      openDialog(dialogId, "详情", "/gdsti2v/resource/resRemittanceProve/info?disabled=true&id=" + id, "", '95%', '95%', function () {

      });

      // console.log(id);
      // console.log(dataIds);
      // remittanceProve.value = {};
      // infoPager.value = {};

      // infoDialog.value = true;
      // const url = '/hfapplication/gdsti/c/remittanceConfigure/remittanceDataList.do';
      // proxy.$http.post(url, {limit: 50, offset: 0, dataIds: dataIds})
      //     .then(res => {
      //       if (res.data.state == 0) {
      //         infoPager.value = res.data.result;
      //       }
      //     }).catch(err => {
      // });

      // proxy.$http.post('/hfapplication/gdsti/c/remittanceProve/getById.do', {id: id})
      //     .then(res => {
      //       if (res.data.state == 0) {
      //         remittanceProve.value = res.data.result;
      //       }
      //     }).catch(err => {
      // });
    }

    function handleDeleteAll() {

    }


    const isShow = ref(false)

    function showRequire() {
      proxy.$router.push({name: 'ResDataProveSelection', path: 'resDataProveSelection'})
    }

    function statusFormatter(row, column, cellValue, index) {
      if (cellValue == 0) {
        return '待审核';
      } else if (cellValue == 1) {
        return '审核通过';
      } else if (cellValue == 2) {
        return '审核不通过';
      }
    }


    onMounted(() => {
      loadData();
    })

    return {
      tablePager, pageChange, search, currentPage, multipleSelection, indexFormatter, statusFormatter,
      handleDelete, handleDeleteAll, handleSelectionChange, handleDownload, handleView,
      isShow, showRequire, downloadUrl, infoDialog, infoPager,remittanceProve,loading
    }
  }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

</style>

