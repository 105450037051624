<template>

  <div class="">
    <p>通过监测各类科技平台的要素配置、投入产出等重要指标，从科学贡献、技术贡献、经济贡献、社会贡献、创新效率、转化效能等多维度开展平台建设、运行评价，引导科技资源要素的优化配置和共享协同。</p>
    <h4>典型案例</h4>
    <p>1、广东省实验室体系建设“十四五”规划</p>
    <p>2、广东省实验室启动建设期考核评估</p>
    <p>3、海南省重点实验室绩效评估</p>
    <p>4、松山湖材料实验室运营体制机制研究</p>
    <p>5、广州实验室促进相关生物医药产业发展专项</p>
    <p>6、南方海洋科学与工程广东省实验室（湛江）管理运行体系建设</p>
    <p>7、东莞市重大公共科技创新平台绩效考核</p>
    <p>8、东莞市共建类新型研发机构建设绩效考核</p>
    <p>9、顺德中集智谷公共技术服务平台建设情况评估</p>


    <br/>


    <img src="@static/img/dianxing.png"/>
    <br/>

    <!--
    <h4>
    联系我们
    </h4>
    <div>
        版权所有：广东省科技基础条件平台中心<br/>
        单位地址：广东省广州市越秀区连新路171号3号楼<br/>
        联系电话：020-83561811<br/>
        传       真：020-83549360<br/>
        邮       编：510033
    </div>
    -->
  </div>
</template>

<script>
export default {
    
}
</script>

<style lang="less" scoped>


</style>

