<template>
    <div>
        <open-head title="数据定制下载" content="调查统计数据、状态指标分析、研究报告等内容数据提供协议下载服务，也诚邀您加入我们开展战略合作。"></open-head>
        <div class="content">
            <Breads :breads="breads"></Breads>
            <div class="list">
                <div class="about-box">
                    <h3 class="about-title">数据有限使用许可</h3>
                    <p class="about-content">
                        <br/>
                        NCSES 提供了限制使用微数据许可证，用于以下用途:<br/>
                        · 早期职业博士学位调查(ECDS)<br/>
                        · 全国大学毕业生调查(NSCG)<br/>
                        · 近期大学毕业生全国调查 (NSRCG)<br/>
                        · 科学家和工程师统计数据系统 (SESTAT) 集成文件<br/>
                        · 博士生调查 (SDR)<br/>
                        · 获得博士学位(SED)/博士学位记录文件(DRF)的调查<br/>
                    <br/>
                    机构或组织内的个人可以使用以下说明通过 NCSES 限制使用数据请求门户请求访问这些 微数据。此外，联邦统计研究数据中心 (FSRDC)提供了一些 NCSES 受限使用微数据。 FSRDC 还存储来自其他机构的微数据;可以在此处探索 FSRDC 中可用的限制使用微数据。
                    </p>
                </div>
                <div class="about-box">
                    <h3 class="about-title">数据协议介绍</h3>
                    <p class="about-content">
                        <br/>
                        创建账户并提交项目申请。申请应包括数据要求、研究计划和所需的特定受限数据使 用变量的列表。数据要求和研究计划表可在此处参考:数据要求和研究计划 (37 KB）。最新的可用调查周期的数据字典也可供参考:NCSES SDAF 数据字典 (1.2 MB)。
                        <br/><br/>
                        一旦项目申请中的研究计划和数据要求获得批准，申请人可以从在线门户下载并完成 完整的许可申请。为了使许可申请被认为是完整的，研究人员必须 (1) 填写适当的申请 表，(2) 获得大学高级官员的签名，该官员有权与 NSF 签订法律协议，并获得能够证明 机构系统安全性的系统安全官，(3) 完成 NCSES 安全培训，以及 (4) 执行保密宣誓书。
                        <br/><br/>
                        许可证申请完成后，申请人应通过在线门户提交。获得批准后，NCSES 将提供有关通 过我们的安全数据访问工具访问受限使用数据的说明。
                    </p>
                </div>
                <div class="about-box">
                    <h3 class="about-title">联系我们获得更多信息</h3>
                    <p class="about-way">
                        单       位：广东省科技基础条件平台中心<br/>
                        单位地址：广州市连新路171号科技信息大楼6楼<br/>
                        联系电话：020-83561811<br/>
                        传       真：020-83549360<br/>
                        邮       编：510033
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue';
import Breads from '../../components/common/Bread';
import OpenHead from '../../components/open/OpenHead'

export default {
    components:{Breads, OpenHead},
    setup(){
        const breads = ref([{
            text:'开放服务',
            name: 'Open',
            path: '/open'
        },{
            text: '数据定制下载'
        }]);

        return {breads}
    }

}
</script>

<style lang="less" scoped>
@import url('../../../public/static/css/about.less');
.list{
    padding-bottom: 44px;
}
</style>