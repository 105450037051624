<template>


    <div class="container">
        <div class="result-icon">
            <img style="width:82px;height:82px; margin-top: 22px;" src="/static/img/science/tip-icon-green.png" />
        </div>
        <div class="result-text">
            <span>
                申请单 {{applyCode}} 提交成功。<br />
                请等待管理员审核，请在用户中心查看。
            </span>
        </div>
        <div class="close-btn">
            <el-button type="default" @click="closeDialog('')">关闭</el-button>
        </div>
    </div>


</template>

<script>
import {ref} from 'vue'
import {useRouter, useRoute} from 'vue-router'

import Topic from '@/components/index/Topic'

export default {
    components: {Topic, },
    setup(){
            const applyCode = ref('')

            const route = useRoute();
            if(route.query.applyCode){
                applyCode.value = route.query.applyCode;
            }
            


            return {applyCode, closeDialog}
    },
    watch:{

    },
    mounted(){

    },
    methods:{

    }
}

</script>

<style lang="less" scoped>
.container{
    text-align: center;
    padding-top: 20px;
}
.result-text{
    font-family: Microsoft YaHei;
    font-size: 26px;
    font-weight: normal;
    line-height: 46px;
    text-align: center;
    letter-spacing: 0px;
    /* light/text/--el-text-color-primary */
    color: #303133;
}

</style>
