<template>
    <div class="data content about-container">
        <!--
        <div class="left-box">
            <a class="left-item" :class="{active: active==0}" @click="changeActive(0, 'id0')">基本情况</a>
            <a class="left-item" :class="{active: active==1}" @click="changeActive(1, 'id1')">汇交政策</a>
            <a class="left-item" :class="{active: active==2}" @click="changeActive(2, 'id2')">汇交流程</a>
            <a class="left-item" :class="{active: active==3}" @click="changeActive(3, 'id3')">汇交内容</a>
            <a class="left-item" :class="{active: active==4}" @click="changeActive(4, 'id4')">汇交范围</a>
        </div>
        -->
        <div class="right-box">
            <div class="about-box" id="id0">
                <h3 class="about-title" >基本情况</h3>
                <p class="about-content">
                        人口健康科学数据定义，指人口健康领域，通过基础研究、应用研究、试验开发等产生的原始性观察、观测数据、检查检测数据、监测数据、诊断治疗数据、试验数据、实验数据、调查和考察数据、统计数据按照某种需求系统加工的数据和相关的元数据，以及相关辅助科学数据和工具软件等。
                </p>
            
            </div>
            <div class="about-box" id="id1">
                <h3 class="about-title" >汇交政策</h3>
                <p class="about-content">
                        人口健康科学数据定义，指人口健康领域，通过基础研究、应用研究、试验开发等产生的原始性观察、观测数据、检查检测数据、监测数据、诊断治疗数据、试验数据、实验数据、调查和考察数据、统计数据按照某种需求系统加工的数据和相关的元数据，以及相关辅助科学数据和工具软件等。
                </p>
            
            </div>
            <div class="about-box" id="id2">
                <h3 class="about-title" >汇交流程</h3>
                <p class="about-content">
                        人口健康科学数据定义，指人口健康领域，通过基础研究、应用研究、试验开发等产生的原始性观察、观测数据、检查检测数据、监测数据、诊断治疗数据、试验数据、实验数据、调查和考察数据、统计数据按照某种需求系统加工的数据和相关的元数据，以及相关辅助科学数据和工具软件等。
                </p>
            
            </div>
            <div class="about-box"  id="id3">
                <h3 class="about-title">汇交内容</h3>
                <p class="about-content">
                        人口健康科学数据定义，指人口健康领域，通过基础研究、应用研究、试验开发等产生的原始性观察、观测数据、检查检测数据、监测数据、诊断治疗数据、试验数据、实验数据、调查和考察数据、统计数据按照某种需求系统加工的数据和相关的元数据，以及相关辅助科学数据和工具软件等。
                </p>
            
            </div>
            <div class="about-box" id="id4">
                <h3 class="about-title">汇交范围</h3>
                <p class="about-content">
                        人口健康科学数据定义，指人口健康领域，通过基础研究、应用研究、试验开发等产生的原始性观察、观测数据、检查检测数据、监测数据、诊断治疗数据、试验数据、实验数据、调查和考察数据、统计数据按照某种需求系统加工的数据和相关的元数据，以及相关辅助科学数据和工具软件等。
                </p>
            
            </div>
        </div>
    </div>
</template>

<script>
import {ref} from 'vue'
export default {
    setup(){
        const active = ref(0);

        function changeActive(index, id){
            active.value = index;
            goAnchor(id)
        }

        function goAnchor(id){
            var anchor = document.getElementById(id);
            anchor.scrollIntoView();
        }

        return {active, changeActive, goAnchor}
    },
    methods:{
        
    }
}

</script>

<style lang="less" scoped>

@import url('../../../public/static/css/about.less');
.data{
    display: flex;
    justify-content: space-between;

    .left-box{
        display:flex;
        flex-direction: column;
        align-items: flex-end;
        width: 132px;
        padding-top: 35px;
        margin-right: 21px;

        .left-item{
            cursor: pointer;
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            line-height: 26px;
            border-right: 2px solid #F2F2F2;
            padding-right: 7px;
        }
        .left-item.active{
            
            color: #007EEC;
            border-right: 2px solid #007EEC;
        }
    }

    .right-box{
        flex:1;
        padding-top: 12px;
    }
}
</style>
