<template>
    <div class="content">
        <Bread :breads="breads"></Bread>
                <div class="news-content">
                    <div class="query-box form-inline">

                        <div class="form-group">
                            <label for="key" class="control-label">关键词</label>
                            <input type="text" id="key" class="form-control" placeholder="请输入" v-model="title"/>
                        </div>
                        <button type="button" class="btn btn-primary query-btn">查询</button>
                        
                    </div>
                    <table class="table table-bordered" v-loading="loading">
                        <thead>
                            <tr>
                                <td>标题</td>
                                <td width="162px">时间</td>
                            </tr>
                        </thead>
                        <tbody v-if="newsItem.content">
                            <tr v-for="item in newsItem.content.list"  :key="item.id">
                                <td><a href="javascript:void(0);" @click="gotoPlatformDetail(item.id)">{{item.adTitle}}</a></td>
                                <td>{{item.adDateForMat}}</td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="center" v-if="newsItem.content.total == 0">暂时没有内容</div>
                    <Pager class="i-pager" @pageChange="pageChange" :currentPage="currentPage" :totalPage="totalPage" ></Pager>
                    
                    
                    
                </div>
         
    </div>
</template>

<script>
import Bread from '../../../components/common/Bread.vue'
import Pager from '../../../components/common/Pager.vue'
import VueDatepickerUi from 'vue-datepicker-ui'
import 'vue-datepicker-ui/lib/vuedatepickerui.css'
import {ref, onMounted, getCurrentInstance} from 'vue'
import {useRoute} from 'vue-router'
export default {
    components:{Bread, Pager, Datepicker: VueDatepickerUi},
    setup(){
        const {proxy} = getCurrentInstance();
        const loading = ref(false);
        const route = useRoute();
        const date = ref();
        const id = ref('');
        const active = ref(0);
        const name = ref('');
        const title = ref('');
        

        const type = ref(route.query.type)
        const breads = ref([{text:'首页', path: '/index', name: 'Index'}]);
        breads.value = [{text: '产业专区', path: '/industry', name: 'Industry'}];
        const industryZoneId = ref(route.query.zoneId)
  
        if(route.query.id){
            id.value = route.query.id;
            name.value = route.query.name;
        }
        breads.value.push({text: name.value});
       

        const newsItem = ref({content:{total: 1}});
        const currentPage = ref(1)

        function getPlatformList(){
            const url = '/hfapplication/gdsti/a/adInfo/moreList';
            loading.value = true;
            proxy.$http.post(url, {limit: 10, offset: (currentPage.value - 1) *10, industryZoneId: industryZoneId}).then(res => {
                loading.value = false;
                if(res.data.state == 0){
                    newsItem.value.content = res.data.result;
                }
            }).catch(err=>{
                loading.value = false;
            })
        }




        onMounted(()=>{
            getPlatformList();
        });

        return {loading,  date, active, breads, getPlatformList, newsItem, currentPage, type, title};
    },
    computed:{
        totalPage(){
            if(this.newsItem.content.length > 0){
                return this.newsItem.content.page;
            }else{
                return 1;
            }
        }
    },
    methods:{
        gotoPlatformDetail(id){
            this.$router.push({name:'IndustryPlatformDetail', path: '/industryPlatformDetail', query: {id: id, type: this.type, zoneId:this.industryZoneId}});
        },
        pageChange(page){
            this.currentPage = page;
            this.getPlatformList();
        }
    }
}
</script>

<style lang="less" scoped>
.i-pager{
    margin-top: 38px;
    margin-bottom: 27px;
}
.table td{
    font-family: SourceHanSansCN-Regular;
    font-size: 16px;
    color: rgba(0,0,0,0.85);
}
.query-box{
    margin-bottom: 16px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.center{
    margin-top:24px;
}
.query-btn{
    margin-left: 60px;
}
#category{
    width: 100px;
}
#key{
    width: 171px;
}
#date{
    display: inline-block;
}

</style>

