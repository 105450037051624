<template>
    <div class="content top-news">
        <div class="left-menu">
            <div class="menu-button" :class="{active:active==0}" @click="changeActive(0)">大赛介绍</div>
            <div class="menu-button" :class="{active:active==1}" @click="changeActive(1)">赛题资源</div>
            <div class="menu-button" :class="{active:active==2}" @click="changeActive(2)">报名参赛</div>
            <div class="menu-button" :class="{active:active==3}" @click="changeActive(3)">联系我们</div>
        </div>
        <div class=right-desc v-if="active==0">
            <div v-html="baseInfo.dasaijieshao"></div>
<!--            <div class="contest-list">-->
<!--                <div class="contest-item" @click="changeItem(0)">-->
<!--                    <div class="item-btn">-->
<!--                        <img src="@static/img/contest_icon1.svg" />-->
<!--                        <div class="circle" v-if="iconActive==0"/>-->
<!--                    </div>-->
<!--                    <div class="item-name">-->
<!--                        论文报告类-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="contest-item" @click="changeItem(1)">-->
<!--                    <div class="item-btn">-->
<!--                        <img src="@static/img/contest_icon2.svg" />-->
<!--                        <div class="circle" v-if="iconActive==1"/>-->
<!--                    </div>-->
<!--                    <div class="item-name">-->
<!--                        应用软件类-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="contest-item" @click="changeItem(2)">-->
<!--                    <div class="item-btn">-->
<!--                        <img src="@static/img/contest_icon3.svg"/>-->
<!--                        <div class="circle" v-if="iconActive==2"/>-->
<!--                    </div>-->
<!--                    <div class="item-name">-->
<!--                        智能硬件类-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="contest-item" @click="changeItem(3)">-->
<!--                    <div class="item-btn">-->
<!--                        <img src="@static/img/contest_icon4.svg"/>-->
<!--                        <div class="circle" v-if="iconActive==3"/>-->
<!--                    </div>-->
<!--                    <div class="item-name">-->
<!--                        多媒体设计类-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="contest-item" @click="changeItem(4)">-->
<!--                    <div class="item-btn">-->
<!--                        -->
<!--                        <img src="@static/img/contest_icon5.svg"/>-->
<!--                        <div class="circle" v-if="iconActive==4"/>-->
<!--                    </div>-->
<!--                    <div class="item-name">-->
<!--                        创业计划类-->
<!--                    </div>-->
<!--                    -->
<!--                </div>-->
<!--            </div>-->
<!--            <div class="contest-icon">-->
<!--                <img src="@static/img/icon_up.png" class="thumb" :style="{left: thumbLeft+'px'}"/>-->
<!--            </div>-->
<!--            <div class="contest-content">-->
<!--                <div v-if="iconActive==0" class="content-info">-->
<!--                    <p>论文报告类参赛作品要求研究主题明确、选题新颖；结构严谨、逻辑规范；观点鲜明、论述充分；研究深入、参考文献充分，具有较强创新及研究价值。</p>-->
<!--                    <br/>-->
<!--                    <p>论文报告类作品可为学术论文、研究报告两种形式。参赛作品字数3000-20000字，研究领域不限，作品内容应全面、完整，应包含<br/>①摘要<br/>②关键词<br/>③正文<br/>④参考文献；<br/>同时应在文中明确标注科技资源使用情况。作品提交格式为doc、docx、pdf。</p>-->
<!--                </div>-->
<!--                <div v-if="iconActive==1" class="content-info">-->
<!--                    <p>应用软件类参赛作品要求创新主题明确、选题新颖、功能及性能指标明确、交互设计良好、界面美观、技术运用合理，具有较强创新及应用价值。作品围绕基于科技资源的科学传播、科技创新和实际应用等主题内容开展应用软件设计、开发和集成，具体作品表现手法和表现形式不限。</p>-->
<!--                    <br/>-->
<!--                    <p>参赛作品需提交：<br/>①软件安装包（含源码，应提交可执行的软件安装文件，如exe、apk、msi文件等，如需部署环境配置则须提交部署说明文档）；<br/>②完整的应用软件作品说明文档（不少于1000字，应包含作品简介、创新思路、主要功能、科技资源使用情况说明等）；<br/>③作品PPT或短视频介绍（其中短视频时长3分钟左右、视频文件大小50M左右、统一格式为mp4）；<br/>④其他相关资料。<br/></p>-->
<!--                </div>-->
<!--                <div v-if="iconActive==2" class="content-info">-->
<!--                    <p>智能硬件类参赛作品要求创新主题明确、选题新颖、功能及性能指标明确、技术特征明显、有成型的硬件样品（产品）、提供较好用户体验、智能场景实现良好，具有较强创新及应用价值。作品围绕基于科技资源的科学传播、科技创新和实际应用等主题内容开展智能硬件产品的研究、设计和制造，具体作品表现手法和表现形式不限。</p>-->
<!--                    <br/>-->
<!--                    <p>参赛作品需提交：<br/>①完整智能硬件作品说明文档（不少于1000字，含作品简介、创新思路、主要功能、资源使用情况说明等）；<br/>②智能硬件测试报告（不少于1000字，含测试手段、测试设备、测试数据、结果分析等）；<br/>③作品PPT或作品短视频介绍（其中短视频时长3分钟左右、视频文件大小50M左右、统一格式为mp4）；<br/>④其他相关资料。</p>-->
<!--                </div>-->
<!--                <div v-if="iconActive==3" class="content-info">-->
<!--                    <p>多媒体设计类参赛作品要求设计主题明确、思路清晰；立意新颖、构思独特；设计巧妙、协调美观；技术应用合理、表现力强，具有较强创新及应用价值。</p>-->
<!--                    <br/>-->
<!--                    <p>（1）平面设计。围绕基于科技资源的科学传播和科技创新等主题内容开展平面设计，作品表现手法和表现形式不限，兼具科学性、艺术性及创新性。</p>-->
<!--                    <p>参赛作品需提交：<br/>①系列高精度彩色平面设计效果图（不少于3张，效果图格式为jpg、tiff等图片格式，分辨率不低于300dpi）；<br/>②完整的设计说明文档（PPT介绍或文字介绍材料，文字材料不少于1000字，说明文档含作品简介、设计思路、科技资源使用情况说明等内容）；<br/>③其他相关资料。<br/></p>-->
<!--                    <br/>-->
<!--                    <p>（2）交互设计。围绕基于科技资源的科学思想、科学传播和科技创新等主题内容开展交互设计，作品表现手法和表现形式不限，可以采用多维动态海报、网页UI设计、APP设计、交互实验设计等表现形式，兼具科学性、艺术性及创新性。</p>-->
<!--                    <p>参赛作品需提交：<br/>①系列设计效果图（产品原型图、软件运行文件、html源码等）；<br/>②完整设计说明文档（PPT介绍或文字介绍材料，文字材料不少于1000字，说明文档含作品简介、设计思路、科技资源使用情况说明等内容）；<br/>③作品短视频介绍（时长3分钟左右、视频文件大小50M左右、统一格式为mp4）；<br/>④其他相关资料。</p>-->
<!--                    <br/>-->
<!--                    <p>（3）视频动画。围绕基于科技资源的科学思想、科学传播和科技创新等主题内容开展短视频动画设计制作，作品表现形式可以为科普动画、科普专题片、科普纪录短视频等，兼具科学性、艺术性及创新性。</p>-->
<!--                    <p>参赛作品需提交：<br/>①视频动画作品（时长3-5分钟、分辨率1920*1080、视频画幅为16:9，视频格式为rmvb、mpeg、vob、avi、mp4、flv、wmv、mov等）；<br/>②完整设计说明文档（PPT介绍或文字介绍材料，文字材料不少于1000字，说明文档含作品简介、设计思路、资源使用情况说明等内容）；<br/>③其他相关资料。<br/></p>-->
<!--                </div>-->
<!--                <div v-if="iconActive==4" class="content-info">-->
<!--                    <p>创业计划类参赛作品要求创业方向和项目明确，市场需求、市场竞争及市场效益等可行性研究充分，项目计划全面清晰，具有很强市场前景。</p>-->
<!--                    <br/>-->
<!--                    <p>参赛作品主要围绕基于科技资源的科技创新、科技服务、科技成果转化等方向开展创新创业，形成商业计划书。</p>-->
<!--                    <br/>-->
<!--                    <p>参赛作品需提交：<br/>①商业计划书（字数3000-20000字）以简洁、创新、完整为基本要求，围绕科技创新、成果转化、科技项目等方向，格式规范、研究充分、模式新颖、目标明晰、内容全面（含团队、产品、技术、资金、营销、运行等方面），作品提交格式为doc、docx、pdf；<br/>②作品PPT或短视频介绍（其中短视频时长3分钟左右、视频文件大小50M左右、统一格式为mp4）；<br/>③其他相关资料。<br/></p>-->
<!--                </div>-->
<!--            </div>-->
        </div>
        <div class=right-desc v-if="active==1">
            <div class="search-bar">
                <div class="form-group">
                    <label class="form-label">关键词</label>
                    <input type="text" class="form-control" placeholder="请输入" v-model="keyword"/>
                    <button type="button" class="btn btn-primary" @click="queryClick">查询</button>
                </div>
            </div>
            <table class="res-table" v-loading="loading">
                <thead>
                    <tr>
                        <th>题目名称</th>
                        <th>赛题来源</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in resList.list" :key="item.id">
                        <td><a href="javascript:void(0)" @click="openRes(item.id)">{{item.title}}</a></td>
                        <td>{{item.articleSource}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="center" v-if="resList.list.length == 0" style="margin-bottom: 24px;">暂无检索结果</div>
            <Pager :currentPage="currentPage" @pageChange="pageChange" :totalPage="resList.totalPage"></Pager>
            
        </div>
        <div class=right-desc v-if="active==2">
            <!--跳转第三方-->
        </div>
        <div class=right-desc v-if="active==3">
            <div v-html="baseInfo.lianxiwomen"></div>
           
        </div>
    </div>
</template>

<script>
import Pager from '../common/Pager'
import {ref, onMounted, watch, getCurrentInstance} from 'vue';

import CommonUtil from "@/utils/common";

export default {
    props:{
        baseInfo:{
            type: Object,
            default: {}
        }
    },
    components: {Pager},
    setup(){
        const active = ref(0);
        const iconActive = ref(0)
        
        const resList = ref({
            total: 0,
            page: 1,
            list: []
        })
        const currentPage = ref(1);
        const loading = ref(false)
        const keyword = ref('');

        const {proxy} = getCurrentInstance();

        async function changeActive(index){

            proxy.$emit('activeChange', index);

            if(index == 2){

                let isLogin =  await CommonUtil.isLogin();
                // console.log(isLogin);
            
                if(isLogin){
                    proxy.$router.push({name:'CompetitionList', path: '/competitionList'})
                }else{
                    proxy.ElMessage.error('您未登录，请登录后操作。');
                }
                
            }else{
                active.value = index;
            }
        }


        function getArticleList(typeCode){

            loading.value = true;
            const url = '/core/cms/a/articleContent/list?timeField=publish_time&typeCode=' + typeCode;
            var offset = (currentPage.value-1) * 10;

            proxy.$http.post(url, {title: keyword.value,offset:offset,limit:10}).then(res=>{
                loading.value = false
                if(res.data.state == 0){
                    resList.value = res.data.result;
                }
            }).catch(err=>{
                loading.value = false
            })
        }

        function pageChange(index){
            currentPage.value = index;
            getArticleList('CPT-QUESTION-RESOURCE');
        }

        function queryClick(){
            currentPage.value = 1;
            getArticleList('CPT-QUESTION-RESOURCE');
        }

        function changeItem(index){
            iconActive.value = index;
        }

        const thumbLeft = ref(40);
        watch(iconActive, (newVal, oldVal)=>{
            thumbLeft.value = 100*newVal + 97*newVal + 40;
        })


        onMounted(()=>{

            getArticleList('CPT-QUESTION-RESOURCE');
        })

        return {queryClick, thumbLeft,keyword, loading, currentPage, resList, active, iconActive, changeActive, pageChange, changeItem}
    },
    methods: {
        openRes(id){
            //增加赛题资源查看日志
            const that = this;
            const url = '/gdsti2/web/a/webCompetition/addComArtLog';
            this.$http.post(url, {id: id}).then(res => {

                if (res.data.state == 0) {
                    that.$router.push({name: 'NewsDetail', path: '/newsDetail', query: {id: id, type: 'contest'}})
                }
            }).catch(err => {})


        }
    }
}
</script>

<style scoped  lang="less">
.contest-list{
    margin-top: 24px;
    display: flex;
    justify-content: space-between;

    .contest-item{
        display: flex;
        flex-direction: column;
        align-items: center;
        cursor: pointer;

        .item-name{
            margin-top: 14px;
            font-family: SourceHanSansCN-Bold;
            font-size: 20px;
            color: #007EEC;
        }

        .item-btn{
            position: relative;
        }

        .circle{
            position: absolute;
            left: 5px;
            top: 5px;
            width: 86px;
            height: 86px;
            border-radius: 86px;
            border: 2px dashed #fff;
        }

    }
    
}
.contest-icon{
    position:relative;
    .thumb{
        position:absolute;
        top: 13px;
    }
}
.contest-content{
    margin-top:30px;
    font-family: SourceHanSansCN-Regular;
    font-size: 16px;
    color: rgba(0,0,0,0.85);
    letter-spacing: 0;
    line-height: 20px;
     padding: 15px 11px;
   
    background: #FFFFFF;
    border: 2px solid #007EEC;
    .content-info{
        border: 2px dashed #007EEC;
         padding: 15px 11px;
    }
    
}
.top-news{
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: 62px;
}
.left-menu{
    display: inline-block;
    width: 283px;
}
.right-desc{
    
    display: inline-block;
    width: 890px;
    
    font-size: 14px;
    color: rgba(0,0,0,0.85);
    letter-spacing: 0;
    
    h3,h4{
        margin-top: 12px;
        margin-bottom: 4px;
        font-family: SourceHanSansCN-Bold;
        font-size: 22px;
        color: rgba(0,0,0,0.85);
        margin-bottom: 13px;
    }

    .quest-box{
        margin-bottom: 22px;
    }
        h6{
            font-family: SourceHanSansCN-Medium;
            font-size: 16px;
            color: rgba(0,0,0,0.85);
            margin-bottom: 12px;
        }
        p{
            line-height: 22px;
            margin-top: 4px;
            font-family: SourceHanSansCN-Regular;
            font-size: 14px;
            color: rgba(0,0,0,0.85);
            letter-spacing: 0;
            text-align: justify;
        }
    
    .contact{
        margin-top: 32px;
        h3{
            margin-bottom: 13px;
        }
        p{
            font-family: SourceHanSansCN-Regular;
            font-size: 18px;
            color: #242424;
            line-height: 25px;
        }
    }


    table{
        border-collapse: collapse;
        width: 100%;

        td,th{
            border: 1px solid #000000;
            margin: 0;
            text-align: center;
            height: 28px;
            width: 220px;
            line-height: 20px;
            font-size: 14px;
            color: rgba(0,0,0,0.85);
        }
        th{
            font-weight: bolder;
            line-height: 48px;
        }
    }

    .res-table{
        border: none;
        border-spacing: 0;
        margin-bottom: 38px;
        margin-top: 22px;
        td, th{
            border: none;
            text-align: left;
            padding-left: 17px;

        }

        thead{
            th{
                background: rgba(217,217,217,0.35);
                font-family: SourceHanSansCN-Medium;
                font-size: 16px;
                
            }
        }
        tbody{
            tr{
                border-bottom: 1px dashed #E7E7E7;
                td{
                    font-family: SourceHanSansCN-Regular;
                    font-size: 16px;
                    color: rgba(0,0,0,0.85);
                    line-height: 48px;
                }
            }
        }
    }
}
.menu-button{
    width: 100%;
    height: 48px;
    line-height: 48px;
    text-align: center;
    font-family: SourceHanSansCN-Regular;
    font-size: 18px;
    color: #242424;
    letter-spacing: 0;
    text-align: center;
    background: #F2F2F2;
    margin-bottom: 14px;
    cursor: pointer;
}
.left-menu .active{
    background: #007EEC;
    color: #fff;
}
.menu-button:hover{
    background: #007EEC;
    color: #fff;
}
.right-desc{
    padding-top: 4px;
    font-family: SourceHanSansCN-Regular;
    font-size: 14px;
    color: rgba(0,0,0,0.85);
    letter-spacing: 0;
    line-height: 18px;
    max-width: 890px;

    /deep/ img,div,p{
        max-width: 890px;
    }
}
</style>
