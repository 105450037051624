<template>
    <div class="content">
        <Bread :breads="breads"></Bread>
        <div class="top-box" v-loading="loading">
            <Banner :imageList="industry.imageUrls" :width="383" :height="198"></Banner>
            <div class="main-info">
                <div class="industry-name">{{industry.zoneName}}</div>
                <div class="info-item">
                    <div class="info-label">地址</div>
                    <div class="info-text">{{industry.zoneAddress}}</div>
                </div>
                <div class="info-item">
                    <div class="info-label">园区类型</div>
                    <div class="info-text">{{industry.zoneType}}</div>
                </div>
                <div class="info-item">
                    <div class="info-label">主导产业</div>
                    <div class="info-text">{{industry.mainIndustry}}</div>
                </div>
                <button type="button" class="btn btn-primary industry-btn" @click="gotoAdmin" v-if="industry.isDel==1">
                    <img src="../../../../public/static/img/share_admin.svg" class="btn-icon"/>
                    跳转后台
                </button>
            </div>
            <div class="publish">
                <div class="publish-text">
                    发布需求
                </div>
                <div class="publish-text">
                    获取优质方案
                </div>
                <div class="publish-btn" @click="publish">
                    发布需求
                </div>
            </div>
        </div>


<!-- <img src="@static/img/industry-2.png"/> -->
<!-- <img src="@static/img/industry-3.png"/> -->

<!--        <div class="industry-img" v-html="industry.content">-->
<!--        </div>-->


        <img id="pic1" src="../../../../public/static/img/industry-1.png" @click="gotoDetail()"/>
        <!--        营商环境-->
        <div class="industry-img2">
            <div class="industry-img2-title" style="padding-top: 20px;padding-left: 30px;">
                <h1>营商环境</h1>
                <p>Business Environment</p>
            </div>
            <div class="industry-img2-row">
                <div  class="industry-img2-row-main">
                    <button  class="industry-img2-row-button" @click="gotoXdfa()">行动方案</button>
                    <p class="industry-img2-row-text">2021年6月，省委改革办以1号文件印发《广州民营科技园改革创新行动方案》（粤改办发〔2021〕1号）。</p>
                </div>
                <div  class="industry-img2-row-main">
                    <button  class="industry-img2-row-button" @click="gotoGslzc()">工商联支持</button>
                    <p class="industry-img2-row-text">2021年10月，全国工商联发布《支持广州民科园改革创新发展十条措施》，授予全国首家“全国工商联民营企业科技创新示范基地”，建立四级协调联动支持民科园改革创新工作机制。</p>
                </div>
            </div>
            <div class="industry-img2-row">
                <div  class="industry-img2-row-main">
                    <button  class="industry-img2-row-button" @click="gotoGzzc()">广州支持</button>
                    <p class="industry-img2-row-text">广州市工信局、工商联出台支持广州民营科技园发展的工作方案与措施。</p>
                </div>
                <div  class="industry-img2-row-main">
                    <button  class="industry-img2-row-button" @click="gotoZczc()">政策支持</button>
                    <p class="industry-img2-row-text">白云区出台“1+4+N”政策体系、“民科园创新十条”“政企银保投试点政策”，设立工商银行科技特色支行、广州信易贷“民科园专区”，支持民营企业创新发展。</p>
                </div>
            </div>
            <div class="industry-img2-row">
                <div  class="industry-img2-row-main">
                    <button  class="industry-img2-row-button" @click="gotoTzkr()">提质扩容</button>
                    <p class="industry-img2-row-text">优化形成“一核三园”38平方公里的发展格局，提升工业用地上限容积率至4.0，优化新增建设面积330万平方米。</p>
                </div>
                <div  class="industry-img2-row-main">
                    <button  class="industry-img2-row-button" @click="gotoSzjjian()">数字基建</button>
                    <p class="industry-img2-row-text">建成全国首个化妆品、皮革制品工业互联网标识解析二级节点，建设“1+6+N”智慧园区，获评工信部2022年智慧园区解决方案优秀案例。</p>
                </div>
            </div>
        </div>
        <!--        营商环境end-->

        <!-- 产业集群-->
        <div class="industry-img3">
            <div class="industry-img2-title" style="padding-top: 20px;padding-left: 30px;">
                <h1>产业集聚</h1>
                <p>Industry Cluster</p>
            </div>
            <div class="industry-img3-row">
                <div  class="industry-img3-row-main">
                    <img src="../../../../public/static/img/yqxyqy.png"/>
                </div>
                <div  class="industry-img3-row-main">
                    <img src="../../../../public/static/img/gycz.png"/>
                </div>
            </div>
            <div class="industry-img3-row">
                <div  class="industry-img3-row-main">
                    <img class="industry-img3-row-img" src="../../../../public/static/img/gxjsqy.png" @click="gotoQiye(0)"/>
                </div>
                <div  class="industry-img3-row-main">
                    <img class="industry-img3-row-img" src="../../../../public/static/img/yyqy.png" @click="gotoQiye(1)"/>
                </div>
            </div>
            <div class="industry-img3-row">
                <div  class="industry-img3-row-main">
                    <img class="industry-img3-row-img" src="../../../../public/static/img/gjj.png" @click="gotoQiye(2)"/>
                </div>
                <div  class="industry-img3-row-main">
                    <img class="industry-img3-row-img" src="../../../../public/static/img/sishangqy.png" @click="gotoQiye(3)"/>
                </div>
            </div>
            <div class="industry-img3-row">
                <div  class="industry-img3-row-main">
                    <img class="industry-img3-row-img" src="../../../../public/static/img/ysgzz.png" @click="gotoQiye(4)"/>
                </div>
                <div  class="industry-img3-row-main">
                    <img class="industry-img3-row-img" src="../../../../public/static/img/shangshiqy.png" @click="gotoQiye(5)"/>
                </div>
            </div>
            <div class="industry-img3-row">
                <div  class="industry-img3-row-main">
                    <img class="industry-img3-row-img" src="../../../../public/static/img/kjxzxqy.png" @click="gotoQiye(6)"/>
                </div>
                <div  class="industry-img3-row-main">
                    <img class="industry-img3-row-img" src="../../../../public/static/img/sjys.png" @click="gotoQiye(7)"/>
                </div>
            </div>
        </div>
        <!-- 产业集群end-->

        <div class="tech-platform" v-if="industry.technologicalInnovationTitle || (platformList && platformList.length > 0)">
            <div class="box-title">科技创新</div>
            <div class="box-title-en">Sci-tech Innovation</div>
            <div class="sub-title">科创平台<a v-if="platformHasMore" class="link-more" href="javascript:void(0)"  @click="gotoPlatformList">查看更多 > </a></div>
            <div class="tech-platform-list">
                <div class="list-item" v-if="industry.technologicalInnovationTitle" @click="gotoThird" >
                    <div class="img">
                        <img :src="toImage(industry.technologicalInnovationImg)"/>
                        <div class="text">
                            <div>{{industry.technologicalInnovationTitle}}</div>
                        </div>
                    </div>
                </div>
                <template v-for="item in platformList" :key="item.id">
                    <div class="list-item" @click="gotoPlatformDetail(item.id)">
                        <div class="img">
                            <img v-if="item.adImg" :src="toImage(item.adImg)"/>
                            <div class="text">
                                <div>{{item.adTitle}}</div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="platformList && platformList.length < adInfoPageSize">
                    <div class="list-item" v-for="(item, index) in adInfoPageSize-platformList.length">

                    </div>
                </template>
                <template v-if="platformList && platformList.length > adInfoPageSize">
                    <div class="list-item" v-for="(item, index) in 4-(platformList.length-adInfoPageSize)%4">

                    </div>
                </template>

            </div>
        </div>
        <!-- v-if="industry.resourcesCatalogueList && industry.resourcesCatalogueList.length > 0" -->
        <div class="res" >
            <div class="res-title">创新资源</div>
            <div class="res-box">
                <div class="tab">
                    <div class="tab-item" :class="{'active': active==0}" @click="changeActive(0)">机构资源</div>
                    <div class="tab-item" :class="{'active': active==1}" @click="changeActive(1)">仪器资源</div>
                    <div class="tab-item" :class="{'active': active==2}" @click="changeActive(2)">政策汇总</div>
                </div>
                <div class="tab-content" v-if="active==0">
                    <div class="res-list">
                        <template v-for="(item, index) in platformList" :key="item.id">
                            <div class="res-item"  @click="gotoOrgan(item.id)" >
                                <img :src="toImage(item.img)"/>
                            </div>
                        </template>
                    </div>

                    <div  v-if="platformList && platformList.length ==0" class="center" style="margin-top:24px;">暂无数据</div>
                </div>

                <div class="tab-content" v-if="active==1">
                    <table class="table table-bordered">
                        <thead>
                        <tr>
                            <td>序号</td>
                            <td>资源名称</td>
                            <!--                                <td>资源类型</td>-->
                            <!--                                <td>注册时间</td>-->
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(item, index) in yiqiList" :key="item.id">
                            <tr>
                                <td>{{index+1}}</td>
                                <td><a href="javascript:void(0)" @click="gotoRes(item.id, item.catalogType)">{{item.cname}}</a></td>

                            </tr>
                        </template>

                        </tbody>
                    </table>
                    <div  v-if="yiqiList && yiqiList.length ==0" class="center" style="margin-top:24px;">暂无数据</div>
                    <Pager v-else class="i-pager" @pageChange="pageChange1" :currentPage="currentPage1" :totalPage="totalPage1" ></Pager>
                </div>

                
                <div class="tab-content" v-if="active==2">
                    <table class="table table-bordered" v-if="active==2">
                        <thead>
                        <tr>
                            <td width="100px">序号</td>
                            <td>标题</td>
                            <td width="200px">时间</td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(item, index) in newsList.list" :key="item.id">
                            <td>{{index+1}}</td>
                            <td><a href="javascript:void(0)" @click="gotoNewsDetail(item.id)">{{item.title}}</a></td>
                            <td>{{item.createTime.substring(0,9)}}</td>
                        </tr>
                        </tbody>
                    </table>
                    <Pager class="i-pager" @pageChange="pageChange2" :currentPage="currentPage2" :totalPage="totalPage2" ></Pager>
                </div>
            </div>
        </div>


        <div class="case content" v-if="platformList &&  platformList.length > 0">
            <div class="case-title">
                产业专题
                <div class="pager">
                    <div class="previous" @click="previous()">&lt;</div>
                    <span>1/{{totalPage}}</span>
                    <div class="next" @click="next()">&gt;</div>
                </div>
            </div>

            <el-carousel class="case-content" ref="swiper">
                <el-carousel-item>
                    <div class="case-list">
                        <div class="case-item" @click="gotoBuilding">
                            <div class="case-img">
                                <img src="../../../../public/static/img/case1.png"/>
                            </div>
                            <div class="case-name">
                                产业专题1
                            </div>
                        </div>

                        <div class="case-item" @click="gotoBuilding">
                            <div class="case-img">
                                <img src="../../../../public/static/img/case2.png"/>
                            </div>
                            <div class="case-name">
                                产业专题2
                            </div>
                        </div>

                        <div class="case-item" @click="gotoBuilding">
                            <div class="case-img">
                                <img src="../../../../public/static/img/case1.png"/>
                            </div>
                            <div class="case-name">
                                产业专题3
                            </div>
                        </div>

                        <div class="case-item" @click="gotoBuilding">
                            <div class="case-img">
                                <img src="../../../../public/static/img/case2.png"/>
                            </div>
                            <div class="case-name">
                                产业专题4
                            </div>
                        </div>

                    </div>
                </el-carousel-item>


            </el-carousel>
        </div>

        <el-dialog v-model="showDialog" title="请填写您的需求">
            <div>
                <Form class="form" ref="target" v-slot="{errors}">
                    <div class="form-group">
                        <label class="control-label col-3"><span class="required">*</span>需求名称</label>
                        <div class="col-8">
                            <Field type="text" name="demandName" class="form-control" v-model="form.demandName" :rules="checkRequired" placeholder="请输入需求名称"/>
                            <div class="error" >{{errors.demandName}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-3"><span class="required">*</span>详细描述</label>
                        <div class="col-8">
                            <Field  :rules="checkRequired" name="demandContent"  v-model="form.demandContent">
                                <textarea rows="5"   v-model="form.demandContent"  class="form-control" placeholder="请输入内容"></textarea>
                            </Field>
                            <div class="error" >{{errors.demandContent}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-3"><span class="required">*</span>联系人</label>
                        <div class="col-8">
                            <Field type="text" name="contactsName" class="form-control" v-model="form.contactsName" :rules="checkRequired" placeholder="请输入联系人"/>
                            <div class="error" >{{errors.contactsName}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-3"><span class="required">*</span>联系电话</label>
                        <div class="col-8">
                            <Field type="text" name="contactsNumber" class="form-control" v-model="form.contactsNumber" :rules="checkRequired" placeholder="请输入手机号码"/>
                            <div class="error" >{{errors.contactsNumber}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-3">相关附件</label>
                        <div class="col-8">
                            <el-upload
                                    class="uploader-btn"
                                    :limit="1"
                                    action="/core/base/c/upload/upload"
                                    :data="{relateType: 'industry_detail'}"
                                    :on-success="handleFileSuccess"
                                    :file-list="fileList"
                                    :before-upload="beforeAvatarUpload"
                                    :on-remove="onRemove"
                            >
                                <el-button type="primary">点击上传文件</el-button>
                            </el-upload>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-3">有效期</label>
                        <div class="col-8">
                            <Field type="text" name="termOfValidity" class="form-control" v-model="form.termOfValidity" placeholder="请输入有效期"/>
                            <div class="error" >{{errors.termOfValidity}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-3">地址</label>
                        <div class="col-8">
                            <Field type="text" name="address" class="form-control" v-model="form.address"  placeholder="请输入地址"/>
                            <div class="error" >{{errors.address}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <button type="button" v-loading="submitLoading" class="btn btn-primary next-btn" @click="handleLogin">提交</button>
                    </div>
                </Form>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import { Form, Field } from 'vee-validate'
    import {ElCarousel, ElCarouselItem, ElUpload, ElButton, ElIcon, ElDialog, ElResult} from 'element-plus'
    import { Download, Plus } from '@element-plus/icons-vue'
    import Banner from '../../../components/common/Banner.vue'
    import Bread from '../../../components/common/Bread.vue'
    import Pager from '../../../components/common/Pager.vue'

    import {ref, reactive, onMounted, computed, getCurrentInstance} from 'vue'
    import {useRoute} from 'vue-router'
    import qs from 'qs'


    export default {
        data(){
            return {
                breads:[
                    {
                        text: '特色专区',
                        name: 'SpecialIndustry',
                        path: '/specialIndustry'
                    },
                    {
                        text: '产业专区',
                        name: 'Industry',
                        path: '/industry'
                    },{
                        text: '产业专区详情',
                    }
                ],
                active: 0
            }
        },
        components: {Download, Plus, Banner, Bread, Pager, ElCarousel, ElCarouselItem, ElUpload, ElButton, ElIcon, ElDialog, ElResult, Form, Field},
        setup(){
            const {proxy} = getCurrentInstance();
            const route = useRoute();
            const id = ref(route.query.id);
            const loading = ref(false);
            const industry = ref({})
            const yiqiList = ref({})
            const platformHasMore = ref(false)
            const platformList = ref([]);

            function getIndustry(){ //产业专区信息
                const url = "/gdsti2/web/a/webZone/info?id=" + id.value;

                loading.value = true;
                proxy.$http.post(url).then(res=>{
                    loading.value = false;

                    if(res.data.state == 0){
                        const result = res.data.result;
                        
                        if(result && result.imgs){
                            const imgs = result.imgs.split(',');
                            const imageUrls = [];
                            for(var i = 0 ; i < imgs.length; i++){
                                imageUrls.push({src: proxy.$getImage(imgs[i])});
                            }
                            result.imageUrls = imageUrls;
                        }
                        industry.value = result;
                        proxy.breads[2].text = industry.value.zoneName

                        let limit = 4;
                        if(industry.value.technologicalInnovationTitle){
                            limit = 3;
                        }
                        getPlatformList(limit);
                    }
                }).catch(err=>{
                    loading.value = false
                })
            }

            function getArticleList(){ //获取科创文章(仅取前3篇)
                const url = '/gdsti2/web/a/webZone/articleList';
                loading.value = true;
                proxy.$http.post(url, {zoneId: id.value, limit:3}).then(res=>{
                    loading.value = false
                    if(res.data.state == 0){
                        yiqiList.value = res.data.result.list;
                    };

                }).catch(err=>{
                    loading.value = false
                })
            }


            function getYiqiList(){ //获取相关仪器信息
                const url = '/gdsti2/web/a/webZone/resourceList';
                loading.value = true;
                proxy.$http.post(url, {zoneId: id.value,limit:9999}).then(res=>{
                    loading.value = false
                    if(res.data.state == 0){
                        yiqiList.value = res.data.result.list;
                    };

                }).catch(err=>{
                    loading.value = false
                })
            }

            function getPlatformList(limit){    //获取相关平台信息
                const url = '/gdsti2/web/a/webZone/platformList';
                loading.value = true;
                proxy.$http.post(url, {limit: limit, offset:0, zoneId: id.value}).then(res => {
                    loading.value = false;
                    if(res.data.state == 0){
                        const result = res.data.result;
                        platformList.value = result.list;
                        if(result.total > limit){
                            //有下一页
                            platformHasMore.value = true;
                        }else{
                            platformHasMore.value = false;
                        }
                    }
                }).catch(err=>{
                    loading.value = false;
                })
            }

            const currentPage0 = ref(1), totalPage0 = ref(1), currentPage1 = ref(1), totalPage1 = ref(1), currentPage2 = ref(1), totalPage2 = ref(1);
            const newsList = ref({page:1, total:1, list:[]});

            function getNewsList(){ //获取相关文章信息
                loading2.value = true;
                const url = '/gdsti2/web/a/webZone/articleList';
                proxy.$http.post(url, {zoneId: id.value, limit: 10, offset: (currentPage2.value - 1) *10})
                    .then(res=>{
                        loading2.value = false
                        if(res.data.state == 0){
                            newsList.value = res.data.result;
                            totalPage2.value = res.data.result.page;
                        }
                    }).catch(err=>{
                    loading2.value = false
                });
            }

            function pageChange2(index){
                currentPage2.value = index();
                getNewsList();
            }

            function pageChange0(index){

            }

            function pageChange1(index){

            }

            onMounted(()=>{
                getIndustry();
                getNewsList();
                getYiqiList();

            })

            const loading2 = ref(false);

            const currentCarousel = ref(1);

            const target = ref(null);
            const form = reactive({
                zoneId: id.value,
                demandName: '',
                demandContent: '',
                contactsName: '',
                contactsNumber: '',
                enclosureCode: '',
                termOfValidity: '',
                address: ''
            });

            const checkPhone = value => {
                if(!value) return '请输入手机号码';
                if(!/^1\d{10}/.test(value)) return '请输入11位手机号码';
                return true;
            }

            const checkRequired = value => {
                if(!value){
                    return '请输入内容';
                }else{
                    return true;
                }
            }

            const showDialog = ref(false)
            const fileList = ref([]);
            const fileCodeList = ref([]);
            const fileUrl = ref('');


            const submitLoading = ref(false);
            const handleLogin = () => {

                target.value.validate().then(valid=>{
                    if (valid) {
                        //手动校验
                        if(!form.demandName){
                            // proxy.ElMessage.error('需求名称不能为空');
                            return false;
                        }
                        if(!form.demandContent){
                            // proxy.ElMessage.error('详细描述为空');
                            return false;
                        }
                        if(!form.contactsName){
                            // proxy.ElMessage.error('联系人不能为空');
                            return false;
                        }
                        if(!form.contactsNumber){
                            // proxy.ElMessage.error('联系电话不能为空');
                            return false;
                        }


                        submitLoading.value = true;
                        const url = '/gdsti2/web/a/webZone/demandAdd';

                        // console.log("fileCodeList:",fileCodeList.value);

                        var attachCodeStr = "";
                        for(let i in fileCodeList.value){
                            if(i == 0){
                                attachCodeStr += fileCodeList.value[i];
                            }else{
                                attachCodeStr += ","+fileCodeList.value[i]
                            }
                        }
                        form.enclosureCode = attachCodeStr;
                        proxy.$http.post(url, form).then(res=>{
                            submitLoading.value = false
                            showDialog.value = false
                            if(res.data.state == 0){
                                proxy.ElMessageBox.alert('您的需求已提交，稍后将有工作人员联系您', '提示');
                                form.zoneId = id.value;
                                form.demandName = '';
                                form.demandContent = '';
                                form.contactsName = '';
                                form.contactsNumber = '';
                                form.enclosureCode = '';
                                form.termOfValidity = '';
                                form.address = '';
                            }
                        }).catch(err=>{
                            submitLoading.value = false
                        });
                        // 表单验证通过，调用接口实现登录
                        // 以下为演示代码

                        return true;
                    }else{
                        console.log("校验不通过");
                    }
                }).catch(err=>{
                    return false;
                });

            }

            return {id, form,target, loading, loading2, industry, currentCarousel,
                pageChange0, pageChange1, pageChange2, newsList,
                currentPage0, totalPage0, currentPage1, totalPage1, currentPage2, totalPage2,
                checkRequired, checkPhone, showDialog, fileList,fileCodeList, fileUrl,
                submitLoading, handleLogin, platformHasMore, platformList,yiqiList}
        },
        computed:{
            totalPage(){
                if(this.industry.workPlaceProjectList && this.industry.workPlaceProjectList.length > 0){
                    const length = this.industry.workPlaceProjectList.length;
                    let total = Number.parseInt(length/4);
                    if(length%4 != null){
                        total += 1;
                    }
                    return total;
                }else{
                    return 1;
                }
            },
            adInfoPageSize(){
                if(this.industry.technologicalInnovationTitle){
                    return 3;
                }else{
                    return 4;
                }
            }
        },
        methods:{
            changeCarousel(index){
                this.currentCarousel = index;
            },
            toImage(id){
                if(id){
                    let targetId = id.split(',')[0];
                    return this.$getImage(targetId);
                }else{
                    return '';
                }
            },
            gotoAdmin(){
                //转到后台
                const url = this.indexUrl + "/portal/";
                window.open(url);
            },
            publish(){
                //发布需求
                this.showDialog = true
            },
            gotoNewsDetail(id){
                this.$router.push({name:'NewsDetail', path: '/newsDetail', query: {id: id, type: 'industry'}});
            },
            gotoPlatformDetail(id){
                this.$router.push({name:'IndustryPlatformDetail', path: '/industryPlatformDetail', query: {id: id, type: 'industry', zoneId:this.id}});
            },
            gotoPlatformList(){
                this.$router.push({name:'IndustryPlatformList', path:'/industryPlatformList', query: {id:123,name:'产业专区科创平台',type:'industry',zoneId: this.id}});
            },
            gotoThird(){
                //http://gbatech.pkusp.com/portal/wcm/hebkjcg/hebkjcg/index.html
                window.open(this.industry.technologicalInnovationUrl);
            },
            gotoRes(id, catalogType){   //仪器数据
                this.$router.push({name: 'Instrument', path: '/instrument', query: {id: id, catalogType: catalogType}});
            },
            gotoOrgan(id){
                this.$router.push({name: 'Organ', path:'/organ', query: {id:id}})
            },
            gotoTemp(){
                this.$router.push({name: "IndustryDetailTemp", path: '/industryDetailTemp', query:{id: this.id}})
            },
            gotoBuilding(){
                this.$router.push({name: 'Building', path: '/building'})
            },
            changeActive(index){
                this.active = index;
            },
            next: function(){
                this.$refs.swiper.next();
            },
            previous: function(){
                this.$refs.swiper.prev();
            },
            handleFileSuccess(response, uploadFile){
                uploadFile['attachCode'] = response.result.list[0].attachInfo.attachCode
                this.fileUrl = this.$getImage(response.result.list[0].attachInfo.attachCode);
                this.fileCodeList.push(response.result.list[0].attachInfo.attachCode);
                // this.form.enclosureCode =
            },
            onRemove(uploadFile, uploadFiles){
                var removeAttachCode = uploadFile.attachCode;
                for(let i in this.fileCodeList){
                    if(this.fileCodeList[i] == removeAttachCode){
                        this.fileCodeList.splice(i,1);
                    }
                }
            },
            beforeAvatarUpload(rawFile){
                // if(rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png'){
                //     this.ElMessage.error('图片需要jpg或png格式');
                //     return false;
                // }else
                if(rawFile.size/1024/1024 > 10){
                    this.ElMessage.error('文件需要小于10MB');
                    return false;
                }
                return true;
            },
            gotoDetail(){
                this.$router.push({name: 'Yuanqujianjie', path: '/yuanqujianjie', query: {type: 'index'}})
            },
            gotoXdfa(){ //行动方案
                this.$router.push({name: 'Xingdongfangan', path: '/xingdongfangan', query: {type: 'index'}})
            },
            gotoGslzc(){
                this.$router.push({name: 'Gongshanglianzhichi', path: '/gongshanglianzhichi', query: {type: 'index'}})
            },
            gotoGzzc(){
                this.$router.push({name: 'Guangzhouzhichi', path: '/guangzhouzhichi', query: {type: 'index'}})
            },
            gotoZczc(){
                this.$router.push({name: 'Zhengcezhichi', path: '/zhengcezhichi', query: {type: 'index'}})
            },
            gotoTzkr(){
                this.$router.push({name: 'Tizhikuorong', path: '/tizhikuorong', query: {type: 'index'}})
            },
            gotoSzjjian(){
                this.$router.push({name: 'Shuzijijian', path: '/shuzijijian', query: {type: 'index'}})
            },
            gotoQiye(index){
                let routeData = this.$router.resolve({name:'Platformcyzq', path:'/platformcyzq', query: {index: index}});
                window.open(routeData.href, '_blank');
            }
        }
    }
</script>

<style lang="less" scoped>
    @import url('../../../../public/static/css/industryDetail.less');
    .industry-img2{
        width: 1200px;
        height: 600px;
        background-image: url('/img/yshj.png');
        background-repeat:no-repeat;
        background-position:50%;
        background-color: #f7f7f7;
    }

    .industry-img3{
        width: 1200px;
        height: 600px;
        background-image: url('/img/cyjj.png');
        background-repeat:no-repeat;
        background-position:50%;
    }
    .form-group{
        justify-content: center;
        margin-bottom: 12px;
    }
    .form-control{
        width: 350px;
    }
    .error{
        margin-left: 10px;
    }
    .uploader-btn{
        margin-left: 10px;
    }
    .link-more{
        float: right;
    }
    #pic1:hover{
        cursor: pointer;
        transform: translate(-10px,-10px);

    }
    #pic1{
        transition: all 1s;
        margin-top: 30px;
    }
    .industry-img2-row-button{
        background-color: #2c7ae9;
        border-radius: 50px;
        color: white;
        border: none;
        width: 140px;
        height: 30px;
        font-size: 16px;
    }
    .industry-img2-row-button:hover{
        cursor: pointer;
    }
    .industry-img2-row-text{
        font-size: 14px;
        line-height: inherit;
        margin-top: 5px;
    }
    .industry-img2-row-main{
        width: 30%;
    }
    .industry-img2-row{
        display: flex;
        justify-content: space-between;
        padding-left: 50px;
        padding-right: 50px;
        margin-top: 30px;
    }
    .industry-img3-row{
        width: 60%;
        display: flex;
        justify-content: center;
        padding-left: 30px;
        margin-top: 20px;
    }
    .industry-img3-row-main{
        width: 50%;
    }
    .industry-img3-row-img:hover{
        cursor: pointer;
        transform: translate(-10px,-10px);
    }
    .industry-img3-row-img{
        transition: all 1s;
    }
</style>

