<template>
    <div class="content about-container manage">
        <div class="about-box">
            <h3 class="about-title">
                科技资源标准体系
            </h3>
            <p class="about-content">
                广东省科技基础条件平台中心（以下简称平台中心）经广东省机构编制委员会于2014年1月批准，由“广东省计算中心”更名而成，公益二类事业单位，直属广东省科学技术厅领导，接受国家科技基础条件平台中心的业务指导。主要任务：承担科技基础条件平台的规范标准、管理方式、运行状况和问题研究；建设运行全省科技资源共享网；承担重大项目和重大工程的基本计算任务；开展公共信息工程评测，公共计算领域方法研究；开展新兴信息技术的研究与应用推广。
            </p>
            <table class="about-table">
                <thead>
                    <tr>
                        <th>文件类型</th>
                        <th>标准名称</th>
                        <th>主要内容</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td rowspan="2">相关法规</td>
                        <td><a href="javascript:void(0);">《中华人民共和国科学技术进步法》</a></td>
                        <td>为了全面促进科学技术进步，发挥科学技术第一生产力、创新第一动力、人才第一资源的作用，促进科技成果向现实生产力转化，推动科技创新支撑和引领经济社会发展，全面建设社会主义现代化国家，根据宪法，制定本法。</td>
                    </tr>
                    <tr>
                        <td><a href="javascript:void(0);">《中华人民共和国数据安全法》</a></td>
                        <td>本法着力解决数据安全领域突出问题，主要内容包括:<br/>(1)确立数据分级分类管理以及风险评估、监测预警和应急处置等数据安全管理各项基本制度。<br/>(2)明确开展数据活动的组织、个人的数据安全保护义务，落实数据安全保护责任。<br/>(3)坚持安全与发展并重，规定支持促进数据安全与发展的措施。<br/>(4)建立保障政务数据安全和推动政务数据开放的制度措施。</td>
                    </tr>

                    <tr>
                        <td rowspan="5">相关办法、规范</td>
                        <td><a href="javascript:void(0);">《科学数据管理办法》（国办发〔2018〕17号）</a></td>
                        <td>为进一步加强和规范科学数据管理，保障科学数据安全，提高开放共享水平，更好支撑国家科技创新、经济社会发展和国家安全，根据《中华人民共和国科学技术进步法》、《中华人民共和国促进科技成果转化法》和《政务信息资源共享管理暂行办法》等规定，制定本办法。</td>
                    </tr>
                    <tr>
                        <td><a href="javascript:void(0);">《国家科技资源共享服务中心管理办法》（国科发基〔2018〕48号）</a></td>
                        <td>为深入实施创新驱动发展战略，规范管理国家科技资源共享服务平台，推进科技资源向社会开放共享，依据《国家科技创新基地优化整合方案》（国科发基〔2017〕250号），科技部、财政部共同研究制定了《国家科技资源共享服务平台管理办法》。</td>
                    </tr>
                    <tr>
                        <td><a href="javascript:void(0);">《科技基础性工作专项项目科学数据汇交管理办法》</a></td>
                        <td>科技基础性工作专项项目科学数据汇交管理办法</td>
                    </tr>
                    <tr>
                        <td><a href="javascript:void(0);">《广东省公共数据管理办法》</a></td>
                        <td>广东省首部省级层面关于公共数据管理的政府规章，《办法》有诸多制度创新，包括国内首次明确将公共服务供给方数据纳入公共数据范畴、首次在省级立法层面真正落实“一数一源”、首次明确数据交易标的等。</td>
                    </tr>
                    <tr>
                        <td><a href="javascript:void(0);">《广东省公共数据安全管理办法（征求意见稿）》</a></td>
                        <td>《广东省公共数据安全管理办法（征求意见稿）》旨在加强广东省数字政府公共数据安全管理，规范公共数据处理活动，促进数据资源有序开发利用，保护个人、组织的合法权益，维护国家主权、安全和发展利益。</td>
                    </tr>

                    <tr>
                        <td rowspan="7">国家标准</td>
                        <td><a href="javascript:void(0);">《科技计划形成的科学数据汇交 技术与管理规范》（GBT 39912-2021）</a></td>
                        <td>本标准规定了科技计划形成的科学数据汇交的原则、管理的主体与职责、主要内容及流程。</td>
                    </tr>
                    <tr>
                        <td><a href="javascript:void(0);">《科技计划形成的科学数据汇交 通用代码集》（GBT 39908-2021）</a></td>
                        <td>本标准规定了科技计划形成的科学数据汇交工作涉及的通用代码集。</td>
                    </tr>
                    <tr>
                        <td><a href="javascript:void(0);">《科技计划形成的科学数据汇交 通用数据元》（GBT 39909-2021）</a></td>
                        <td>本标准规定了科技计划形成的科学数据汇交通用数据元描述规范，数据元目录。</td>
                    </tr>
                    <tr>
                        <td><a href="javascript:void(0);">《科技平台元数据注册与管理》GB/T 30524-2014</a></td>
                        <td>本标准规定了科技平台元数据注册与管理的整体框架、组织机构和流程。</td>
                    </tr>
                    <tr>
                        <td><a href="javascript:void(0);">《科技平台资源核心元数据》GB/T 30523-2014</a></td>
                        <td>本标准规定了科技平台资源核心元数据及其描述方法,核心元数据的扩展类型与规则,以及一致性要求。</td>
                    </tr>
                    <tr>
                        <td><a href="javascript:void(0);">《科技平台一致性测试的原则与方法》GB/T 31071-2014</a></td>
                        <td>本标准规定了科技平台一致性测试的基本原则、方法和流程。</td>
                    </tr>
                    <tr>
                        <td><a href="javascript:void(0);">《科技平台服务核心元数据》GB/T 31073-2014</a></td>
                        <td>本标准规定了科技平台服务核心元数据的描述方法及扩展规则。</td>
                    </tr>

                    <tr>
                         <td rowspan="3">广东省地方标准</td>
                        <td><a href="javascript:void(0);">《大型科学仪器设施共享服务平台数据交换规范》DB44/T 2192—2019</a></td>
                        <td>本技术规范规定了大型科学仪器设施共享服务平台的总则、数据交换概述与模式、数据对接规范内容、数据传输安全。</td>
                    </tr>
                    <tr>
                        <td><a href="javascript:void(0);">《大型科学仪器设施共享服务平台运行规范》DB44/T 2193—2019</a></td>
                        <td>本标准规定了大型科学仪器设施共享服务平台的数据管理、功能分类与运行管理、服务方式与要求、信息安全和平台维护。</td>
                    </tr>
                    <tr>
                        <td><a href="javascript:void(0);">《科技平台建设规范》DB44/T 2194—2019</a></td>
                        <td>本标准规范了科技平台建设的总体要求、建设要点、建设与管理、运行与维护。 本标准适用于广东省科技平台建设。</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script setup>

</script>

<style lang="less" scoped>
@import url('../../../public/static/css/about.less');

.manage{
    padding-top: 6px;
    .about-table{
        margin-top: 36px;
        width: 900px;
        border-spacing: 0;
        border: none;
        margin-left: auto;
        margin-right: auto;
        
        
       td{
            border-right: 1px solid #bfbfbf;
            border-bottom: 1px solid #bfbfbf;
            outline: none;
            padding: 6px 12px;

            a{
                color: rgb(82, 155, 238)
            }
        }

        thead{
            background: #F1F1F1;
            th{
                border-bottom: 1px solid #bfbfbf;
            }
        }
        tbody{
            tr{
                td[rowspan]{
                    border-left: 1px solid #bfbfbf;
                    width: 210px;
                }
                td:last-child{
                    width: 310px;
                    
                }
            }
        }
    }
}
</style>
