<template>
    <div class="report-menu">
        <div class="menu-name" @click="showChange">
            {{title}}
            <img src="@static/img/arrow-up.svg" :class="{showArrow:isShow, closeArrow: !isShow}" />
        </div>
            <div class="menu-list"  :class="{showAni:isShow, closeAni: !isShow}">
                <template v-for="(item, index) in items"  :key="item.id">
                    <div class="menu-item"  @click="activeChange(index)" :class="{active:active==index}">
                        <div>
                            {{item.name}}<br/> {{item.date}}
                        </div>
                    </div>
                </template>
                
            </div>
    </div>
</template>

<script>
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    props:{
        title: {
            type: String,
            default: ''
        },
        items: {
            type: Array,
            default: []
        }
    },
    setup(){
        const active = ref(-1);
        const isShow = ref(false);
        const {proxy} = getCurrentInstance();

        function activeChange(index){
            active.value = index;
            proxy.$emit('activeChange', index);
        }

        function showChange(){
            console.log('showChange')
            isShow.value = !isShow.value;
            
        }

        return {isShow, active, activeChange, showChange};
    },
    watch:{
        active(newVal){
            if(newVal < 0){
                if(this.isShow){
                    this.isShow = false;
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
.showAni{
    animation: show 300ms linear 0s;
    animation-fill-mode: forwards;
}
.closeAni{
    animation: close 300ms linear 0s;
    animation-fill-mode: forwards;
}
.showArrow{
    animation: showArrow 300ms linear 0s;
    animation-fill-mode: forwards;
}
.closeArrow{
    animation-fill-mode: forwards;
    animation: closeArrow 300ms linear 0s;
}

@keyframes showArrow{
    0% {
        transform: rotateZ(0deg);
    }
    100% {
        transform: rotateZ(180deg);
    }
}

@keyframes closeArrow{
    0% {
        transform: rotateZ(180deg);
    }
    100% {
        transform: rotateZ(0deg);
    }
}

@keyframes show{
    0% {
        height: 0;
        display: block;
    }
    100% {
        height: 244px;
    }
}

@keyframes close{
    0% {
        height: 244px;
    }
    100% {
        height: 0;
        display: none;
    }
}

.report-menu {
    margin-bottom: 14px;
  .menu-name {
    background: #f2f2f2;
    text-align: center;
    cursor: pointer;
    line-height: 48px;
    
    img {
      float: right;
      margin-right: 15px;
      margin-top: 12px;
    }
  }
  .menu-list {
    max-height: 244px;
    overflow-y: auto;
    overflow-x: hidden;
    border-left: 1px solid #f2f2f2;
    border-right: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    .menu-item {
      font-family: SourceHanSansCN-Regular;
      font-size: 16px;
      line-height: 16px;
      color: rgba(0, 0, 0, 0.5);
      display: flex;
      justify-content: center;
      padding-left: 26px;
      padding-right: 20px;
      border-bottom:  1px solid #f2f2f2;
      padding-top: 10px;
      padding-bottom: 6px;
      cursor: pointer;
      min-height: 36px;
    }
    .menu-item:last-child{
        border-bottom: none;
    }
    .menu-item.active {
      color: #007eec;
    }
  }

}
</style>
