<template>
    <el-pagination
        class="pager"
        background
        :layout="layout"
        :current-page="currentPage"
        @size-change="handleSizeChange"
        :page-count="totalPage"
        :total="totalRecord"
        @current-change="pageChange"></el-pagination>
</template>
<script>
import {ElPagination} from 'element-plus'
import {ref, watch} from 'vue'
export default {
    components: {ElPagination},
    props:{
        totalPage:{
            type: Number,
            default: 1
        },
        currentPage:{
            type: Number,
            default: 1
        },
        totalRecord:{
            type: Number,
            default: 0
        },
        layout:{
            type: String,
            default: 'total, sizes, prev, pager, next'
        }
    },

    methods:{
        handleSizeChange(val) {
            this.$emit('sizeChange', val);
        },
        pageChange: function(index){
            this.$emit('pageChange', index);
            console.log(this.totalPage)
            return true;
        }
    },
    
}
</script>
<style lang="less" scoped>
.pager{
    display: flex;
    justify-content: center;
}
</style>

