<template>
  <div class="user-info">
    <div>
<!--      <el-button type="danger" @click="showDelete" v-if="!showDeleteAll">批量删除</el-button>-->
<!--      <el-button type="danger" @click="deleteAll" v-else>删除选中</el-button>-->
    </div>
    <div class="res-list" style="margin-top:24px;">
      <label class="res-item" @click="gotoLabLib('person')" :style="{'background-image': 'url(\'/static/img/res/人才库.svg\')'}">
        <div class="item-name">人才库</div>
        <div class="item-en">Talent Pool</div>
        <el-checkbox type="checkbox" v-if="showDeleteAll" value="1" @click="deleteCheck" class="checkbox"/>
      </label>


      <label :style="{'background-image': 'url(\'/static/img/res/项目库.svg\')'}" class="res-item"
             @click="gotoLabLib('project' )">
        <div class="item-name">项目库</div>
        <div class="item-en">Project Library</div>
        <el-checkbox type="checkbox" v-if="showDeleteAll" value="2" @click="deleteCheck" class="checkbox"/>
      </label>
      <label :style="{'background-image': 'url(\'/static/img/res/奖项库.svg\')'}" class="res-item"
             @click="gotoLabLib('awards' )">
        <div class="item-name">奖项库</div>
        <div class="item-en">Awards Library</div>
        <el-checkbox type="checkbox" v-if="showDeleteAll" value="2" @click="deleteCheck" class="checkbox"/>
      </label>
      <label :style="{'background-image': 'url(\'/static/img/res/论文库.svg\')'}" class="res-item"
             @click="gotoLabLib('paper')">
        <div class="item-name">论文库</div>
        <div class="item-en">Thesis Library</div>
        <el-checkbox type="checkbox" v-if="showDeleteAll" value="2" @click="deleteCheck" class="checkbox"/>
      </label>
      <label :style="{'background-image': 'url(\'/static/img/res/论著库.svg\')'}" class="res-item"
             @click="gotoLabLib('works')">
        <div class="item-name">论著库</div>
        <div class="item-en">Treatise Library</div>
        <el-checkbox type="checkbox" v-if="showDeleteAll" value="2" @click="deleteCheck" class="checkbox"/>
      </label>
      <label :style="{'background-image': 'url(\'/static/img/res/专利库.svg\')'}" class="res-item"
             @click="gotoLabLib('patent')">
        <div class="item-name">专利库</div>
        <div class="item-en">Patent Library</div>
        <el-checkbox type="checkbox" v-if="showDeleteAll" value="2" @click="deleteCheck" class="checkbox"/>
      </label>
      <label :style="{'background-image': 'url(\'/static/img/res/标准库.svg\')'}" class="res-item"
             @click="gotoLabLib('standard')">
        <div class="item-name">标准库</div>
        <div class="item-en">Standard Library</div>
        <el-checkbox type="checkbox" v-if="showDeleteAll" value="2" @click="deleteCheck" class="checkbox"/>
      </label>
      <label :style="{'background-image': 'url(\'/static/img/res/软著库.svg\')'}" class="res-item"
             @click="gotoLabLib('softness')">
        <div class="item-name">软著库</div>
        <div class="item-en">Soft Copy Library</div>
        <el-checkbox type="checkbox" v-if="showDeleteAll" value="2" @click="deleteCheck" class="checkbox"/>
      </label>
      <label class="res-item" @click="gotoLabLib('research')" :style="{'background-image': 'url(\'/static/img/res/开放课题库.svg\')'}">
        <div class="item-name">开放课题库</div>
        <div class="item-en">Open Subject Library</div>
        <el-checkbox type="checkbox" v-if="showDeleteAll" value="2" @click="deleteCheck" class="checkbox"/>
      </label>
      <label class="res-item" @click="gotoLabLib('meeting')" :style="{'background-image': 'url(\'/static/img/res/大型学术会议库.svg\')'}">
        <div class="item-name">大型学术会议库</div>
        <div class="item-en">Large Academic Conference Library</div>
        <el-checkbox type="checkbox" v-if="showDeleteAll" value="2" @click="deleteCheck" class="checkbox"/>
      </label>
      <label class="res-item" @click="gotoLabLib('medicine')" :style="{'background-image': 'url(\'/static/img/res/新药库.svg\')'}">
        <div class="item-name">新药库</div>
        <div class="item-en">New Drug Library</div>
        <el-checkbox type="checkbox" v-if="showDeleteAll" value="2" @click="deleteCheck" class="checkbox"/>
      </label>
      <label class="res-item" @click="gotoLabLib('file')" :style="{'background-image': 'url(\'/static/img/res/重要附件.svg\')'}">
        <div class="item-name">备案库</div>
        <div class="item-en">Important Attachment Library</div>
        <el-checkbox type="checkbox" v-if="showDeleteAll" value="2" @click="deleteCheck" class="checkbox"/>
      </label>
      
      <label class="res-item" @click="gotoLabLib('committee')" :style="{'background-image': 'url(\'/static/img/res/学术委员会成员库.svg\')'}">
        <div class="item-name">学术委员会成员库</div>
        <div class="item-en">Important Attachment Library</div>
        <el-checkbox type="checkbox" v-if="showDeleteAll" value="2" @click="deleteCheck" class="checkbox"/>
      </label>
    </div>

  </div>
</template>

<script>
import {ElButton, ElCheckbox, ElDialog, ElIcon, ElResult} from 'element-plus';
import {Plus} from '@element-plus/icons-vue'
import {getCurrentInstance, ref} from 'vue';

export default {
  components: {ElButton, ElDialog, ElResult, ElIcon, Plus, ElCheckbox},
  setup() {
    const {proxy} = getCurrentInstance();
    const showDeleteAll = ref(false);
    const showAddDialog = ref(false);
    const deleteSelections = ref([]);
    const addSelections = ref([]);

    function showDelete() {
      showDeleteAll.value = true;
    }

    function deleteAll() {
      showDeleteAll.value = false;
      deleteSelections.value = [];
    }
      

      function gotoLabLib(libTypeStr) {//跳转各资源库
          // if (libTypeStr != 'person' && libTypeStr != 'project') {
          //     return proxy.$upgrading();
          // }

          if (libTypeStr == 'person') {//人才库
              proxy.$router.push({name: 'LibPerson', path: '/libPerson'})
          }else if (libTypeStr == 'project') {//项目库
              proxy.$router.push({name: 'LibProject', path: '/libProject'})
          }else if (libTypeStr == 'awards') {//奖项库
              proxy.$router.push({name: 'LibAwards', path: '/libAwards'})
          }else if (libTypeStr == 'paper') {//论文库
              proxy.$router.push({name: 'LibPaper', path: '/libPaper'})
          }else if (libTypeStr == 'works') {//论著库
              proxy.$router.push({name: 'LibWorks', path: '/libWorks'})
          }else if (libTypeStr == 'patent') {//专利库
              proxy.$router.push({name: 'LibPatent', path: '/libPatent'})
          }else if (libTypeStr == 'standard') {//标准库
              proxy.$router.push({name: 'LibStandard', path: '/libStandard'})
          }else if (libTypeStr == 'softness') {//软著库
              proxy.$router.push({name: 'LibSoftness', path: '/libSoftness'})
          }else if (libTypeStr == 'research') {//开放课题库
              proxy.$router.push({name: 'LibResearch', path: '/libResearch'})
          }else if (libTypeStr == 'meeting') {//大型学术会议库
              proxy.$router.push({name: 'LibMeeting', path: '/libMeeting'})
          }else if (libTypeStr == 'medicine') {//新药库
              proxy.$router.push({name: 'LibMedicine', path: '/libMedicine'})
          }else if (libTypeStr == 'file') {//备案库
              proxy.$router.push({name: 'LibFile', path: '/libFile'})
          }else if (libTypeStr == 'committee') {//学术委员会成员库
              proxy.$router.push({name: 'LibCommittee', path: '/libCommittee'})
          }

      }

    function saveAddCheck() {
      showAddDialog.value = false
      addSelections.value = [];
    }

    function deleteCheck(event) {
      console.log(event);
      let value = event.target.value;
      let index = deleteSelections.value.indexOf(value);
      if (index >= 0) {
        deleteSelections.value.splice(index, 1);
      } else {
        deleteSelections.value.push(index);
      }
    }

    function addCheck(event) {
      console.log(event);
      let value = event.target.value;
      let index = addSelections.value.indexOf(value);
      if (index >= 0) {
        addSelections.value.splice(index, 1);
      } else {
        addSelections.value.push(index);
      }
    }

    return {
      showDeleteAll, deleteAll, showDelete, showAddDialog, gotoLabLib,
      addCheck, deleteCheck, saveAddCheck
    }
  }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

.res-list {
  display: flex;
  flex-wrap: wrap;

  .res-item {
    cursor: pointer;
    width: 290px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #ffffff;
    margin-right: 12px;
    margin-bottom: 12px;
    position: relative;

    .item-name {
      font-family: SourceHanSansCN-Medium;
      font-size: 36px;
      line-height: 36px;
      margin-left: 24px;
    }

    .item-en {
      font-family: SourceHanSansCN-Normal;
      font-size: 12px;
      line-height: 12px;
      margin-top: 12px;
      margin-left: 24px;
    }

    .checkbox {
      position: absolute;
      right: 12px;
      top: 0px;
    }
  }

  .res-item:hover {
    filter: brightness(0.9);
  }

  .res-item.selected {
    filter: grayscale(1);
  }
}

.add-res.res-item {
  flex-direction: row;
  align-items: center;
  border: 3px dashed #E2E2E2;
  width: 284px;
  height: 94px;
  color: #E2E2E2;
  font-family: SourceHanSansCN-Regular;
  font-size: 26px;
  color: #C4C4C4;

  .el-icon {
    border-radius: 26px;
    border: 1px solid #C4C4C4;
    margin-right: 10px;
  }
}
</style>

