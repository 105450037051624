<template>
    <div class="user-info">
        <el-tabs type="border-card">
            <el-tab-pane label="黄页编辑">
                <Form class="form" ref="target" v-slot="{ errors}">
                    <div class="form-group">
                        <label class="control-label col-1">标题名称<span class="required">*</span></label>
                        <div class="col-8">
                            <Field type="text" name="name" :rules="checkRequired" class="form-control" v-model="form.name" placeholder="请输入标题名称"/>
                            <div class="error" >{{errors.name}}</div>
                        </div>
                    </div>
                    <div class="form-group">
                        <label class="control-label col-1">LOGO上传</label>
                        <div class="col-8">
                            <el-upload
                                class="editor"
                                :action="uploadUrl"
                                list-type="picture-card"
                                :on-success="handleUploadSuccess"
                                :before-upload="beforeAvatarUpload"
                                :file-list="fileList"
                                :limit="1"
                            >
                                <el-icon><Plus /></el-icon>
                            </el-upload>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label col-1">简介</label>
                        <div class="col-8">
                             <UEditor id="editorIntro"  class="editor" ref="editorIntro"></UEditor>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label col-1">资源简介</label>
                        <div class="col-8">
                             <UEditor id="editorResIntro"  class="editor" ref="editorResIntro"></UEditor>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label col-1">资源体系</label>
                        <div class="col-8">
                             <UEditor id="editorSystem"  class="editor" ref="editorSystem"></UEditor>
                        </div>
                    </div>

                    <div class="form-group">
                        <label class="control-label col-1">服务方式</label>
                        <div class="col-8">
                             <UEditor id="editorServe" class="editor" ref="editorServe"></UEditor>
                        </div>
                    </div>

                
                    <div class="form-group">
                        <label class="control-label col-1">资源处理</label>
                        <div class="col-11">
                            <el-table :data="tablePager.list" style="width: 100%;margin-left: 10px;">
                                <el-table-column type="selection"  width="55"/>
                                <el-table-column label="缩略图" >
                                    <template #default="scope">
                                        <img :src="toImage(scope.row.img)" class="table-img"/>
                                    </template>
                                    </el-table-column>
                                <el-table-column prop="title" label="标题" />
                                <el-table-column prop="date" label="更新时间" />
                                <el-table-column  label="操作" width="80">
                                   <template #default="scope">
                                        <el-popover placement="bottom" :width="50" trigger="click">
                                            <template #reference>
                                                <el-icon class="link-more"><MoreFilled /></el-icon>
                                            </template>
                                            <div class="option-list">
                                                <a href="javascript:void(0)" class="primary" @click="handleUpdate(scope.$index)">编辑</a>
                                                <a href="javascript:void(0)" class="danger" @click="handleDelete(scope.$index)">删除</a>
                                            </div>
                                        </el-popover>
                                        

                                        
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                    <div class="form-group">
                        <div class="col-1">

                        </div>
                        <div class="col-8" style="padding-left: 10px;">
                            <el-button type="primary" v-loading="loading" @click="handleCommit">保存</el-button >
                            <el-button type="default" @click="back">返回</el-button>
                        </div>
                    </div>
                </Form>
            </el-tab-pane>
            <el-tab-pane label="典型案例">
                <div class="collect-head">
                    <SearchBar @onSearch="search"></SearchBar>
                    <div class="select" >
                        <el-button type="danger" class="table-btns" @click="handleDeleteAll()">批量删除</el-button>
                        <el-button type="primary" class="table-btns" @click="addClick()">新增</el-button>
                    </div>
                </div>
                <el-table :data="tablePagerCase.list" style="width: 100%;" @selection-change="handleSelectionChange">
                                <el-table-column type="selection"  width="55"/>
                                <el-table-column label="缩略图" >
                                    <template #default="scope">
                                        <img :src="toImage(scope.row.img)" class="table-img"/>
                                    </template>
                                    </el-table-column>
                                <el-table-column prop="title" label="标题" />
                                <el-table-column prop="type" label="文章类型" />
                                <el-table-column prop="date" label="更新时间" />
                                <el-table-column prop="status" label="状态" :formatter="statusFormatter" />
                                <el-table-column  label="操作" width="80">
                                   <template #default="scope">
                                        <el-popover placement="bottom" :width="50" trigger="click">
                                            <template #reference>
                                                <el-icon class="link-more"><MoreFilled /></el-icon>
                                            </template>
                                            <div class="option-list">
                                                <a href="javascript:void(0)" class="primary" @click="handleCaseUpdate(scope.$index)">编辑</a>
                                                <a href="javascript:void(0)" class="danger" @click="handleCaseDelete(scope.$index)">删除</a>
                                            </div>
                                        </el-popover>
                                        

                                        
                                    </template>
                                </el-table-column>
                            </el-table>

                            <div class="bottom-btns" style="margin-left: 15px;">
                                <el-button type="default" @click="back">返回</el-button>
                                <div></div>
                            </div>
            </el-tab-pane>
        </el-tabs>
        
      
    </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import UEditor from '../../../components/UEditor'
import { Form, Field } from 'vee-validate';
import { ElButton, ElTable, ElTableColumn, ElDialog, ElResult, ElTabs, ElTabPane,ElUpload, ElIcon, ElPopover, } from 'element-plus';
import { Plus, MoreFilled } from '@element-plus/icons-vue'
import {ref, reactive, onMounted, computed, getCurrentInstance} from 'vue';
export default {
    components:{ SearchBar, Form, Field, ElButton, ElTable, ElTableColumn, ElDialog, ElResult, ElTabs, ElTabPane, ElIcon,ElUpload, UEditor, Plus, MoreFilled, ElPopover },
    setup(){
        const {proxy} = getCurrentInstance();
        const form = reactive({
            name:''
        });
        const fileList = ref([]);
        const target = ref(null)
        const loading = ref(false);

        const showDialog = ref(false)

        const tablePager = ref({
            total: 1,
            page: 1,
            list: [{id:'1', title:'2000-2012年全国1km空间分辨率气温和降水栅格数据集', img:'', name:'生态数据中心', dataType:"农业",  date: '2021-12-12'}]
        })

        const checkRequired = value => {
            if(!value){
                return '请输入内容';
            }else{
                return true;
            }
        }


        const handleCommit = () => {
            console.log('handleCommit');
            showDialog.value = true

            target.value.validate().then(valid=>{
                if (valid) {
                    loading.value = true;
                    const url = '/hf/base/a/xxx';
                    proxy.$http.post(url, {
                        name: form.name,
                        intro: proxy.$refs.editorIntro.getContent(),
                        resIntro: proxy.$refs.editorResIntro.getContent(),
                        system: proxy.$refs.editorSystem.getContent(),
                        serve: proxy.$refs.eidtorServe.getContent()
                    }).then(res=>{
                        loading.value = false
                    }).catch(err=>{
                        loading.value = false
                    });
                    return true;
                }else{
                    return false;
                }
            }).catch(err=>{
                return false;
            })
        }

        function loadData(){

        }

        function handleDelete(index){

        }

        function handleUpdate(index){

        }




        const uploadUrl = computed(()=>{
            const url =  proxy.indexUrl + '/hf/base/a/fileManage/fileUpload';
            return url;
        });

        function toImage(id){
            return proxy.$getImage(id);
        };

        function handleUploadSuccess(response, uploadFile){
            let attachCode = response.result.list[0].attachInfo.attachCode;
            uploadFile['attachCode'] = attachCode
        }
    
        function beforeAvatarUpload(rawFile){
            if(rawFile.type !== 'image/jpeg' && rawFile.type !== 'image/png'){
                proxy.ElMessage.error('图片需要jpg或png格式');
                return false;
            }else if(rawFile.size/1024/1024 > 2){
                proxy.ElMessage.error('图片需要小于2MB');
                return false;
            }
            return true;
        }

        const tablePagerCase = ref({
            total: 1,
            page: 1,
            list: [{id:'1', title:'2000-2012年全国1km空间分辨率气温和降水栅格数据集', img:'', name:'生态数据中心', type:"农业",  
            status: 1,
            date: '2021-12-12'}]
        })
        const multipleSelection = ref([])
        function handleSelectionChange(selections){
            multipleSelection.value = selections;
        }

        function loadDataCase(){

        }

        function handleCaseDelete(index){

        }

        function handleCaseUpdate(index){

        }

        function handleDeleteAll(){

        }

        function search(value){

        }

        function statusFormatter(row, column, cellValue, index){
            console.log(cellValue)
            if(cellValue == 1){
                return '审核通过';
            }else if(cellValue == 0){
                return '待审核';
            }else if(cellValue == -1){
                return '审核拒绝';
            }
        }

        onMounted(()=>{
            loadData();
        })

        return {target, form, loading, handleCommit, checkRequired, tablePager, handleDelete, handleUpdate,
            fileList, uploadUrl, toImage, handleUploadSuccess, beforeAvatarUpload,
            tablePagerCase, loadDataCase, handleCaseDelete, handleCaseUpdate, handleSelectionChange, handleDeleteAll, search, statusFormatter
            }
    },
    methods:{
        back(){
            this.$router.back();
        },
        backToResDataProve(){
            this.$router.replace({name: 'ResDataProve', path: 'resDataProve'})
        }
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';
.next-btn{
    margin: 0 auto;
}
.form{
    width: 100%;
    margin: 0 auto;
}
.form-group{
    margin-bottom: 26px;
    .form-control{
        width: 350px;
    }
}
.editor{
    margin-left: 10px;
}
.table-img{
    width: 80px;
    height: 48px;
}
</style>

