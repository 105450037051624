const cities = [
    {value:'广州', name:'广州市',code: '440100'},
    {value:'韶关', name:'韶关市',code: '440200'},
    {value:'深圳', name:'深圳市',code: '440300'},
    {value:'珠海', name:'珠海市',code: '440400'},
    {value:'汕头', name:'汕头市',code: '440500'},
    {value:'佛山', name:'佛山市',code: '440600'},
    {value:'江门', name:'江门市',code: '440700'},
    {value:'湛江', name:'湛江市',code: '440800'},
    {value:'茂名', name:'茂名市',code: '440900'},
    {value:'肇庆', name:'肇庆市',code: '441200'},
    {value:'惠州', name:'惠州市',code: '441300'},
    {value:'梅州', name:'梅州市',code: '441400'},
    {value:'汕尾', name:'汕尾市',code: '441500'},
    {value:'河源', name:'河源市',code: '441600'},
    {value:'阳江', name:'阳江市',code: '441700'},
    {value:'清远', name:'清远市',code: '441800'},
    {value:'东莞', name:'东莞市',code: '441900'},
    {value:'中山', name:'中山市',code: '442000'},
    {value:'潮州', name:'潮州市',code: '445100'},
    {value:'揭阳', name:'揭阳市',code: '445200'},
    {value:'云浮', name:'云浮市',code: '445300'},
]
module.exports = {
    cityList: cities
}