<template>
    <div class="user-info">
        <el-tabs type="border-card">
            <el-tab-pane v-for="item in list" :label="item.name">
                <Table :id="item.id"></Table>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import {ElTabs, ElTabPane} from 'element-plus';
import {ref, onMounted, getCurrentInstance} from 'vue';
import Table from '../../../components/PlatformInfoTable'
export default {
    components: {ElTabs, ElTabPane, Table},
    setup(){
        const {proxy} = getCurrentInstance();

        const list = ref([
            {name:'实验室', id:'12314'},
            {name:'科学数据中心', id:'12314'},
            {name:'其他', id:'12314'},
        ])

        return {list}
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';

</style>

