<template>
    <div class="user-info">
        <div class="collect-head">
            <SearchBar @onSearch="search"></SearchBar>
        </div>
        <el-table :data="tablePager.list" style="width: 100%" @selection-change="handleSelectionChange">
            <el-table-column type="index" label="序号" width="80"/>
            <el-table-column prop="account" label="账号" />
            <el-table-column prop="platformName" label="依托单位名称" />
            <el-table-column prop="platformType" label="依托单位名称" />
            <el-table-column prop="deptName" label="依托单位名称" />
            <el-table-column prop="phone" label="联系电话" />
            <el-table-column prop="email" label="邮箱" />
            <el-table-column prop="address" label="地址" />
            <el-table-column prop="status" label="审核状态" :formatter="statusFormatter"/>
            <el-table-column  label="账号状态" >
                <template #default="scope">
                    <el-switch
                        v-model="scope.row.open"
                        size="large"
                        inline-prompt
                        active-text="启用"
                        inactive-text="停用"
                    />
                </template>
            </el-table-column>
            <el-table-column  label="操作" width="80">
                <template #default="scope">
                    <a href="javascript:void(0)"  class="primary" @click="handleView(scope.$index)">查看</a>
                </template>
            </el-table-column>
        </el-table>
        <Pager :totalPage="tablePager.totalPage" :currentPage="currentPage" @pageChange="pageChange" class="pager"/>
    </div>
</template>

<script>
import SearchBar from '../../../components/common/SearchBar'
import {ElTable, ElTableColumn, ElButton, ElSwitch} from 'element-plus';
import Pager from '../../../components/common/Pager'
import {ref, onMounted, getCurrentInstance} from 'vue';
export default {
    components: {SearchBar, ElTable, ElTableColumn, ElButton, Pager, ElSwitch},
    setup(){
        const {proxy} = getCurrentInstance();
        const currentPage = ref(1);
        const loading = ref(false);
        const searchKey = ref('');
        const tablePager = ref({
            total: 1,
            page: 1,
            list: [{id:'1', account:'YUN01',  platformName: '广东省显示材料与技术重点实验室', 
            deptName:'中山大学',
            platformType: '广东省重点实验室',
            parentDept:'广东省教育局',phone:'020-25421231', 
            email:'12345@126.com', address:'广州市天河区燕岭路231号', updateTime:'2022-01-21 12:13:12',
            status: 1, open: 0}]
        })

        function loadData(){
            loading.value = true;
            const url = '';
            proxy.$http.post(url, {limit: 10, offset: (currentPage.value-1)*10, searchKey: searchKey.value})
            .then(res=>{
                loading.value = false
                if(res.data.state == 0){
                    tablePager.value = res.data.result;
                }
            }).catch(err=>{
                loading.value = false
            })
        }

        function pageChange(index){
            currentPage.value = index;
            loadData();
        }

        function search(val){
            searchKey.value = val;
            pageChange(1);
        }

        const multipleSelection = ref([])
        function handleSelectionChange(selections){
            multipleSelection.value = selections;
        }


        function indexFormatter(row, column, cellValue, index){
            return row+1+'';
        }

        function handleView(index){
            console.log(index)
        }

        function statusFormatter(row, column, cellValue, index){
            console.log(cellValue)
            if(cellValue == 1){
                return '审核通过';
            }else if(cellValue == 0){
                return '待审核';
            }else if(cellValue == -1){
                return '审核拒绝';
            }
        }

        

        onMounted(()=>{
            // loadData();
        })

        return {tablePager,pageChange, search,currentPage, multipleSelection, indexFormatter, 
        handleView, handleSelectionChange, statusFormatter}
    }
}
</script>

<style lang="less" scoped>
@import 'public/static/css/userBaseInfo.less';
</style>

