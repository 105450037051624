<template>
    <div>
        <div class="index-content">
            <div class="content content-nav">
                <Nav></Nav>
                <Banner :imageList="bannerList" :height="259" :width="897" v-loading="bannerLoading"></Banner>
            </div>
            <div class="content content-topic">
                <Topic></Topic>
                <div class="content-news" v-loading="newsLoading">
                    <NewsBox :items="newsItems" :breads="breads"></NewsBox>
                </div>
            </div>
            <Technology></Technology>
        </div>
        <Platform></Platform>
    </div>
</template>

<script>
import Platform from '../components/index/Platform'
import Technology from '../components/index/Technology'
import Topic from '../components/index/Topic'
import NewsBox from '../components/index/NewsBox'
import Nav from '../components/index/Nav'
import Banner from '../components/common/Banner'

import {ref, toRefs, reactive, getCurrentInstance, onMounted} from 'vue'

export default {
    setup(props, context){
        const {proxy} = getCurrentInstance();
        const bannerList = ref([]);
        const newsItems = ref([]);
        const breads = ref([{text:'首页', name:'Index', path:'/index'}])
        const bannerLoading = ref(false)
        const newsLoading = ref(false)

        function fetchBannerList(){ //读取滚动大图
            bannerLoading.value = true;
            var url = "/core/cms/a/webAdInfo/list?noPage=1&typeCode=WEB_HOME_ROLL_IMG";
         
            proxy.$http.post(url).then(res=>{
                bannerLoading.value = false
                if(res.data.state == 0){
                    var list = [];
                    for(let i = 0 ; i < res.data.result.length; i++){
                        const imageLink = proxy.$getImage(res.data.result[i].imgCode);
                        list.push({src: imageLink, url: res.data.result[i].linkUrl});
                    }

                    bannerList.value = list;
                }
            }).catch(err=>{
                bannerLoading.value = false
            })
        }

        const tempItems = ref([]);
        async function getArticleList(typeCode, newsNum){   //获取文章列表
            newsLoading.value = true
            const url = '/core/cms/a/articleContent/list?limit=' + newsNum + '&typeCode=' + typeCode + '&apprStatus=1,99&publishStatus=1&timeField=publish_time';
            await proxy.$http.post(url).then(res=>{
                console.log(res)
                newsLoading.value = false
                if(res.data.state == 0){
                    tempItems.value = res.data.result;
                    
                    // newsItems.value.push(tempItems.value);

                    // console.log(tempItems);
                }
            }).catch(err=>{
                newsLoading.value = false
            })
        }

        async function loadNewsItem(){  //使用同步方法调用文章列表
            //显示文章

            await getArticleList('WEB-NOTICE', 6);   //通知公告
            let noticeNewsItem = {};
            noticeNewsItem.id = "WEB-NOTICE";
            noticeNewsItem.name = "通知公告";
            noticeNewsItem.content = tempItems.value;

            await getArticleList('WEB-OPENSOURCE', 6);   //开放课题
            let opensourceNewsItem = {};
            opensourceNewsItem.id = "WEB-OPENSOURCE";
            opensourceNewsItem.name = "开放课题";
            opensourceNewsItem.content = tempItems.value;

            await getArticleList('WEB-DOWNLOAD-CENTER', 6);  //下载中心
            let dCenterNewsItem = {};
            dCenterNewsItem.id = "WEB-DOWNLOAD-CENTER";
            dCenterNewsItem.name = "下载中心";
            dCenterNewsItem.content = tempItems.value;


            // await getArticleList(''); //最新发布
            let lastNewsItem = {};
            lastNewsItem.id = "LAST_NEWS";
            lastNewsItem.name = "最新发布";
            // lastNewsItem.content = tempItems.value;

            //从通知公告、开放课题、下载中心 中一类选取2条最新记录，然后再进行时间排序
            let lastNewsItemList = [];
            if(noticeNewsItem.content.list.length > 2){
                lastNewsItemList = lastNewsItemList.concat(noticeNewsItem.content.list.slice(0, 2));
            }else{
                lastNewsItemList = lastNewsItemList.concat(noticeNewsItem.content.list);
            }
            if(opensourceNewsItem.content.list.length > 2){
                lastNewsItemList = lastNewsItemList.concat(opensourceNewsItem.content.list.slice(0, 2));
            }else{
                lastNewsItemList = lastNewsItemList.concat(opensourceNewsItem.content.list);
            }
            if(dCenterNewsItem.content.list.length > 2){
                lastNewsItemList = lastNewsItemList.concat(dCenterNewsItem.content.list.slice(0, 2));
            }else{
                lastNewsItemList = lastNewsItemList.concat(dCenterNewsItem.content.list);
            }
            //按照时间排序
            lastNewsItemList.sort((a, b) => {
                if(a.createTime < b.createTime){
                    return 1;   //b排前面
                }else{
                    return -1;  //a排前面
                }
            });
            

            lastNewsItem.content = {total: lastNewsItemList.length, list: lastNewsItemList};
            

            newsItems.value.push(lastNewsItem);
            newsItems.value.push(noticeNewsItem);
            newsItems.value.push(opensourceNewsItem);
            newsItems.value.push(dCenterNewsItem);

        }

        onMounted(()=>{
            //显示BAR条内容
            fetchBannerList();

            //加载首页文章
            loadNewsItem();
        })

        
        return {
            bannerLoading, bannerList, newsItems, breads, newsLoading
        }
    },
    components: {Banner, Nav, NewsBox, Topic, Technology, Platform, },
    methods:{
        
    }
    
    
}
</script>

<style lang="less" scoped>
.index-content{
    background: #f6f6f6;
    padding-top: 16px;
}
.content-nav, .content-topic{
    margin-bottom: 30px;
}
.content-topic{
    margin-bottom: 33px;
    display: flex;
}
.content-news{
    width: 594px;
    height: 343px;
    justify-content: space-between;
}
.content-nav{
    display: flex;
    justify-content: space-between;
}
</style>

